import { useNavigate } from 'react-router';
import { tss } from 'tss-react/mui';

import { useAuthStore } from '@sticky/auth';
import { ActionButton } from '@sticky/components';
import { t } from '@sticky/i18n';

import { AuthenticationLoginButton } from '../../features/authentication';
import { Routes } from '../../routes/routes';

const useStyles = tss.create(({ theme }) => ({
  footerButton: {
    position: 'fixed',
    display: 'flex',
    height: theme.typography.pxToRem(80),
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    background: theme.app.colors.white,
    padding: theme.spacing(2),
    zIndex: 1000,
    boxShadow: '0px -3px 0.4em rgba(0, 0, 0, 0.2)',
  },
}));

export const FooterButton = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { status } = useAuthStore();

  return (
    <div className={classes.footerButton}>
      {status === 'authenticated' ? (
        <ActionButton
          fullWidth
          onClick={() => {
            navigate(Routes.getPathById('customer/my-travels'));
          }}
        >
          {t('auth.account.access')}
        </ActionButton>
      ) : (
        <AuthenticationLoginButton variant="outlined" fullWidth>
          {t('auth.login.button')}
        </AuthenticationLoginButton>
      )}
    </div>
  );
};
