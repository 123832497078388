import type { ReactNode, RefObject } from 'react';
import { Card } from '@mui/material';
import Radio from '@mui/material/Radio';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    app: { colors },
  } = theme;
  return {
    card: {
      borderRadius: theme.spacing(2),
      border: `solid 1px ${colors.secondary}`,
      width: '100%',
      cursor: 'pointer',
      display: 'grid',
      gridTemplateAreas: `"title title"
                          "radio content"`,
      gridTemplateColumns: `min-content 1fr`,
      justifyContent: 'space-between',
      '&.MuiCard-root': {
        padding: `${theme.spacing(2)}`,
      },
      transition: theme.transitions.create(['border-color']),
      '&.selected': {
        borderColor: colors.primary,
        boxShadow: `0 0 0 1px ${colors.primary}`,
      },
      '& .Mui-focusVisible': {
        outline: 'dashed',
        outlineOffset: '-4px',
        outlineWidth: 'thin',
        outlineColor: colors.primary,
      },
    },
    title: {
      gridArea: 'title',
    },
    radio: {
      gridArea: 'radio',
      justifySelf: 'center',
      alignSelf: 'center',
      paddingRight: theme.spacing(2),
    },
    radioWithTitle: {
      gridRowStart: 'title',
      gridRowEnd: 'radio',
      gridColumnStart: 'title',
      gridColumnEnd: 'radio',
      justifySelf: 'center',
      alignSelf: 'center',
      // paddingRight: theme.spacing(2),
    },
    content: {
      gridArea: 'content',
      justifySelf: 'center',
      alignSelf: 'center',
    },
  };
});

interface RadioCardProps {
  children?: ReactNode;
  value: string;
  title?: ReactNode | string;
  label?: string;
  selected?: string;
  tabIndex?: number;
  className?: string;
  'aria-label'?: string;
  slotProps?: {
    content?: {
      className?: string;
    };
  };

  onChange(value?: string | null): void;

  inputRef?: RefObject<HTMLElement>;
}

export const RadioCard = (props: RadioCardProps) => {
  const { classes } = useStyles();
  const {
    title,
    value,
    onChange,
    children,
    selected,
    tabIndex,
    inputRef,
    slotProps,
    className,
  } = props;

  return (
    <Card
      className={[className, classes.card, selected === value && 'selected']
        .filter(Boolean)
        .join(' ')}
      onClick={() => onChange(value)}
      tabIndex={-1}
    >
      {title && <div className={classes.title}>{title}</div>}
      <Radio
        className={title ? classes.radioWithTitle : classes.radio}
        inputProps={{ 'aria-label': props['aria-label'] }}
        value={value}
        checked={selected === value}
        tabIndex={tabIndex}
        inputRef={inputRef}
      />
      <div
        className={[slotProps?.content?.className, classes.content]
          .filter(Boolean)
          .join(' ')}
      >
        {children}
      </div>
    </Card>
  );
};
