import { removeUndefined } from '@sticky/helpers';

import { Routes } from '../../../routes/routes';
import type { IHelpDeskInput } from '../models/help-desk';

export const getHelpDeskUrl = (helpDeskInput: IHelpDeskInput) => {
  const p = removeUndefined({
    subreasonCode: helpDeskInput.subreasonCode,
    reasonCode: helpDeskInput.reasonCode,
    detailCode: helpDeskInput.detailCode,
    productType: helpDeskInput.productType,
  });
  const qs = new URLSearchParams(p);

  return `${Routes.getPathById('support')}?${qs.toString()}`;
};
