import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    // Hauteur du navigation bottom
    paddingBottom: 80,
    [theme.breakpoints.up('breakPointDesktop')]: {
      paddingBottom: 0,
    },
  },
}));

export const ContentLayout = ({ children, className, ...rest }: BoxProps) => {
  const { classes } = useStyles();

  return (
    <Box
      className={[className, classes.root].filter(Boolean).join(' ')}
      {...rest}
    >
      {children}
    </Box>
  );
};
