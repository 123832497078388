export type Offer = {
  descriptions: string[];
  isDark: boolean;
  isBigger?: boolean;
  title: string;
  chooseButton?: { product: string; target: string };
};

export enum CONTEXT_FAQ {
  IDTGVMAX = 'idtgvmax2',
  OUIGO = 'ouigo',
  OUIGO_IDTGVMAX = 'ouigo_idtgvmax2',
}
