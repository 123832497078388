import type { BoxProps } from '@mui/system';
import { Box } from '@mui/system';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(({ theme }) => ({
  root: {
    backgroundColor: theme.app.colors.white,
    borderRadius: '1rem',
    padding: '0.5rem',
  },
  error: {
    borderStyle: 'solid',
    borderColor: theme.app.colors.errorDark,
  },
}));

type PanelProps = BoxProps & {
  hasError?: boolean;
};

export const Panel = ({
  className,
  hasError,
  children,
  ...rest
}: PanelProps) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.root, hasError && classes.error, className)}
      {...rest}
    >
      {children}
    </Box>
  );
};
