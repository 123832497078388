/**
 * Retourne un nouvel objet sans les clés ayant une valeur `undefined`.
 *
 * Exemple:
 * removeUndefined({ a: 'a', b: undefined, c: '' }) => { a: 'a', c: '' }
 *
 * @param email l'objet à nettoyer
 * @returns le nouvel objet nettoyé
 */

export const removeUndefined = <
  T,
  R extends Partial<{ [K in keyof T]: Exclude<T[K], undefined> }>,
>(
  obj: T,
) => {
  if (!obj) {
    return obj as unknown as R;
  }

  return Object.entries(obj).reduce(
    (acc, [key, value]) =>
      value === undefined ? acc : { ...acc, [key]: value },
    {} as R,
  );
};
