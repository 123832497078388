import type { ICard } from '../../customer/models/customer';

/**
 * Find a customer card with its number.
 *
 * @param {ICard[]} inputCards - The customer cards.
 * @param cardNumber - The cardNumber to search.
 * @returns The card found or undefined if not found.
 */
export function getCustomerCard(
  inputCards?: ICard[],
  cardNumber?: string,
): ICard | undefined {
  if (!inputCards?.length) return;
  if (!cardNumber) return;
  return inputCards.find(card => card.cardNumber === cardNumber);
}
