import React from 'react';
import { Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    '&': {
      border: '0',
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: '1px',
      margin: '0',
      overflow: 'hidden',
      padding: '0',
      position: 'absolute',
      width: '1px',
      whiteSpace: 'nowrap',
    },
    '&:focus': {
      width: 'initial',
      height: 'initial',
      overflow: 'initial',
      padding: 'initial',
      clip: 'initial',
      clipPath: 'initial',
      position: 'fixed',
      top: '-2rem',
      left: '1rem',
    },
  },
}));

export const SkipToMainLink = (): JSX.Element => {
  const { classes } = useStyles();

  const bodyStyle = document.querySelector('body')?.style ?? {
    transform: undefined,
  };
  const onFocus = () => {
    bodyStyle['transform'] = 'translateY(2.5rem)';
  };
  const onBlur = () => {
    bodyStyle['transform'] = 'unset';
  };

  return (
    <Link
      href="#main"
      onFocus={onFocus}
      onBlur={onBlur}
      className={classes.root}
    >
      Aller au contenu principal
    </Link>
  );
};
