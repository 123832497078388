import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

import {
  ActionButton,
  Panel,
  PanelActionButtons,
  PanelContent,
  Wrapper,
} from '@sticky/components';
import { t } from '@sticky/i18n';

import { AlertMessage } from '../../../../components/alert/alert-message';
import { useAppDispatch } from '../../../../hooks';
import { Routes } from '../../../../routes/routes';
import { cleanBasketAndProposal } from '../../../basket/store/basket';
import { getCardsWithAllowedProductType } from '../../../subscription';
import type { ICard } from '../../models/customer';
import {
  invalidateReadCustomerCache,
  readCustomer,
} from '../../store/customer-slice';

const useStyles = tss.create(({ theme }) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  paragraph: {
    marginTop: theme.spacing(1),
  },
  alertError: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

interface ConfirmSubscriptionCardProps {
  product: string;
}

export const ConfirmSubscriptionCard = (
  props: ConfirmSubscriptionCardProps,
) => {
  const { classes } = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [activationError, setActivationError] = useState<
    { errorCode: string } | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const waitAsyncCardActivation = async () => {
    // Block button in loading mode
    setLoading(true);
    setActivationError(undefined);

    // Wait for a response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: any;
    let allowedCards: ICard[];
    let delay = 0;
    let timeSinceStart = 0;
    const startAt = Date.now();
    const firstDelay = 500;
    const timeout = 5000;
    do {
      delay += delay !== 0 ? Math.floor(delay / 2) : firstDelay;
      timeSinceStart = Date.now() - startAt;

      // Refresh customer metas
      await dispatch(invalidateReadCustomerCache());
      response = await dispatch(readCustomer());

      // Get active cards
      allowedCards = getCardsWithAllowedProductType(response?.payload?.cards);

      // Loop until cards are found in the customer metas, or if in timeout
      // eslint-disable-next-line no-loop-func
      await new Promise(resolve => setTimeout(resolve, delay));
    } while (
      !response?.error &&
      !allowedCards.length &&
      timeSinceStart < timeout
    );

    // If response is in timeout
    if (timeSinceStart >= timeout || response?.error) {
      setLoading(false);
      setActivationError({ errorCode: 'RCU_CARD_ACTIVATION_TIMEOUT' });
      return;
    }

    // If response contains cards
    dispatch(cleanBasketAndProposal());

    // Redirect to customer's subscription
    navigate(Routes.getPathById('customer/my-subscription'));
  };

  return (
    <Wrapper className={classes.card}>
      <Panel>
        <PanelContent>
          <Typography variant="h2" gutterBottom>
            {t('subscription.validation.cards.subscription.title')}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.paragraph}
            role="heading"
            aria-level={3}
          >
            {t('subscription.validation.cards.subscription.thanks', {
              product: props.product,
            })}
          </Typography>
          <Typography>
            {t('subscription.validation.cards.subscription.mailConfirm')}
          </Typography>

          {activationError && (
            <AlertMessage
              error={activationError}
              className={classes.alertError}
            />
          )}
        </PanelContent>

        <PanelActionButtons>
          <ActionButton
            loading={isLoading}
            onClick={() => waitAsyncCardActivation()}
            loadingChildren={t(
              'subscription.validation.cards.subscription.activation-wait',
            )}
          >
            {t('subscription.validation.cards.subscription.button-account')}
          </ActionButton>
        </PanelActionButtons>
      </Panel>
    </Wrapper>
  );
};
