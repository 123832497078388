import type { AxiosError } from 'axios';

type StickyGatewayError = AxiosError<{ errorCode: string; message: string }>;

export const isStickyGatewayError = (error: unknown) => {
  if ((error as StickyGatewayError)?.response?.data?.errorCode) {
    return error as StickyGatewayError & {
      response: NonNullable<StickyGatewayError['response']>;
    };
  }
  return undefined;
};
