import React from 'react';

import { t, Trans } from '@sticky/i18n';

import { ModalBox } from '../modal-box';

interface CGVErrorModalProps {
  open: boolean;
  onclose: () => void;
}

export const CGVErrorModal = ({
  open,
  onclose,
}: CGVErrorModalProps): JSX.Element => (
  <ModalBox
    open={open}
    title={t('terms.unavailable-cgv-modal.title')}
    titleComponent="h2"
    fontWeightTitle={700}
    closeButton="header"
    onClose={onclose}
  >
    <Trans i18nKey="terms.unavailable-cgv-modal.content" />
  </ModalBox>
);
