import { EncryptStorage } from 'encrypt-storage';

import { ConfigCommon, getEnv } from '@sticky/config';

/**
 * Permet de créer une storage persistant, obstruée, propre à chaque produit et
 * indépendant de l'environnement.
 */
export const createStickyStorage = (options: { prefix?: string }) => {
  const storage = new EncryptStorage(getEnv('VITE_LOCALSTORAGE_ENCRYPT_KEY'), {
    doNotEncryptValues: !ConfigCommon.env.isProd,
    prefix: [
      '@sticky',
      ConfigCommon.appName,
      !ConfigCommon.env.isProd && ConfigCommon.env.name,
      options.prefix,
    ]
      .filter(Boolean)
      .join(':')
      .toLocaleLowerCase(),
    stateManagementUse: false,
  });

  return {
    key: (index: number) => storage.key(index),
    getItem: <T>(key: string) => storage.getItem<T>(key) ?? null,
    setItem: <T>(key: string, value: T) => {
      storage.setItem(key, value);
    },
    removeItem: (key: string) => {
      storage.removeItem(key);
    },
  };
};
