import { lazy } from 'react';

import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { LAYOUT } from '../layout';
import type { IRoute } from '../routes';

const routes: IRoute[] = [
  {
    id: 'migration/start',
    path: '/migration/accueil',
    title: t('migration.start.pageTitle'),
    layout: LAYOUT.HOME,
    element: lazy(
      () => import('../../pages/migration/migration-login/migration-login'),
    ),
    isDisabled: !getFeatures().migration.enabled,
  },
  {
    id: 'migration/account-creation',
    path: '/migration/creation-du-compte',
    title: t('migration.create-account.pageTitle'),
    element: lazy(() => import('../../pages/migration/account-creation')),
    layout: LAYOUT.MIGRATION,
    isDisabled: !getFeatures().migration.enabled,
  },
  {
    id: 'migration/account-validation-new',
    path: '/migration/validation-de-mon-compte',
    title: t('migration.account-validation.pageTitle'),
    element: lazy(() => import('../../pages/migration/account-validation')),
    layout: LAYOUT.MIGRATION,
    isDisabled: !getFeatures().migration.enabled,
  },
  {
    id: 'migration/update-personal-datas',
    path: '/migration/maj-donnes-personnelles',
    title: t('migration.update-personal-datas.pageTitle'),
    element: lazy(
      () =>
        import(
          '../../pages/migration/update-personal-data/update-personal-data'
        ),
    ),
    layout: LAYOUT.MIGRATION,
    isDisabled: !getFeatures().migration.enabled,
  },
  {
    id: 'migration/subscription-verify',
    path: '/migration/verification-abonnement',
    title: t('migration.subscription-verify.pageTitle'),
    element: lazy(() => import('../../pages/migration/subscription-verify')),
    layout: LAYOUT.MIGRATION,
    isDisabled: !getFeatures().migration.enabled,
  },
  {
    id: 'migration/subscription-validation',
    path: '/migration/validation-abonnement',
    title: t('migration.subscription-validation.pageTitle'),
    element: lazy(() => import('../../pages/migration/migration-complete')),
    layout: LAYOUT.MIGRATION,
    isDisabled: !getFeatures().migration.enabled,
  },
];

export default routes;
