import React from 'react';
import AccessibleIcon from '@mui/icons-material/Accessible';
import type { Theme } from '@mui/material';
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

interface WheelchairPlacementChipProps {
  hasBorder?: boolean;
}

type StyleParams = {
  hasBorder?: boolean;
};

const useStyles = makeStyles<StyleParams>()(
  ({ app: { colors }, typography: { pxToRem } }: Theme, { hasBorder }) => ({
    wheelChairPlacementChip: {
      background: colors.white,
      border: hasBorder ? 'default' : 'none',
      gridArea: 'wheelChairPlacementChip',
      marginTop: pxToRem(5),
      width: 'fit-content',
    },
  }),
);

export const WheelchairPlacementChip = ({
  hasBorder = true,
}: WheelchairPlacementChipProps): JSX.Element => {
  const { classes } = useStyles({ hasBorder });
  return (
    <Chip
      className={classes.wheelChairPlacementChip}
      label={t('reservation.wheelChairPlacement')}
      icon={<AccessibleIcon />}
      variant="outlined"
    />
  );
};
