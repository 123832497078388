import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { t } from '@sticky/i18n';
import { ModalBox } from '@sticky/sticky-common';
import { useAppSelector } from '@sticky/sticky-common/src/hooks';

export const ProposalErrorModal = () => {
  const proposalError = useAppSelector(
    state => state.proposal?.error?.data?.details,
  );
  const [error, setError] = useState('');

  useEffect(() => {
    if (proposalError) {
      setError(
        proposalError.includes('3063') ? 'AGE_MIN_REQUIRED' : 'QUOTE_SALES_KO',
      );
    }
  }, [proposalError]);

  return (
    <ModalBox
      open={!!error}
      title={t(`errorCode.${error}.title`)}
      titleComponent="h2"
      closeButton="header"
      onClose={() => setError('')}
    >
      <Typography>{t(`errorCode.${error}.message`)}</Typography>
    </ModalBox>
  );
};
