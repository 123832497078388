import { lazy } from 'react';

import { t } from '@sticky/i18n';

import { LAYOUT } from '../layout';
import type { IRoute } from '../routes';

const routes: IRoute[] = [
  {
    id: 'enrollment/payment',
    path: '/souscription/paiement',
    title: t('enrollment.payment.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(() => import('../../pages/enrollment/payment/index')),
    private: true,
  },
  {
    id: 'enrollment/payment/waiting',
    path: '/souscription/paiement-attente',
    title: t('enrollment.payment.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/payment/payment-issuing'),
    ),
    private: true,
  },
  {
    id: 'enrollment/payment/unavailable',
    path: '/souscription/paiement-indisponible',
    title: t('enrollment.payment.error'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/payment/payment-unavailable'),
    ),
    private: true,
  },
  {
    id: 'enrollment/payment/unexpected-error',
    path: '/souscription/paiement-erreur-inattendue',
    title: t('enrollment.payment.error'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/payment/payment-unexpected-error'),
    ),
    private: true,
  },
  {
    id: 'enrollment/payment/canceled',
    path: '/souscription/annulation',
    title: t('enrollment.canceled'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/payment/payment-cb-canceled'),
    ),
    private: true,
  },
];

export default routes;
