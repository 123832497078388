import type { FunctionComponent } from 'react';
import { lazy, Suspense } from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// Build classNames
const useStyles = makeStyles()(() => ({
  skel: {
    minHeight: '3em',
    width: '100%',
    // Use connect endpoints
    '@media (min-width: 769px)': {
      height: '4em',
    },
  },
}));

const LazyHeader = lazy(() => import('./lazy-header'));

export const SncfConnectHeader: FunctionComponent = () => {
  const { classes } = useStyles();
  return (
    <Suspense fallback={<Skeleton className={classes.skel} />}>
      <LazyHeader />
    </Suspense>
  );
};
