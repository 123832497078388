import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { StickyGatewayClient } from '@sticky/gateway-client';

import type { DetailsTravel, RootState, TravelDetailsCache } from '../../..';
import { TravelConfirmed } from '../../..';
import type { WeekDay } from '../../../utils/date';
import { loadPersistentState, savePersistentState } from '../..';
import type {
  IReservationState,
  PreferencesForWeekDay,
  ReservationPreferences,
  Travel,
} from '../models/reservation';
import {
  getTravelId,
  highlightTravels,
  mapStatusEnums,
  sortTravelsByDepartureDate,
} from '../utils/travel';

export interface UpdatePreferencesPayload {
  activeWeekDays?: Partial<Record<WeekDay, boolean>>;
  cardNumber: string;
  criteriaForWeekDay?: Partial<
    Record<WeekDay, PreferencesForWeekDay | undefined>
  >;
}

export interface TravelMandatoryInfo {
  departureDateTime: string;
  marketingCarrierRef: string;
  orderId: string;
  trainNumber: string;
}

export interface TravelInfoWithCustomer extends TravelMandatoryInfo {
  customerName: string;
}

export interface ConfirmReservationPayload {
  marketingCarrierRef: string;
  trainNumber: string;
  departureDateTime: string;
}

export interface CancelReservationInfo extends TravelMandatoryInfo {
  cancelled: boolean;
  cancelErrorCode?: string;
  isMultiPassengerTravel?: boolean;
}

export interface CancelReservationResponse {
  info: CancelReservationInfo[];
}

interface TravelConsultationOutput extends Omit<Travel, 'marketingCarrierRef'> {
  dvNumber: string;
}

const UNHIGHLIGHT_DATE_LOCALSTORAGE_KEY = 'reservationUnhighlightDate';
const SEARCH_DATES_LOCALSTORAGE_KEY = 'reservationSearchDates';
const DEFAULT_SEARCH_DATES = {
  fromDate: null,
  toDate: null,
};

const initialState: IReservationState = {
  searchDates:
    loadPersistentState(SEARCH_DATES_LOCALSTORAGE_KEY) ?? DEFAULT_SEARCH_DATES,
  unhighlightDate: loadPersistentState<Record<string, string>>(
    UNHIGHLIGHT_DATE_LOCALSTORAGE_KEY,
  )?.value,
  activeWeekDays: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  },
  criteriaForWeekDay: {
    0: undefined,
    1: undefined,
    2: undefined,
    3: undefined,
    4: undefined,
    5: undefined,
    6: undefined,
  },
  bookingFinished: false,
  travels: [],
  preferencesInfo: { fetched: false, loading: false },
  travelsInfo: { fetched: false, loading: false },
  travelDetailsCache: [],
};

export const getPreferences = createAsyncThunk(
  'post/read-preferences',
  async (cardNumber: string, thunkApi) => {
    const httpClient = StickyGatewayClient.authClient();
    const asyncFn = httpClient.post<ReservationPreferences>(
      '/public/reservation/read-preferences',
      { cardNumber },
    );
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
  {
    condition: (_, { getState }) => {
      const reservation: IReservationState = (getState() as RootState)
        .reservation;
      // Already fetched or in progress, don't need to re-fetch
      if (
        reservation.preferencesInfo.loading ||
        reservation.preferencesInfo.fetched
      ) {
        return false;
      }
    },
  },
);

export const updatePreferences = createAsyncThunk(
  'post/update-preferences',
  async (payload: UpdatePreferencesPayload, thunkApi) => {
    const { reservation } = thunkApi.getState() as RootState;
    const activeWeekDays = {
      ...reservation.activeWeekDays,
      ...(payload?.activeWeekDays ?? {}),
    };
    const criteriaForWeekDay = {
      ...reservation.criteriaForWeekDay,
      ...(payload?.criteriaForWeekDay ?? {}),
    };
    const httpClient = StickyGatewayClient.authClient();
    const asyncFn = httpClient.post<ReservationPreferences>(
      '/public/reservation/update-preferences',
      {
        activeWeekDays,
        criteriaForWeekDay,
        cardNumber: payload.cardNumber,
      },
    );
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
  {
    condition: (_, { getState }) => {
      const reservation: IReservationState = (getState() as RootState)
        .reservation;
      if (reservation.preferencesInfo.loading) {
        return false;
      }
    },
  },
);

type TravelConsultationProps = {
  cardNumber: string;
  startDate?: string;
  endDate?: string;
};
export const getAllTravels = createAsyncThunk(
  'post/travel-consultation',
  async (travelConsultationProps: TravelConsultationProps, thunkApi) => {
    const httpClient = StickyGatewayClient.authClient();
    const asyncFn = httpClient.post<TravelConsultationOutput[]>(
      `/public/reservation/travel-consultation`,
      travelConsultationProps,
    );
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

export const getTravelDetails = createAsyncThunk(
  'post/get-travel',
  async (payload: TravelInfoWithCustomer, thunkApi) => {
    const httpClient = StickyGatewayClient.authClient();
    const asyncFn = httpClient.post<DetailsTravel>(
      '/public/reservation/get-travel',
      {
        customerName: payload.customerName,
        departureDateTime: payload.departureDateTime,
        marketingCarrierRef: payload.marketingCarrierRef,
        trainNumber: payload.trainNumber,
      },
    );
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
  {
    condition: (payload: TravelInfoWithCustomer, { getState }) => {
      const alreadyCached = (
        getState() as RootState
      ).reservation.travelDetailsCache.find(
        (cache: TravelDetailsCache) => cache.id === getTravelId(payload),
      );
      return !alreadyCached;
    },
  },
);

const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    setReservationDates: (state, action) => {
      state.searchDates = action.payload;
      savePersistentState(SEARCH_DATES_LOCALSTORAGE_KEY, action.payload);
    },
    resetReservationDates: state => {
      state.searchDates = DEFAULT_SEARCH_DATES;
      savePersistentState(SEARCH_DATES_LOCALSTORAGE_KEY, DEFAULT_SEARCH_DATES);
    },
    resetReservationError: state => {
      state.preferencesInfo.error = undefined;
    },
    resetReservationState: () => ({
      ...initialState,
      unhighlightDate: loadPersistentState<Record<string, string>>(
        UNHIGHLIGHT_DATE_LOCALSTORAGE_KEY,
      )?.value,
    }),
    saveTransactionId: (state, action) => {
      state.transactionId = action.payload;
    },
    resetTransactionId: state => {
      state.transactionId = undefined;
    },
    setBookingFinished: (state, action) => {
      state.bookingFinished = action.payload;
    },
    updateActiveWeekDay: (
      state,
      { payload }: { payload: { weekDay: WeekDay; activated: boolean } },
    ) => {
      const { weekDay, activated } = payload;
      state.activeWeekDays[weekDay] = activated;
    },
    removeReservations: (
      state,
      { payload }: { payload: CancelReservationInfo[] },
    ) => {
      state.travels = state.travels.filter(
        travel =>
          !payload.some(
            p =>
              travel.marketingCarrierRef === p.marketingCarrierRef &&
              travel.orderId === p.orderId &&
              travel.trainNumber === p.trainNumber &&
              travel.departureDateTime === p.departureDateTime,
          ),
      );
    },
    setReservationAsConfirmed: (
      { travels },
      {
        payload: { departureDateTime, marketingCarrierRef },
      }: { payload: ConfirmReservationPayload },
    ) =>
      travels
        .filter(
          travel =>
            travel.marketingCarrierRef === marketingCarrierRef &&
            travel.departureDateTime === departureDateTime,
        )
        .forEach(
          travel => (travel.travelConfirmed = TravelConfirmed.CONFIRMED),
        ),
    setUnhighlightDate: (state, action) => {
      state.unhighlightDate = action.payload;
      state.travels = highlightTravels(state.travels, action.payload);
      savePersistentState(UNHIGHLIGHT_DATE_LOCALSTORAGE_KEY, {
        value: action.payload,
      });
    },
    setTravelsCacheExpired: (
      state,
      { payload }: { payload: TravelMandatoryInfo[] },
    ) => {
      payload.forEach(info => {
        const travelId = getTravelId(info);
        const correspondingCache = state.travelDetailsCache.find(
          (cache: TravelDetailsCache) => cache.id === travelId,
        );
        if (correspondingCache) {
          correspondingCache.expired = true;
        }
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(getPreferences.pending, state => {
      state.preferencesInfo.error = undefined;
      state.preferencesInfo.loading = true;
    });
    builder.addCase(getPreferences.fulfilled, (state, action) => {
      state.activeWeekDays = action.payload.activeWeekDays;
      state.criteriaForWeekDay = action.payload.criteriaForWeekDay;
      state.preferencesInfo.error = undefined;
      state.preferencesInfo.fetched = true;
      state.preferencesInfo.loading = false;
    });
    builder.addCase(getPreferences.rejected, state => {
      state.preferencesInfo.error = 'read';
      state.preferencesInfo.fetched = false;
      state.preferencesInfo.loading = false;
    });
    builder.addCase(getAllTravels.pending, state => {
      state.travels = [];
      state.travelsInfo.error = undefined;
      state.travelsInfo.fetched = false;
      state.travelsInfo.loading = true;
    });
    builder.addCase(
      getAllTravels.fulfilled,
      (state, { payload }: { payload: TravelConsultationOutput[] }) => {
        const travels = payload.map(value => {
          const { dvNumber, ...travel } = value;
          return {
            marketingCarrierRef: dvNumber,
            ...travel,
          };
        });
        state.travels = mapStatusEnums(
          sortTravelsByDepartureDate(
            highlightTravels(travels, state.unhighlightDate),
          ),
        );
        state.travelsInfo.error = undefined;
        state.travelsInfo.fetched = true;
        state.travelsInfo.loading = false;

        // cache clean
        state.travelDetailsCache = state.travelDetailsCache.filter(
          cache => !cache.expired,
        );
      },
    );
    builder.addCase(getAllTravels.rejected, state => {
      state.travels = [];
      state.travelsInfo.error = 'error';
      state.travelsInfo.fetched = false;
      state.travelsInfo.loading = false;
    });
    builder.addCase(updatePreferences.pending, (state, action) => {
      state.preferencesInfo.error = undefined;
      state.preferencesInfo.loading = true;
      if (action.meta.arg) {
        const { activeWeekDays, criteriaForWeekDay } = action.meta.arg;
        state.activeWeekDays = { ...state.activeWeekDays, ...activeWeekDays };
        state.criteriaForWeekDay = {
          ...state.criteriaForWeekDay,
          ...criteriaForWeekDay,
        };
      }
    });
    builder.addCase(updatePreferences.fulfilled, state => {
      state.preferencesInfo.error = undefined;
      state.preferencesInfo.loading = false;
    });
    builder.addCase(updatePreferences.rejected, state => {
      state.preferencesInfo.error = 'update';
      state.preferencesInfo.loading = false;
    });
    builder.addCase(getTravelDetails.fulfilled, (state, action) => {
      const arg = action.meta.arg;
      if (arg) {
        const travelId = getTravelId(arg);
        const correspondingCache = state.travelDetailsCache.find(
          (cache: TravelDetailsCache) => cache.id === travelId,
        );
        if (correspondingCache) {
          correspondingCache.detailsState = { fetched: true, loading: false };
          correspondingCache.travelDetails = action.payload;
        }
      }
    });
    builder.addCase(getTravelDetails.rejected, (state, action) => {
      const arg = action.meta.arg;
      if (arg) {
        const travelId = getTravelId(arg);
        const correspondingCache = state.travelDetailsCache.find(
          (cache: TravelDetailsCache) => cache.id === travelId,
        );
        if (correspondingCache) {
          correspondingCache.detailsState = {
            fetched: false,
            loading: false,
            error: 'error',
          };
        }
      }
    });
    builder.addCase(getTravelDetails.pending, (state, action) => {
      const arg = action.meta.arg;
      if (arg) {
        state.travelDetailsCache.push({
          id: getTravelId(arg),
          detailsState: { fetched: false, loading: true },
        });
      }
    });
  },
});

export const {
  setBookingFinished,
  setUnhighlightDate,
  removeReservations,
  setReservationAsConfirmed,
  resetReservationError,
  resetReservationState,
  setReservationDates,
  saveTransactionId,
  resetReservationDates,
  resetTransactionId,
  updateActiveWeekDay,
  setTravelsCacheExpired,
} = reservationSlice.actions;

const reservationReducer = reservationSlice.reducer;
export { reservationReducer };
