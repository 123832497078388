import type { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

import type { Seat } from '../..';
import { TypoWithSkeleton } from '../..';
import { WheelchairPlacementChip } from '../chip/wheelchair-placement-chip';

const useStyles = makeStyles()(
  ({ breakpoints, typography: { pxToRem } }: Theme) => ({
    seat: {
      gridArea: 'seat',
      fontSize: pxToRem(12),
      [breakpoints.up('breakPointDesktop')]: {
        fontSize: pxToRem(16),
      },
    },
  }),
);

interface SeatDetailsProps {
  seat?: Seat;
}

const SeatDetails = ({ seat }: SeatDetailsProps): JSX.Element => {
  const { classes } = useStyles();

  const seatDetails = !seat
    ? undefined
    : [
        t(`travels.detailsTravel.assignmentOption.${seat.seatPosition}`, {
          defaultValue: '',
        }),
        t(`travels.detailsTravel.assignmentOption.${seat.spaceType}`, {
          defaultValue: '',
        }),
        t(`travels.detailsTravel.assignmentOption.${seat.tgvDeck}`, {
          defaultValue: '',
        }),
        seat.facingForward === true
          ? t('travels.detailsTravel.facingForward')
          : '',
      ]
        .filter(label => label !== '')
        .join(' - ');

  return (
    <>
      {!seat?.wheelchair ? (
        <TypoWithSkeleton
          variant="body1"
          skelProps={{ width: '60%' }}
          isReady={!!seat}
          className={classes.seat}
        >
          {seatDetails}
        </TypoWithSkeleton>
      ) : (
        <WheelchairPlacementChip hasBorder={false} />
      )}
    </>
  );
};

export default SeatDetails;
