import Cookies from 'js-cookie';

import { getEnv } from '../get-env';

export const ConfigCommon = {
  /**
   * current environment.
   */
  env: {
    name: getEnv('VITE_ENV').toLocaleUpperCase(),
    get isProd(): boolean {
      return (
        this.name === 'PROD' &&
        Cookies.get(ConfigCommon.cookieVersionKey) !== 'two'
      );
    },
    get isTest(): boolean {
      return !this.name;
    },
    get isDev(): boolean {
      return this.name === 'DEV';
    },
    get isUsine(): boolean {
      return this.name === 'USN';
    },
    get isRecette(): boolean {
      return this.name === 'REC';
    },
    get isPab(): boolean {
      return (
        this.name === 'PROD' &&
        Cookies.get(ConfigCommon.cookieVersionKey) === 'two'
      );
    },
  },

  /**
   * current app name.
   */
  appName: getEnv('VITE_NAME').toLocaleUpperCase(),

  /**
   * Name of the offer
   */
  offerType: getEnv('VITE_PRODUCT_OFFER_TYPE').toLocaleUpperCase(),

  /**
   * App version
   */
  version: getEnv('PACKAGE_VERSION').toLocaleUpperCase(),

  /**
   * Branch name
   */
  branchName: getEnv('BRANCH_NAME').toLocaleUpperCase(),

  /**
   * Name of the cookie to set S3 version
   */
  cookieVersionKey: 'x-syf-path',

  /**
   * Name of the cookie to set backend target
   */
  cookieBackKey: 'x-syf-back',

  get apiUrl(): string {
    return `${window.location.origin}/api`;
  },
};
