import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ActionButton } from '@sticky/components';
import { i18n, Trans } from '@sticky/i18n';
import { Logger } from '@sticky/logger';

import { BrandContext } from '../../../features/brand/brand-provider';
import { setDisclaimerAgencyRgpd } from '../../../features/disclaimer/store/disclaimer-slice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Routes } from '../../../routes/routes';
import { ModalBox } from '../modal-box';

export const ModalDisclaimerAgencyRgpd = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { brand } = useContext(BrandContext);

  // Detect if we're on the homepage
  const isHomepage =
    Routes.getRouteByPathname(location.pathname)?.id === 'home';

  // As modal should be display on every site reload, persistence is only stored in store
  const { agencyRgpd } = useAppSelector(state => state.disclaimer);

  // Define condition to show the modal
  const isEligible = brand && 'feria' === brand.id;
  const showModal =
    isHomepage &&
    !!agencyRgpd?.mustBeDisplayed &&
    !agencyRgpd?.hasBeenDisplayed;

  // Store management
  useEffect(() => {
    // Set modal show condition
    if (isEligible && !agencyRgpd?.mustBeDisplayed) {
      dispatch(
        setDisclaimerAgencyRgpd({
          mustBeDisplayed: true,
          hasBeenDisplayed: false,
        }),
      );
    }
  }, [isEligible, agencyRgpd?.mustBeDisplayed, dispatch]);

  // Handle modal close
  const handleModalClose = () => {
    dispatch(
      setDisclaimerAgencyRgpd({ ...agencyRgpd, hasBeenDisplayed: true }),
    );
  };

  // Translation group
  const translationKey = 'disclaimer.agency.modal-rgpd';

  // Warning if the modal should be displayed, but no transaltion files are set.
  if (showModal && !i18n.exists(`${translationKey}.content`)) {
    Logger.error(
      'ModalDisclaimerAgencyRgpd',
      new Error(
        'You want to display diclaimer RGPD for an agency, but translation is not set.',
      ),
    );
  }

  // Confirm button
  const ConfirmButton = (
    <ActionButton onClick={() => handleModalClose()}>
      <Trans i18nKey={`${translationKey}.actions.accept`} />
    </ActionButton>
  );

  // Modal Element
  return (
    <ModalBox
      open={showModal}
      closeButton={false}
      isDisableBackdropClick={true}
      actions={[ConfirmButton]}
    >
      <Trans i18nKey={`${translationKey}.content`} />
    </ModalBox>
  );
};
