import { t, Trans } from '@sticky/i18n';

import { proposalSelectors } from '../../..';
import { useAppSelector } from '../../../hooks';
import { ModalBox } from '../modal-box';

interface AlreadySubscribedModalModalProps {
  open: boolean;
  onClose: () => void;
}

export const AlreadySubscribedModal = ({
  open,
  onClose,
}: AlreadySubscribedModalModalProps) => {
  const product = useAppSelector(proposalSelectors.getProposalLabel);

  return (
    <ModalBox
      open={open}
      title={t('enrollment.alreadySubscribed.title')}
      titleComponent="h2"
      closeButton="header"
      onClose={onClose}
    >
      <Trans
        i18nKey="enrollment.alreadySubscribed.message"
        values={{ product }}
      />
    </ModalBox>
  );
};
