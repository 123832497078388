/**
 * Parse an ISO 8601 date string to Date.
 *
 * @param value  {string} - The string to parse in Date format.
 */
export const parseUtcDateIso8601 = (
  value: string | undefined,
): Date | undefined => {
  if (!value) return undefined;

  // YYYY
  if (value.length === 4) {
    return new Date(Date.UTC(parseFloat(value), 1, 1));
  }

  // YYYY-MM
  else if (value.length === 7) {
    const parts = value.split('-');
    return new Date(
      Date.UTC(parseFloat(parts[0]), parseFloat(parts[1]) - 1, 1),
    );
  }

  // YYYY-MM-DD
  else if (value.length === 10) {
    const parts = value.split('-');
    return new Date(
      Date.UTC(
        parseFloat(parts[0]),
        parseFloat(parts[1]) - 1,
        parseFloat(parts[2]),
      ),
    );
  }

  // Not supported
  else {
    return undefined;
  }
};

/**
 * Internal helper function for parsers.
 */
const buildDateFromParts = (day: number, month: number, year: number): Date => {
  const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  const daysPerMonth = [
    31,
    isLeapYear ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];

  if (
    day <= 0 ||
    day > 31 ||
    month <= 0 ||
    month > 12 ||
    day > daysPerMonth[month - 1]
  )
    return new Date(NaN);

  return new Date(Date.UTC(year, month - 1, day));
};

/**
 * Parses a date in dd/MM/yyyy format. Returns new Date(NaN) if the date is invalid (e.g. '30/02/2021').
 *
 * @param value the string to parse
 * @returns the Date parsed
 */
export const parseDDMMYYYY = (value: string): Date => {
  const parts = value.match(
    /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(\d{4})$/,
  );
  if (!parts) return new Date(NaN);

  return buildDateFromParts(
    parseInt(parts[1]),
    parseInt(parts[2]),
    parseInt(parts[3]),
  );
};

/**
 * Parses a date in ISO 8601 format (i.e. yyyy-MM-dd). Returns new Date(NaN) if the date is invalid (e.g. '2021-02-30').
 *
 * @param value the string to parse
 * @returns the Date parsed
 */
export const parseYYYYMMDD = (value: string): Date => {
  const parts = value.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (!parts) return new Date(NaN);

  return buildDateFromParts(
    parseInt(parts[3]),
    parseInt(parts[2]),
    parseInt(parts[1]),
  );
};

/**
 * Parse a date in 'dd/MM/yyyy' format. If the date is invalid, returns null.
 *
 * @param value the string to parse
 * @returns the Date parsed or null if invalid
 */
export const parseDDMMYYYYOrNull = (value?: string | null): Date | null => {
  const parsed = parseDDMMYYYY(value ?? '');
  if (isNaN(parsed.getTime())) return null;
  return parsed;
};

/**
 * Parse a date in 'yyyy-MM-dd' format. If the date is invalid, returns null.
 *
 * @param value the string to parse
 * @returns the Date parsed or null if invalid
 */
export const parseYYYYMMDDOrNull = (value?: string | null): Date | null => {
  const parsed = parseYYYYMMDD(value ?? '');
  if (isNaN(parsed.getTime())) return null;
  return parsed;
};

/**
 * Parses a date-time in ISO 8601 format (i.e. yyyy-MM-ddTHH:mm). Returns new Date(NaN) if the date is invalid (e.g. '2021-02-30T12:15').
 *
 * @param value the string to parse
 * @returns the Date parsed
 */
export const parseYYYYMMDDHHMM = (value: string): Date => {
  const parts = value.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})$/);
  if (!parts) return new Date(NaN);

  const hours = parseInt(parts[4], 10);
  const minutes = parseInt(parts[5], 10);
  if (
    hours < 0 ||
    hours > 24 ||
    minutes < 0 ||
    minutes > 59 ||
    (hours === 24 && minutes !== 0)
  )
    return new Date(NaN);

  const date = buildDateFromParts(
    parseInt(parts[3]),
    parseInt(parts[2]),
    parseInt(parts[1]),
  );
  date.setHours(hours);
  date.setMinutes(minutes);

  return date;
};
