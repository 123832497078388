import { create } from 'zustand';

type State = {
  consentedVendors: string[];
};

type Action = {
  setConsentedVendors: (consentedVendors: string[]) => void;
};

type DidomiStore = State & Action;

export const useDidomiStore = create<DidomiStore>(set => ({
  consentedVendors: [],

  setConsentedVendors: consentedVendors => set({ consentedVendors }),
}));
