import { createSlice } from '@reduxjs/toolkit';

import { getEnv } from '@sticky/config';
import { StickyGatewayClient } from '@sticky/gateway-client';

import type { AppThunk } from '../../../store';
import { getHelpDeskUrl } from '../../help-desk/utils/getHelpDeskUrl';
import type { SubscriptionProductId } from '../../subscription/models/subscription';
import type {
  ITermsEncryptInput,
  ITermsEncryptOutput,
  ITermsModalInput,
  ITermsState,
} from '../models/terms';

// Name of the reducer
const REDUCER_NAME = 'terms';

// Initial state
const initialState: ITermsState = {
  error: '',
};

// used to get CGV url in read mode
export const getCGVUrl = async ({
  productId,
  startDateValidity,
}: {
  productId: SubscriptionProductId;
  startDateValidity?: string;
}): Promise<string> => {
  // Get API client
  const httpClient = await StickyGatewayClient.authClient();

  // If not set and productId provided, set 'startDateValidity' to now
  if (productId && !startDateValidity) {
    startDateValidity = new Date().toISOString();
  }

  // Functional checks
  if (productId && !startDateValidity) {
    throw new Error(
      'You have to provide "startDateValidity" if you provide "productId"',
    );
  }
  if (!productId && startDateValidity) {
    throw new Error(
      'You have to provide "productId" if you provide "startDateValidity"',
    );
  }
  if (
    startDateValidity &&
    Number.isNaN(new Date(startDateValidity).valueOf())
  ) {
    throw new Error('"startDateValidity" is incorrect');
  }

  const response = await httpClient.get<{ current: { url: string }[] }>(
    '/public/refdata/cgv-reference',
    {
      params: {
        mode: 'read',
        ...{ productId, startDateValidity },
      },
    },
  );

  return response.data.current[0].url;
};

// Encrypt datas to get CGV iframe
export const encryptCgvDatas = async (
  props: ITermsEncryptInput,
): Promise<ITermsEncryptOutput> => {
  const httpClient = StickyGatewayClient.authClient();
  return httpClient
    .post<ITermsEncryptOutput>('/public/refdata/encrypting-data-cgv', props)
    .then(response => response.data);
};

// Init a CGV request
export const buildCgvValidationStates =
  (props: ITermsModalInput): AppThunk =>
  async dispatch => {
    try {
      const baseUrl = getEnv('VITE_CGV_URL_BASE');
      if (!baseUrl) {
        dispatch(
          setTermsStoreError({ payload: 'VITE_CGV_URL_BASE is not defined' }),
        );
        return;
      }

      // Get support url
      const supportUrl = getHelpDeskUrl(props.helpDeskInput);

      // Get encrypted datas
      const encrypted = await encryptCgvDatas({
        mode: props.mode,
        cardNumber: props.cardNumber,
        subscriptionId: props.subscriptionId,
        version: props.version,
        callbackSignedUrl: window.location.href,
        callbackRejectUrl: supportUrl,
      });

      dispatch(
        setTermsStore({
          iFrameUrl:
            `${baseUrl}?mode=${encrypted.mode}&productId=${encrypted.productId}` +
            `&subscriptionId=${encrypted.subscriptionId}&version=${encrypted.version}` +
            `&callbackSignedUrl=${encrypted.callbackSignedUrl}&callbackRejectUrl=${encrypted.callbackRejectUrl}`,
          supportUrl,
        }),
      );
    } catch (e) {
      dispatch(setTermsStoreError({ payload: e }));
    }
  };

// The Slice reducer
const termsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setTermsStore: (state, action) => {
      state.error = '';
      state.iFrameUrl = action.payload?.iFrameUrl;
      state.supportUrl = action.payload?.supportUrl;
    },
    setTermsStoreError: (state, action) => {
      state.error = action.payload;
      delete state.iFrameUrl;
      delete state.supportUrl;
    },
  },
});

// Export
export const { setTermsStore, setTermsStoreError } = termsSlice.actions;
const termsReducer = termsSlice.reducer;
export { termsReducer };
