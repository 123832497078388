import type { IError } from '../../../resources/types';

export enum CardType {
  NF = 'NF',
  FF = 'FF',
  FIDEL = 'FIDEL',
  HC = 'HC',
  TGV_MAX_SENIOR = 'TGV_MAX_SENIOR',
  TGV_MAX_JEUNE = 'TGV_MAX_JEUNE',
  IDTGV_MAX = 'IDTGV_MAX',
}

export enum NsdStatus {
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed',
}

export enum PictureStatus {
  VALIDATED = 'validated',
  NOT_VALIDATED = 'notvalidated',
  INVALIDATED = 'invalidated',
  DELETED = 'deleted',
}

/**
 * List the prefix codes for Migration Cards (ie: MAX JEUNE).
 *
 * @export
 * @enum {number}
 */
export enum CardPrefixCode {
  HC = 'HC',
  MX = 'MX',
}

/**
 * Associate CardPrefixCode with Prefix number for each type of cards.
 *
 * @export
 * @enum {number}
 */
export enum CardPrefixCodeNumber {
  HC = '29090125',
  MX = '29090126',
}

export const POLICE_CARD = 'C0032G8';

export const ICardContractStatus: { [key: string]: string } = {
  Annulé: 'Annulé',
  INVALIDE: 'Invalide',
  Incomplet: 'Incomplet',
  REMBOURSE: 'Remboursé',
  'SUSPENSION CLIENT': 'Suspension Client',
  'SUSPENSION IMPAYE': 'Suspension Impayé',
  Suspendu: 'Suspendu',
  Actif: 'Actif',
  VALIDE: 'Valide',
};

export enum CardStatus {
  VALIDE = 'VALIDE',
  Actif = 'Actif',
  REMBOURSE = 'REMBOURSE',
  SUSPENSION_CLIENT = 'SUSPENSION CLIENT',
  SUSPENSION_IMPAYE = 'SUSPENSION IMPAYE',
  Suspendu = 'Suspendu',
  INVALIDE = 'INVALIDE',
  Annule = 'Annulé',
  Incomplet = 'Incomplet',
}

export interface ICard {
  productType: CardType;
  cardNumber: string;
  validityStartDate: string;
  validityEndDate: string;
  marketingCarrierRef: string;
  contractStatus: CardStatus;
  cashPayment: string;
  bookCounterN0?: number;
  bookCounterN1?: number;
  FCE?: string;
}

export enum MarketingCarrierRefCode {
  RENOU = 'RENOU',
}

// Customer model
export interface ICustomer {
  iuc: string;
  createdAt: string;
  updatedAt: string;
  civility: string;
  lastName: string;
  firstName: string;
  birthDate?: string;
  nationality?: string;
  language?: string;
  address?: string;
  addressNote1?: string;
  addressNote2?: string;
  addressNote3?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  email: string;
  mobilePhone?: string;
  pictureUpdate: string;
  cards?: ICard[];
  fidelityCard?: string;
  selectedCardNumber?: string;
  avatar?: string;
  nsdStatus?: string;
  pictureStatus?: PictureStatus;
  pictureCounter?: number;
}

export interface ICustomerCreation {
  civility: string;
  lastName: string;
  firstName: string;
  birthDate: Date;
  mobilePhone: string;
  email: string;
  emailConfirmation?: string;
  password: string;
  passwordConfirmation?: string;
}

export interface ICustomerUpdate {
  customerAddress: string;
  customerAddressNote1?: string;
  customerAddressNote2?: string;
  customerAddressNote3?: string;
  customerZipCode: string;
  customerCity: string;
  customerCountry: string;
  customerEmail: string;
  customerMobilePhone: string;
}

export enum ICustomerAuthReason {
  'SUBSCRIPTION' = '0100',
  'MIGRATION' = '0200',
}

// Store customer state
export enum ICustomerStateErrorCode {
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
}

export interface ICustomerState {
  loading: boolean;
  at?: number;
  readAt: number;
  message?: string;
  error?: IError<string, ICustomerStateErrorCode | string>;
  isAuth: boolean;
  customer?: ICustomer;
  editCustomer?: ICustomer;
  newUserEmail?: string;
  migrationUserEmail?: string;
  logAfterQuote?: boolean;
  serviceTemporaryUnavailable?: boolean;
  serviceAvailable?: boolean;
  serviceAvailableForceDisable?: boolean;
}

export interface IUpdateCustomerInput {
  address: string;
  addressNote1?: string;
  addressNote2?: string;
  addressNote3?: string;
  city: string;
  civility?: string;
  country: string;
  mobilePhone: string;
  zipCode: string;
}
