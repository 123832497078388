import { AppCommon, createStore } from '@sticky/sticky-common';

import { ProposalErrorModal } from './components';
import { theme } from './theme';

createStore();

// Application init
export const App = () => (
  <AppCommon theme={theme}>
    <ProposalErrorModal />
  </AppCommon>
);
