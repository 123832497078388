import { lazy } from 'react';

import { t } from '@sticky/i18n';

import { LAYOUT } from '../layout';
import type { IRoute } from '../routes';

const routes: IRoute[] = [
  {
    id: 'enrollment/my-coordinates',
    path: '/souscription/mes-coordonnees',
    title: t('enrollment.coordinates.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () =>
        import('../../pages/enrollment/my-coordinates/pages/my-coordinates'),
    ),
    private: true,
  },
  {
    id: 'enrollment/my-coordinates/error',
    path: '/souscription/mes-coordonnees-erreur',
    title: t('enrollment.coordinates.error.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () =>
        import(
          '../../pages/enrollment/my-coordinates/pages/my-coordinates-error'
        ),
    ),
    private: true,
  },
];

export default routes;
