import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type KeyNumberProps = Omit<BoxProps, 'children'> & {
  number: string;
  text: string;
  isPercentage: boolean;
};

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
  },
  number: {
    fontSize: '2.5rem',
    fontWeight: '800',
    lineHeight: '1.2',
    color: '#3C3C3B',
  },
  text: {
    marginLeft: theme.spacing(1),
    fontSize: '1rem',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
  },
  percentage: {
    marginLeft: '2px',
  },
}));

export const KeyNumber = ({
  number,
  text,
  isPercentage,
  className,
  ...rest
}: KeyNumberProps) => {
  const { classes } = useStyles();
  return (
    <Box
      className={[className, classes.root].filter(Boolean).join(' ')}
      {...rest}
    >
      <div className={classes.number}>{number}</div>
      {isPercentage && <div className={classes.percentage}>%</div>}
      <div className={classes.text}>{text}</div>
    </Box>
  );
};
