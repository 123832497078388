import type { IStation } from './station';

export interface IProposal {
  amount?: string;
  productCode: string;
  productSelect?: ProductSelect;
  productLabel?: string;
  purchaseType: PurchaseType;
  cardNumber?: string;
  startDateValidity?: string;
  origin?: IStation;
  destination?: IStation;
  travelClass?: string;
  cashPayment?: boolean;
  paymentInitialInMonths?: number;
  paymentMethod?: PaymentMethod;
  birthDate?: string;
}

export interface IProposalState extends IProposal {
  at?: number;

  expirationDate?: number;
  loading: boolean;
  serviceUnavailable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  plans?: IProposalPlan[];
  planId?: string;
  planSelected?: IProposalPlan;
  offerTypeQuotes?: {
    value?: OffertTypeQuoteOutput[];
    loading?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
    at?: number;
  };
  projectedInventory?: {
    value?: number;
    loading?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
    at?: number;
  };
}

export interface IProposalPlan {
  idProposal: string;
  amount: number;
  currency: string;
  cashPayment: boolean;
  validityStartDate: string;
  validityExpirationDate: string;
  productLabel: string;
  productCode: string;
  productType: string;
  productSelect: string;
  purchaseType: string;
  origin: string;
  destination: string;
  travelClass: string;
  seniority: string;
}

export interface ITravelClass {
  value: string;
  label: string;
  title: string;
}

export enum PurchaseType {
  INITIAL = 'INITIAL',
  RENEWAL = 'RENEWAL',
}

export enum ProductSelect {
  FULL_TIME = 'FULLTIME',
  PART_TIME = 'PARTTIME',
}

export enum PaymentMethod {
  CB = 'CB',
  IBAN = 'IBAN',
}

export interface ITravelClass {
  value: string;
  label: string;
  title: string;
}

export interface IProposalInput {
  proposal?: IProposalState;
  isKnown?: boolean;
  productCode: string;
  purchaseType?: string;
  birthDate?: string;
  startDateValidity?: string;
}

export enum ProductCodes {
  MFA = 'MON_FORFAIT_ANNUEL',
  TGV_MAX = 'TGV_MAX',
  TMS = 'TGV_MAX_SENIOR',
}

export interface OffertTypeQuoteOutput {
  ratePlanType: string;
  price: number;
  currency?: string;
  promoPeriods?: number;
  effectiveEndDate?: string;
  promoName?: string;
  promoDescription?: string;
}

export enum RatePlanTypeOfferType {
  STANDARD = 'STANDARD',
  PROMO = 'PROMO',
}

export interface ISearchFreeplacesProposalsInput {
  origin: string;
  destination: string;
  departureDateTime?: string;
}

export interface ISearchFreeplacesProposals {
  origin: { label: string; rrCode: string };
  destination: { label: string; rrCode: string };
  departureDate: string;
  arrivalDate: string;
  freePlaces: number;
  trainNumber: string;
  trainEquipment: string;
}

export interface ISearchFreeplacesProposalsOutput {
  proposals: ISearchFreeplacesProposals[];
  freePlacesRatio: number;
  updatedAt?: number;
  expiresAt?: number;
}

export interface IGetFreeplacesStationsOutput {
  stations: { codeStation: string; station: string }[];
}
