import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { ConfigCommon } from '@sticky/config';
import { createStickyStorage } from '@sticky/helpers';

import { decodeMidJwt } from './utils';

type Auth = {
  idToken: string;
  refreshToken: string;
  expiresIn: number;
  expiresAt: number;
  rememberMe?: boolean;
};

type State = {
  auth: Auth | null;
  loading: boolean;
  error: unknown | null;
  status: 'not-ready' | 'authenticated' | 'not-authenticated';
  config: {
    loginRedirectUri: string;
    logoutRedirectUri: string;
    onLogin?: () => Promise<void>;
    onLogout?: () => Promise<void>;
  } | null;
};

type Actions = {
  initialize: (config: State['config']) => void;
  preLogin: () => void;
  login: (auth: Omit<Auth, 'expiresAt'>) => void;
  postLogin: (err?: unknown) => void;
  logout: () => void;
};

export type AuthStore = State & Actions;

export const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      set => ({
        auth: null,
        config: null,
        error: null,
        loading: true,
        status: 'not-ready',

        initialize(config) {
          set({
            config,
          });
        },

        preLogin() {
          set({ loading: true });
        },
        login(auth) {
          if (!auth) {
            set({ auth: null });
          } else {
            const decodedJwt = decodeMidJwt(auth.idToken);

            set({
              auth: auth
                ? {
                    ...auth,
                    expiresAt: Date.now() + auth.expiresIn * 1000,
                    rememberMe: decodedJwt.remember_me,
                  }
                : auth,
            });
          }
        },
        postLogin(error) {
          if (error) {
            set({ loading: false, status: 'not-authenticated', error });
          } else {
            set({ loading: false, status: 'authenticated', error: null });
          }
        },

        logout() {
          set({
            auth: null,
            status: 'not-authenticated',
            error: null,
            loading: false,
          });
        },
      }),
      {
        name: 'auth-store',
        storage: createStickyStorage({ prefix: 'auth' }),
        // Sélection des données que l'on souhaite persistantes
        partialize: ({ auth }) => {
          if (!auth) {
            return {};
          }

          return { auth };
        },
      },
    ),
    {
      name: 'auth-store',
      enabled: !ConfigCommon.env.isProd && !ConfigCommon.env.isTest,
    },
  ),
);
