import { useState } from 'react';
import type { TooltipProps, TypographyVariant } from '@mui/material';
import { ClickAwayListener, Fab, Tooltip } from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ variant: TypographyVariant }>()(theme => {
  const {
    app: { colors },
  } = theme;
  return {
    root: {
      minHeight: 0,
      borderRadius: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
      '&.Mui-focusVisible': {
        borderStyle: 'solid',
        borderColor: colors.black,
        borderWidth: 3,
      },
    },
  };
});

interface TooltipChipProps extends TooltipProps {
  isClickable?: boolean;
  buttonAriaLabel?: string;
  size?: TypographyVariant;
  buttonClassname?: string;
}

const HtmlTooltip = withStyles(Tooltip, theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: theme.palette.text.primary,
    fontSize: 13,
    padding: 15,
    borderRadius: 5,
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.25)',
  },
}));

const DefaultTooltipChip = ({
  buttonClassname,
  children,
  size,
  ...props
}: TooltipChipProps) => {
  const { classes } = useStyles({ variant: size ?? 'body1' });

  return (
    <HtmlTooltip {...props} className={`${classes.root} ${props.className}`}>
      <Fab size="small" color="primary" className={buttonClassname}>
        {children}
      </Fab>
    </HtmlTooltip>
  );
};

const ClickableTooltipChip = ({
  buttonClassname,
  buttonAriaLabel,
  children,
  size,
  ...props
}: TooltipChipProps) => {
  const { classes } = useStyles({ variant: size ?? 'body1' });

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <HtmlTooltip
        {...props}
        className={classes.root}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <Fab
          size="small"
          className={buttonClassname}
          onClick={handleTooltipOpen}
          aria-label={buttonAriaLabel}
        >
          {children}
        </Fab>
      </HtmlTooltip>
    </ClickAwayListener>
  );
};

export const TooltipChip = ({
  isClickable,
  children,
  ...props
}: TooltipChipProps) =>
  isClickable ? (
    <ClickableTooltipChip {...props}>{children}</ClickableTooltipChip>
  ) : (
    <DefaultTooltipChip {...props}>{children}</DefaultTooltipChip>
  );
