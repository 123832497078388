import React from 'react';
import type { Theme } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({ typography: { pxToRem } }: Theme) => ({
  loading: {
    margin: '0 1rem',
    maxHeight: pxToRem(24),
    maxWidth: pxToRem(24),
  },
}));

export const CircularLoader = (): JSX.Element => {
  const { classes } = useStyles();

  return <CircularProgress className={classes.loading} size="1rem" />;
};
