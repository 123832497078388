import { getEnv, getFeatures } from '@sticky/config';

// Setup Distribution Channel
export enum DistributionChannel {
  SNCF_CONNECT = 'OUI',
  OTHER = 'OTHER',
  TRAINLINE = 'TRAINLINE',
  KOMBO = 'KOMBO',
}

export const BrandIds = [
  'sncf-connect',
  'feria',
  'trainline',
  'kombo',
  'lighthouse',
];

export type BrandConfig = {
  readonly id: (typeof BrandIds)[number];
  readonly isPublic: boolean;
  readonly distributionChannel: DistributionChannel;
  readonly enabled: boolean;
  readonly url?: string;
};

export const BrandConfigs: BrandConfig[] = [
  {
    id: 'sncf-connect',
    enabled: true,
    isPublic: true,
    distributionChannel: DistributionChannel.SNCF_CONNECT,
    url: 'https://www.sncf-connect.com/',
  },
  {
    id: 'feria',
    enabled: getFeatures().brands.feria,
    isPublic: false,
    distributionChannel: DistributionChannel.OTHER,
  },
  {
    id: 'lighthouse',
    enabled: true,
    isPublic: false,
    distributionChannel: DistributionChannel.OTHER,
  },
  {
    id: 'trainline',
    enabled: getFeatures().brands.trainline,
    isPublic: true,
    distributionChannel: DistributionChannel.TRAINLINE,
    url: 'https://www.thetrainline.com/fr',
  },
  {
    id: 'kombo',
    enabled: getFeatures().brands.kombo,
    isPublic: true,
    distributionChannel: DistributionChannel.KOMBO,
    url: getEnv('VITE_BRAND_URL_KOMBO'),
  },
];
