import { useContext, useEffect } from 'react';
import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { t } from '@sticky/i18n';

import { BrandContext } from '../../features/brand/brand-provider';
import { useRoute } from '../../features/router/useRoute';

export const PageMetas = () => {
  const route = useRoute();
  const { brand } = useContext(BrandContext);

  let title = `${t('brand.productName')} ${route?.title}`;
  if (brand)
    title += ` - ${t('brand.name', {
      context: brand?.id?.toUpperCase(),
    })}`;

  // Change page title
  useEffect(() => {
    if (document.title !== title) {
      document.title = title;
    }
  }, [title]);

  // Add Typography
  return (
    <Typography component="h1" sx={visuallyHidden}>
      {route?.title}
    </Typography>
  );
};
