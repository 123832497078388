import { Typography } from '@mui/material';

import { ActionButton } from '@sticky/components';
import { t, Trans } from '@sticky/i18n';

import { ModalBox } from '../../../../components/modal/modal-box';
import { useAppSelector } from '../../../../hooks';
import { Routes } from '../../../../routes/routes';

export const ModalServiceTemporaryUnavailable = () => {
  const serviceTemporaryUnavailable = useAppSelector(
    state => state.customer.serviceTemporaryUnavailable,
  );

  return (
    <ModalBox
      open={!!serviceTemporaryUnavailable}
      title={t('auth.modals.service-unavailable.title')}
      closeButton={false}
      actions={[
        <ActionButton href={Routes.getPathById('home')} key={0}>
          {t('actions.go-home-page')}
        </ActionButton>,
      ]}
    >
      <Typography>
        <Trans i18nKey="auth.modals.service-unavailable.message" />
      </Typography>
    </ModalBox>
  );
};
