import { z } from 'zod';

export const midEnvVars = z
  .object({
    VITE_MID_URL: z.string(),
    VITE_MID_CLIENT_ID: z.string(),
    VITE_MID_RESPONSE_TYPE: z.string(),
    VITE_MID_SCOPE: z.string(),
  })
  .parse(import.meta.env);
