import { capitalize } from '@mui/material';
import { parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { getEnv } from '@sticky/config';
import { Logger } from '@sticky/logger';

/**
 * Convert an ISO String to 'PPP' format (ie 02 Juillet 2021 in fr).
 *
 * @param {string|undefined} isoDateString - The date to transform.
 * @returns {string} - The date formated in 'PPP' format.
 */
export const toFrenchDate = (isoDateString: string | undefined): string =>
  isoDateString
    ? new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(new Date(isoDateString))
    : '';

/**
 * Convert an ISO String to numeric format (ie DD/MM/YYYY in fr).
 *
 * @param {string} isoDateString - The date to transform.
 * @returns {string} - The date formated to DD/MM/YYYY.
 */
export const toNumericFrenchDate = (isoDateString?: string | null): string =>
  isoDateString
    ? new Intl.DateTimeFormat('fr-FR').format(new Date(isoDateString))
    : '';

/**
 * Convert a string date formated as 'YYYY-MM-DD' to 'Month YY'
 *
 * @param {string} str - The string date to transform.
 * @returns {string} - The date formated to Month YY.
 */
export const formatYYYYMMDDMonthNameAndYearTz = (
  str: string | undefined = '',
): string => {
  const date = zonedTimeToUtc(
    parse(str, 'yyyy-MM-dd', new Date()),
    getEnv('VITE_TZ'),
  );
  return capitalize(
    new Intl.DateTimeFormat('fr-FR', {
      year: 'numeric',
      month: 'long',
      timeZone: getEnv('VITE_TZ'),
    }).format(date),
  );
};

export const toNumericFrenchDateTime = (isoDateString?: string): string =>
  isoDateString
    ? new Intl.DateTimeFormat('fr-FR', {
        dateStyle: 'long',
        timeStyle: 'short',
      }).format(new Date(isoDateString))
    : '';

/**
 * Return the day of the month and month as "DD/MM".
 *
 * @param {Date} date the date
 * @return {string} the date formatted as "DD/MM"
 */
export const formatToDDMM = (date: Date | null): string => {
  if (!date) return '';
  return new Intl.DateTimeFormat('fr-FR', {
    day: '2-digit',
    month: '2-digit',
  }).format(date);
};

/**
 * Return date to the string format YYYY-MM-dd
 *
 * @param value {Date} - The date to format.
 * @returns {string} the date formatted as "YYYY-MM-dd".
 */
export const formatApiDateUTC = (
  value: Date | string | undefined,
): string | undefined => {
  if (!value) return undefined;
  if (typeof value === 'string') {
    return value.slice(0, 10);
  } else {
    const withoutTimezoneOffset = new Date(
      value.valueOf() - value.getTimezoneOffset() * 60 * 1000,
    );
    return withoutTimezoneOffset.toISOString().slice(0, 10);
  }
};

/**
 * Formats a date to full month name and year (e.g. "septembre 2021").
 * @param date the date to format
 * @returns the formatted date
 */
export const formatToMonthNameAndYear = (date: Date): string =>
  new Intl.DateTimeFormat('fr-FR', {
    month: 'long',
    year: 'numeric',
  }).format(date);

/**
 * Formats the time part of a date as "HH'h'mm".
 * @param date the date to format
 * @returns the time
 */
export const formatToHHMM = (date?: Date): string => {
  try {
    return new Intl.DateTimeFormat('fr-FR', { timeStyle: 'short' })
      .formatToParts(date)
      .filter(part => ['hour', 'minute'].includes(part.type))
      .map(part => part.value)
      .join('h');
  } catch (e) {
    Logger.error(`Date with error : ${date}`, e);
    return '';
  }
};

/**
 * Formats the date as a fully human readable date as "jeudi 16 février 2023".
 *
 * @param date the date
 * @param withoutYear if true, the format will not have year.
 * @returns the formatted date
 */
export const formatToFullHumanReadableDate = (
  date: Date,
  withoutYear = false,
): string => {
  try {
    return new Intl.DateTimeFormat('fr-FR', {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      ...(!withoutYear && { year: 'numeric' }),
    }).format(date);
  } catch (e) {
    return '';
  }
};

/**
 * Formats date as "jeu. 16 févr. 2023".
 *
 * @param date
 * @param withoutYear
 * @returns
 */
export const formatToShortHumanReadableDate = (
  date: Date,
  withoutYear = false,
): string =>
  new Intl.DateTimeFormat('fr-FR', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    ...(!withoutYear && { year: 'numeric' }),
  }).format(date);

/**
 * Formats to the ISO 8601 format with only the date part, without zimezone (e.g. 2021-09-17)
 *
 * @param date the date to format
 * @returns the formatted date
 */
export const formatToISODate = (date: Date): string =>
  `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

/**
 * Formats to the ISO 8601 format with the date, hours and minutes part, without timezone (e.g. 2021-09-17T15:52)
 *
 * @param date the date to format
 * @returns the formatted date
 */
export const formatToISODateHHMM = (date: Date): string =>
  `${formatToISODate(date)}T${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
