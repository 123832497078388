import type { Theme, TypographyProps } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Trans } from '@sticky/i18n';

import { TypoWithSkeleton } from '../typography/typography-skeleton';

export type Field = [
  string | undefined,
  ...(string | JSX.Element | undefined)[],
];

const useStyles = makeStyles<{ disabled?: boolean }>()((
  theme: Theme,
  props,
) => {
  const {
    app: { colors },
  } = theme;
  return {
    label: {
      width: 'min-content',
    },
    row: {
      '& p': {
        color: props.disabled ? colors.warmGray2 : colors.black,
      },
    },
  };
});

export const Label = (props: { value?: string }): JSX.Element => (
  <Typography variant="subtitle2" component="p">
    <Trans i18nKey={props.value}></Trans>
  </Typography>
);
export const Value = ({
  ValueOrComponent,
  ...props
}: {
  ValueOrComponent?: string | JSX.Element;
  isReady?: boolean;
  color?: TypographyProps['color'];
  bold?: boolean;
}): JSX.Element => (
  <TypoWithSkeleton
    component="p"
    skelProps={{ width: `${Math.random() * 20}vw` }}
    isReady={!!props.isReady}
    color={props.color}
    isBold={props.bold}
    data-dd-privacy="mask"
  >
    {ValueOrComponent ?? ''}
  </TypoWithSkeleton>
);

export const DataTable = ({
  colorDifferent,
  disabled,
  fields,
  id,
  indexBold,
  indexColorDifferent,
  isReady,
}: {
  fields: Field[];
  isReady?: boolean;
  indexColorDifferent?: number;
  indexBold?: number;
  colorDifferent?: TypographyProps['color'];
  disabled?: boolean;
  id?: string;
}) => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('breakPointDesktop'));
  const { classes } = useStyles({ disabled });

  // Able to attribute a color if necessary
  const getColor = (valkey: number): TypographyProps['color'] => {
    if (
      indexColorDifferent &&
      colorDifferent &&
      valkey === indexColorDifferent
    ) {
      return colorDifferent;
    }
    return undefined;
  };

  // Able to attribute a bold extension
  const getBold = (valkey: number): boolean =>
    !!(indexBold && valkey === indexBold);

  const Item = ([lab, ...values]: Field, key: number): JSX.Element => (
    <TableRow key={key} className={classes.row}>
      {isDesktop ? (
        <>
          <TableCell
            component="th"
            className={classes.label}
            scope="row"
            role="rowheader"
          >
            <Label value={lab} />
          </TableCell>

          {values.map((val, valKey) => (
            <TableCell key={valKey} data-test-id={lab}>
              <Value
                ValueOrComponent={val}
                isReady={isReady}
                color={getColor(key)}
                bold={getBold(key)}
              />
            </TableCell>
          ))}
        </>
      ) : (
        <TableCell data-test-id={lab}>
          <Label value={lab} />
          {values.map((val, valKey) => (
            <Value
              key={valKey}
              ValueOrComponent={val}
              isReady={isReady}
              color={getColor(key)}
              bold={getBold(key)}
            />
          ))}
        </TableCell>
      )}
    </TableRow>
  );

  return (
    <Table id={id}>
      <TableBody>
        {fields.filter(([, value]) => !isReady || !!value).map(Item)}
      </TableBody>
    </Table>
  );
};
