import { Field } from 'react-final-form';
import type { CheckboxData, CheckboxesProps } from 'mui-rff';
import { Checkboxes } from 'mui-rff';
import { makeStyles } from 'tss-react/mui';

import type { IValidator } from './form-validators';

interface FormInputCheckboxesProps extends CheckboxesProps {
  data: CheckboxData | CheckboxData[];
  bold?: boolean;
  validate?: IValidator;
}

const useStyles = makeStyles()(() => ({
  isBold: {
    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
    },
  },
}));

export const FormInputCheckbox = (
  props: FormInputCheckboxesProps,
): JSX.Element => {
  const { label, name, data, validate, value, bold = 'true', ...rest } = props;
  const { classes } = useStyles();
  const className = bold ? classes.isBold : '';
  const inputProps = {
    inputProps: {
      'aria-labelledby': props.name + '-label',
    },
  };
  return (
    <Field name={name} validate={validate}>
      {() => (
        <div className={className}>
          <Checkboxes
            label={label}
            name={name}
            data={data}
            {...rest}
            color="primary"
            {...inputProps}
          />
        </div>
      )}
    </Field>
  );
};
