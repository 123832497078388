import { outputs } from './outputs';

export class Logger {
  public static outputs = outputs;

  /**
   * Add user context for logging (iuc only for yet).
   *
   * @param user
   */
  static setUser(user: { iuc: string } | undefined) {
    Object.values(this.outputs).forEach(output => output.setUser(user));
  }

  /**
   * Log a normal message
   *
   * @param title - Title of the message to log.
   * @param args - Arguments to log (optional)
   * @returns
   */
  static log(title: string, ...args: unknown[]): void {
    Object.values(this.outputs).forEach(output => output.log(title, ...args));
  }

  /**
   * Log a warning message
   *
   * @param title - Title of the message to log.
   * @param args - Arguments to log (optional)
   * @returns
   */
  static warn(title: string, ...args: unknown[]): void {
    Object.values(this.outputs).forEach(output => output.warn(title, ...args));
  }

  /**
   * Log an error message
   *
   * @param title - Title of the message to log.
   * @param exception - The exception to log
   * @returns
   */
  static error(title: string, exception: Error | unknown): void {
    Object.values(this.outputs).forEach(output =>
      output.error(title, exception),
    );
  }
}
