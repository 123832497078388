import React from 'react';
import { Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

const useStyles = makeStyles()((theme: Theme) => {
  const { breakpoints } = theme;
  return {
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      textAlign: 'center',
      alignSelf: 'center',

      [breakpoints.up('breakPointDesktop')]: {
        textAlign: 'left',
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: '30rem',
      },
    },
  };
});

export const HomeDescription = (): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Typography className={classes.root} component="h1" variant="h1">
      {t('home.description')}
    </Typography>
  );
};
