import { configureFeatures } from '@sticky/config';

configureFeatures({
  dev: {
    enrollment: {
      mandate: {
        check: true,
      },
      payment: {
        canUseCB: true,
      },
      id360: {
        help: true,
      },
      gpg: {
        sendSms: false,
        aisHelp: true,
      },
    },
    strapi: {
      enabled: true,
    },
    customer: {
      invoice: {
        regularizeUnpayment: true,
      },
      noShow: {
        cancel: true,
      },
    },
    migration: {
      enabled: false,
    },
    banners: {
      activeOnHomePage: true,
    },
    brands: {
      feria: false,
      trainline: true,
      kombo: true,
    },
    ouigo: {
      enabled: true,
    },
    idtgvmax2: {
      enabled: true,
    },
    freePlaces: {
      enabled: true,
      withCalendar: true,
      withKeyNumbers: true,
    },
    tracker: {
      didomi: {
        enabled: true,
      },
      datadog: {
        enabled: true,
        traceSampleRate: 100,
        sessionSampleRate: 100,
        trackUserInteractions: true,
        sessionReplaySampleRate: 0,
      },
    },
    subscription: {
      displayQRCode: true,
    },
    enrichment: {
      enabled: true,
    },
  },

  prod: {
    subscription: {
      displayQRCode: true,
    },
    enrollment: {
      mandate: {
        check: true,
      },
      payment: {
        canUseCB: true,
      },
      id360: {
        help: true,
      },
      gpg: {
        sendSms: true,
        aisHelp: true,
      },
    },
    strapi: {
      enabled: true,
    },
    customer: {
      invoice: {
        regularizeUnpayment: true,
      },
      noShow: {
        cancel: true,
      },
    },
    migration: {
      enabled: false,
    },
    brands: {
      feria: false,
      trainline: true,
      kombo: true,
    },
    ouigo: {
      enabled: true,
    },
    banners: {
      activeOnHomePage: true,
    },
    idtgvmax2: {
      enabled: true,
    },
    freePlaces: {
      enabled: true,
      withCalendar: true,
      withKeyNumbers: true,
    },
    tracker: {
      didomi: {
        enabled: true,
      },
      datadog: {
        enabled: true,
        traceSampleRate: 5,
        sessionSampleRate: 5,
        trackUserInteractions: true,
        sessionReplaySampleRate: 5,
      },
    },
    enrichment: {
      enabled: false,
    },
  },
});
