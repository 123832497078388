import { createSlice } from '@reduxjs/toolkit';

import type { IDisclaimerState } from '../models/disclaimer';

// Name of the reducer
const REDUCER_NAME = 'disclaimer';

// Initial state
const initialState: IDisclaimerState = {
  agencyRgpd: {
    mustBeDisplayed: false,
    hasBeenDisplayed: false,
  },
};

// The Slice reducer
const disclaimerSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setDisclaimerAgencyRgpd: (state, action) => {
      state.agencyRgpd = action.payload;
    },
  },
});

// Export
export const { setDisclaimerAgencyRgpd } = disclaimerSlice.actions;
const disclaimerReducer = disclaimerSlice.reducer;
export { disclaimerReducer };
