import { useEffect, useState } from 'react';

import type { DetailsTravel, Travel } from '../../../features/reservation';
import { ReservationService } from '../../../features/reservation';

export const usePrintTravel = (
  travel: Travel | undefined,
  travelDetails: DetailsTravel | undefined,
  cacheExpired: boolean | undefined,
): {
  isIdle: boolean;
  isLoading: boolean;
  barcodeImg: string | undefined;
  error?: string;
} => {
  const [status, setStatus] = useState('idle');
  const [barcodeImg, setBarcodeImg] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();

  const orderId = travel?.orderId;
  const serviceItemId = travel?.serviceItemId;
  const tcn = travelDetails?.tcn;

  useEffect(() => {
    const fetchData = async () => {
      setStatus('fetching');

      if (!orderId || !serviceItemId || !tcn || cacheExpired) return;

      try {
        const data = await ReservationService.printTravel({
          orderId,
          serviceItemId,
          tcn: [tcn],
        });
        setBarcodeImg(data.barcodeImageData);
        setStatus('fetched');
      } catch {
        setError('technicalError');
        setStatus('error');
      }
    };
    fetchData();
  }, [cacheExpired, orderId, serviceItemId, tcn]);

  return {
    isIdle: status === 'idle',
    isLoading: status === 'fetching',
    barcodeImg,
    error,
  };
};
