import type { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import type { IStep } from './StepperCommon';
import { StepperCommon } from './StepperCommon';

const useStyle = makeStyles()(
  ({ breakpoints, app: { colors }, typography: { pxToRem } }: Theme) => ({
    globalSteps: {
      padding: `${pxToRem(15)} ${pxToRem(15)} ${pxToRem(15)} ${pxToRem(15)}`,
      [breakpoints.up('breakPointDesktop')]: {
        backgroundColor: colors.disabledBackground,
        display: 'flex',
        justifyContent: 'center',
      },
    },
    header: {
      [breakpoints.up('breakPointDesktop')]: {
        backgroundColor: colors.white,
        borderTopLeftRadius: pxToRem(16),
        borderTopRightRadius: pxToRem(16),
        marginBottom: pxToRem(-12),
        padding: `${pxToRem(27)} ${pxToRem(40)} ${pxToRem(30)}`,
      },
    },
  }),
);

interface WayStepProps {
  steps: IStep[];
  hasError?: boolean;
}

export const WayStep = ({ steps, hasError }: WayStepProps): JSX.Element => {
  const { classes } = useStyle();

  const theme = useTheme();

  return (
    <header className={classes.header}>
      <div className={classes.globalSteps}>
        <StepperCommon
          steps={steps}
          orientation="horizontal"
          colorIcon={theme.app.colors.warmGray2}
          isWayStepper
          colorLabels={theme.app.colors.textPrimary}
          hasError={hasError}
          showLabelOnMobile
        />
      </div>
    </header>
  );
};
