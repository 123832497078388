import { Field, useField } from 'react-final-form';
import { Box, IconButton, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { t, Trans } from '@sticky/i18n';

import type { Station } from '../../features/reservation';

import type { IInputAutocompleteAsyncSearchOptions } from './input-autocomplete';
import { InputAutocomplete } from './input-autocomplete';

const SwitchButton = () => {
  const {
    app: { colors },
  } = useTheme();
  return (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M8.25214 6.36617V15.0372L6.05532 12.8465L5.1925 13.7093L8.86407 17.3809L12.5356 13.7093L11.6667 12.8465L9.476 15.0372V6.36617H8.25214Z"
        fill={colors.textContrasted}
      />
      <path
        d="M15.7479 17.3799L15.7479 8.70892L17.9447 10.8996L18.8075 10.0368L15.1359 6.36523L11.4644 10.0368L12.3333 10.8996L14.524 8.70892L14.524 17.3799L15.7479 17.3799Z"
        fill={colors.textContrasted}
      />
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={colors.textContrasted}
        strokeWidth="2"
      />
    </svg>
  );
};

type StyleProps = { switchButtonPaddingTop?: number };
const useStyles = makeStyles<StyleProps>()(
  ({ breakpoints, app: { colors }, typography: { pxToRem } }: Theme) => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
    },

    searchFields: {
      flex: 1,
    },

    autocompleteField: {
      marginBottom: pxToRem(23),
      [breakpoints.up('breakPointDesktop')]: {
        display: 'grid',
        gridTemplateColumns: `${pxToRem(195)} 1fr`,
        columnGap: '1rem',
        marginBottom: pxToRem(15),
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
    autocompleteWrapper: {
      '& .Mui-error': {
        color: colors.errorDark,
        fontSize: pxToRem(14),
        marginTop: pxToRem(6),
        [breakpoints.up('breakPointDesktop')]: {
          fontSize: pxToRem(16),
        },
      },
    },
    inputLabel: {
      fontSize: pxToRem(13),
      '& span': {
        color: colors.textContrasted,
      },
      [breakpoints.up('breakPointDesktop')]: {
        paddingTop: pxToRem(12),
        fontSize: pxToRem(16),
        fontWeight: 900,
      },
    },

    switchButtonBox: {
      display: 'flex',
      alignItems: 'center',
    },

    switchButton: {
      cursor: 'pointer',
      width: pxToRem(36),
      height: pxToRem(36),
      marginLeft: pxToRem(14),
      marginTop: pxToRem(14),
      padding: 0,
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: pxToRem(0),
      },
      '&:focus-visible': {
        outline: 'dashed',
        outlineOffset: '2px',
        outlineWidth: 'thin',
        outlineColor: colors.textContrasted,
      },

      '& > svg': {
        width: '80%',
        height: '80%',
      },
    },
  }),
);

interface OriginDestinationInputsProps {
  className?: string;
  loading?: boolean;
  loadingText?: string;
  handleLoading?: () => void;
  originStations: Station[];
  destinationStations: Station[];
  originAsyncSearchOptions?: IInputAutocompleteAsyncSearchOptions;
  destinationAsyncSearchOptions?: IInputAutocompleteAsyncSearchOptions;
  onChange?: (
    property: 'destination' | 'origin',
    value?: Station | null,
  ) => void;
  fieldClassName?: string;
  prefix?: string;
  prefixAriaLabel?: string;
  withSwitch?: boolean;
}

export const OriginDestinationInputs = ({
  className = '',
  loading = false,
  loadingText,
  handleLoading,
  originStations,
  destinationStations,
  originAsyncSearchOptions,
  destinationAsyncSearchOptions,
  onChange,
  fieldClassName,
  prefix,
  prefixAriaLabel,
  withSwitch,
}: OriginDestinationInputsProps) => {
  const { classes } = useStyles({});

  const { input: originInput } = useField<Station | null>(
    `${prefix ? `${prefix}.` : ''}origin`,
  );
  const { input: destinationInput } = useField<Station | null>(
    `${prefix ? `${prefix}.` : ''}destination`,
  );

  const _prefixAriaLabel = prefixAriaLabel ? `${prefixAriaLabel}, ` : '';

  const onSwitch = () => {
    const _origin = originInput.value;
    const _destination = destinationInput.value;
    originInput.onChange(_destination);
    destinationInput.onChange(_origin);
  };

  // Filter autocomplete options : keep input value and exclude other input
  const filterInputOptions = (
    options: Station[],
    inputValue: string,
    exclude: Station | null,
  ): Station[] =>
    options.filter(
      station =>
        new RegExp(inputValue, 'i').test(station.label) &&
        station.label !== exclude?.label,
    );

  return (
    <div className={`${classes.wrapper} ${className}`.trim()}>
      <div className={classes.searchFields}>
        <div
          className={`${classes.autocompleteField}${
            fieldClassName ? ` ${fieldClassName}` : ''
          }`}
        >
          <span
            id={`${prefix}-origin-station-aria-label`}
            className="onlyForScreenReader"
          >
            {`${_prefixAriaLabel}${t(
              'reservation.originDestinationInputs.aria.origin',
            )}`}
          </span>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={`${prefix ? `${prefix}-` : ''}origin-station`}>
            <Typography className={classes.inputLabel}>
              <Trans components={[<span key={0} />]}>
                {t('reservation.originDestinationInputs.origin')}
              </Trans>
            </Typography>
          </label>
          <Field name={`${prefix ? `${prefix}.` : ''}origin`}>
            {({ input, meta }) => (
              <div className={classes.autocompleteWrapper}>
                <InputAutocomplete<Station>
                  id={`${prefix ? `${prefix}-` : ''}origin-station`}
                  error={!!meta.error}
                  filterOptions={(options, { inputValue }) =>
                    filterInputOptions(
                      options,
                      inputValue,
                      destinationInput?.value,
                    )
                  }
                  helperText={meta.touched ? meta.error : ''}
                  loading={loading}
                  loadingText={loadingText}
                  onBlur={input.onBlur}
                  onChange={(_, value = null) => {
                    onChange?.('origin', value);
                    originInput.onChange(value);
                  }}
                  onOpen={() => handleLoading?.()}
                  options={originStations}
                  value={input.value || null}
                  ariaLabel={
                    prefix ? `${prefix}-origin-station-aria-label` : undefined
                  }
                  getOptionLabel={(option: Station | string) =>
                    (typeof option === 'string'
                      ? option
                      : option.label
                    )?.toUpperCase() ?? ''
                  }
                  asyncSearchOptions={originAsyncSearchOptions}
                  autoComplete={false}
                />
              </div>
            )}
          </Field>
        </div>
        <div
          className={`${classes.autocompleteField}${
            fieldClassName ? ` ${fieldClassName}` : ''
          }`}
        >
          <span
            id={`${prefix}-destination-station-aria-label`}
            className="onlyForScreenReader"
          >
            {`${_prefixAriaLabel}${t(
              'reservation.originDestinationInputs.aria.destination',
            )}`}
          </span>

          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={`${prefix ? `${prefix}-` : ''}destination-station`}>
            <Typography className={classes.inputLabel}>
              <Trans components={[<span key={0} />]}>
                {t('reservation.originDestinationInputs.destination')}
              </Trans>
            </Typography>
          </label>
          <Field name={`${prefix ? `${prefix}.` : ''}destination`}>
            {({ input, meta }) => (
              <div className={classes.autocompleteWrapper}>
                <InputAutocomplete<Station>
                  id={`${prefix ? `${prefix}-` : ''}destination-station`}
                  error={!!meta.error}
                  filterOptions={(options, { inputValue }) =>
                    filterInputOptions(options, inputValue, originInput?.value)
                  }
                  helperText={meta.touched ? meta.error : ''}
                  loading={loading}
                  loadingText={loadingText}
                  onBlur={input.onBlur}
                  onChange={(_, value = null) => {
                    onChange?.('destination', value);
                    destinationInput.onChange(value);
                  }}
                  onOpen={() => handleLoading?.()}
                  options={destinationStations}
                  value={input.value || null}
                  ariaLabel={
                    prefix
                      ? `${prefix}-destination-station-aria-label`
                      : undefined
                  }
                  getOptionLabel={(option: Station | string) =>
                    (typeof option === 'string'
                      ? option
                      : option.label
                    )?.toUpperCase() ?? ''
                  }
                  asyncSearchOptions={destinationAsyncSearchOptions}
                  autoComplete={false}
                />
              </div>
            )}
          </Field>
        </div>
      </div>
      {withSwitch && (
        <Box className={classes.switchButtonBox}>
          <IconButton
            className={classes.switchButton}
            onClick={onSwitch}
            aria-label={t('reservation.originDestinationInputs.aria.switch')}
            size="large"
          >
            <SwitchButton />
          </IconButton>
        </Box>
      )}
    </div>
  );
};
