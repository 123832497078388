import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Theme } from '@mui/material';
import { Card, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { t } from '@sticky/i18n';
import { useGpgStore } from '@sticky/service-gpg';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { Routes } from '../../../../routes/routes';
import { InvoiceRejectType } from '../../../subscription/models/invoice';
import { subscriptionSelectors } from '../../../subscription/selectors';
import {
  clearInvoice,
  clearRegularizationInfos,
  readSubscription,
  SUBSCRIPTION_STORE_SHOW_CARD_NUMBER,
} from '../../../subscription/store/subcription-slice';

const useStyles = makeStyles()(({ app: { colors }, spacing }: Theme) => ({
  card: {
    marginTop: spacing(3),
    height: '100%',
    padding: '2rem',
  },
  title: {
    color: colors.textContrasted,
  },
  paragraph: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  button: {
    marginTop: spacing(2),
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export const ConfirmInvoicePaymentCard = () => {
  const { classes } = useStyles();

  const theme = useTheme();
  const { breakpoints } = theme;
  const isDesktop = useMediaQuery(breakpoints.up('breakPointDesktop'));
  const dispatch = useAppDispatch();
  const [oppositionInvoice, setOppositionInvoice] = useState(false);
  const navigate = useNavigate();
  const gpgStore = useGpgStore();

  const subscriptionState = useAppSelector(state => state.subscription);
  const isSepaPayment = useAppSelector(
    subscriptionSelectors.isSepaPaymentMethod,
  );

  const invoice = subscriptionState.subscription?.invoices.find(
    i => i.id === subscriptionState.regularizationInfos?.invoiceId,
  );

  useEffect(() => {
    if (invoice?.rejectType === InvoiceRejectType.OPPOSITION) {
      setOppositionInvoice(true);
    }

    dispatch(clearRegularizationInfos());
    dispatch(
      readSubscription({
        cardNumber: window.localStorage.getItem(
          SUBSCRIPTION_STORE_SHOW_CARD_NUMBER,
        ) as string,
      }),
    );
  }, [invoice?.rejectType, dispatch, navigate]);

  const handleUpdateIban = () => {
    gpgStore.reset();
    navigate(Routes.getPathById('customer/my-coordinates/update-iban'));
  };

  return (
    <Card className={classes.card}>
      <Typography
        variant={isDesktop ? 'h2' : 'subtitle2'}
        className={classes.title}
      >
        {t('customer.subscription.regularization.success.header')}
      </Typography>
      <Typography
        variant={isDesktop ? 'h6' : 'body2'}
        className={classes.paragraph}
      >
        {t('customer.subscription.regularization.success.thanks')}
      </Typography>
      {oppositionInvoice && isSepaPayment && (
        <Typography variant={isDesktop ? 'h6' : 'body1'}>
          {t('customer.subscription.regularization.success.update-iban-text')}
        </Typography>
      )}
      <div className={classes.button}>
        {oppositionInvoice && isSepaPayment && (
          <ActionButton
            variant="outlined"
            onClick={handleUpdateIban}
            loading={subscriptionState.loading}
          >
            {t('customer.subscription.regularization.success.update-iban')}
          </ActionButton>
        )}
        <ActionButton
          onClick={() => {
            dispatch(clearInvoice());
            navigate(Routes.getPathById('customer/my-subscription'));
          }}
        >
          {t('customer.subscription.regularization.success.back')}
        </ActionButton>
      </div>
    </Card>
  );
};
