import type { StickyAssets } from '@sticky/components';

export const assets: StickyAssets = {
  companyLogo: '/assets/logos/logo-tgv-inoui-white.svg',
  enterpriseLogo: '/assets/logos/logo-sncf.svg',
  brandLogo: {
    default: '/assets/logos/brand-logo-max-junior.svg',
    IDTGV_MAX: '/assets/logos/brand-logo-idtgv-max2.svg',
  },
};
