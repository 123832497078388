import type { FunctionComponent } from 'react';
import { lazy, Suspense } from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({ breakpoints }) => ({
  skel: {
    minHeight: '37px',
    [breakpoints.up('breakPointDesktop')]: {
      height: '60px',
    },
  },
}));

const LazyHeaderFooter = lazy(() => import('./lazy'));

export const TrainlineHeader: FunctionComponent = () => {
  const { classes } = useStyles();
  return (
    <Suspense fallback={<Skeleton className={classes.skel} />}>
      <LazyHeaderFooter />
    </Suspense>
  );
};
