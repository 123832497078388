import type { AxiosRequestConfig } from 'axios';

import { getAuthConfig } from '../../authConfig';
import { isStickyGatewayError } from '../../errors/StickyGatewayError';
import { createClient } from '../../helpers';

type RetryableAxiosRequestConfig = AxiosRequestConfig & {
  _retry: boolean;
};

export const auth = createClient();

auth.interceptors.request.use(
  config => {
    const token = getAuthConfig()?.getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

auth.interceptors.response.use(
  response => response,
  async error => {
    const stickyGatewayError = isStickyGatewayError(error);

    // Si le token n'est plus valide, on essaye en demandant un nouveau token
    // grâce au refresh token
    if (
      error.response?.status === 401 ||
      (stickyGatewayError &&
        ['SYG_40101', 'SYG_40100'].includes(
          stickyGatewayError.response.data.errorCode,
        ))
    ) {
      const originalRequest = error.config as RetryableAxiosRequestConfig;

      if (!originalRequest._retry) {
        originalRequest._retry = true;
        await getAuthConfig().refreshToken();

        return auth(originalRequest);
      } else {
        // Redirige vers la page d'accueil
        window.location.href = '/';
      }
    }

    return Promise.reject(error);
  },
);
