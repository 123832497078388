import React from 'react';
import { Link, Skeleton, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { getEnv, getFeatures } from '@sticky/config';
import { t, Trans } from '@sticky/i18n';

import { useHasIdTgvMaxSubscription } from '../../../features/subscription/hooks/useHasIdTgvMaxSubscription';
import { subscriptionSelectors } from '../../../features/subscription/selectors';
import { useAppSelector } from '../../../hooks';
import { Routes } from '../../../routes/routes';
import { isMFA } from '../../../utils/product';
import { CGVLink } from '../../terms/cgv-link';
import { TypoWithSkeleton } from '../../typography/typography-skeleton';

type LegalMentionsFooterWidgetProps = {
  loading?: boolean;
  withRetractation?: boolean;
  withSubscription?: boolean;
  productSelect?: string;
};

const useStyles = makeStyles()((theme: Theme) => {
  const {
    app: { colors },
  } = theme;

  return {
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    title: {
      paddingTop: theme.spacing(3),
      marginBottom: 20,
      borderBottom: `1px solid ${colors.warmGray2}`,
    },
    link: {
      cursor: 'pointer',
      fontWeight: 'bold',
      color: colors.textContrasted,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  };
});

export const LegalMentionsFooterWidget = ({
  loading = false,
  withSubscription = true,
  withRetractation = false,
  productSelect,
}: LegalMentionsFooterWidgetProps) => {
  const { classes } = useStyles();

  // Get subscription state
  const { subscription } = useAppSelector(
    subscriptionSelectors.getSubscriptionState,
  );
  const hasIdTgvMaxSubscription = useHasIdTgvMaxSubscription();

  // Set context for translation or choose productSelect default
  const context =
    productSelect ??
    subscription?.subscription?.productSelect ??
    getEnv('VITE_PRODUCT_SELECT_DEFAULT');

  const freePlacesUrl = getFeatures().freePlaces.enabled
    ? Routes.getPathById('free-places/available-places')
    : t('free-places.simulator.legacy-href');

  // Get CGV translations
  const cgvTranslationKey = hasIdTgvMaxSubscription
    ? 'cgvIdtgvmax2.subscriptions'
    : 'cgv.subscriptions';
  const cgvs: {
    title: string;
    content: string;
    'cgv-link'?: string;
    'external-link'?: string;
  }[] = t(cgvTranslationKey, {
    returnObjects: true,
    context: !isMFA() && getFeatures().ouigo.enabled ? 'ouigo' : context,
    freePlaces: getFeatures().freePlaces.enabled
      ? t('free-places.name')
      : t('free-places.simulator.legacy-name'),
    monIdentifiant: 'https://monidentifiant.sncf/tos/cgu.pdf',
  });

  return (
    <div
      className={classes.root}
      data-test-id="legal-mentions-footer"
      id="legal-mentions-footer"
    >
      {withSubscription &&
        cgvs?.map(cgv => (
          <div key={cgv.title}>
            <Typography
              component="h2"
              className={classes.title}
              variant="subtitle2"
            >
              {cgv.title}
            </Typography>
            {loading ? (
              <>
                <Skeleton animation="wave"></Skeleton>
                <Skeleton animation="wave"></Skeleton>
                <Skeleton animation="wave"></Skeleton>
              </>
            ) : (
              <TypoWithSkeleton
                component="p"
                skelProps={{ width: '90%' }}
                isReady
                variant="caption"
              >
                <Trans
                  defaults={cgv.content}
                  components={{
                    LinkFreePlaces: (
                      <Link href={freePlacesUrl} className={classes.link} />
                    ),
                    LinkMonIdentifiant: (
                      <Link
                        href="https://monidentifiant.sncf/tos/cgu.pdf"
                        className={classes.link}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                    LinkExternal: (
                      <Link
                        href={cgv['external-link'] ?? ''}
                        className={classes.link}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                    LinkCGV: (
                      <CGVLink
                        link={{
                          product: hasIdTgvMaxSubscription ? 'IDTGV_MAX' : '',
                        }}
                        className={classes.link}
                        variant="caption"
                      />
                    ),
                  }}
                />
              </TypoWithSkeleton>
            )}
          </div>
        ))}
      {withRetractation && (
        <>
          <Typography
            component="h2"
            className={classes.title}
            variant="subtitle2"
          >
            <Trans i18nKey="cgv.retraction.title" />
          </Typography>
          <Typography component="p" variant="caption">
            <Trans i18nKey="cgv.retraction.content" />
          </Typography>
        </>
      )}
    </div>
  );
};
