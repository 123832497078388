import type { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEnv, getFeatures } from '@sticky/config';
import { StickyGatewayClient } from '@sticky/gateway-client';
import { Logger } from '@sticky/logger';

import type { IError } from '../../../resources/types';
import type { AppThunk, RootState } from '../../../store';
import { formatToISODate, parseUtcDateIso8601 } from '../../../utils/date';
import { removeEmptyStringKey } from '../../../utils/objects';
import { formatPhoneToApi } from '../../../utils/phone/phone';
import { getCardsWithAllowedProductType } from '../../subscription';
import { readSubscription } from '../../subscription/store/subcription-slice';
import type {
  ICard,
  ICustomer,
  ICustomerState,
  ICustomerUpdate,
  IUpdateCustomerInput,
} from '../models/customer';
import {
  CardStatus,
  CardType,
  ICardContractStatus,
  ICustomerStateErrorCode,
} from '../models/customer';
import { ServiceMigrateSubscription } from '../services/migrate-subscription.service';

import { createCustomer } from './customer-slice-create';

// Variable which define the name of the customer's store
export const CUSTOMER_STORE = 'customer';

// Constants for localStorage
const CUSTOMER_LOCALSTORAGE_KEY = 'subscriptionCustomer';
const CUSTOMER_STORE_SERVICE_AVAILABLE_FORCE_DISABLE = `@sticky:${CUSTOMER_STORE}:service-available:force-disable`;

// Initial state
const initialState: ICustomerState = {
  loading: false,
  at: 0,
  readAt: 0,
  isAuth: false,
  logAfterQuote: false,
  serviceAvailableForceDisable: JSON.parse(
    window.localStorage.getItem(
      CUSTOMER_STORE_SERVICE_AVAILABLE_FORCE_DISABLE,
    ) ?? 'false',
  ),
  serviceTemporaryUnavailable: false,
};

// Request Handler to read customer infos
const retrieveCustomer: AsyncThunkPayloadCreator<
  ICustomer,
  { force?: boolean } | void,
  { rejectValue: IError }
> = async (_props, thunkApi) => {
  const httpClient = StickyGatewayClient.authClient();

  // Get default productTypes from env config
  const productTypes: string[] = getEnv('VITE_READ_CUSTOMER_CARDS')?.split(',');

  // Add feature flipped productTypes if necessary
  if (getFeatures().idtgvmax2.enabled) {
    productTypes.push(CardType.IDTGV_MAX);
  }

  const asyncFn = httpClient.post<ICustomer>('/public/customer/read-customer', {
    productTypes,
  });
  return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
};

export const readCustomer = createAsyncThunk<
  ICustomer,
  { force?: boolean } | void,
  {
    rejectValue: IError;
  }
>('get/readCustomer', retrieveCustomer, {
  condition: ({ force } = {}, { getState }) => {
    if (force) {
      return true;
    }

    const { customer } = getState() as RootState;
    const tenMinutes = 1000 * 60 * 10;
    if (
      customer.loading ||
      (customer.customer && Date.now() - customer.readAt < tenMinutes)
    ) {
      // Already fetched or in progress, don't need to re-fetch
      return false;
    }
  },
});

export const getCustomerWithSubscription =
  ({ force }: { force?: boolean } = {}): AppThunk =>
  async (dispatch, getState) => {
    // Get customer infos and cards
    await dispatch(readCustomer({ force }));

    // Get main subscription card infos
    const state = getState();
    const customer = state.customer?.customer;
    const allowedCards = getCardsWithAllowedProductType(customer?.cards);

    if (allowedCards?.length) {
      const defaultCardNumber =
        allowedCards.find(card => card.contractStatus === CardStatus.VALIDE)
          ?.cardNumber ?? allowedCards[0]?.cardNumber;
      await dispatch(
        readSubscription({ cardNumber: defaultCardNumber, force }),
      );

      // If Migration Features flipping is enabled, bind migration.
      if (
        getFeatures().migration.enabled &&
        defaultCardNumber &&
        customer?.birthDate
      ) {
        try {
          await ServiceMigrateSubscription.migrateCard({
            birthDate: formatToISODate(new Date(customer.birthDate)),
            cardNumber: defaultCardNumber,
          });
        } catch (err) {
          Logger.error('Can not bind subscription', err);
        }
      }
    }
  };

export const refreshCounters = createAsyncThunk(
  'get/refreshCounters',
  retrieveCustomer,
);

// Request Handler to get customer avatar
export const getCustomerAvatar = createAsyncThunk(
  'get/thumbnail',
  async (props, thunkApi) => {
    const httpClient = StickyGatewayClient.authClient();
    const asyncFn = httpClient.get<string>('/public/customer/thumbnail', {
      headers: {
        Accept: 'image/jpeg',
      },
    });
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

// Request Handler to update customer
export const updateCustomer = createAsyncThunk(
  'post/updateCustomer',
  async (input: IUpdateCustomerInput, thunkApi) => {
    const httpClient = StickyGatewayClient.authClient();
    removeEmptyStringKey(input);
    const asyncFn = httpClient.post<ICustomerUpdate>(
      '/public/customer/update-customer',
      {
        address: input.address,
        addressNote1: input.addressNote1,
        addressNote2: input.addressNote2,
        addressNote3: input.addressNote3,
        city: input.city,
        civility: input.civility,
        country: input.country,
        mobilePhone: formatPhoneToApi(input.mobilePhone),
        zipCode: input.zipCode,
      },
    );
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

// Sort customer cards list
export const sortCustomerCards = (cards?: ICard[]): ICard[] | undefined =>
  cards
    ?.filter(card => card.validityStartDate)
    ?.sort((a: ICard, b: ICard) => {
      const contractStatusIndex = Object.keys(ICardContractStatus);
      const noteA = contractStatusIndex.indexOf(a.contractStatus);
      const noteB = contractStatusIndex.indexOf(a.contractStatus);
      const compareA = `${noteA}-${a.validityStartDate}`;
      const compareB = `${noteB}-${b.validityStartDate}`;
      return compareA.localeCompare(compareB);
    })
    ?.reverse();

// Utils
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isIError = (value: any): value is IError => value && 'errorCode' in value;

// Redux slice
const customerSlice = createSlice({
  name: CUSTOMER_STORE,
  initialState: {
    ...initialState,
  },
  reducers: {
    setCustomerAuth: (state, action) => {
      if (action.payload !== state.isAuth) state.isAuth = action.payload;
    },
    setCustomerLogAfterQuote: state => {
      state.logAfterQuote = true;
    },
    openModifyCustomer: (state, action) => {
      state.editCustomer = action.payload;
    },
    cleanCustomerLogAfterQuote: state => {
      state.logAfterQuote = false;
    },
    cleanCustomerError: state => {
      delete state.error;
    },
    cleanCustomerMessage: state => {
      delete state.message;
    },
    cancelModifyCustomer: state => {
      state.editCustomer = initialState.editCustomer;
    },
    cleanLocalStorageCustomer: () => {
      window.localStorage.removeItem(CUSTOMER_LOCALSTORAGE_KEY);
    },
    cleanCustomerServiceUnavailable: state => {
      delete state.serviceAvailable;
    },
    setCustomerServicesAvailableForceDisable: (state, action) => {
      if (action.payload === true) {
        state.serviceAvailableForceDisable = action.payload;
        window.localStorage.setItem(
          CUSTOMER_STORE_SERVICE_AVAILABLE_FORCE_DISABLE,
          JSON.stringify('true'),
        );
      } else {
        delete state.serviceAvailableForceDisable;
        window.localStorage.removeItem(
          CUSTOMER_STORE_SERVICE_AVAILABLE_FORCE_DISABLE,
        );
      }
    },
    cleanCustomer: () => {
      Logger.setUser(undefined);
      return initialState;
    },
    invalidateReadCustomerCache: state => {
      state.readAt = 0;
    },

    updateMigrationUserEmail: (state, action: { payload: string }) => {
      state.migrationUserEmail = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(readCustomer.pending, state => {
      state.loading = true;
      state.at = Date.now();
      state.readAt = Date.now();
    });
    builder.addCase(readCustomer.fulfilled, (state, action) => {
      const customer = {
        ...action.payload,
        isAuth: true,
        cards: sortCustomerCards(action.payload?.cards),
      };

      // Convert Birthdate YYYY/MM/DD -> ISOString
      if (customer.birthDate) {
        customer.birthDate = parseUtcDateIso8601(
          customer.birthDate,
        )?.toISOString();
      }

      // Get the fidelity card
      const fidelityCard = action.payload.cards?.find(
        (c: ICard) => c.productType === CardType.FIDEL,
      )?.cardNumber;

      // Update states
      state.loading = false;
      state.message = '';
      delete state.error;
      delete state.serviceTemporaryUnavailable;
      state.customer = { ...customer, fidelityCard };
      state.serviceAvailable = true;

      // Set logger context
      Logger.setUser({ iuc: customer?.iuc });
    });
    builder.addCase(readCustomer.rejected, (state, action) => {
      const error = action.payload;
      state.loading = false;
      state.message = '';
      state.error = error;
      state.serviceTemporaryUnavailable = true;

      // Turn site into maintenance if RCU is down
      if (error?.errorCode && ['RCU_50001'].includes(error.errorCode)) {
        delete state.customer;
        state.serviceAvailable = false;
      }
    });

    builder.addCase(refreshCounters.fulfilled, (state, action) => {
      const cards = action.payload?.cards;
      state.customer?.cards?.forEach(card => {
        const refreshedCard = cards?.find(
          (_card: ICard) => _card.cardNumber === card.cardNumber,
        );
        if (!refreshedCard) return;
        card.bookCounterN0 = refreshedCard.bookCounterN0;
        card.bookCounterN1 = refreshedCard.bookCounterN1;
      });
    });

    builder.addCase(refreshCounters.rejected, state => {
      // Turn site into maintenance if RCU is down
      if (
        isIError(state?.error) &&
        ['RCU_50001'].includes(state?.error.errorCode)
      ) {
        delete state.customer;
        state.serviceAvailable = false;
      }
    });

    builder.addCase(createCustomer.pending, state => {
      state.loading = true;
      state.at = Date.now();
    });
    builder.addCase(createCustomer.fulfilled, (state, action) => {
      state.loading = false;
      state.message = '';
      delete state.error;
      state.newUserEmail = action.meta.arg.customer.email;
    });
    builder.addCase(createCustomer.rejected, (state, action) => {
      state.loading = false;
      state.message = '';
      state.error = action.payload;
    });

    builder.addCase(updateCustomer.pending, state => {
      state.loading = true;
      state.at = Date.now();
      state.readAt = 0;
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      state.loading = false;
      state.message = 'UPDATE_SUCCESS';
      delete state.error;
      state.editCustomer = initialState.editCustomer;

      state.customer = {
        ...state.customer,
        mobilePhone: action.meta.arg.mobilePhone,
        address: action.meta.arg.address,
        addressNote1: action.meta.arg.addressNote1,
        city: action.meta.arg.city,
        zipCode: action.meta.arg.zipCode,
        country: action.meta.arg.country,
        civility: action.meta.arg.civility,
      } as ICustomer;
    });
    builder.addCase(updateCustomer.rejected, state => {
      state.loading = false;
      state.message = '';
      state.error = { errorCode: ICustomerStateErrorCode.UPDATE_CUSTOMER };
    });

    builder.addCase(getCustomerAvatar.pending, state => {
      state.loading = true;
      state.at = Date.now();
    });
    builder.addCase(getCustomerAvatar.fulfilled, (state, action) => {
      state.loading = false;
      state.message = '';
      delete state.error;

      // TODO SYG-1316 : Short term fix for avatar 404 error
      // -> skip avatar base64 if payload is a part of html
      if (/<\/html>/.test(action.payload)) {
        return state;
      }

      // Prefix payload jpg with data base64 tag
      state.customer = {
        ...state.customer,
        avatar: 'data:image/jpeg;base64,' + action.payload,
      } as ICustomer;
    });
    builder.addCase(getCustomerAvatar.rejected, state => {
      state.loading = false;
      state.message = '';
      // FIXME do better when have time
      //state.error = action.error.message;
    });
  },
});

export const {
  cancelModifyCustomer,
  cleanCustomer,
  cleanCustomerError,
  cleanCustomerMessage,
  cleanCustomerLogAfterQuote,
  cleanCustomerServiceUnavailable,
  invalidateReadCustomerCache,
  openModifyCustomer,
  setCustomerAuth,
  setCustomerLogAfterQuote,
  setCustomerServicesAvailableForceDisable,
  updateMigrationUserEmail,
} = customerSlice.actions;

export const customerReducer = customerSlice.reducer;
