import { StickyGatewayClient } from '@sticky/gateway-client';

import type {
  IServiceMigrateSubscriptionInput,
  IServiceMigrateSubscriptionResponse,
} from '../models/migrate-subscription';

export class ServiceMigrateSubscription {
  static async migrateCard(
    input: IServiceMigrateSubscriptionInput,
  ): Promise<IServiceMigrateSubscriptionResponse | void> {
    return StickyGatewayClient.authClient()
      .post<IServiceMigrateSubscriptionResponse>(
        '/public/subscription/bind-subscription',
        input,
      )
      .then(response => response.data)
      .catch(error => {
        const errorCode = error.response?.data?.errorCode;
        if (errorCode) {
          // Special case for error ONI_50006 : silently fail, and lets continue (https://jira.vsct.fr/browse/SYG-2769)
          if (errorCode === 'ONI_50006') {
            return;
          }

          // In other cases, throw the error
          throw new Error(errorCode);
        } else {
          throw error;
        }
      });
  }
}
