import { t } from '@sticky/i18n';

import { DidomiPurposeStickyAuthentication } from '../purposes';
import type { CustomVendor } from '../types';

export const DidomiVendorMid: CustomVendor = {
  id: 'mid',
  name: t('didomi.vendors.mid.name'),
  purposeIds: [DidomiPurposeStickyAuthentication.id],
  policyUrl: t('didomi.vendors.mid.policyUrl'),
};
