import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import type { IRoute } from '../../routes/routes';
import { Routes } from '../../routes/routes';

import { useStickyRoutes } from './useStickyRoutes';

export const useRoute = (): IRoute | undefined => {
  const location = useLocation();
  const allRoutes = useStickyRoutes();
  const [route, setRoute] = useState<IRoute | undefined>();

  useEffect(() => {
    const foundRoute = allRoutes.find(r => r.path === location.pathname);
    setRoute(foundRoute || Routes.getRouteById('not-found'));
  }, [location, allRoutes]);

  return route;
};
