import { cleanBasket } from './features/basket/store/basket';
import { cleanCustomer } from './features/customer/store/customer-slice';
import { cleanProposal } from './features/proposal';
import {
  resetExchangeCriteria,
  resetReservationState,
} from './features/reservation';
import { clearEquivalentStations } from './features/reservation/store/equivalent-stations-slice';
import { clearSubscription } from './features/subscription/store/subcription-slice';
import { useAppDispatch } from './hooks';

// safe hook to use store
export const useStore = (): { cleanAllStores: () => void } => {
  const dispatch = useAppDispatch();

  const cleanAllStores = () => {
    dispatch(cleanBasket());
    dispatch(cleanCustomer());
    dispatch(clearSubscription());
    dispatch(cleanProposal());
    dispatch(resetReservationState());
    dispatch(resetExchangeCriteria());
    dispatch(clearEquivalentStations());
  };

  return { cleanAllStores };
};
