import { getEnv } from '@sticky/config';

import type { ICard } from '../../customer/models/customer';
import { POLICE_CARD } from '../../customer/models/customer';

/**
 * Filter cards to productType configured only.
 *
 * @param {ICard[]} inputCards - The list of cards to filter.
 */
export const getCardsWithAllowedProductType = (
  inputCards?: ICard[],
): ICard[] => {
  // Skip if no cards
  if (!inputCards?.length) return [];

  // Filter police cards
  const activeCards = inputCards.filter(card => card.FCE !== POLICE_CARD);

  // Get allowed types from env var
  const cardTypesAllowed = getEnv('VITE_SHOW_CUSTOMER_CARDS').split(',');

  // Returns origin cards if nothing is configured
  if (!cardTypesAllowed?.length) return inputCards;

  // Else filter inputCards
  return activeCards.filter(card =>
    cardTypesAllowed.includes(card.productType),
  );
};
