import React from 'react';
import { Outlet } from 'react-router-dom';

import { getFeatures } from '@sticky/config';
import { TrackerProvider } from '@sticky/tracker-provider';

import {
  BrandHeaderFooter,
  Header,
  HealthCheck,
  ModalDisclaimerAgencyRgpd,
  PageMetas,
  ScrollToTop,
} from '../../components';
import { SkipToMainLink } from '../../components/link/SkipToMainLink';
import { CaptchaModal } from '../../components/modal/captcha/captcha-modal';
import { IdleTimeoutModal } from '../../components/modal/idle-timeout/idle-timeout-modal';
import { useAppSelector } from '../../hooks';
import { ModalTermsSimple } from '../terms/components/terms-modal-simple';

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout = ({ children: Plugin = <></> }: LayoutProps) => {
  const isAuth = useAppSelector(state => state.customer.isAuth);

  return (
    <>
      <CaptchaModal />
      <SkipToMainLink />
      <IdleTimeoutModal />
      <PageMetas />
      <ScrollToTop />
      <HealthCheck />
      <ModalDisclaimerAgencyRgpd />
      {isAuth && getFeatures().terms.validation && <ModalTermsSimple />}
      {Plugin}
      <BrandHeaderFooter>
        <Header />
        <TrackerProvider>
          <Outlet />
        </TrackerProvider>
      </BrandHeaderFooter>
    </>
  );
};
