import { Box, Icon, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { Panel, PanelContent } from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t, Trans } from '@sticky/i18n';

export type WhySubscribeBox = {
  icon: string;
  title: string;
  description: string;
  descriptionAvantage?: string;
};

const useStyles = makeStyles()((theme: Theme) => ({
  why: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.secondary.main,
    '& h2': {
      textAlign: 'center',
      paddingBottom: theme.spacing(4),
    },

    [theme.breakpoints.up('breakPointDesktop')]: {
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: 'transparent',

      '& h2': {
        textAlign: 'left',
      },
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('breakPointDesktop')]: {
      display: 'grid',
      gap: theme.spacing(4),
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  itemContent: {
    display: 'flex',

    [theme.breakpoints.up('breakPointDesktop')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    display: 'block',
    backgroundColor: theme.app.colors.illustrationIcon,
    maskRepeat: 'no-repeat',
    maskSize: 'contain',
    width: '3rem',
    height: '3rem',
    marginRight: '1.5rem',

    [theme.breakpoints.up('breakPointDesktop')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(2),
      width: '4.375rem',
      height: '4.375rem',
    },
  },
  title: {
    [theme.breakpoints.up('breakPointDesktop')]: {
      textAlign: 'center',
    },
  },
}));

export const WhySubscribe = (): JSX.Element => {
  const { classes } = useStyles();
  const isEnrichmentActivated = getFeatures().enrichment.enabled;

  const boxes = t('home.whySubscribe.blocks', {
    returnObjects: true,
  }) as WhySubscribeBox[];

  return (
    <Box className={classes.why}>
      <Typography component="h2" variant="h2">
        {t('home.whySubscribe.title')}
      </Typography>

      <Box className={classes.list}>
        {boxes.map((box, key) => (
          <Panel key={key}>
            <PanelContent className={classes.itemContent}>
              <Icon
                className={classes.icon}
                sx={{
                  maskImage: `url(${box.icon})`,
                }}
              />
              <Box>
                <Typography variant="h3" gutterBottom className={classes.title}>
                  {box.title}
                </Typography>
                <Typography>
                  <Trans
                    i18nKey={box.description}
                    components={{
                      linkWhichTrains: <strong />,
                    }}
                  />
                </Typography>

                {isEnrichmentActivated && (
                  <Typography>
                    <Trans i18nKey={box.descriptionAvantage} />
                  </Typography>
                )}
              </Box>
            </PanelContent>
          </Panel>
        ))}
      </Box>
    </Box>
  );
};
