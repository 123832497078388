import type { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { t, Trans } from '@sticky/i18n';

import { ModalBox } from '../modal-box';

const useStyles = makeStyles()(({ app: { colors } }: Theme) => ({
  phoneLink: {
    color: colors.textPrimary,
    fontWeight: 800,
  },
}));

interface FraudControlModalProps {
  open: boolean;
  onClose: () => void;
}

export const FraudControlModal = ({
  open,
  onClose,
}: FraudControlModalProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <ModalBox
      open={open}
      title={t('modals.fraudControl.title')}
      titleComponent="h2"
      closeButton="header"
      onClose={onClose}
    >
      <Trans
        i18nKey="modals.fraudControl.message"
        values={{ phoneNumber: t('home.contact-phone-number') }}
        components={[
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            className={classes.phoneLink}
            href={`tel:${t('home.contact-phone-number-intl')}`}
            key={0}
          />,
        ]}
      />
    </ModalBox>
  );
};
