import { useEffect, useState } from 'react';

import { PriceCard as PriceCardComponent } from '@sticky/components';
import { t } from '@sticky/i18n';

import { proposalSelectors } from '../../features/proposal/selectors';
import { useAppSelector } from '../../hooks';

import SubscribeButton from './subscribe-button';

export const PriceCard = () => {
  const isInPromo = useAppSelector(proposalSelectors.getIsInPromo);
  const standardPrice = useAppSelector(proposalSelectors.getStandardPrice);
  const promoOfferType = useAppSelector(proposalSelectors.getPromo);

  const [isErrorInfoStock, setErrorInfoStock] = useState(false);

  // Loading state
  const offerTypeQuotes = useAppSelector(
    state => state.proposal.offerTypeQuotes,
  );

  const projectedInventory = useAppSelector(
    state => state.proposal.projectedInventory,
  );

  // Manage error if infoStock API is in error
  useEffect(() => {
    if (projectedInventory?.error === 'infoStock') {
      setErrorInfoStock(true);
    }
  }, [projectedInventory?.error]);

  const isInventoryLoading = !!projectedInventory?.loading;
  const isSoldOut = !projectedInventory?.value && !isErrorInfoStock;

  return (
    <PriceCardComponent
      isOfferTypeLoading={offerTypeQuotes?.loading}
      isInventoryLoading={isInventoryLoading}
      isSoldOut={isSoldOut}
      standardPrice={standardPrice}
      {...(isInPromo && {
        promo: {
          price: promoOfferType?.price,
          effectiveEndDate: promoOfferType?.effectiveEndDate,
          promoPeriods: promoOfferType?.promoPeriods,
        },
      })}
      button={
        <SubscribeButton
          disabled={isSoldOut}
          label={isSoldOut ? t('home.capping.come-back-later') : ''}
        />
      }
    />
  );
};
