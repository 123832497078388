type OpenPopupOptions = {
  id: string;
  url: string;
  width?: number;
  height?: number;
};

// Fonction pour ouvrir une fenêtre popup centrée sur l'écran
export const openPopup = ({
  id,
  url,
  height = 550,
  width = 400,
}: OpenPopupOptions) => {
  // Calcul des coordonnées pour centrer la popup dans l'écran
  const innerWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const innerHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;
  const left = (innerWidth - width) / 2;
  const top = (innerHeight - height) / 2;

  // Deux scénarios possibles :
  // - Si une popup avec le même ID est déjà ouverte, nous la récupérons,
  // - Sinon, nous créons une nouvelle popup.
  const popup = window.open(
    url,
    id,
    [
      'toolbar=no',
      'location=no',
      'directories=no',
      'status=no',
      'menubar=no',
      'scrollbars=no',
      'resizable=no',
      'copyhistory=no',
      `width=${width}`,
      `height=${height}`,
      `top=${top}`,
      `left=${left}`,
    ].join(', '),
  );

  if (!popup) {
    throw new Error("Can't open popup");
  }

  // Mettre la fenêtre popup au premier plan si elle a été créée avec succès
  popup.focus();

  return popup;
};
