import type { ReactNode } from 'react';
import {
  AccordionActions,
  AccordionDetails,
  Box,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { tss } from 'tss-react/mui';

import {
  AccordionHeader,
  AccordionPanel,
  Panel,
  PanelActionButtons,
  PanelContent,
  PanelHeader,
} from '@sticky/components';

import { AlertMessage } from '../alert/alert-message';

import type { Field } from './data-table';
import { DataTable } from './data-table';

const useStyles = tss.create(({ theme }) => ({
  error: {
    marginTop: '1rem',
  },
  accordion: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      fontWeight: 'bold',
      color: theme.app.colors.textContrasted,
    },
    '& .MuiAccordionDetails-root': {
      marginTop: 0,
    },
  },
}));

type DataTableCardProps = {
  actions?: ReactNode;
  alertErrorCode?: string;
  onAlertClose?: () => void;
  children?: ReactNode | ReactNode[];
  className?: string;
  defaultExpanded?: boolean;
  disabled?: boolean;
  error?: string | JSX.Element;
  fields?: Field[];
  header: ReactNode;
  id?: string;
  isReady?: boolean;
};

export const DataTableCard = ({
  actions,
  alertErrorCode,
  onAlertClose,
  children,
  className,
  defaultExpanded,
  disabled,
  error,
  fields,
  header,
  id = '',
  isReady = true,
  ...rest
}: DataTableCardProps) => {
  const { cx, classes } = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));

  const content = (
    <>
      {error && <Box className={isDesktop ? classes.error : ''}>{error}</Box>}
      {alertErrorCode && (
        <AlertMessage
          error={{ errorCode: alertErrorCode }}
          onClose={onAlertClose}
        />
      )}
      {fields && (
        <DataTable
          aria-label={header}
          disabled={disabled}
          fields={fields}
          isReady={isReady}
        />
      )}
      {children}
    </>
  );

  return isDesktop ? (
    <Panel id={id} className={className} {...rest}>
      <PanelHeader>
        <Typography variant="h2" gutterBottom>
          {header}
        </Typography>
      </PanelHeader>
      <PanelContent>{content}</PanelContent>
      {actions && <PanelActionButtons>{actions}</PanelActionButtons>}
    </Panel>
  ) : (
    <AccordionPanel
      id={id}
      className={cx(className, classes.accordion)}
      defaultExpanded={defaultExpanded}
      {...rest}
    >
      <AccordionHeader>{header}</AccordionHeader>
      <AccordionDetails>{content}</AccordionDetails>
      {isReady && actions && <AccordionActions>{actions}</AccordionActions>}
    </AccordionPanel>
  );
};
