import type { RouteObject } from 'react-router-dom';

import { getEnv } from '@sticky/config';
import { Logger } from '@sticky/logger';

import { BrandIds } from '../features/brand/config';

import commonCustomerMyCoordinatesUpdateBillto from './customer/my-coordinates/update-billto';
import commonEnrollmentDocuments from './enrollment/documents';
import commonMandates from './enrollment/mandate';
import commonFreePlaces from './free-places/free-places';
import commonMigration from './migration/migration';
import commonRoutes from './common';
import type { LAYOUT } from './layout';

export type IRoute = Omit<RouteObject, 'element' | 'index'> & {
  private?: boolean;
  id: string;
  isDisabled?: boolean;
  title: string;
  path: string;
  layout: LAYOUT;
  element: React.ComponentType;
};

class Routes {
  private static routes: IRoute[] = [];
  private static subscribers: ((allRoutes: IRoute[]) => void)[] = [];

  /**
   * Add an array of routes.
   *
   * @static
   * @param {IRoute[]} routes
   * @return {Routes} - List of routes.
   * @memberof Routes
   */
  public static addRoutes(routes: IRoute[]): Routes {
    const oldLength = this.routes.length;

    routes.forEach(route => {
      if (route.isDisabled) {
        Logger.log('[SKIP]', `Route is disabled "${route.id}"`);
        return false;
      }
      if (this.getRouteById(route.id)) {
        Logger.log('[SKIP]', `Route is already declared "${route.id}"`);
        return false;
      }
      this.routes.push(route);
    });

    // Notify subscribers only if the number of routes has changed.
    if (this.routes.length !== oldLength)
      this.subscribers.forEach(s => s(this.routes));

    return this;
  }

  /**
   * Returns all routes declared.
   *
   * @static
   * @return {IRoute[]} - List of routes.
   * @memberof Routes
   */
  public static getAllRoutes(): IRoute[] {
    return this.routes.filter(route => !route.isDisabled);
  }

  /**
   * Find a route by its ID.
   *
   * @static
   * @param {string} id
   * @return {(IRoute | void)} - The route found or void if not found.
   * @memberof Routes
   */
  public static getRouteById(id: string): IRoute | undefined {
    return this.routes.find(r => r.id === id);
  }

  /**
   * Search route by location path.
   *
   * @static
   * @param {string} path
   * @return {*}  {(IRoute | undefined)}
   * @memberof Routes
   */
  public static getRouteByPathname(path: string): IRoute | undefined {
    const replace = new RegExp(`^/(${BrandIds.join('|')}|)(|/)`);
    let pathWithoutBrand = path.replace(replace, '$2');

    if (!pathWithoutBrand) {
      pathWithoutBrand = '/';
    }

    if (!/^\//.test(pathWithoutBrand)) {
      pathWithoutBrand = ['/', pathWithoutBrand].join('');
    }

    return this.routes.find(r => r.path === pathWithoutBrand);
  }

  /**
   * Returns a relative path from its id.
   *
   * @static
   * @param {string} id - The route id.
   * @param {boolean} withBrand add brand or not
   * @param {string} [hash?] - Optional hash.
   * @return {string} The path of the route.
   * @memberof Routes
   */
  public static getPathById(id: string, hash?: string | null): string {
    const path = this.getRouteById(id)?.path ?? '/';

    const h = typeof hash === 'string' ? `#${hash}` : '';

    return path + h;
  }

  /**
   * Get absolute path (with base url and distribution channel).
   *
   * @static
   * @param {string} id - The route id.
   * @param {boolean} withBrand add brand or not
   * @param {string} [hash?] - Optional hash.
   * @return {string} The absolute path of the route.
   * @memberof Routes
   */
  public static getAbsolutePathById(id: string, hash?: string): string {
    return `${getEnv('VITE_PUBLIC_URL')}${this.getPathById(id, hash)}`;
  }

  /**
   * Subscribe to route changes.
   * @param onChange - Callback to be executed when routes change.
   */
  static subscribe(onChange: (allRoutes: IRoute[]) => void): void {
    this.subscribers.push(onChange);
  }

  /**
   * Unsubscribe to route changes.
   * @param onChange - Callback to be executed when routes change.
   */
  static unSubscribe(onChange: () => void): void {
    this.subscribers = this.subscribers.filter(s => s !== onChange);
  }
}

// Add common routes and routes that use async functions in their declaration
Routes.addRoutes(commonRoutes);

// Add migration routes
Routes.addRoutes(commonMigration);

// Add free-places routes
Routes.addRoutes(commonFreePlaces);

// Enrollment
Routes.addRoutes(commonEnrollmentDocuments);
Routes.addRoutes(commonMandates);

// Customer
Routes.addRoutes(commonCustomerMyCoordinatesUpdateBillto);

// Export static class
export { Routes };
