import { Box, LinearProgress, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface ReservationProgressBarProps {
  totalBooking: number;
  currentBooking: number;
  isBooked?: boolean;
  hideCounter?: boolean;
}

const useStyles = makeStyles()(({ typography: { pxToRem } }: Theme) => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  progressContainer: {
    width: '100%',
  },
  marginRight: {
    marginRight: pxToRem(20),
  },
  typoContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mfaProgress: {
    height: '6px',
    borderRadius: '6px',
  },
  typoCurrentBooking: {
    color: '#7e7f74',
  },
}));

export const ReservationProgressBar = ({
  totalBooking,
  currentBooking,
  isBooked,
  hideCounter = false,
}: ReservationProgressBarProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Box
        className={`${classes.progressContainer} ${
          totalBooking !== 0 ? classes.marginRight : null
        }`}
      >
        {isBooked ? (
          <LinearProgress
            variant="determinate"
            value={100}
            valueBuffer={100}
            className={classes.mfaProgress}
          />
        ) : (
          <LinearProgress className={classes.mfaProgress} />
        )}
      </Box>
      {!hideCounter && totalBooking > 0 ? (
        <Box className={classes.typoContainer}>
          <Typography>
            <span className={classes.typoCurrentBooking}>{currentBooking}</span>
            /{totalBooking}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
