import type { AccordionSummaryProps } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import { tss } from 'tss-react/mui';

import { useAccordionContext } from './AccordionContext';

const useStyles = tss.create(({ theme }) => ({
  withDivider: {
    position: 'relative',

    ['&:after']: {
      position: 'absolute',
      height: 1,
      content: "''",
      bottom: '-1px',
      left: theme.spacing(2),
      right: theme.spacing(2),
      backgroundColor: theme.palette.divider,

      [theme.breakpoints.up('breakPointDesktop')]: {
        left: theme.spacing(4),
        right: theme.spacing(4),
      },
    },
  },
}));

type AccordionHeaderProps = AccordionSummaryProps & {
  withDivider?: boolean;
};

export const AccordionHeader = ({
  className,
  children,
  withDivider,
  ...rest
}: AccordionHeaderProps) => {
  const context = useAccordionContext();
  const { classes, cx } = useStyles();

  return (
    <AccordionSummary
      aria-controls={`${context.id}-content`}
      id={`${context.id}-header`}
      onClick={context.toggle}
      className={cx(className, withDivider && classes.withDivider)}
      {...rest}
    >
      {children}
    </AccordionSummary>
  );
};
