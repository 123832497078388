import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { CardNumber, TypoWithSkeleton } from '../../../../components';
import { useAppSelector } from '../../../../hooks';
import { Routes } from '../../../../routes/routes';
import { ModalChooseSubscription } from '../../../subscription';
import { subscriptionSelectors } from '../../../subscription/selectors';
import type { ICard } from '../../models/customer';
import { customerSelectors } from '../../selectors';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { colors },
  } = theme;

  return {
    root: {
      marginTop: '5px',
      marginBottom: '0',
      alignSelf: 'flex-end',
      lineHeight: 1.2,
      color: colors.textPrimary,
      [breakpoints.up('breakPointDesktop')]: {
        marginBottom: '0.2rem',
        color: colors.textSecondary,
      },
    },
    grid: {
      display: 'grid',
      gridTemplateAreas: `"gridCardNumberLabel ."
                          "gridCardNumber gridSwitchIcon"`,
      gridTemplateRows: 'min-content min-content',
      [breakpoints.up('breakPointDesktop')]: {
        display: 'flex',
        flexFlow: 'wrap',
      },
    },
    gridCardNumberLabel: {
      fontWeight: 900,
      gridArea: 'gridCardNumberLabel',
      justifySelf: 'start',
      whiteSpace: 'nowrap',
      [breakpoints.up('breakPointDesktop')]: {
        marginRight: '0.3rem',
      },
    },
    gridCardNumber: {
      gridArea: 'gridCardNumber',
      justifySelf: 'start',
    },
    gridSwitchIcon: {
      gridArea: 'gridSwitchIcon',
      marginLeft: '0.2rem',
      justifySelf: 'start',
      alignSelf: 'center',
      fontSize: '130%',
    },
    multiCard: {
      cursor: 'pointer',
    },
  };
});

interface NavigationHeaderCardNumberWrapToggleProps {
  children: JSX.Element;
  onClick: () => void;
  cards?: ICard[];
}

const NavigationHeaderCardNumberWrapToggle = ({
  children,
  onClick,
  cards = [],
}: NavigationHeaderCardNumberWrapToggleProps): JSX.Element => {
  const { classes } = useStyles();

  // No or only one card : don't wrap card change
  if (cards.length < 2) {
    return <div className={classes.grid}>{children}</div>;
  }

  // Else wrap link change + icon
  return (
    <Typography
      variant="body1"
      color="inherit"
      onClick={onClick}
      className={`${classes.grid} ${classes.multiCard}`}
      data-test-id="toggle-change-sub"
    >
      {children}{' '}
      <SwapHorizontalCircleIcon
        color="inherit"
        className={classes.gridSwitchIcon}
      />
    </Typography>
  );
};

export const NavigationHeaderCardNumber = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  // Locale state
  const [modalChooseSubscriptionOpen, setModalChooseSubscriptionOpen] =
    useState(false);
  const [maybeNeedRedirect, setMaybeNeedRedirect] = useState(false);

  // Get stores
  const cards = useAppSelector(customerSelectors.getAllowedCards);
  const isSubscriptionReady = useAppSelector(
    subscriptionSelectors.isSubscriptionReady,
  );
  const subscriptionNumber = useAppSelector(
    subscriptionSelectors.getSubscriptionNumber,
  );
  const isSubscriptionCancelled = useAppSelector(
    subscriptionSelectors.isCancelled,
  );
  const isCustomerWithoutSubscription = useAppSelector(
    customerSelectors.isCustomerWithoutSubscription,
  );

  const hideNoActiveSubscriptionLabel =
    !subscriptionNumber && getFeatures().migration.enabled;

  const handleModalClose = () => {
    setMaybeNeedRedirect(true);
    setModalChooseSubscriptionOpen(false);
  };

  useEffect(() => {
    if (isSubscriptionCancelled && maybeNeedRedirect) {
      setMaybeNeedRedirect(false);
      navigate(Routes.getPathById('customer/my-subscription'));
    }
  }, [navigate, isSubscriptionCancelled, maybeNeedRedirect]);

  return (
    <>
      <TypoWithSkeleton
        component="div"
        skelProps={{ width: '60%' }}
        isReady={isSubscriptionReady || isCustomerWithoutSubscription}
        className={classes.root}
      >
        <NavigationHeaderCardNumberWrapToggle
          cards={cards}
          onClick={() => setModalChooseSubscriptionOpen(true)}
        >
          {subscriptionNumber !== undefined ? (
            <>
              <span className={classes.gridCardNumberLabel}>
                {t('customer.nav.cardNumber')}
              </span>
              <CardNumber
                className={classes.gridCardNumber}
                value={subscriptionNumber}
              />
            </>
          ) : (
            <span data-test-id="noSubscription">
              {hideNoActiveSubscriptionLabel
                ? ' '
                : t('customer.nav.noActiveSubscription')}
            </span>
          )}
        </NavigationHeaderCardNumberWrapToggle>
      </TypoWithSkeleton>
      {cards.length > 1 ? (
        <ModalChooseSubscription
          open={modalChooseSubscriptionOpen}
          onClose={handleModalClose}
        />
      ) : null}
    </>
  );
};
