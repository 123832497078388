import type { Selector } from 'react-redux';
import { addHours, isSameDay, parseISO, startOfDay } from 'date-fns';
import { createSelector } from 'reselect';

import type { RootState } from '../../../store';
import type { WeekDay } from '../../../utils/date/get';
import type {
  IReservationState,
  PreferencesForWeekDay,
  ReservationDay,
  Travel,
  TravelDetailsCache,
} from '..';
import type { IExchangeState } from '../models/exchange';
import { isPreferencesFilled } from '../utils/preference';
import { getTravelId } from '../utils/travel';

const getReservationState = (state: RootState) => state.reservation;

// Accessors
const getResa =
  <T>(accessor: (reservation: IReservationState) => T) =>
  (state: RootState) =>
    accessor(getReservationState(state));

const getTravels = getResa(reservation => reservation.travels);
const getTravelsError = getResa(reservation => reservation.travelsInfo.error);
const isTravelsFetched = getResa(
  reservation => reservation.travelsInfo.fetched,
);
const isTravelsLoading = getResa(
  reservation => reservation.travelsInfo.loading,
);

const isBookingFinished = getResa(reservation => reservation.bookingFinished);

const getPreferencesError = getResa(
  reservation => reservation.preferencesInfo.error,
);
const isPreferencesFetched = getResa(
  reservation => reservation.preferencesInfo.fetched,
);
const isPreferencesLoading = getResa(
  reservation => reservation.preferencesInfo.loading,
);

const getActiveWeekDays = getResa(reservation => reservation.activeWeekDays);
const getCriteriaForWeekDay = getResa(
  reservation => reservation.criteriaForWeekDay,
);

const getTravelDetailsCache = getResa(resa => resa.travelDetailsCache);

// Simple Selectors
const isWeekDayActive = (
  state: RootState,
  weekDay: WeekDay | undefined,
): boolean =>
  // Don't remove `!== undefined` condition because `weekDay` can be equal to `0` in Sunday case
  // Don't remove `=== true` condition because `activeWeekDays` object can be empty
  // noinspection PointlessBooleanExpressionJS
  weekDay !== undefined ? getActiveWeekDays(state)[weekDay] === true : false;
const getWeekDayCriteria = (
  state: RootState,
  weekDay: WeekDay | undefined,
): PreferencesForWeekDay | undefined =>
  // Dont remove `!== undefined` condition because `weekDay` can be equal to `0` in Sunday case
  weekDay !== undefined ? getCriteriaForWeekDay(state)[weekDay] : undefined;
const isPreferencesValid = (state: RootState, weekDay: WeekDay): boolean => {
  const criteria = getWeekDayCriteria(state, weekDay);
  if (!criteria) return false;
  const { departurePreferences, returnPreferences } = criteria;
  return (
    isPreferencesFilled(departurePreferences) &&
    (!returnPreferences || isPreferencesFilled(returnPreferences))
  );
};

const getDetailedTravel = (
  state: RootState,
  travel: Travel,
): TravelDetailsCache | undefined =>
  getTravelDetailsCache(state).find(cache => {
    const id = getTravelId(travel);
    return cache.id === id;
  });

// Cached Selectors

// Memoization is only needed if you are truly deriving results,
// and if the derived results would likely create new references every time.
// A selector function that does a direct lookup and return of a value should be a plain function, not memoized.

const getNbHighlightedTravels: Selector<RootState, number> = createSelector(
  [getTravels],
  travels => travels.filter(travel => travel.highlighted === true).length,
);

const getAllTravelsByDate: Selector<RootState, ReservationDay[]> =
  createSelector([getTravels], travels => groupTravelsByDate(travels));

const getAllTravelsForDate = createSelector(
  [getAllTravelsByDate, (getAllTravelsByDate, date) => date],
  (getAllTravelsByDate, date) =>
    getAllTravelsByDate.find(t => t.date === date)?.travels ?? [],
);

const getUnfinishedTravels: Selector<RootState, Travel[]> = createSelector(
  getTravels,
  travels => {
    const now = new Date();
    return travels.filter(t => addHours(parseISO(t.arrivalDateTime), 1) > now);
  },
);

const getOldTravels: Selector<RootState, [ReservationDay[], Travel[]]> =
  createSelector(getTravels, travels => {
    const oneHourEarly = addHours(new Date(), -1); //considering trains could be maximum 1h late,

    // we keep travels where arrival date time are 1 hour early from now
    const oldTravels = travels
      .filter(t => parseISO(t.arrivalDateTime) <= oneHourEarly)
      .reverse();

    return [groupTravelsByDate(oldTravels), oldTravels];
  });

const groupTravelsByDate = (travels: Travel[]): ReservationDay[] =>
  travels.reduce<ReservationDay[]>((acc, travel) => {
    const date = new Date(travel.departureDateTime);
    const day = acc.find(value => isSameDay(value.date, date));
    if (day) {
      day.travels.push(travel);
    } else {
      acc.push({ date: startOfDay(date), travels: [travel] });
    }
    return acc;
  }, []);

export const reservationSelectors = {
  getReservationState,
  getNbHighlightedTravels,
  getPreferencesError,
  getTravels,
  getTravelsError,
  getWeekDayCriteria,
  isBookingFinished,
  isPreferencesFetched,
  isPreferencesLoading,
  isPreferencesValid,
  isTravelsFetched,
  isTravelsLoading,
  isWeekDayActive,
  getUnfinishedTravels,
  getOldTravels,
  getDetailedTravel,
  getAllTravelsByDate,
  getAllTravelsForDate,
};

const getExchangeState = (state: RootState) => state.exchange;

// Accessors
const getExchange =
  <T>(accessor: (reservation: IExchangeState) => T) =>
  (state: RootState) =>
    accessor(getExchangeState(state));

const travelToExchange = getExchange(exchange => exchange.travelToExchange);
const exchangeCriteria = getExchange(exchange => exchange.exchangeCriteria);

export const exchangeSelectors = {
  travelToExchange,
  exchangeCriteria,
};
