import type { BoxProps } from '@mui/material';
import { Box, Skeleton, Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

import { t, Trans } from '@sticky/i18n';

import { PromoCard } from './PromoCard';

type PriceCardProps = Omit<BoxProps, 'children'> & {
  isOfferTypeLoading?: boolean;
  isInventoryLoading?: boolean;
  isSoldOut?: boolean;
  standardPrice?: number;
  promo?: {
    price?: number;
    effectiveEndDate?: string;
    promoPeriods?: number;
  };
  button: JSX.Element;
};

const useStyles = tss.create(({ theme }) => {
  const {
    breakpoints,
    app: { colors },
    typography: { pxToRem },
  } = theme;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > .MuiButtonBase-root.Mui-disabled': {
        [breakpoints.up('breakPointDesktop')]: {
          background: colors.disabledBackground,
        },
      },
    },
    amount: {
      fontFamily: '"Avenir Black", "Avenir Roman", sans-serif',
      whiteSpace: 'nowrap',
      fontSize: pxToRem(42),
      textAlign: 'center',
      '& span': {
        fontSize: '180%',
      },
      marginBottom: theme.spacing(2),

      [breakpoints.up('breakPointDesktop')]: {
        marginBottom: 0,
      },
    },
    buttonSkeleton: {
      borderRadius: '0.5rem',
    },
  };
});

export const PriceCard = ({
  className,
  isOfferTypeLoading,
  isInventoryLoading,
  isSoldOut,
  standardPrice,
  promo,
  button,
  ...rest
}: PriceCardProps) => {
  const { cx, classes } = useStyles();

  // OFFER TYPE
  const OfferType = () => (
    <Box className={classes.amount}>
      {isOfferTypeLoading ? (
        <Skeleton animation="wave" height={100} width={240} />
      ) : (
        <Trans
          i18nKey="price-per-month"
          components={[<span key={0} />]}
          values={{ price: standardPrice }}
        />
      )}
    </Box>
  );

  // OFFER BUTTON
  const OfferButtons = () => {
    if (isInventoryLoading) {
      return (
        <Typography variant="h3">
          <Skeleton
            className={classes.buttonSkeleton}
            animation="wave"
            height={50}
            width={240}
          />
        </Typography>
      );
    }

    return (
      <>
        {isSoldOut && (
          <Typography variant="h3">{t('home.capping.sold-out')}</Typography>
        )}
        {button}
      </>
    );
  };

  return (
    <Box className={cx(classes.root, className)} {...rest}>
      {promo ? (
        <PromoCard standardPrice={standardPrice} promo={promo} />
      ) : (
        <OfferType />
      )}
      <OfferButtons />
    </Box>
  );
};
