import { getLogoutUrl } from '@sticky/auth';

import type { INavigationMenuItem } from '../../../../resources/types/navigation/menuItem';

// Navigation Menu
export const getNavigationMenuItems = (
  hasSubscription: boolean,
): Array<INavigationMenuItem> => [
  {
    label: 'menu.myTravels',
    route: '/mes-voyages',
    icon: '/assets/icons/icon-my-travels.svg',
    disabled: !hasSubscription,
  },
  {
    label: 'menu.myCoordinates',
    route: '/mes-coordonnees',
    icon: '/assets/icons/icon-my-subscription.svg',
  },
  {
    label: 'menu.mySubscription',
    route: '/mon-abonnement',
    icon: '/assets/icons/icon-coordinates.svg',
    disabled: !hasSubscription,
  },
  {
    label: 'menu.contactAndFaq',
    route: '/contact-et-faq',
    icon: '/assets/icons/icon-contact.svg',
  },
  {
    label: 'menu.logoutMe',
    route: getLogoutUrl(),
    hideOnMobile: true,
  },
];

export type { INavigationMenuItem };
