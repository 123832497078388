export enum InvoiceStatus {
  DRAFT = 'Draft',
  POSTED = 'Posted',
  ERROR = 'Error',
  CANCELLED = 'Cancelled',
}

export interface IInvoice {
  id: string;
  billingDate: string | number;
  amount: number;
  status: InvoiceStatus;
  balance: number;
  invoiceNumber: string;
  dueDate: string;
  rejectType: string;
  rejectNumber: string;
  payed: string;
}

export enum InvoiceStatusPayment {
  PAID = 'paid',
  UNPAID = 'unpaid',
  WAITING = 'waiting',
  REFUND = 'refund',
}

export enum InvoiceRejectType {
  OPPOSITION = 'Opposition',
  ERROR_ANOMALY = 'Erreur-Anomalie',
  INSUFFICIENT = 'Provisions Insuffisantes',
}

export interface IRegularizationInitInvoiceInput {
  invoiceId: string;
  amount: number;
  callbackUrl: string;
  accountId: string;
}

export interface IRegularizationInfos {
  invoiceId: string;
  paymentId: string;
}

export interface IRegularizationInitInvoiceOutput {
  urlPage: string;
  paymentId: string;
}

export interface IConfirmRegularizationInput {
  invoiceId: string;
  paymentId: string;
  cardNumber: string;
}
