import type { IIssuingCreditCardOutput } from '../../basket/models/basket';

import type { IRegularizationInitInvoiceOutput } from './invoice';
import type { IRatePlan } from './rate-plan';

export enum SubscriptionProductId {
  MAX_ACTIF = 'MFA_PT',
  MAX_ACTIF_PLUS = 'MFA_FT',
  TGV_MAX_SENIOR = 'TGV_MAX_SENIOR',
  TGV_MAX_JEUNE = 'TGV_MAX_JEUNE',
  IDTGV_MAX = 'IDTGV_MAX',
}

export enum BrandNameProduct {
  MFA = 'MFA',
  TMS = 'TMS',
  TMJ = 'TMJ',
}

export enum SubscriptionTermType {
  EVERGREEN = 'EVERGREEN',
  TERMED = 'TERMED',
}

export enum SubscriptionUnpaidStatus {
  ACTIVE = 'Actif',
  SUSPENDED = 'Suspendu',
  TERMINATED = 'Resilie',
}

export enum SubscriptionCancelledStatusResultCode {
  ELEVEN = 11,
  TWELVE = 12,
  THIRTEEN = 13,
  FOURTEEN = 14,
  SIXTEEN = 16,
}

export enum SubscriptionSuspendedStatusResultCode {
  TWENTY_ONE = 21,
  TWENTY_TWO = 22,
  TWENTY_THREE = 23,
}

export enum SubscriptionActiveStatusResultCode {
  THIRTY_ONE = 31,
  THIRTY_TWO = 32,
  THIRTY_THREE = 33,
  THIRTY_FOUR = 34,
  THIRTY_FIVE = 35,
  // Normal case: 36,
  THIRTY_SEVEN = 37,
  THIRTY_EIGHT = 38,
  THIRTY_NINE = 39,
  FORTY = 40,
  FORTY_ONE = 41,
}

export enum SubscriptionStatus {
  DRAFT = 'Draft',
  PENDING_ACTIVATION = 'Pending Activation',
  PENDING_ACCEPTANCE = 'Pending Acceptance',
  ACTIVE = 'Active',
  CANCELED = 'Cancelled',
  SUSPENDED = 'Suspended',
  IN_PROGRESS = 'En cours',
  SUSPENDED_FR = 'Suspendu',
  TERMINATED = 'Résilié',
}

export enum periodTypeContracts {
  MONTHS = 'Month',
  ANNUAL = 'Annual',
}

// Subscription model
export interface ISubscription {
  id: string;
  number: string;
  type: string;
  startDate: string;
  endDate: string | null;
  status: SubscriptionStatus;
  suspendStartDate?: string;
  suspendEndDate?: string;
  suspendReason: string | null;
  suspendUnpaidStartDate: string | null;
  suspendUnpaidEndDate: string | null;
  cancellationUnpaidDate: string | null;
  unpaidType: string | null;
  unpaidStatus: SubscriptionUnpaidStatus | null;
  cancelationReason: string | null;
  periodTypeContract: string;
  periodContract: number;
  productId: SubscriptionProductId;
  productSelect: string;
  autoRenew: boolean;
  termType: SubscriptionTermType | undefined;
  anniversaryDate: string | null | undefined;
  ratePlans: IRatePlan[];
  ratePlanSelected: IRatePlan;
  signedCgv?: string;
  dateSignedCgv?: string;
  cancellationDate?: string | null;
  cancelReasonCode?: string;
  channelOfSales?: string;
  pointOfSalesIUC?: string;
  lastSuspendDate?: string;
  lastResumeDate?: string;
  iban?: string;
  regularization?: IRegularizationInitInvoiceOutput;
  issuingRegularization: IIssuingCreditCardOutput;
  isRegularized?: boolean;
  statusResult: number;
}

export interface ISubscriptionList {
  id: string;
  label: string;
  value: string;
  productId: string;
  productSelect: string;
  productCode: string;
  productCodes: string[];
}

export enum TravelClasses {
  ONE = '1',
  TWO = '2',
}

export enum PaymentState {
  CUSTOMER_ABANDONMENT = 'ABANDON CLIENT',
  ABANDONMENT = 'ABANDON',
  PAYMENT_TRANSACTION = 'TRANSACTION DE PAIEMENT',
}
