import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { ConfigCommon } from '@sticky/config';
import { createStickyStorage } from '@sticky/helpers';

import type { UpdateBillToInput } from './client/services';
import type { Bank, BankBranch, Mandate } from './types';

type State = {
  bank?: Bank;
  bankBranch?: BankBranch;
  bankListAis?: Bank[];
  mandate?: Mandate;
  resendOtpCodeTimer?: number;
  rum?: string;
  billToInfo?: Partial<UpdateBillToInput>;
  bankUrl?: string;
};

const gpgStorage = createStickyStorage({ prefix: 'mandate' });

const useStore = create<State>()(
  devtools(
    persist(() => ({}), {
      name: 'gpg-store',
      storage: gpgStorage,
    }),
    {
      name: 'gpg-store',
      enabled: !ConfigCommon.env.isProd && !ConfigCommon.env.isTest,
    },
  ),
);

const reset = () => {
  useStore.setState(state => {
    clearInterval(state.resendOtpCodeTimer);

    return {};
  }, true);
};

const setBank = (bank?: Bank) => {
  useStore.setState(() => ({
    bank,
    bankBranch: undefined,
    bankUrl: undefined,
  }));
};

const setBankBranch = (bankBranch?: BankBranch) => {
  useStore.setState({
    bankBranch,
    bankUrl: undefined,
  });
};

const setBankListAis = (bankListAis: Bank[]) => {
  useStore.setState({
    bankListAis,
  });
};

const setMandate = (mandate: Mandate) => {
  useStore.setState({
    mandate,
  });
};

const setRum = (rum: string) => {
  useStore.setState({
    rum,
  });
};

const setBankUrl = (bankUrl: string) => {
  useStore.setState({
    bankUrl,
  });
};

const setBillToInfo = (
  billToInfo: Omit<UpdateBillToInput, 'RUM' | 'cardNumber'>,
) => {
  useStore.setState({
    billToInfo,
  });
};

const blockResendOtpCode = () => {
  // Ce timer permet de bloquer le renvoi d'un code OTP pendant 1 min
  const resendOtpCodeTimer = setInterval(() => {
    useStore.setState(() => ({ resendOtpCodeTimer: undefined }));
  }, 60 * 1000) as unknown as number;

  useStore.setState(state => {
    // Suppression de l'ancien timer si nécessaire
    clearInterval(state.resendOtpCodeTimer);

    return {
      resendOtpCodeTimer,
    };
  });
};

export const useGpgStore = () => {
  const store = useStore();

  return {
    ...store,
    reset,
    setBank,
    setBankBranch,
    setBankListAis,
    setBankUrl,
    setBillToInfo,
    setMandate,
    setRum,
    blockResendOtpCode,
  };
};
