import type { StackProps } from '@mui/system';
import { Stack } from '@mui/system';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(({ theme }) => ({
  root: {
    flexFlow: 'row-reverse wrap',
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(2),
    justifyContent: 'center',
    margin: '1.4rem 1rem',

    [theme.breakpoints.up('breakPointDesktop')]: {
      margin: '1.6rem 2rem',
    },
  },
}));

export const PanelActionButtons = ({
  className,
  children,
  ...rest
}: StackProps) => {
  const { classes, cx } = useStyles();

  return (
    <Stack className={cx(classes.root, className)} {...rest}>
      {children}
    </Stack>
  );
};
