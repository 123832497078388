import { Field } from 'react-final-form';
import { Typography } from '@mui/material';
import { showErrorOnBlur, showErrorOnChange, TextField } from 'mui-rff';
import type { TextFieldProps } from 'mui-rff/src/TextField';

import type { IValidator } from './form-validators';

export interface FormInputTextProps extends TextFieldProps {
  readonly?: boolean;
  minLength?: number;
  maxLength?: number;
  uppercase?: boolean;
  readOnly?: boolean;
  validate?: IValidator;
  format?: (value: string, name: string) => string;
  parse?: (value: string, name: string) => string;
  formatOnBlur?: boolean;
  autoComplete?: string;
  inputType?: string;
  prefixReadOnly?: string;
}

export const FormInputText = ({
  formatOnBlur,
  format,
  name,
  parse,
  readOnly,
  uppercase,
  validate: v2,
  inputType,
  prefixReadOnly = '',
  ...restProps
}: FormInputTextProps): JSX.Element => {
  const _format = (value: string, name: string) => {
    const formattedValue = format ? format(value, name) : value;
    return uppercase ? (formattedValue ?? '').toUpperCase() : formattedValue;
  };

  const _parse = (value: string, name: string) => {
    const parsedValue = parse ? parse(value, name) : value;
    return uppercase ? (parsedValue ?? '').toUpperCase() : parsedValue;
  };

  return (
    <Field
      name={name}
      validate={v2}
      format={_format}
      parse={_parse}
      formatOnBlur={formatOnBlur}
    >
      {({ input, meta }) => {
        const {
          type,
          validate,
          required,
          disabled,
          value = '',
          ...attrs
        } = input;
        const hasError = !!(meta?.touched && (meta.error || meta.submitError));

        // Display value if readOnly
        if (readOnly) {
          return (
            <Typography>
              {prefixReadOnly}
              {value}
            </Typography>
          );
        }

        // Else display the editable field
        return (
          <TextField
            id={name}
            {...attrs}
            {...restProps}
            aria-labelledby={name + '-label'}
            variant="outlined"
            showError={hasError ? showErrorOnChange : showErrorOnBlur}
            InputLabelProps={{ shrink: true }}
            value={value}
          />
        );
      }}
    </Field>
  );
};
