import type { Theme } from '@mui/material';
import { Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { formatToHHMM } from '../../utils/date';

import type { Travel, TravelSuccess } from './travel-details';

const useStyle = makeStyles()(
  ({ breakpoints, app: { colors }, typography: { pxToRem } }: Theme) => ({
    dateAndStation: {
      display: 'flex',
      minWidth: 0,
      alignItems: 'baseline',
    },
    date: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: pxToRem(15),
    },
    dateText: {
      fontSize: pxToRem(14),
      [breakpoints.up('breakPointDesktop')]: {
        fontSize: pxToRem(16),
      },
    },
    station: {
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: pxToRem(14),
      [breakpoints.up('breakPointDesktop')]: {
        fontSize: pxToRem(16),
      },
    },
    error: {
      color: colors.errorDark,
      fontSize: pxToRem(14),
      [breakpoints.up('breakPointDesktop')]: {
        fontSize: pxToRem(16),
      },
      '& .errorContext': {
        fontWeight: 900,
      },
    },
  }),
);

interface StationProps {
  travel: Travel;
  dateSelector: (travel: TravelSuccess) => Date;
  stationSelector: (travel: TravelSuccess) => string;
  showError?: boolean;
}

export const TravelStation = ({
  travel,
  dateSelector,
  stationSelector,
  showError = false,
}: StationProps): JSX.Element | null => {
  const { classes } = useStyle();

  let date;
  switch (travel.state) {
    case 'success':
      date = dateSelector(travel);
      return (
        <div className={classes.dateAndStation}>
          <time dateTime={date.toISOString()} className={classes.date}>
            <Typography
              variant="subtitle2"
              component="span"
              className={classes.dateText}
            >
              {formatToHHMM(date)}
            </Typography>
          </time>
          <Typography
            variant="body1"
            component="span"
            className={classes.station}
          >
            {stationSelector(travel)}
          </Typography>
        </div>
      );
    case 'error':
      return showError ? (
        <Typography variant="body1" component="span" className={classes.error}>
          {travel.errorMessage}
        </Typography>
      ) : null;
    case 'loading':
      return <Skeleton animation="wave" />;
  }
};
