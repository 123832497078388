import type { PropsWithChildren } from 'react';
import { cloneElement } from 'react';
import type { ModalProps } from '@mui/material';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fade,
  Modal,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { t } from '@sticky/i18n';

import { CloseButton } from '../../components';

import ModalBoxScroller from './modal-box-scroller';

// Modal Properties
type ModalBoxProps = PropsWithChildren<{
  actions?: JSX.Element[];
  actionButtonsFullWidth?: boolean;
  actionsButtonSticky?: boolean;
  alignTitleCenter?: boolean;
  ariaBusy?: boolean;
  closeButton?: false | 'header' | 'bottom';
  colorCloseButton?: string;
  fontWeightTitle?: 300 | 400 | 500 | 700 | 900;
  forceTitleMobileState?: boolean;
  isDarkHeader?: boolean;
  isDisableBackdropClick?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  open: boolean;
  placement?: 'center' | 'top';
  title?: string;
  titleComponent?: 'h1' | 'h2' | 'h3' | 'h4';
  width?: string;
  backGroundColor?: string;
  titleColor?: string;
  showCardHeaderSeparator?: boolean;
}>;

type StyleParams = {
  fontWeightTitle: number;
  width: string;
  alignTitleCenter: boolean;
  placement: string;
  forceTitleMobileState: boolean;
  actionButtonsFullWidth: boolean;
  actionsButtonSticky?: boolean;
  hasTitle: boolean;
  showCardHeaderSeparator: boolean;
  backGroundColor?: string;
  titleColor?: string;
};
// Styling
const useStyles = makeStyles<StyleParams>()(
  (
    {
      app: { colors },
      breakpoints,
      palette: { background },
      spacing,
      typography: { pxToRem },
    },
    {
      fontWeightTitle,
      width,
      alignTitleCenter,
      placement,
      forceTitleMobileState,
      actionButtonsFullWidth,
      hasTitle,
      showCardHeaderSeparator,
      backGroundColor,
      titleColor,
      actionsButtonSticky,
    },
  ) => ({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: placement,
      '& .MuiCardContent-root:last-child': {
        paddingBottom: 0,
      },
    },
    actionButton: {
      '&.MuiButton-root': {
        width: '100%',
        [breakpoints.up('breakPointDesktop')]: {
          width: actionButtonsFullWidth ? '100%' : 'auto',
        },
      },
    },
    card: {
      '&.MuiCard-root': {
        backgroundColor: backGroundColor ?? background.paper,
        marginTop: placement === 'top' ? pxToRem(30) : 0,
        maxHeight: '90vh',
        maxWidth: width === 'auto' ? '' : '80vw',
        padding: '1.4rem 0',
        width: width || undefined,
        [breakpoints.up('breakPointDesktop')]: {
          maxWidth: '60vw',
          padding: '1.4rem 0',
        },
        '& .MuiCardHeader-root': {
          color: titleColor ?? colors.textPrimary,
          borderBottom: showCardHeaderSeparator
            ? `1px solid ${colors.warmGray1}`
            : '0px',
          display: 'grid',
          gridTemplate: `'. title closeButton' / ${pxToRem(32)} 1fr ${pxToRem(
            32,
          )}`,
          padding: '0 1rem',
          '& .MuiCardHeader-action': {
            alignSelf: 'center',
            display: 'flex',
            gridArea: 'closeButton',
            justifyContent: 'right',
            marginTop: 0,
          },
          '& .MuiCardHeader-content': {
            gridArea: 'title',
            '& .MuiTypography-root': {
              fontWeight: fontWeightTitle,
            },
          },
          [breakpoints.up('breakPointDesktop')]: {
            gridTemplate: forceTitleMobileState
              ? `'. title closeButton' / ${pxToRem(32)} 1fr ${pxToRem(32)}`
              : `'title closeButton' / 1fr ${pxToRem(32)}`,
            padding: '0 2rem',
          },
        },
        '&:focus': {
          outline: 'none',
        },
      },
    },
    cardWithTitle: {
      '&.MuiCard-root': {
        paddingTop: 0,
      },
    },
    cardContent: {
      height: actionsButtonSticky ? `calc(100% - ${pxToRem(76)})` : '100%',
      overflow: actionsButtonSticky ? 'auto' : 'initial',
      paddingBottom: 0,
    },
    cardHeaderDark: {
      '& .MuiCardHeader-root': {
        background: colors.black,
        borderRadius: '24px 24px 0 0',
        '& .MuiTypography-root': {
          fontSize: pxToRem(20),
          color: colors.white,
          fontWeight: 700,
        },
      },
    },
    cardActions: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: actionsButtonSticky ? 0 : pxToRem(32),
      padding: actionsButtonSticky ? `${pxToRem(13)} 0` : 0,
      position: actionsButtonSticky ? 'sticky' : 'relative',
      bottom: actionsButtonSticky ? 0 : '',
      [breakpoints.up('breakPointDesktop')]: {
        flexDirection: actionButtonsFullWidth ? 'column' : 'row',
      },
    },
    contentWrapper: {
      height: hasTitle ? `calc(100% - ${pxToRem(30)})` : '100%',
      position: 'relative',
      [breakpoints.up('breakPointDesktop')]: {
        height: hasTitle
          ? `calc(100% - ${pxToRem(forceTitleMobileState ? 30 : 39)})`
          : '100%',
      },
    },
    title: {
      fontSize: pxToRem(18),
      fontWeight: 900,
      gridArea: 'title',
      textAlign: 'center',
      paddingTop: spacing(1.75),
      paddingBottom: spacing(1.75),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
      [breakpoints.up('breakPointDesktop')]: forceTitleMobileState
        ? {}
        : {
            fontSize: pxToRem(24),
            textAlign: alignTitleCenter ? 'center' : 'left',
            paddingTop: spacing(2),
            paddingBottom: spacing(2),
          },
    },
  }),
);

// Modal Component
export const ModalBox = ({
  actions = [],
  actionButtonsFullWidth = false,
  actionsButtonSticky = false,
  alignTitleCenter = false,
  ariaBusy = false,
  children,
  closeButton = 'bottom',
  colorCloseButton,
  fontWeightTitle = 900,
  forceTitleMobileState = false,
  isDarkHeader,
  onClose,
  placement = 'center',
  width = '',
  backGroundColor,
  showCardHeaderSeparator = true,
  titleColor,
  ...props
}: ModalBoxProps) => {
  const { classes } = useStyles({
    actionButtonsFullWidth,
    actionsButtonSticky,
    alignTitleCenter,
    fontWeightTitle,
    forceTitleMobileState,
    placement,
    width,
    hasTitle: !!props.title,
    showCardHeaderSeparator,
    backGroundColor,
    titleColor,
  });

  const theme = useTheme();

  // Prepare CardActions
  const actionButtons: JSX.Element[] = actions || [];
  if (closeButton === 'bottom') {
    actionButtons.push(
      <ActionButton onClick={onClose}>{t('actions.close')}</ActionButton>,
    );
  }

  // Manage close event (and disableBackdropClick option)
  const onCloseEvent: ModalProps['onClose'] = () => {
    if (props.isDisableBackdropClick) return false;
    onClose?.();
  };

  // Build component
  return (
    <Modal
      open={props.open}
      onClose={onCloseEvent}
      closeAfterTransition
      className={[
        classes.root,
        isDarkHeader ? classes.cardHeaderDark : '',
      ].join(' ')}
      aria-modal
      role="dialog"
      aria-labelledby="modalTitle"
      aria-busy={ariaBusy}
    >
      <Fade in={props.open}>
        <Card
          className={[
            classes.card,
            props.title ? classes.cardWithTitle : '',
          ].join(' ')}
        >
          {props.title && (
            <CardHeader
              title={props.title}
              titleTypographyProps={{
                component: props.titleComponent ?? 'h3',
                className: classes.title,
                'data-test-id': 'modalTitle',
                id: 'modalTitle',
              }}
              action={
                closeButton === 'header' && (
                  <CloseButton
                    onClose={onClose}
                    color={colorCloseButton ?? theme.app.colors.textContrasted}
                  />
                )
              }
            />
          )}
          <div className={classes.contentWrapper}>
            <ModalBoxScroller>
              <div>
                <CardContent className={classes.cardContent}>
                  {children}
                </CardContent>
                {actionButtons?.length > 0 && (
                  <CardActions className={classes.cardActions}>
                    {actionButtons.map((el, key) =>
                      cloneElement(el, {
                        key,
                        className: classes.actionButton,
                      }),
                    )}
                  </CardActions>
                )}
              </div>
            </ModalBoxScroller>
          </div>
        </Card>
      </Fade>
    </Modal>
  );
};
