import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { ConfigCommon } from '@sticky/config';
import { delay } from '@sticky/helpers';
import { Logger } from '@sticky/logger';

import { registerStickyServiceWorker } from './registerStickyServiceWorker';

type State = {
  forceUpdate: boolean;
  status:
    | 'updating'
    | 'preloading'
    | 'show-prompt'
    | 'initializing'
    | 'updated';
  initialize: () => void;
  activateUpdate?: () => void;
};

// Désactive l'affichage de message invitant à installer la PWA en tant
// qu'application sur android
window.addEventListener('beforeinstallprompt', event => {
  event.preventDefault();
});

/**
 * Hook pour gérer l'installation et l'état de la progressive web app (PWA).
 */
export const usePwaStore = create<State>()(
  devtools(
    (set, get) => ({
      status: 'initializing',
      forceUpdate: true,

      initialize: () => {
        // On ne fait rien si le service worker a déjà été initialisé
        if (get().status !== 'initializing') {
          return;
        }

        // On désactive la partie chargement de la PWA si les service workers
        // ne sont pas disponibles (par exemple : Firefox en navigation privée)
        if (typeof navigator.serviceWorker === 'undefined') {
          set({ status: 'updated' });
          return;
        }

        const onNeedRefresh = () => {
          Logger.log('[PWA] the new version is ready :', {
            forceUpdate: get().forceUpdate,
          });

          if (get().forceUpdate) {
            const activateUpdate = get().activateUpdate;

            if (activateUpdate) {
              activateUpdate();
            }
          } else {
            set({ status: 'show-prompt' });
          }
        };

        // Enregistre le service worker qui permet de précharger les assets (fichiers
        // js, png, ...) en cache et détecter les mises à jour.
        const { activateNewVersion } = registerStickyServiceWorker({
          onNeedRefresh,

          onRegistered: () => {
            Logger.log('[PWA] the service worker is registered');
            set({ status: 'updated' });
          },

          onCheckForUpdate: () => {
            Logger.log('[PWA] check for update');
          },

          onPreloadingUpdate: () => {
            Logger.log('[PWA] a new update is available');
            set({ status: 'preloading' });
          },

          onRegisterError: () => {
            Logger.log('[PWA] the registration of service worker has failed');
            set({ status: 'updated' });
          },
        });

        const activateUpdate = async () => {
          Logger.log('[PWA] activate the new version');

          set({ status: 'updating' });

          // Ajout d'un délai minimum pour que l'utilisateur puisse lire le message de
          // mise à jour.
          await delay(5);
          await activateNewVersion();

          set({ status: 'updated' });
        };

        set({ activateUpdate });
      },
    }),
    {
      name: 'pwa-store',
      enabled: !ConfigCommon.env.isProd && !ConfigCommon.env.isTest,
    },
  ),
);
