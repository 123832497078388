import type { Theme } from '@mui/material';
import { Box, Container } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

import { useHasIdTgvMaxSubscription } from '../../features/subscription/hooks/useHasIdTgvMaxSubscription';
import { SubscriptionProductId } from '../../features/subscription/models/subscription';
import { TermsListLink } from '../../features/terms/components/terms-list-link';
import type { ITermsLink } from '../../features/terms/service/terms-service';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { commonSpacing },
  } = theme;

  return {
    root: {
      paddingTop: theme.spacing(commonSpacing),
      paddingBottom: theme.spacing(commonSpacing),
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',

      [breakpoints.up('breakPointTablet')]: {
        flexDirection: 'row',
      },
    },
  };
});

export const FooterCGV = () => {
  const { classes } = useStyles();

  const hasIdTgvMaxSubscription = useHasIdTgvMaxSubscription();

  const terms: ITermsLink[] = t('cgv.links', {
    returnObjects: true,
    context: hasIdTgvMaxSubscription ? SubscriptionProductId.IDTGV_MAX : '',
  });

  return (
    <Container>
      <Box className={classes.root}>
        {terms.map(term => (
          <TermsListLink key={term.label} term={term} />
        ))}
      </Box>
    </Container>
  );
};
