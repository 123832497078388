import { type PropsWithChildren, useEffect } from 'react';
import { Alert, Button } from '@mui/material';
import { Box } from '@mui/system';
import { tss } from 'tss-react/mui';

import { t } from '@sticky/i18n';

import { LazyLoader } from '../../components/suspense/lazy-loader';

import { usePwaStore } from './usePwaStore';

const useStyles = tss.create(({ theme }) => ({
  alert: {
    position: 'fixed',
    bottom: '6rem',
    width: 'max-content',
    left: '50%',
    transform: 'translateX(-50%)',

    [theme.breakpoints.up('breakPointDesktop')]: {
      bottom: theme.spacing(2),
    },
  },
}));

export const PwaProvider = ({ children }: PropsWithChildren) => {
  const { status, activateUpdate, initialize, forceUpdate } = usePwaStore();
  const { classes } = useStyles();

  useEffect(() => {
    initialize();
  }, []);

  if (status === 'updating' || (forceUpdate && status === 'preloading')) {
    return (
      <LazyLoader
        title={t('pwa.update.loading.title')}
        description={t('pwa.update.loading.description')}
        reloadTimeout={45000}
      />
    );
  }

  let alert;
  if (status === 'show-prompt') {
    alert = (
      <Alert
        severity="info"
        action={
          <Button color="inherit" size="small" onClick={activateUpdate}>
            {t('actions.reload')}
          </Button>
        }
      >
        {t('pwa.update.available')}
      </Alert>
    );
  } else if (status === 'preloading') {
    alert = <Alert severity="info">{t('pwa.update.loading.title')}</Alert>;
  }

  return (
    <>
      {children}

      {alert && <Box className={classes.alert}>{alert}</Box>}
    </>
  );
};
