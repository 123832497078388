import type { Time } from '..';
import { HOURS, MINUTES } from '..';

const TIMES: Time[] = HOURS.reduce<Time[]>(
  (acc, hour) =>
    acc.concat(
      MINUTES.map(minute => ({
        minutes: minute,
        hours: hour,
      })),
    ),
  [],
);

export const getNextTime = (value: Time): Time => {
  const timeIndex = TIMES.findIndex(
    time => value.hours === time.hours && value.minutes === time.minutes,
  );
  return timeIndex === -1 || timeIndex === TIMES.length - 1
    ? value
    : TIMES[timeIndex + 1];
};

export const getTimeMinutes = (value: Time): number =>
  value.hours * 60 + value.minutes;
