import { useCallback, useEffect, useRef } from 'react';

import { openPopup } from '@sticky/helpers';

import { useAuthStore } from '../../store';

import { getLoginUrl } from './getLoginUrl';
import { loginWithAuthenticateCode } from './loginWithAuthenticateCode';

type Options = {
  email?: string;
  state?: Record<string, unknown>;
  callback: (error?: unknown) => void;
};

export const LOGIN_POPUP_ID = 'login-popup' as const;
export const LOGIN_POPUP_DATA_TYPE = 'mid-authentication' as const;

export const useLoginPopup = () => {
  const authStore = useAuthStore();
  const popupRef = useRef<Window>();
  const messageListenerRef = useRef<(event: MessageEvent) => void>();

  // Ferme la popup proprement
  const closePopup = () => {
    if (messageListenerRef.current) {
      window.removeEventListener('message', messageListenerRef.current);
    }

    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  // Ouvre la fenêtre contextuelle et écoute les messages jusqu'à recevoir le
  // code d'authentification MID. Ce code permet de générer un token
  // d'authentification.
  const loginPopup = useCallback(({ email, state, callback }: Options) => {
    if (!messageListenerRef.current) {
      messageListenerRef.current = async (event: MessageEvent) => {
        if (event.data.type === LOGIN_POPUP_DATA_TYPE) {
          closePopup();
          useAuthStore.getState().preLogin();

          try {
            await loginWithAuthenticateCode({
              authenticationCode: event.data.authenticationCode,
            });
          } catch (error) {
            callback(error);
            return;
          }

          callback();
        }
      };

      window.addEventListener('message', messageListenerRef.current);
      window.addEventListener('beforeunload', closePopup);
    }

    try {
      popupRef.current = openPopup({
        id: LOGIN_POPUP_ID,
        url: getLoginUrl({
          state: { ...state, from: LOGIN_POPUP_ID },
          email,
        }),
      });
    } catch (error) {
      authStore.postLogin(error);
      closePopup();
      callback(error);
    }
  }, []);

  useEffect(
    () => () => {
      closePopup();
    },
    [],
  );

  return { loginPopup };
};
