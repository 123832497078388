import type { PropsWithChildren } from 'react';
import { useContext } from 'react';

import { isInsideIframe } from '@sticky/helpers';

import { LAYOUT } from '../../routes/layout';
import { useRoute } from '../router/useRoute';

import { BrandContext } from './brand-provider';
import { KomboHeader } from './kombo';
import { SncfConnectHeader } from './sncf-connect';
import { TrainlineHeader } from './trainline';

const brandHeaders = {
  'sncf-connect': SncfConnectHeader,
  trainline: TrainlineHeader,
  kombo: KomboHeader,
};

export const BrandHeaderFooter = ({ children }: PropsWithChildren) => {
  const { brand } = useContext(BrandContext);
  const route = useRoute();

  const Header =
    brand?.id && brandHeaders[brand.id as keyof typeof brandHeaders];
  const isABrandedPage =
    route && ![LAYOUT.EMPTY, LAYOUT.TECHNICAL].includes(route.layout);
  const showBrand = Header && isABrandedPage && !isInsideIframe();

  return (
    <>
      {showBrand && <Header />}
      {children}
    </>
  );
};
