import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ButtonProps } from '@mui/material/Button';
import { addMonths } from 'date-fns';

import { useAuthStore } from '@sticky/auth';
import { ActionButton } from '@sticky/components';
import { getEnv } from '@sticky/config';
import { t } from '@sticky/i18n';

import type { IProposalPlan } from '../..';
import {
  cleanProposalPlans,
  customerSelectors,
  formatApiDateUTC,
  getProposalQuoteSales,
  getStartDateValidityListTz,
  PaymentMethod,
  ProductCodes,
  ProductSelect,
  PurchaseType,
  setProposalAmount,
  setProposalPaymentInitialInMonths,
  setProposalPaymentMethod,
  setProposalPlanId,
  setProposalPlanSelected,
  setProposalProductSelect,
  setProposalTravelClass,
} from '../..';
import { BlacklistControlService } from '../../features/customer/services/blacklist-control.service';
import { cleanCustomerLogAfterQuote } from '../../features/customer/store/customer-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Routes } from '../../routes/routes';
import {
  AccountBlockedModal,
  AccountConnectModal,
  AgeRequiredModal,
  AlreadySubscribedModal,
  BlacklistControlProblemModal,
  FraudControlModal,
} from '..';

interface SubscribeButtonProps {
  color?: ButtonProps['color'];
  disabled?: boolean;
  label?: string;
  variant?: 'contained' | 'outlined' | 'text';
}

const SubscribeButton = ({
  label,
  color = 'primary',
  disabled = false,
  variant = 'contained',
}: SubscribeButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authStore = useAuthStore();

  const { isAuth, customer, logAfterQuote } = useAppSelector(
    state => state.customer,
  );
  const proposal = useAppSelector(state => state.proposal);
  const hasAlreadyMAXSubscription = useAppSelector(
    customerSelectors.hasAlreadyMAXSubscription,
  );

  const [isLoading, setLoading] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [isDisplayAccountModal, setDisplayAccountModal] = useState(false);
  const [isDisplayAgeRequiredModal, setDisplayAgeRequiredModal] =
    useState(false);
  const [isDisplayAlreadySubscribedModal, setDisplayAlreadySubscribedModal] =
    useState(false);
  const [
    isDisplayBlacklistControlProblemModal,
    setDisplayBlacklistControlProblemModal,
  ] = useState(false);
  const [isDisplayBlockedAccountCard, setDisplayBlockedAccountCard] =
    useState(false);
  const [isDisplayFraudControlModal, setDisplayFraudControlModal] =
    useState(false);

  const allowedCards = useAppSelector(customerSelectors.getAllowedCards);

  const maxAgeRequiredInMonths = Number(
    getEnv('VITE_MAX_AGE_REQUIRED_IN_MONTHS'),
  );
  const minAgeRequiredInMonths = Number(
    getEnv('VITE_MIN_AGE_REQUIRED_IN_MONTHS'),
  );

  // When proposal has been received, set store states and continue enrollment
  const nextStep = useCallback(
    async (selectedPlan: IProposalPlan): Promise<void> => {
      setLoading(true);
      setSubmit(false);

      return BlacklistControlService.throwIfRejected()
        .then(() => {
          dispatch(setProposalPlanSelected(selectedPlan));
          dispatch(setProposalPlanId(selectedPlan.idProposal));
          dispatch(setProposalAmount(selectedPlan.amount));
          dispatch(setProposalPaymentMethod(PaymentMethod.IBAN));
          dispatch(setProposalProductSelect(ProductSelect.PART_TIME));
          dispatch(setProposalTravelClass('2'));
          dispatch(setProposalPaymentInitialInMonths(1));
          dispatch(cleanProposalPlans());

          // Start enrollment
          navigate(Routes.getPathById('enrollment/my-coordinates'));

          setLoading(false);
        })
        .catch(err => {
          if (err.message === 'BLACKLISTED') {
            setDisplayBlockedAccountCard(true);
          } else if (err.message === 'FRAUD') {
            setDisplayFraudControlModal(true);
          } else {
            setDisplayBlacklistControlProblemModal(true);
          }
          setLoading(false);
        });
    },
    [dispatch, navigate],
  );

  // Common code to get a proposal
  const getProposal = useCallback(() => {
    // Abort if customer has already a MAX SENIOR sub
    if (hasAlreadyMAXSubscription) {
      setDisplayAlreadySubscribedModal(true);
      return;
    }

    // Abort if Age conditions ar not good
    if (maxAgeRequiredInMonths || minAgeRequiredInMonths) {
      let ageControlFailure = false;
      const birthDate = new Date(customer?.birthDate ?? NaN).valueOf();

      // Age Control fails : if no birthdate provided
      if (isNaN(birthDate)) {
        ageControlFailure = true;
      }

      // Check if birthdate is below the max age limit
      if (maxAgeRequiredInMonths) {
        if (
          birthDate <
          addMonths(new Date(), -1 * maxAgeRequiredInMonths).valueOf()
        ) {
          ageControlFailure = true;
        }
      }

      // Check if birthdate is above the min age limit
      if (minAgeRequiredInMonths) {
        if (
          birthDate >=
          addMonths(new Date(), -1 * minAgeRequiredInMonths).valueOf()
        ) {
          ageControlFailure = true;
        }
      }

      // Display modal if age control is KO
      if (ageControlFailure) {
        setDisplayAgeRequiredModal(true);
        return;
      }
    }

    // Ask for proposal quotes
    dispatch(
      getProposalQuoteSales({
        startDateValidity: getStartDateValidityListTz(new Date(), 1)[0],
        productCode: ProductCodes.TGV_MAX,
        purchaseType: PurchaseType.INITIAL,
        birthDate: formatApiDateUTC(customer?.birthDate),
        isKnown: isAuth && !!allowedCards,
      }),
    );
  }, [
    allowedCards,
    customer?.birthDate,
    hasAlreadyMAXSubscription,
    dispatch,
    isAuth,
    maxAgeRequiredInMonths,
    minAgeRequiredInMonths,
  ]);

  const handleSubscribe = () => {
    if (isLoading || proposal.loading) return;
    if (isAuth) {
      setSubmit(true);
      getProposal();
    } else {
      setDisplayAccountModal(true);
    }
  };

  // Manage logAfter
  useEffect(() => {
    if (logAfterQuote && customer) {
      getProposal();
      dispatch(cleanCustomerLogAfterQuote());
    }
  }, [customer, dispatch, getProposal, logAfterQuote]);

  useEffect(() => {
    // If getProposal() is done
    const selectedPlan = proposal?.plans?.[0];
    if (selectedPlan && isSubmit) {
      nextStep(selectedPlan);
    }
  }, [isSubmit, nextStep, proposal.plans]);

  const isButtonSubscribeLoading =
    authStore.loading || isLoading || proposal.loading;

  return (
    <>
      <ActionButton
        color={color}
        disabled={disabled}
        variant={variant}
        onClick={handleSubscribe}
        loading={isButtonSubscribeLoading}
      >
        {label || t('actions.subscribe')}
      </ActionButton>

      <AccountBlockedModal
        open={isDisplayBlockedAccountCard}
        onClose={() => setDisplayBlockedAccountCard(false)}
      />
      <AccountConnectModal
        open={isDisplayAccountModal && !isAuth}
        onclose={() => setDisplayAccountModal(false)}
      />
      <AgeRequiredModal
        open={isDisplayAgeRequiredModal}
        onclose={() => setDisplayAgeRequiredModal(false)}
      />
      <AlreadySubscribedModal
        open={isDisplayAlreadySubscribedModal}
        onClose={() => setDisplayAlreadySubscribedModal(false)}
      />
      <BlacklistControlProblemModal
        open={isDisplayBlacklistControlProblemModal}
        onClose={() => setDisplayBlacklistControlProblemModal(false)}
      />
      <FraudControlModal
        open={isDisplayFraudControlModal}
        onClose={() => setDisplayFraudControlModal(false)}
      />
    </>
  );
};

export default SubscribeButton;
