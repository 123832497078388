import { StickyGatewayClient } from '@sticky/gateway-client';

export class CaptchaService {
  /**
   * Calls /api/api/public/serverless/check-recaptcha to validate the captcha through the WAF
   * - the first /api is removed by cloudfront
   * - the second matches WAF's endpoint
   *
   * @return the client's promise. When resolved correctly, a 204 is returned.
   **/
  public static async sendCaptcha(value?: string): Promise<Response> {
    const data = new URLSearchParams({
      'g-recaptcha-response': value ?? '',
      formname: 'vsccaptcha',
    }).toString();

    return StickyGatewayClient.anonymousClient().post(
      '/api/public/serverless/check-recaptcha',
      data,
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      },
    );
  }
}
