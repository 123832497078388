import { Field } from 'react-final-form';
import { Warning } from '@mui/icons-material';
import { Checkbox, Radio } from '@mui/material';
import { tss } from 'tss-react/mui';

import { formatToDDMMYYYY } from '@sticky/helpers';
import { t } from '@sticky/i18n';

import { CheckboxIcon, formatToHHMM } from '../..';

import type { TravelDetailsProps } from './travel-details';

type StatusProps = Pick<TravelDetailsProps, 'travel' | 'selectionDisabled'> & {
  fieldType: 'checkbox' | 'radio';
};

const useStatusStyle = tss.create(
  ({
    theme: {
      app: { colors },
    },
  }) => ({
    checkboxContainer: {
      display: 'inline-block',
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
        '&.Mui-focusVisible': {
          outlineOffset: '4px',
        },
      },
    },

    checkboxIcon: {
      'input:disabled ~ &': {
        backgroundColor: colors.disabledBackground,
        fill: colors.warmGray1,
      },
    },

    alert: {
      fill: colors.errorDark,
    },
  }),
);

export const Status = ({
  travel,
  fieldType = 'checkbox',
  selectionDisabled = false,
}: StatusProps) => {
  const { classes } = useStatusStyle();

  switch (travel.state) {
    case 'success': {
      const ariaLabel = t('reservation.travelDetails.checkboxAriaLabel', {
        origin: travel.origin,
        destination: travel.destination,
        departureDate: formatToDDMMYYYY(travel.departureDate),
        departureTime: formatToHHMM(travel.departureDate),
      });

      return (
        <span className={classes.checkboxContainer}>
          <Field
            name={
              fieldType === 'radio' ? 'selectedTravel' : `travels[${travel.id}]`
            }
            type={fieldType}
          >
            {({ input }) =>
              fieldType === 'radio' ? (
                <Radio
                  name={input.name}
                  value={travel.id}
                  onClick={event => event.stopPropagation()}
                  onChange={input.onChange}
                  disabled={selectionDisabled}
                  color="primary"
                  inputProps={{
                    'aria-label': ariaLabel,
                    id: `travel-${travel.id}`,
                  }}
                />
              ) : (
                <Checkbox
                  name={input.name}
                  checked={input.checked}
                  onClick={event => event.stopPropagation()}
                  onChange={input.onChange}
                  disabled={selectionDisabled}
                  icon={<CheckboxIcon className={classes.checkboxIcon} />}
                  color="primary"
                  inputProps={{
                    'aria-label': ariaLabel,
                  }}
                />
              )
            }
          </Field>
        </span>
      );
    }
    case 'loading':
      return <span className={classes.checkboxContainer}>&nbsp;</span>;
    case 'error':
      return <Warning className={classes.alert} />;
  }
};
