type Options = {
  withSign?: boolean;
  currency?: string;
  locale?: string;
  condensed?: boolean;
};

/**
 * Cette fonction permet de formatter un nombre dans un prix en €.
 */
export const formatPrice = (
  amount: number | bigint | undefined,
  { withSign, currency = 'EUR', locale = 'fr-FR', condensed }: Options = {},
) => {
  if (!amount) {
    return '';
  }

  const prefix = amount > 0 && withSign ? '+' : '';
  const price =
    prefix +
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(amount);

  return condensed ? price.replace(/\s/g, '') : price;
};
