import { lazy } from 'react';

import { t } from '@sticky/i18n';

import { LAYOUT } from '../../../layout';
import type { IRoute } from '../../../routes';

const routes: IRoute[] = [
  {
    id: 'customer/my-subscription/regularization/confirm',
    path: '/mon-abonnement/confirmation-paiement',
    title: `${t('customer.subscription.index')} - ${t(
      'customer.subscription.regularization.success.pageTitle',
    )}`,
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import(
          '../../../../pages/customer/subscription/invoice/regularization/customer-invoice-payment-confirmation'
        ),
    ),
    private: true,
  },
  {
    id: 'customer/my-subscription/regularization/error',
    path: '/mon-abonnement/confirmation-paiement-erreur',
    title: `${t('customer.subscription.index')} - ${t(
      'customer.subscription.regularization.error.pageTitle',
    )}`,
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import(
          '../../../../pages/customer/subscription/invoice/regularization/customer-invoice-payment-confirmation-error'
        ),
    ),
    private: true,
  },
];

export default routes;
