import { forwardRef } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

type LinkBehaviorProps = Omit<LinkProps, 'to'> & {
  href: LinkProps['to'] | string;
};

export const LinkBehavior = forwardRef<HTMLAnchorElement, LinkBehaviorProps>(
  function InnerLinkBehavior({ href, ...rest }, ref) {
    // Vérifier si c'est une URL absolue vers un lien externe
    const isExternalLink =
      typeof href === 'string' &&
      /^https?:\/\//.test(href) &&
      !href.startsWith(window.location.origin);

    if (isExternalLink) {
      // Créer un lien externe
      return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a ref={ref} href={href} target="_blank" rel="noreferrer" {...rest} />
      );
    }

    // Créer un lien interne
    return <Link ref={ref} to={href} {...rest} />;
  },
);
