import React, { useState } from 'react';
import type { Theme } from '@mui/material';
import { Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import type { ITermsLink } from '../service/terms-service';

import { TermsListModal } from './terms-list-modal';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { colors },
  } = theme;

  return {
    link: {
      color: colors.black,
      textDecoration: 'underline',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      display: 'block',
      border: 'none',
      fontWeight: 'normal',
      [breakpoints.up('breakPointTablet')]: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingTop: 0,
        paddingBottom: 0,
        borderRight: 'solid 1px',
      },
      '&:hover': {
        color: colors.textContrasted,
        cursor: 'pointer',
      },
      '&:last-child': {
        border: 'none',
      },
    },
  };
});

type TermsListLinkProps = { term: ITermsLink };

export const TermsListLink = ({ term }: TermsListLinkProps) => {
  const { classes } = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Link className={classes.link} onClick={() => setModalOpen(true)}>
        {term.label}
      </Link>

      <TermsListModal
        term={term}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );
};
