import { useCallback, useEffect, useState } from 'react';
import type { AccordionProps } from '@mui/material';
import { Accordion } from '@mui/material';
import { tss } from 'tss-react/mui';

import { AccordionContext } from './AccordionContext';

const useStyles = tss.create(({ theme }) => ({
  root: {
    overflow: 'hidden',

    '& .MuiAccordionSummary-content.Mui-expanded': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

export type AccordionPanelProps = AccordionProps & {
  id: string;
  withDivider?: boolean;
};

export const AccordionPanel = ({
  id,
  children,
  className,
  withDivider,
  defaultExpanded,
  ...rest
}: AccordionPanelProps) => {
  const { classes, cx } = useStyles();

  // Pour ouvrir ou fermer par défaut l'accordéon, on se base sur la valeur
  // donnée dans les props sinon en se basant sur le hash dans l'url
  const [expanded, setExpandedValue] = useState<boolean>(
    defaultExpanded ?? `#${id}` === window.location.hash,
  );

  // Permet d'ouvrir ou de fermer l'accordéon
  const toggle = useCallback(() => {
    // Si l'url contient l'id, on le supprime de l'url
    if (`#${id}` === window.location.hash) {
      window.history.replaceState(null, '', window.location.pathname);
    }

    setExpandedValue(!expanded);
  }, [expanded]);

  useEffect(() => {
    if (`#${id}` === window.location.hash) {
      setExpandedValue(true);
    }
  }, [window.location.hash, id]);

  return (
    <AccordionContext.Provider value={{ id, expanded, toggle }}>
      <Accordion
        id={id}
        className={cx(classes.root, className)}
        expanded={expanded}
        {...rest}
      >
        {children}
      </Accordion>
    </AccordionContext.Provider>
  );
};
