import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { StickyGatewayClient } from '@sticky/gateway-client';

import type { RootState } from '../../..';
import { savePersistentState } from '../..';
import type {
  IDestinationStations,
  IOriginStation,
  IReadStationsState,
  IStation,
} from '../models/station';

// Constants for localStorage
const LOCALSTORAGE_KEY = 'stations';

const initialState: IReadStationsState = {
  loading: false,
  error: '',
  isDestinationInvalid: false,
};

export const getStationsList = createAsyncThunk(
  'get/stationsList',
  async () => {
    const { data } = await StickyGatewayClient.anonymousClient().get<
      IStation[]
    >('/public/refdata/eligible-mfa-stations');
    return data;
  },
  {
    condition: (_, { getState }) => {
      const { stations } = getState() as RootState;
      if (stations.loading || stations.eligibleStations?.length) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  },
);

export const getDestinationStationsList = createAsyncThunk(
  'post/destinationStationsList',
  async (
    originStation: IOriginStation,
    thunkApi,
  ): Promise<IDestinationStations> => {
    const httpClient = StickyGatewayClient.anonymousClient();
    const postData = {
      origin: originStation.origin,
      productSelect: originStation.productSelect,
    };
    const url = '/public/refdata/destination-mfa';
    const asyncFn = httpClient
      .post<IDestinationStations>(url, postData)
      .catch((reason: AxiosError) => {
        throw reason.response;
      });
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
  {
    condition: (_, { getState }) => {
      const { stations } = getState() as RootState;
      if (stations.loading) {
        return false;
      }
    },
  },
);

const stationsSlice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    clearStations: () => initialState,
    cleanDestinationStations: state => {
      delete state.destinationStations;
      savePersistentState(LOCALSTORAGE_KEY, state);
    },
  },
  extraReducers: builder => {
    builder.addCase(getStationsList.pending, state => {
      state.loading = true;
    });
    builder.addCase(getStationsList.fulfilled, (state, action) => {
      state.loading = false;
      state.eligibleStations = action.payload?.sort((a, b) =>
        a.labelStation > b.labelStation ? 1 : -1,
      );
    });
    builder.addCase(getStationsList.rejected, state => {
      state.loading = false;
      state.error = 'errors.generic-error';
    });

    builder.addCase(getDestinationStationsList.pending, state => {
      state.isDestinationInvalid = false;
      state.loading = true;
    });
    builder.addCase(getDestinationStationsList.fulfilled, (state, action) => {
      state.loading = false;
      if (state.eligibleStations?.length)
        state.destinationStations = state.eligibleStations.filter(station =>
          action.payload?.destinations.includes(station.codeStation),
        );
    });
    builder.addCase(getDestinationStationsList.rejected, state => {
      state.loading = false;
      state.error = 'errors.generic-error';
    });
  },
});

export const { clearStations, cleanDestinationStations } =
  stationsSlice.actions;

const stationsReducer = stationsSlice.reducer;
export { stationsReducer };
