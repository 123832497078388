import { getEnv, getFeatures } from '@sticky/config';
import { createStickyStorage } from '@sticky/helpers';

import { MyId } from '../customer/services/MyID';

import type { BrandConfig } from './config';
import { BrandIds, DistributionChannel } from './config';

export const BrandConfigs: BrandConfig[] = [
  {
    id: 'sncf-connect',
    enabled: true,
    isPublic: true,
    distributionChannel: DistributionChannel.SNCF_CONNECT,
    url: 'https://www.sncf-connect.com/',
  },
  {
    id: 'feria',
    enabled: getFeatures().brands.feria,
    isPublic: false,
    distributionChannel: DistributionChannel.OTHER,
  },
  {
    id: 'lighthouse',
    enabled: true,
    isPublic: false,
    distributionChannel: DistributionChannel.OTHER,
  },
  {
    id: 'trainline',
    enabled: getFeatures().brands.trainline,
    isPublic: true,
    distributionChannel: DistributionChannel.TRAINLINE,
    url: 'https://www.thetrainline.com/fr',
  },
  {
    id: 'kombo',
    enabled: getFeatures().brands.kombo,
    isPublic: true,
    distributionChannel: DistributionChannel.KOMBO,
    url: getEnv('VITE_BRAND_URL_KOMBO'),
  },
];

const brandStorage = createStickyStorage({ prefix: 'brand' });

/**
 * Manage Brands
 */
export class BrandsService {
  /**
   * Singleton BrandConfig (resolved at init)
   * @private
   */
  static brand: BrandConfig | undefined;

  /**
   * Key used to transfer and store the brand id.
   *
   * @static
   * @memberof Brands
   */
  static readonly BRAND_ID_STORAGE_KEY = 'BRAND_ID';

  public static getBrandIdFromPath(): string | undefined {
    const path = window.location.pathname;
    return BrandIds.find(id => path.includes(id));
  }

  static async loadBrand(): Promise<BrandConfig | undefined> {
    let resolvedBrand;
    const brandFromPath = this.getBrandConfig(this.getBrandIdFromPath());

    if (brandFromPath?.enabled) {
      resolvedBrand = brandFromPath;
      this.storeBrand(resolvedBrand);
    } else {
      resolvedBrand = await this.recoverAValidBrand();
      if (resolvedBrand) {
        this.updateBrand(resolvedBrand);
      }
    }

    return resolvedBrand;
  }

  private static updateBrand(brand: BrandConfig): void {
    this.updateBrandInPath(brand?.id);
    this.storeBrand(brand);
  }

  private static updateBrandInPath(newBrandId?: string) {
    const brandInPath = this.getBrandIdFromPath();
    const origin = window.location.origin;
    const rest = window.location.href
      .replace(origin, '')
      .replace(`/${brandInPath}`, '');
    window.history.replaceState({}, '', `/${newBrandId}${rest}`);
  }

  /**
   * Just an accessor from that service
   */
  static getBrandConfigs(): BrandConfig[] {
    return BrandConfigs;
  }

  static getAllPublic() {
    return this.getBrandConfigs().filter(
      ({ enabled, isPublic: pub }) => pub && enabled,
    );
  }

  private static getPublic(brandId?: string | null) {
    return this.getBrandConfigs().find(
      ({ enabled, isPublic: pub, id }) => pub && enabled && brandId === id,
    );
  }

  private static getBrandConfig(brandId?: string | null) {
    return this.getBrandConfigs().find(({ id }) => brandId === id);
  }

  /**
   * Method used to recover the brand from storage or params
   */
  static async recoverAValidBrand() {
    const publicBrands = BrandsService.getAllPublic();
    return (
      (publicBrands.length === 1 && publicBrands[0]) ||
      (await BrandsService.getBrandFromQueryParams()) ||
      (await BrandsService.getBrandFromStorage())
    );
  }

  /**
   * Store the brand id in storage and ram.
   *
   * @private
   * @static
   * @param {BrandConfig} brand
   * @return {Promise<void>}
   * @memberof BrandService
   */
  public static storeBrand(brand?: BrandConfig) {
    BrandsService.brand = brand;
    if (!brand) return;

    brandStorage.setItem(BrandsService.BRAND_ID_STORAGE_KEY, brand.id);
  }

  private static getBrandFromQueryParams(
    storageKey = BrandsService.BRAND_ID_STORAGE_KEY,
  ) {
    const queryParams = new URLSearchParams(window.location.search);

    return (
      this.getPublic(queryParams.get(storageKey)) ||
      this.getPublic(MyId.parseState(queryParams)?.[storageKey])
    );
  }

  private static getBrandFromStorage() {
    return this.getPublic(
      brandStorage.getItem<string>(BrandsService.BRAND_ID_STORAGE_KEY),
    );
  }
}
