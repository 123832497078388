import type { Theme } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { t } from '@sticky/i18n';

import type { Travel } from '../..';
import { reservationSelectors } from '../..';
import { useAppSelector } from '../../hooks';

const useStyles = makeStyles()(
  ({ breakpoints, typography: { pxToRem } }: Theme) => ({
    actionsContainer: {
      gridArea: 'actions',
      width: '100%',
      textAlign: 'center',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.up('breakPointDesktop')]: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: pxToRem(14),
      },
      marginBottom: pxToRem(14),
    },
    actionButton: {
      marginTop: pxToRem(15),
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: 0,
        marginLeft: pxToRem(30),
      },
    },
  }),
);

interface ExchangeActionsProps {
  travel: Travel;
  onCancelTravel: (travel: Travel) => void;
  onExchangeTravel: (travel: Travel) => void;
}

export const ExchangeActions = ({
  onCancelTravel,
  onExchangeTravel,
  travel,
}: ExchangeActionsProps): JSX.Element | null => {
  const { classes } = useStyles();
  const details = useAppSelector(state =>
    reservationSelectors.getDetailedTravel(state, travel),
  );

  const nonExchangeableNorRefundableMessage = () => {
    if (details?.detailsState?.fetched) {
      if (
        !details?.travelDetails?.exchangeable &&
        !details?.travelDetails?.refundable
      ) {
        return (
          <Typography>
            {t('travels.detailsTravel.nonAnnulableNorExchangeable')}
          </Typography>
        );
      }
      if (!details?.travelDetails?.exchangeable) {
        return (
          <Typography>{t('travels.detailsTravel.nonExchangeable')}</Typography>
        );
      }
      if (!details?.travelDetails?.refundable) {
        return (
          <Typography>{t('travels.detailsTravel.nonRefundable')}</Typography>
        );
      }
    }
  };

  return (
    <div className={classes.actionsContainer}>
      <div className={classes.buttonsContainer}>
        <ActionButton
          variant={
            !details?.travelDetails?.exchangeable ? 'contained' : 'outlined'
          }
          onClick={() => onExchangeTravel(travel)}
          disabled={!details?.travelDetails?.exchangeable}
        >
          {t('travels.detailsTravel.buttonSwitch')}
        </ActionButton>
        <ActionButton
          onClick={() => onCancelTravel(travel)}
          className={classes.actionButton}
          disabled={!details?.travelDetails?.refundable}
        >
          {t('travels.detailsTravel.buttonCancel')}
        </ActionButton>
      </div>
      {nonExchangeableNorRefundableMessage()}
    </div>
  );
};
