export class ExchangeSearchRequest {
  constructor(
    private customerName: string,
    private departureDateTime: string,
    private destination: string,
    private marketingCarrierRef: string,
    private orderId: string,
    private origin: string,
    private cardNumber: string,
    private productId: string,
    private travelClass: string,
  ) {}
}
