type ImportMetaEnv = Record<string, string>;

/**
 * Get, replace variables and format an environment variable.
 *
 * @param {string} key - The key of the env variable.
 * @param {string} defaultValue - The default value
 * @returns The value of the env variable.
 */
export const getEnv = function (key: string, defaultValue = ''): string {
  const keys = import.meta.env as ImportMetaEnv;
  return replaceEnvKey(key, keys) || defaultValue;
};

// Private methods to replace vars
const replaceRegex = /{{(.*?)}}/g;
const replaceEnvKey = function (value: string, keys: ImportMetaEnv): string {
  return (keys[value] || '').replace(
    replaceRegex,
    (match: string, offset: string) => {
      if (replaceRegex.test(keys[offset])) {
        return replaceEnvKey(offset, keys);
      }
      return keys[offset];
    },
  );
};
