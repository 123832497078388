import { v4 as uuid } from 'uuid';

export const HEADER_CORRELATION_ID = 'x-syg-correlation-id';

export const getCorrelationId = () => {
  let correlationID = localStorage.getItem(HEADER_CORRELATION_ID);

  if (!correlationID) {
    correlationID = uuid();
    localStorage.setItem(HEADER_CORRELATION_ID, correlationID);
  }

  return correlationID;
};
