import { lazy } from 'react';

import { t } from '@sticky/i18n';

import { LAYOUT } from '../../../layout';
import type { IRoute } from '../../../routes';

const routes: IRoute[] = [
  {
    id: 'customer/my-coordinates/update-billto',
    path: '/mes-coordonnees/modification-payeur',
    title: t('customer.coordinates.update-billto.edit'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import('../../../../pages/customer/account/update-billto/contact-form'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-billto/bank-list',
    path: '/mes-coordonnees/modification-payeur/liste-banques',
    title: t('customer.coordinates.update-billto.edit'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import('../../../../pages/customer/account/update-billto/bank-list'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-billto/bank-branch-list',
    path: '/mes-coordonnees/modification-payeur/liste-branches',
    title: t('customer.coordinates.update-billto.edit'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import(
          '../../../../pages/customer/account/update-billto/bank-branch-list'
        ),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-billto/iban-input',
    path: '/mes-coordonnees/modification-payeur/saisie-iban',
    title: t('customer.coordinates.update-billto.edit'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import('../../../../pages/customer/account/update-billto/iban-input'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-billto/otu-input',
    path: '/mes-coordonnees/modification-payeur/saisie-otu',
    title: t('customer.coordinates.update-billto.edit'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import('../../../../pages/customer/account/update-billto/otu-input'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-billto-success',
    path: '/mes-coordonnees/modification-payeur/confirmation',
    title: t('customer.coordinates.update-billto.success.pageTitle'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () => import('../../../../pages/customer/account/update-billto/success'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-billto-error',
    path: '/mes-coordonnees/modification-payeur/confirmation-erreur',
    title: t('customer.coordinates.update-billto.error.pageTitle'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import(
          '../../../../pages/customer/account/update-billto/sepa-unavailable'
        ),
    ),
    private: true,
  },
];

export default routes;
