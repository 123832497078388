import { encodeStringHex, toQueryString } from '@sticky/helpers';

import { midClient } from '../../clients';
import { useAuthStore } from '../../store';

export type GetSignUpUrlOptions<T = Record<string, unknown>> = {
  email?: string;
  state?: T;
};

/**
 * Retourne l'URL de la page de création de compte MID.
 */
export const getSignUpUrl = ({ email, state }: GetSignUpUrlOptions = {}) => {
  const authState = useAuthStore.getState();

  if (!authState.config) {
    throw new Error('The auth store should be initialized');
  }

  const queryString = toQueryString({
    login_hint: email,
    state: state ? encodeStringHex(JSON.stringify(state)) : undefined,
  });

  return midClient.signUpUrl({
    callback: `${authState.config.loginRedirectUri}${
      queryString ? `?${queryString}` : ''
    }`,
  });
};
