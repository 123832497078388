import { useEffect, useState } from 'react';
import type { Theme } from '@mui/material';
import { Box, Link, Skeleton, Typography } from '@mui/material';
import type { AxiosResponse } from 'axios';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

import { AlertMessage } from '../../../components';
import { ModalBox } from '../../../components/modal/modal-box';
import { toFrenchDate } from '../../../utils/date';
import type {
  IServiceTermsReferenceOutput,
  ITermsLink,
} from '../service/terms-service';
import { ServiceTerms } from '../service/terms-service';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    app: { colors },
  } = theme;
  return {
    group: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(3),
      },
      '&:last-of-type': {
        marginBottom: theme.spacing(3),
      },
    },

    title: {
      borderBottom: `1px solid ${colors.warmGray1}`,
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },

    link: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(1),
      },
    },
  };
});

interface TermsListModalProps {
  term: ITermsLink;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TermsListModal = ({
  term,
  modalOpen,
  setModalOpen,
}: TermsListModalProps): JSX.Element => {
  const { classes } = useStyles();

  // States
  const [cgvDatas, setCgvDatas] = useState<IServiceTermsReferenceOutput>();
  const [requestStatus, setRequestStatus] =
    useState<AxiosResponse['status']>(0);

  // Get the keys of the reference output
  const groups: { [name: string]: { title: string; title_other?: string } } = t(
    'terms.modal-list-all',
    {
      returnObjects: true,
    },
  );
  const cgvKeys = Object.keys(groups) as (keyof IServiceTermsReferenceOutput)[];

  // Fetch links
  useEffect(() => {
    if (modalOpen === false) return;
    setRequestStatus(0);
    ServiceTerms.getDatas({ productId: term.productId })
      .then(response => {
        setCgvDatas(response.data);
        setRequestStatus(response.status);
      })
      .catch(() => {
        setRequestStatus(400);
      });
  }, [modalOpen, term.productId]);

  // Build modal
  return (
    <ModalBox
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title={term.label}
      closeButton="header"
    >
      <>
        {/* LOADING */}
        {requestStatus === 0 && (
          <>
            <Skeleton></Skeleton>
            <Skeleton width="60%"></Skeleton>
            <Skeleton width="60%"></Skeleton>
          </>
        )}

        {/* ERROR */}
        {requestStatus >= 400 && <AlertMessage />}

        {/* DISPLAYING RESULTS */}
        {requestStatus === 200 &&
          cgvKeys.map(itemKey => {
            // Get items for the specified period and productId (all items if term.productId is undefined)
            const items = cgvDatas?.[itemKey]?.filter(item =>
              term.productId === undefined
                ? true
                : item.productId === term.productId,
            );

            // Skip if nothing to display for that period
            if (!items || !items.length) return <></>;

            // Build header with links
            return (
              <Box className={classes.group} key={itemKey}>
                <Typography variant="subtitle2" className={classes.title}>
                  {items.length > 1
                    ? groups[itemKey].title_other
                    : groups[itemKey].title}
                </Typography>

                {items.map(item => (
                  <Box key={item.url} className={classes.link}>
                    <Link href={item.url} target="_blank" underline="always">
                      {term.label} - {toFrenchDate(item.applicationDate)}
                    </Link>
                  </Box>
                ))}
              </Box>
            );
          })}
      </>
    </ModalBox>
  );
};
