import type { IHomeBanner } from '@sticky/sticky-common/src/resources/types/banner/homeBanner';

export const loadBannerHome = (): IHomeBanner[] => [
  {
    title: 'Confirmez vos voyages !',
    mainMessage:
      'Pensez à confirmer vos voyages à partir de 48 heures avant le départ et au plus tard la veille à 17h.',
    secondMessage:
      'Sans confirmation de votre part, votre réservation sera annulée.',
  },
];

export const loadIdTGVMAX2CgvTranslation = async () => {
  const cgvIdtgvmax2 = await import('../fr/cgv-idtgvmax2.json');
  const contactFaqIdtgvmax2 = await import('../fr/contact-faq-idtgvmax2.json');

  return {
    cgvIdtgvmax2,
    contactFaqIdtgvmax2,
  };
};
