import { isAfter } from 'date-fns';
import { createSelector } from 'reselect';

import { t } from '@sticky/i18n';

import {
  type OffertTypeQuoteOutput,
  RatePlanTypeOfferType,
  type RootState,
} from '../../..';
import type { ISubscriptionList } from '../../subscription/models/subscription';

const getProposalOfferTypeQuotes = (
  state: RootState,
): OffertTypeQuoteOutput[] | undefined => state.proposal.offerTypeQuotes?.value;

const getProposalLabel = (state: RootState): string => {
  // Build list of deals and initial value
  const subscriptions: ISubscriptionList[] = t('proposal.deals', {
    returnObjects: true,
  });

  // Get proposal
  const proposal = state.proposal;

  // Search for translation with productCode and productSelect
  return subscriptions.find(
    sub =>
      sub.productCodes.includes(proposal.productCode) &&
      sub.value === proposal.productSelect,
  )?.label as string;
};

const getStandardPrice = createSelector(
  getProposalOfferTypeQuotes,
  offerTypeQuotes => {
    if (!offerTypeQuotes) {
      return undefined;
    }

    const standard = offerTypeQuotes.find(
      o => o.ratePlanType === RatePlanTypeOfferType.STANDARD,
    );

    return standard?.price;
  },
);

const getPromo = createSelector(getProposalOfferTypeQuotes, offerTypeQuotes => {
  if (!offerTypeQuotes) {
    return undefined;
  }

  return offerTypeQuotes.find(
    o => o.ratePlanType === RatePlanTypeOfferType.PROMO,
  );
});

const getIsInPromo = createSelector(
  getProposalOfferTypeQuotes,
  offerTypeQuotes => {
    if (!offerTypeQuotes) {
      return false;
    }
    return isInPromo(offerTypeQuotes);
  },
);

function isInPromo(offerTypeQuotes: OffertTypeQuoteOutput[]): boolean {
  const promo = offerTypeQuotes.find(
    o => o.ratePlanType === RatePlanTypeOfferType.PROMO,
  );

  let shouldBeInPromo = !!promo;
  if (shouldBeInPromo && promo?.effectiveEndDate) {
    shouldBeInPromo = isAfter(Date.parse(promo?.effectiveEndDate), new Date());
  }
  return shouldBeInPromo;
}

export const proposalSelectors = {
  getProposalLabel,
  getIsInPromo,
  getPromo,
  getStandardPrice,
};
