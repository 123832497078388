import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { tss } from 'tss-react/mui';

import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { PriceCard } from '..';

import OfferElement from './offer';
import type { Offer } from './types';

const useStyles = tss
  .withParams<{ withPriceCard: boolean }>()
  .create(({ theme }) => {
    const { breakpoints } = theme;

    return {
      root: {
        [breakpoints.down('breakPointDesktop')]: {
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        },

        [breakpoints.up('breakPointDesktop')]: {
          display: 'flex',
          alignItems: 'stretch',
          gap: theme.spacing(4),
          justifyContent: 'space-between',
        },
      },
      summary: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: theme.spacing(2),

        [breakpoints.up('breakPointDesktop')]: {
          marginTop: theme.spacing(13),
          maxWidth: '25rem',
        },
      },
      offers: {
        display: 'flex',
        flexDirection: 'column-reverse',
        gap: theme.spacing(2),

        [breakpoints.up('breakPointDesktop')]: {
          flexDirection: 'row',
          gap: 0,
          flex: 2,
          maxWidth: '50rem',
        },
      },
      offersWithPriceCard: {
        [breakpoints.up('breakPointDesktop')]: {
          flex: 2,
          maxWidth: '28rem',
        },
      },
      // offers: {
      //   display: 'flex',
      //   flexDirection: 'column-reverse',
      //   marginLeft: 0,
      //   alignItems: props.withPriceCard ? 'center' : 'baseline',
      //   '& .MuiBox-root': {
      //     width: '100%',
      //     '&:last-child .MuiCard-root': {
      //       marginBottom: '1rem',
      //     },
      //     [breakpoints.up('breakPointDesktop')]: {
      //       '&:last-child .MuiCard-root': {
      //         marginBottom: 0,
      //         paddingTop: '2rem',
      //         paddingBottom: '2rem',
      //       },
      //     },
      //   },
      //   [breakpoints.up('breakPointDesktop')]: {
      //     display: 'grid',
      //     gridTemplateColumns: 'auto auto',
      //     marginLeft: theme.spacing(commonSpacing),
      //     marginTop: 0,
      //     '& .MuiCard-root': {
      //       marginBottom: 0,
      //     },
      //   },
      // },
      // paragraph: {
      //   marginTop: '0.625rem',
      // },
      customOffer: {
        alignSelf: 'center',
        marginLeft: theme.spacing(2),
      },
      // biggerOffer: {
      //   '& .MuiSvgIcon-root': {
      //     fill: theme.app.colors.illustrationIcon,
      //   },
      //   [breakpoints.up('breakPointDesktop')]: {
      //     transform: 'translateX(-1rem)',
      //     '& > .MuiPaper-root': {
      //       paddingBottom: '5rem !important',
      //       paddingTop: '3rem !important',
      //     },
      //   },
      // },
    };
  });

interface OffersProps {
  offers: Offer[];
  withPriceCard?: boolean;
}

export const Offers = ({ offers, withPriceCard = false }: OffersProps) => {
  const { cx, classes } = useStyles({ withPriceCard });
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));

  const presentation = t('home.offersBox.presentation.blocks', {
    returnObjects: true,
    context: getFeatures().ouigo.enabled ? 'ouigo' : undefined,
  }) as string[];

  return (
    <Box className={classes.root}>
      {isDesktop && (
        <Box className={classes.summary}>
          <Typography component="h2" variant="h3">
            {t('home.offersBox.presentation.title')}
          </Typography>
          {presentation.map((text, key) => (
            <Typography key={key} component="p">
              {text}
            </Typography>
          ))}
        </Box>
      )}

      <Box
        className={cx([
          classes.offers,
          withPriceCard && classes.offersWithPriceCard,
        ])}
      >
        {offers.map(offer => (
          <OfferElement
            key={offer.title}
            offer={offer}
            withPriceCard={withPriceCard}
          />
        ))}
      </Box>

      {isDesktop && withPriceCard && (
        <Box className={classes.customOffer}>
          <PriceCard />
        </Box>
      )}
    </Box>
  );
};
