import { useEffect, useMemo, useState } from 'react';
import type { Theme } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { getQueryParam } from '@sticky/helpers';
import { t, Trans } from '@sticky/i18n';

import { AuthenticationLoginButton } from '../../../features/authentication';
import { Basket } from '../../../features/basket/class/basket-class';
import { PaymentMethodCodeResumptionLink } from '../../../features/basket/models/basket';
import {
  setBasketBillTo,
  setBasketId,
  setBasketMethodPaymentCB,
  setBasketMethodPaymentIBAN,
} from '../../../features/basket/store/basket';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Routes } from '../../../routes/routes';
import { ModalBox } from '../modal-box';

const useStyles = makeStyles()((theme: Theme) => ({
  subtitle: {
    marginTop: theme.spacing(1),
  },
}));

export const ResumptionModal = () => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(state => state.customer.isAuth);
  const [showModal, setShowModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  // Condition to display modal
  useEffect(() => {
    const resumptionLink = getQueryParam('repriseSouscription');
    if (!resumptionLink) return;

    const { basketId, paymentCode, state } = Basket.readResumptionLink();

    if (basketId && paymentCode) {
      dispatch(setBasketId(basketId));

      dispatch(
        paymentCode === PaymentMethodCodeResumptionLink.CB
          ? setBasketMethodPaymentCB()
          : setBasketMethodPaymentIBAN(),
      );

      if (
        paymentCode === PaymentMethodCodeResumptionLink.SEPA_AND_CB ||
        paymentCode === PaymentMethodCodeResumptionLink.SEPA
      ) {
        if (state?.companyName) {
          setRedirectUrl(Routes.getPathById('enrollment/iban-input'));
        } else {
          setRedirectUrl(Routes.getPathById('enrollment/mandate'));
        }
      } else {
        setRedirectUrl(Routes.getPathById('enrollment/payment'));
      }

      if (state) {
        dispatch(
          setBasketBillTo({ ...state, isBillToCompany: !!state.companyName }),
        );
      }

      setShowModal(true);
    }
  }, [dispatch]);

  // useMemo to avoid next useEffect called on every render
  const actionsButtons = useMemo(() => {
    const buttons = [
      <ActionButton
        variant="outlined"
        key="home"
        onClick={() => setShowModal(false)}
        href={Routes.getPathById('home')}
      >
        {t('home.resumption.back-home-button')}
      </ActionButton>,
    ];

    if (isAuth) {
      buttons.push(
        <ActionButton key="continue" href={redirectUrl}>
          <Typography variant="subtitle2" color="inherit">
            {t('home.resumption.continue-subscription-button')}
          </Typography>
        </ActionButton>,
      );
    } else {
      buttons.push(
        <AuthenticationLoginButton key="login" redirectUrl={redirectUrl}>
          {t('home.resumption.continue-subscription-button')}
        </AuthenticationLoginButton>,
      );
    }

    return buttons;
  }, [isAuth, redirectUrl]);

  return (
    <ModalBox
      open={showModal}
      title={t('home.resumption.title')}
      closeButton={false}
      actions={actionsButtons}
    >
      <>
        <Typography variant="subtitle2" className={classes.subtitle}>
          <Trans i18nKey="home.resumption.not-connected-text" />
        </Typography>
      </>
    </ModalBox>
  );
};
