import type { MouseEventHandler } from 'react';
import React, { useState } from 'react';
import { Field, useField } from 'react-final-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import type { Keys } from '@sticky/i18n';
import { t } from '@sticky/i18n';

import { getPasswordPoints } from '../../utils/password/password';

import type { IValidator } from './form-validators';

// Component Props
interface IInputPasswordProps {
  name: string;
  label: string | undefined;
  placeholder?: Keys;
  maxLength?: number;
  minLength?: number;
  helperText?: string;
  showPasswordElement?: React.ReactNode;
  showIndicatorAlways?: boolean;
  keepIndicatorOnBlur?: boolean;
  hideIndicator?: boolean;
  'aria-label'?: string;
  validate?: IValidator;
}

interface IInputPasswordState {
  label?: string;
  color?: string;
  minRequired: number;
  message?: string;
  valid?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => {
  const {
    app: { colors },
  } = theme;
  return {
    root: {
      width: '100%',
      '& input': {
        paddingLeft: '1.25rem',
        paddingRight: '1.25rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.warmGray1,
      },
      '& .MuiFormLabel-root': {
        background: 'transparent',

        '& .MuiInputLabel-asterisk': {
          color: colors.textContrasted,
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
    indicatorOpen: {
      '&.inputPassword.MuiTextField-root': {
        marginBottom: 0,
      },
    },
    strengthIndicatorLabel: {
      display: 'block',
      width: '100%',
      paddingLeft: '0.875rem',
      marginTop: '0.175rem',
      '& span:last-child': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    progressBar: {
      height: '0.75rem',
      width: '100%',
      display: 'inline-grid',
      gridTemplateColumns: 'auto auto auto',
      columnGap: theme.spacing(1),
      paddingLeft: '0.875rem',
      paddingRight: '0.875rem',
      marginBottom: '1rem',
    },

    strengthIndicator: {
      width: '100%',
      borderRadius: '0.375rem',
      border: 'none',
      background: colors.warmGray2,
      '&:first-of-type': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  };
});

export const FormInputPassword = (props: IInputPasswordProps): JSX.Element => {
  const { classes } = useStyles();

  const inputProps = {
    inputProps: {
      'aria-labelledby': props.name + '-label',
    },
  };

  const {
    maxLength = t('password.max-length'),
    minLength = t('password.min-length'),
    showIndicatorAlways = false,
    keepIndicatorOnBlur = false,
    hideIndicator = false,
    placeholder = 'password.placeholder',
    showPasswordElement = (
      ariaLabel: string,
      status: boolean,
      onClick: MouseEventHandler,
      onMouseDown: MouseEventHandler,
    ) => (
      <IconButton
        onClick={onClick}
        onMouseDown={onMouseDown}
        edge="end"
        aria-label={ariaLabel}
        size="large"
      >
        {status ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    ),
  } = props;

  const [helperText, setHelperText] = useState(props.helperText);
  const [showPassword, togglePassword] = useState(false);
  const [showIndicator, setShowIndicator] = useState(
    showIndicatorAlways && !hideIndicator,
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>(undefined);

  // Get config from translations file (password)
  const passwordStates: IInputPasswordState[] = t('password.states', {
    returnObjects: true,
  });

  // Listen to password field
  const field = useField(props.name, {
    subscription: { touched: true, value: true },
  });
  const { value } = field.input;

  // Show password on icon click
  const handleClickShowPassword = () => {
    togglePassword(!showPassword);
  };

  // Prevent event defaults
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  // Get the state by comparing bonus points
  const getState = (_value: string): IInputPasswordState => {
    const points = getPasswordPoints(_value);
    let _state: IInputPasswordState = {
      minRequired: 0,
      label: t('password.security-level-init'),
      message: t('password.empty-password'),
    };
    if (_value && _value.length > 0) {
      for (const ruleState of passwordStates) {
        if (points < ruleState?.minRequired) break;
        _state = ruleState;
      }
    }
    return _state;
  };

  // Test rules conditions and increment bonus points
  const bonusPoints = getPasswordPoints(value);

  // Select the rule to apply against bonus points
  let state = getState(value);

  // The indicator
  const passwordIndicator = () => (
    <>
      <div className={classes.strengthIndicatorLabel}>
        <Typography component="span">{t('password.security-level')}</Typography>
        <Typography component="span" style={{ color: state?.color }}>
          {state?.label}
        </Typography>
      </div>
      <div className={classes.progressBar}>
        {passwordStates
          .filter(current => !!current.color)
          .map((current, key) => {
            const color =
              bonusPoints >= current.minRequired ? state?.color : '';
            return (
              <Tooltip
                disableFocusListener
                title={
                  current.message ? `${current.label}: ${current.message}` : ''
                }
                key={key}
              >
                <button
                  className={classes.strengthIndicator}
                  style={{ backgroundColor: color }}
                />
              </Tooltip>
            );
          })}
      </div>
    </>
  );

  // Handle input blur and focus
  const handleFocus = () => {
    setShowIndicator(true);
  };
  const handleBlur = () => {
    if (!keepIndicatorOnBlur) {
      setShowIndicator(showIndicatorAlways);
    }
  };

  // Check if the field is valid
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validate = (_value: string, _allValues?: any, _meta?: any) => {
    let _error = undefined;
    if (field.meta.touched || _meta?.touched) {
      state = getState(_value);
      const message = (state && !!state.valid ? '' : state.message) as Keys;
      setHelperText(t(message || ''));
      setError(true);
      return message;
    } else {
      if (props.validate) {
        _error = props.validate(_value);
      }
      if (_error) {
        setError({ [props.name]: _error });
      }
      return _error;
    }
  };

  // Limit password length
  if (maxLength && value && value.length > maxLength) {
    field.input.onChange(value.substring(0, maxLength));
  }

  // Display component
  return (
    <Field name={props.name} validate={validate}>
      {({ input, meta }) => {
        const { type, ...attrs } = input;
        const hasError =
          (meta.touched || meta.submitError) && !!error && meta.error;
        const ariaFullLabel = props['aria-label']
          ? props['aria-label']
          : `${props.label} ${placeholder ? t(placeholder) : ''}`;
        const ariaLabel = showPassword
          ? t('password.hide-password')
          : t('password.show-password');
        return (
          <>
            <TextField
              id={props.name}
              {...attrs}
              label={props.label}
              placeholder={placeholder && t(placeholder)}
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              error={!!helperText || !!hasError}
              helperText={helperText || (hasError && meta.error)}
              className={`inputPassword  ${classes.root} ${
                showIndicator && classes.indicatorOpen
              }`}
              aria-label={ariaFullLabel}
              onFocus={() => !hideIndicator && handleFocus()}
              onBlur={e => {
                !hideIndicator && handleBlur();
                input.onBlur(e);
                validate(input.value, {}, { ...input.meta, touched: true });
              }}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {typeof showPasswordElement === 'function' &&
                      showPasswordElement(
                        ariaLabel,
                        showPassword,
                        handleClickShowPassword,
                        handleMouseDownPassword,
                      )}
                  </InputAdornment>
                ),
                'aria-labelledby': props.name + '-label',
              }}
              {...inputProps}
              InputLabelProps={{ shrink: true }}
            />
            {minLength &&
              value?.length >= minLength &&
              showIndicator &&
              passwordIndicator()}
          </>
        );
      }}
    </Field>
  );
};
