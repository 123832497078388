import { compareAsc, isAfter, max, parseISO, subHours } from 'date-fns';

import type {
  Travel,
  TravelConfirmed,
  TravelMandatoryInfo,
  TravelStatus,
} from '..';

const TRAVEL_ID_SEPARATOR = '/---/';

export const sortTravelsByDepartureDate = (travels: Travel[]): Travel[] =>
  travels.sort((travelLeft, travelRight) =>
    compareAsc(
      parseISO(travelLeft.departureDateTime),
      parseISO(travelRight.departureDateTime),
    ),
  );

export const highlightTravels = (
  travels: Travel[],
  unhighlightDate?: string,
): Travel[] => {
  const fromDate = subHours(new Date(), 1);
  const dateToCompare = unhighlightDate
    ? max([parseISO(unhighlightDate), fromDate])
    : fromDate;

  return travels.map(travel => ({
    ...travel,
    highlighted: isAfter(parseISO(travel.reservationDate), dateToCompare),
  }));
};

export const mapStatusEnums = (travels: Travel[]): Travel[] =>
  travels.map(travel => {
    travel.travelStatus = travel.travelStatus?.toUpperCase() as TravelStatus;
    travel.travelConfirmed =
      travel.travelConfirmed?.toUpperCase() as TravelConfirmed;
    return travel;
  });

export const getTravelId = ({
  marketingCarrierRef,
  orderId,
  trainNumber,
  departureDateTime,
}: TravelMandatoryInfo): string =>
  [marketingCarrierRef, orderId, trainNumber, departureDateTime].join(
    TRAVEL_ID_SEPARATOR,
  );

export const explodeTravelId = (id: string): TravelMandatoryInfo => {
  const [marketingCarrierRef, orderId, trainNumber, departureDateTime] =
    id.split(TRAVEL_ID_SEPARATOR);
  return {
    marketingCarrierRef,
    orderId,
    trainNumber,
    departureDateTime,
  };
};
