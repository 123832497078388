import { useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { KeyboardArrowDown } from '@mui/icons-material';
import { FormHelperText, Select } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

import type { Hour, Minute, Time } from '../../features/reservation';
import { HOURS, MINUTES } from '../../features/reservation';

const useStyles = makeStyles()(
  ({ app: { colors }, typography: { pxToRem }, breakpoints }: Theme) => ({
    container: {
      alignItems: 'baseline',
      display: 'flex',
    },
    error: {
      color: colors.errorDark,
      fontSize: pxToRem(14),
      marginTop: pxToRem(6),
      [breakpoints.up('breakPointDesktop')]: {
        fontSize: pxToRem(16),
      },
    },
    select: {
      background: colors.white,
      fontSize: pxToRem(16),
      maxHeight: pxToRem(45),
      '& fieldset': {
        borderColor: colors.warmGray2,
      },
      '&:not(:last-child)': {
        marginRight: pxToRem(10),
      },
    },
  }),
);

interface TimeInputProps {
  error?: string;
  initialValue?: Time;
  onChange?: (value: Time) => void;
  prefix?: string;
}

export const TimeInput = ({
  error,
  initialValue = { hours: 0, minutes: 0 },
  onChange,
  prefix,
}: TimeInputProps): JSX.Element => {
  const { classes } = useStyles();

  const [minutesFocused, setMinutesFocused] = useState(false);

  const _prefix = prefix ? `${prefix}-` : '';

  // we use parse config option here because with native Select the value of the input is parse to string
  const {
    input: {
      onChange: onHoursChange,
      value: hours = initialValue.hours,
      ...hoursInput
    },
  } = useField<Hour>(`${prefix ? `${prefix}.` : ''}time.hours`, {
    parse: value => Number(value) as Hour,
  });
  const {
    input: {
      onChange: onMinutesChange,
      value: minutes = initialValue.minutes,
      ...minutesInput
    },
  } = useField<Minute>(`${prefix ? `${prefix}.` : ''}time.minutes`, {
    parse: value => Number(value) as Minute,
  });

  const timeAriaLabel = useMemo(() => {
    let label = t('reservation.reservationDayDetail.aria.hours', {
      hours,
      count: hours,
    });
    if (minutes) label = `${label} ${minutes}`;
    if (prefix)
      label = `${label}, ${t(
        `reservation.reservationDayDetail.aria.${prefix}Travel`,
      )}`;
    label = `${label}, ${t(
      `reservation.reservationDayDetail.aria.${
        minutesFocused ? 'selectMinutes' : 'selectHours'
      }`,
    )}`;
    return label;
  }, [hours, minutes, minutesFocused, prefix]);

  return (
    <div>
      <div className={classes.container}>
        <span id={`${_prefix}time-aria-label`} className="onlyForScreenReader">
          {timeAriaLabel}
        </span>
        <Select
          {...hoursInput}
          className={classes.select}
          IconComponent={KeyboardArrowDown}
          inputProps={{
            id: `${_prefix}select-departure-hour`,
            'data-test-id': `${_prefix}select-departure-hour`,
            'aria-invalid': !!error,
            'aria-required': true,
            'aria-labelledby': `${_prefix}time-aria-label`,
          }}
          native
          onChange={event => {
            onChange?.({
              hours: event.target.value as Hour,
              minutes,
            });
            onHoursChange(event);
          }}
          onFocus={() => setMinutesFocused(false)}
          required
          value={hours}
          variant="outlined"
        >
          {HOURS.map(hour => (
            <option value={hour} key={hour}>
              {`${`${hour}`.padStart(2, '0')}h`}
            </option>
          ))}
        </Select>
        <Select
          {...minutesInput}
          className={classes.select}
          IconComponent={KeyboardArrowDown}
          inputProps={{
            id: `${_prefix}select-departure-minute`,
            'data-test-id': `${_prefix}select-departure-minute`,
            'aria-invalid': !!error,
            'aria-required': true,
            'aria-labelledby': `${_prefix}time-aria-label`,
          }}
          native
          onChange={event => {
            onChange?.({
              hours,
              minutes: event.target.value as Minute,
            });
            onMinutesChange(event);
          }}
          onFocus={() => setMinutesFocused(true)}
          required
          value={minutes}
          variant="outlined"
        >
          {MINUTES.map(minute => (
            <option value={minute} key={minute}>
              {`${minute}`.padStart(2, '0')}
            </option>
          ))}
        </Select>
      </div>
      {error ? (
        <FormHelperText
          className={classes.error}
          error
          id="time-preferences-error"
        >
          {error}
        </FormHelperText>
      ) : null}
    </div>
  );
};

/**
 * We need to create a parent component because if we put `useField` into `TimeInput` an error occured,
 * I guess it's a react-final-form error
 */
export const FormTimeInput = ({ error, prefix, ...props }: TimeInputProps) => {
  const { meta: timeFieldMeta } = useField(`${prefix ? `${prefix}.` : ''}time`);
  const {
    meta: { touched: isHoursSelectTouched },
  } = useField(`${prefix ? `${prefix}.` : ''}time.hours`);
  const {
    meta: { touched: isMinutesSelectTouched },
  } = useField(`${prefix ? `${prefix}.` : ''}time.minutes`);

  const errorMessage =
    error ??
    (isHoursSelectTouched || isMinutesSelectTouched
      ? timeFieldMeta.error
      : undefined);

  return <TimeInput error={errorMessage} prefix={prefix} {...props} />;
};
