import type { Theme } from '@mui/material';
import { SvgIcon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyle = makeStyles()(({ typography: { pxToRem } }: Theme) => ({
  icon: {
    width: pxToRem(18),
    height: pxToRem(18),
    margin: pxToRem(3),
  },
}));

interface CheckboxIconProps {
  className?: string;
}

/*
 * Override of default MUI checkbox icon to allow setting a background color without ugly overflow.
 */
export const CheckboxIcon = ({ className }: CheckboxIconProps): JSX.Element => {
  const { classes } = useStyle();
  return (
    <SvgIcon
      className={classes.icon + (className ? ` ${className}` : '')}
      viewBox="3 3 18 18"
    >
      <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    </SvgIcon>
  );
};
