import { StickyGatewayClient } from '@sticky/gateway-client';

export type RefreshTokenInput = {
  refreshToken: string;
  redirectUri: string;
};

export type RefreshTokenOutput = {
  idToken: string;
  refreshToken: string;
  expiresIn: number;
};

/**
 * Ask for a token on login.
 */
export const refreshToken = (input: RefreshTokenInput) =>
  StickyGatewayClient.anonymousClient().post<RefreshTokenOutput>(
    `/public/auth/sfc/token`,
    {
      type: 'REFRESH_TOKEN',
      refreshToken: input.refreshToken,
      redirectUri: input.redirectUri.replace('www.', ''),
    },
  );
