import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { scrollToId } from '../../utils/scroll';

export const ScrollToTop = (): JSX.Element => {
  const location = useLocation();

  // Get the location from the state or from hash
  const locationState = location.state as { scrollToElement: string };
  const locationHash = location.hash.replace('#', '');

  // Define if there is an element to scroll on
  const scrollToElement = locationState?.scrollToElement ?? locationHash;

  // Update states or remove hash from url
  useEffect(() => {
    if (scrollToElement) {
      scrollToId(scrollToElement);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newState = { ...(location.state as any) };
      delete newState.scrollToElement;
      location.state = newState;

      return;
    }

    window.scrollTo(0, 0);
  }, [location, scrollToElement, locationHash]);

  return <></>;
};
