/**
 * Returns the date formatted as "dd/MM/yyyy" or "" if the date is invalid.
 * @param date the date to format
 * @return the date formatted
 */
export const formatToDDMMYYYY = (date?: Date | string | null) => {
  if (!date) {
    return '';
  }

  try {
    return new Intl.DateTimeFormat('fr-FR').format(
      typeof date === 'string' ? new Date(date) : date,
    );
  } catch (error) {
    return '';
  }
};
