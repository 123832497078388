import { metaFeatures } from './features';
import type { FeatureFlippingConfig, FeatureFlippingMeta } from './types';

const isFeatureFlippingMeta = (object: unknown) =>
  typeof object === 'object' &&
  (object as object).hasOwnProperty('defaultValue');

const getDefaultValue = <T extends FeatureFlippingMeta>(value: T) =>
  value.defaultValue;

/**
 * Generate default config based on feature files
 */
function toDefaultConfig<T = typeof metaFeatures>(
  object: T,
): FeatureFlippingConfig<T> {
  const keys = Object.keys(object as object) as (keyof typeof object)[];

  return keys.reduce((acc, key) => {
    const value = object[key];

    if (isFeatureFlippingMeta(value)) {
      return { ...acc, [key]: getDefaultValue(value as FeatureFlippingMeta) };
    }

    return { ...acc, [key]: toDefaultConfig(value) };
  }, {} as FeatureFlippingConfig<T>);
}

export const defaultConfig = toDefaultConfig(metaFeatures);
