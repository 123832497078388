import React from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import type { Theme } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    app: { colors },
  } = theme;
  return {
    root: {
      fontWeight: 'normal',
      color: theme.palette.text.primary,
      textDecoration: 'none',
      '&:visited': {
        color: theme.palette.text.primary,
      },
      '&:hover, &:focus, &:active': {
        color: theme.palette.primary.main,
      },
    },
    disabled: {
      cursor: 'default',
      color: theme.palette.text.secondary,
      pointerEvents: 'none',
      '&:visited, &:hover, &:active, &:focus': {
        color: theme.palette.text.secondary,
      },
    },
    active: {
      fontWeight: 'bold',
      '&:visited, &:hover, &:focus, &:active': {
        color: colors.textContrasted,
      },
      cursor: 'unset',
    },
  };
});

export const RouteLink = (props: RouteLinkProps): JSX.Element => {
  const { classes } = useStyles();
  const to = props.to;
  const { active, ...attributes } = props;

  const classActive = active === true ? classes.active : '';
  const className = [
    classes.root,
    props.disabled ? classes.disabled : '',
    props.disabled ? 'disabled' : '',
    classActive,
    'link',
  ].join(' ');
  const href = (!props.disabled ? to : '#') as string;

  return (
    <MuiLink
      component={RouterLink}
      className={className}
      {...attributes}
      to={href}
      title={props.title}
    />
  );
};

export interface RouteLinkProps extends LinkProps {
  disabled?: boolean;
  active?: boolean;
  title?: string;
}
