/**
 * Cette fonction retourne une copie d'un tableau avec ses éléments triés de
 * façon aléatoire.
 */
export const shuffle = <T>(array?: T[]): T[] => {
  const newArray = [...(array ?? [])];
  let currentIndex = newArray.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [newArray[currentIndex], newArray[randomIndex]] = [
      newArray[randomIndex],
      newArray[currentIndex],
    ];
  }

  return newArray;
};
