export const setHours = (date: Date, hours: number): Date =>
  new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );

export const setMinutes = (date: Date, minutes: number): Date =>
  new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    minutes,
    date.getSeconds(),
    date.getMilliseconds(),
  );
