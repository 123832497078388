import { addDays, addMonths, startOfMonth } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

import { getEnv } from '@sticky/config';

import { getStartOfDayDate } from './get';

/**
 * Build a list of dates with 1 month formated as 'yyyy-MM-dd' in the defined timeZone.
 *
 * @param dateStart - The first date.
 * @param months - Count of months.
 * @param tz - The desired timezone.
 * @returns {string[]} A list of dates 'yyyy-MM-dd'.
 */
export const getStartDateValidityListTz = (
  dateStart: Date | undefined = new Date(),
  months: number | undefined = 3,
  tz: string | undefined = getEnv('VITE_TZ'),
): string[] => {
  const dateRef = new Date(dateStart);
  const dateList: string[] = [];
  for (let idx = 0; idx < months; idx++) {
    let dateCurrent = utcToZonedTime(dateRef, tz);
    if (idx > 0) {
      dateCurrent = startOfMonth(addMonths(dateCurrent, idx));
    }
    dateList.push(format(dateCurrent, 'yyyy-MM-dd', { timeZone: tz }));
  }

  return dateList;
};

/**
 * Returns all the days in the given interval, including start and end.
 *
 * @param {Date} start the first day of the interval
 * @param {Date} end the last day of the interval
 * @return {Date[]} all the days of the interval
 */
export function eachDayOfInterval(
  start: Date | null,
  end: Date | null,
): Date[] {
  if (!start || !end) return [];
  if (start > end) throw new Error('end date must be after start date');
  const result = [];
  const endAtMidnight = getStartOfDayDate(end);
  let current = getStartOfDayDate(start);
  while (current <= endAtMidnight) {
    result.push(current);
    current = addDays(current, 1);
  }
  return result;
}
