import { useCallback, useEffect, useRef } from 'react';

/**
 * Cette hook permet de créer une fonction de rappel qui est retardée dans son
 * exécution jusqu'à ce qu'un certain délai se soit écoulé depuis la dernière
 * fois qu'elle a été appelée.
 */
export const useDebouncedCallback = <T>(
  callback?: (...args: T[]) => void,
  delay?: number,
): ((...args: [T]) => void) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(
    () => () => {
      if (timeout.current) clearTimeout(timeout.current);
    },
    [],
  );

  return useCallback(
    (...args) => {
      if (!callback || delay === undefined) return;

      const later = () => {
        clearTimeout(timeout.current);
        callback(...args);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, delay);
    },
    [callback, delay],
  );
};
