import { Button } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    borderColor: theme.palette.text.primary,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '0.313rem',
    padding: 0,
    overflowY: 'hidden',
    display: 'flex',
    width: '100%',
    color: theme.palette.text.primary,
  },
  items: {
    padding: '0.7rem 0.5rem',
    textAlign: 'center',
    flex: 0.5,
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('breakPointDesktop')]: {
      flex: 'auto',
    },
  },
  description: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium as number,
  },
}));

export const PhoneDisplay = (props: PhoneDisplayProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Button href={`tel:${props.phoneNumber}`} className={classes.root}>
      <span className={classes.items}>{props.phoneNumber}</span>
      <span className={classes.items + ' ' + classes.description}>
        {props.description}
      </span>
    </Button>
  );
};

export interface PhoneDisplayProps {
  phoneNumber: string;
  description: string;
}
