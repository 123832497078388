import { getEnv } from '@sticky/config';

enum ProductType {
  MFA = 'MFA',
  TMJ = 'TMJ',
  TMS = 'TMS',
}

const CLIENT_APP = {
  [ProductType.MFA]: 'MAX_ACTIF',
  [ProductType.TMJ]: 'MAX_JEUNE',
  [ProductType.TMS]: 'MAX_SENIOR',
};

export const getClientApp = (): string =>
  CLIENT_APP[getEnv('VITE_PRODUCT_TYPE') as ProductType] ?? '';
