import { Skeleton, Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

import { getLogoutUrl, useAuthStore, useSignUpPopup } from '@sticky/auth';
import {
  ActionButton,
  Panel,
  PanelActionButtons,
  PanelContent,
  PanelHeader,
} from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t, Trans } from '@sticky/i18n';

import { useAppSelector } from '../../../hooks';
import { Routes } from '../../../routes/routes';
import { AuthenticationLoginButton } from '..';

const useStyles = tss.create(({ theme }) => ({
  caption: {
    lineHeight: '1.41',

    [theme.breakpoints.up('breakPointDesktop')]: {
      paddingBottom: theme.spacing(0.5),
      fontSize: '0.9rem',
    },
  },
  card: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('breakPointDesktop')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },

    [theme.breakpoints.up('breakPointDesktop')]: {
      marginBottom: 0,
    },
  },
}));

export const AuthenticationWidget = () => {
  const { classes } = useStyles();
  const { customer } = useAppSelector(state => state.customer);
  const authStore = useAuthStore();
  const { signUpPopup } = useSignUpPopup();

  return (
    <Panel className={classes.card}>
      <PanelHeader variant="dark">
        <Typography variant="h3" textAlign="center" component="h3">
          {t('auth.title')}
        </Typography>
      </PanelHeader>

      <PanelContent>
        {authStore.status === 'authenticated' ? (
          <>
            <Typography component="p" align="center">
              {t('auth.connected-as')}
            </Typography>

            <Typography
              component="p"
              align="center"
              variant="subtitle2"
              data-dd-privacy="mask"
            >
              {[customer?.firstName, customer?.lastName]
                .filter(Boolean)
                .join(' ') || (
                <Skeleton sx={{ maxWidth: '8rem', margin: '0 auto' }} />
              )}
            </Typography>
          </>
        ) : (
          <Typography component="p" className={classes.caption}>
            <Trans
              i18nKey="widgetMyId.caption"
              values={{ authTitle: t('auth.title') }}
            />
          </Typography>
        )}
      </PanelContent>

      <PanelActionButtons>
        {authStore.status === 'authenticated' ? (
          <ActionButton variant="outlined" href={getLogoutUrl()} target="_self">
            {t('auth.logout.button')}
          </ActionButton>
        ) : (
          <>
            {getFeatures().auth.midSignUp ? (
              <ActionButton
                variant="outlined"
                onClick={() => {
                  signUpPopup();
                }}
              >
                {t('auth.account.create')}
              </ActionButton>
            ) : (
              <ActionButton
                variant="outlined"
                href={Routes.getPathById('customer/signup')}
              >
                {t('auth.account.create')}
              </ActionButton>
            )}

            <AuthenticationLoginButton>
              {t('auth.login.button')}
            </AuthenticationLoginButton>
          </>
        )}
      </PanelActionButtons>
    </Panel>
  );
};
