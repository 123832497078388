import { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { tss } from 'tss-react/mui';

import type { Field } from './data-table';
import { Label, Value } from './data-table';

const useStyles = tss.create(({ theme }) => ({
  container: {
    display: 'grid',
    margin: '1rem -0.5rem',
    gridTemplateColumns: '100%',
    gridTemplateAreas: '"col1"',
    [theme.breakpoints.up('breakPointDesktop')]: {
      gridTemplateColumns: '50%',
      gridTemplateAreas: `"col1 col2"`,
      margin: '1rem -1rem',
    },
  },
  col1: {
    display: 'inline-table',
    [theme.breakpoints.up('breakPointDesktop')]: {
      display: 'grid',
    },
  },
  col2: {},
  expand: {
    textAlign: 'center',
    cursor: 'pointer',
    '& u': {
      color: theme.app.colors.primary,
    },
  },
  cell: {
    [theme.breakpoints.up('breakPointDesktop')]: {
      '&.MuiTableCell-root': {
        borderBottomStyle: 'none',
      },
    },
  },
}));

export const DataTableOrderSummary = (props: {
  fields: Field[];
  isMultiColumns?: boolean;
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  // Get desktop screen for responsive
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));

  const fields = useMemo(() => [...props.fields], [props.fields]);
  const nbFields = fields.length;
  const [fieldsCol1, setFieldsCol1] = useState<Field[]>([]);
  const [fieldsCol2, setFieldsCol2] = useState<Field[]>([]);
  const [ready, setReady] = useState(false);
  const [showMoreContent, setShowMoreContent] = useState(true);

  useEffect(() => {
    if (isDesktop && props.isMultiColumns) {
      setFieldsCol1(fields.filter((f, index) => index < nbFields / 2));
      setFieldsCol2(fields.filter((f, index) => index >= nbFields / 2));
    }
    setReady(true);
  }, [isDesktop, fields, nbFields, props.isMultiColumns]);

  const Item = ([lab, ...values]: Field, key: number): JSX.Element => (
    <TableRow key={key}>
      <TableCell className={classes.cell}>
        <Label value={lab} />
        {values.map((val, index: number) => (
          <Value ValueOrComponent={val} isReady={ready} key={index} />
        ))}
      </TableCell>
    </TableRow>
  );

  return (
    <Table className={classes.container}>
      {isDesktop ? (
        props.isMultiColumns ? (
          <>
            <TableBody className={classes.col1}>
              {fieldsCol1.filter(([, value]) => !!value).map(Item)}
            </TableBody>
            <TableBody className={classes.col2}>
              {fieldsCol2.filter(([, value]) => !!value).map(Item)}
            </TableBody>
          </>
        ) : (
          <TableBody className={classes.col2}>
            {fields.filter(([, value]) => !!value).map(Item)}
          </TableBody>
        )
      ) : (
        <>
          <TableBody className={classes.col1}>
            {ready &&
              fields
                .filter(([, value], index) => !!value && index < 3)
                .map(Item)}
            <TableRow onClick={() => setShowMoreContent(!showMoreContent)}>
              <TableCell className={classes.expand}>
                <Value
                  ValueOrComponent={
                    !showMoreContent ? (
                      <b>
                        <u>Voir moins</u>
                      </b>
                    ) : (
                      <b>
                        <u>Voir plus</u>
                      </b>
                    )
                  }
                  isReady={ready}
                />
              </TableCell>
            </TableRow>
            {!showMoreContent &&
              fields
                .filter(([, value], index) => !!value && index >= 3)
                .map(Item)}
          </TableBody>
        </>
      )}
    </Table>
  );
};
