import type { TFunction as i18next_TFunction, TOptions } from 'i18next';
import { t as i18next_t } from 'i18next';
import type { z, ZodSchema } from 'zod';

import type { Keys } from './types';

// TODO: Utiliser tant que toutes les erreurs n'ont pas été corrigées.
type TemporaryKey = Keys | string;

export type TFunction = {
  (key: TemporaryKey): string;
  <Schema extends ZodSchema>(
    key: TemporaryKey,
    options: TOptions & { schema: Schema },
  ): z.infer<Schema>;
  // TODO: Supprimer la surcharge ci dessous lorsque l'on aura remplacé tous les
  // returnObjects par la version avec les schemas zod.
  (
    key: TemporaryKey,
    options: Omit<TOptions, 'returnObjects'> & { returnObjects: true },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any;
  <Schema extends ZodSchema>(
    key: TemporaryKey,
    options: TOptions & { schema?: Schema },
  ): ReturnType<i18next_TFunction>;
};

/**
 * Typage de la fonction t de i18next.
 */
export const t: TFunction = <Schema extends ZodSchema>(
  key: TemporaryKey,
  options?: TOptions & { schema?: Schema },
) => {
  if (!options) {
    return i18next_t(key);
  }

  if (options.schema) {
    const trad = i18next_t(key, { returnObjects: true, ...options });
    return options.schema.parse(trad);
  }

  return i18next_t(key, options);
};
