import type { Theme } from '@mui/material';
import { Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Trans } from '@sticky/i18n';

import type { Travel, TravelDetailsCache } from '../..';

import { usePrintTravel } from './hooks/usePrintTravel';

const useStyles = makeStyles()(
  ({ breakpoints, app: { colors }, typography: { pxToRem } }: Theme) => ({
    barcode: {
      width: '148px',
      gridArea: 'barcode',
      marginBottom: pxToRem(20),
      [breakpoints.up('breakPointDesktop')]: {
        marginBottom: 0,
        textAlign: 'right',
      },
    },
    barcodeError: {
      color: colors.errorDark,
    },
  }),
);

interface BarCodeProps {
  travel: Travel;
  details: TravelDetailsCache | undefined;
}

const BarCode = ({ travel, details }: BarCodeProps): JSX.Element => {
  const { classes } = useStyles();

  const { barcodeImg, error } = usePrintTravel(
    travel,
    details?.travelDetails,
    details?.expired,
  );

  return (
    <div className={classes.barcode}>
      {barcodeImg ? (
        <img src={barcodeImg} alt="" width="150px" height="150px" aria-hidden />
      ) : error || details?.detailsState.error ? (
        <Typography
          component="p"
          variant="body1"
          className={classes.barcodeError}
        >
          <Trans i18nKey="travels.detailsTravel.error.barcode" />
        </Typography>
      ) : (
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={148}
          width={148}
        />
      )}
    </div>
  );
};

export default BarCode;
