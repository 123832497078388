import type { ElementType } from 'react';
import type { SkeletonProps, TypographyProps } from '@mui/material';
import { Skeleton, Typography } from '@mui/material';

type TypoWithSkeletonProps = TypographyProps & {
  comp?: ElementType;
  skelProps: SkeletonProps;
  children: TypographyProps['children'];
  className?: string;
  isReady: boolean;
  color?: TypographyProps['color'];
  isBold?: boolean;
  variant?: TypographyProps['variant'];
};

export const TypoWithSkeleton = ({
  isReady,
  isBold,
  children,
  skelProps,
  ...rest
}: TypoWithSkeletonProps) => (
  <Typography {...rest}>
    {isReady ? (
      isBold ? (
        <b>{children}</b>
      ) : (
        children
      )
    ) : (
      <Skeleton height="100%" animation="wave" {...skelProps} />
    )}
  </Typography>
);
