import type { JSXElementConstructor } from 'react';
import React, { lazy, Suspense } from 'react';
import { Skeleton } from '@mui/material';
import type { DateCalendarProps } from '@mui/x-date-pickers';

import {
  validateMobilePhoneNumber,
  validatePhoneNumber,
} from '../../../utils/phone/phone';
import type { FormInputValues, IFormInputField } from '..';
import FormInputCardNumber from '../card-number';
import { FormInputCheckbox } from '../checkbox';
import { FormInputDatepicker } from '../datepicker/form-input-datepicker';
import type { IValidator } from '../form-validators';
import {
  composeValidators,
  validateAddress,
  validateAddressNote,
  validateBirthDate,
  validateCardNumberMigration,
  validateCity,
  validateCompanyName,
  validateCountry,
  validateDate,
  validateEmail,
  validateFirstname,
  validateIban,
  validateLastname,
  validateRequired,
  validateSiren,
  validateZipCode,
} from '../form-validators';
import FormInputIban from '../iban';
import { FormInputPassword } from '../password';
import { FormInputRadio } from '../radio';
import { FormInputRadioButton } from '../radio-button';
import { FormInputSelect } from '../select';
import FormInputSiren from '../siren-number';
import { FormInputText } from '../text';

// Lazy load
const FormInputPhoneNumber = lazy(() => import('../phonenumber'));

// Skeleton
const inputSkeleton = () => <Skeleton animation="wave" height="50px" />;

// Build form field
export const buildFormField = (
  formInputField: Partial<IFormInputField>,
  values?: FormInputValues,
  opts?: { compact?: boolean; readOnly?: boolean },
): React.ReactNode => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    cardId,
    inputtype,
    inputTypeAlias,
    validate,
    InputProps = {},
    apiProperty,
    ...props
  } = formInputField;

  // Get the field value
  const fieldValue: string | boolean | undefined =
    formInputField.name && values ? values[formInputField.name] : undefined;

  // Noop validator
  const noopValidator = () => undefined;

  // Prepare input
  let formInput: React.ReactNode;

  // Remove label for inputs if in desktop
  const { ...attrs } = props;
  if (!opts?.compact) {
    delete attrs.label;
  }

  // Init the component to use
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let Component: JSXElementConstructor<any> = React.Fragment;

  // Init specific validators to add to the field
  let ComponentExtraValidators: IValidator[] = [noopValidator];

  // Select the form input
  switch (inputtype) {
    case 'birthdate':
      Component = FormInputDatepicker;
      ComponentExtraValidators = [validateDate, validateBirthDate];
      (InputProps as DateCalendarProps<never>).views = ['year', 'day'];
      (InputProps as DateCalendarProps<never>).openTo = 'year';
      break;

    case 'birthdateNoAgeRestriction':
      Component = FormInputDatepicker;
      ComponentExtraValidators = [validateDate];
      break;

    case 'cardNumber':
      Component = FormInputCardNumber;
      ComponentExtraValidators = [validateCardNumberMigration];
      break;

    case 'checkbox':
      Component = FormInputCheckbox;
      break;

    case 'city':
      Component = FormInputText;
      ComponentExtraValidators = [validateCity];
      break;

    case 'companyName':
      Component = FormInputText;
      ComponentExtraValidators = [validateCompanyName];
      break;

    case 'country':
      Component = FormInputText;
      ComponentExtraValidators = [validateCountry];
      break;

    case 'date':
      Component = FormInputDatepicker;
      ComponentExtraValidators = [validateDate];
      break;

    case 'email':
    case 'emailConfirmation':
      Component = FormInputText;
      ComponentExtraValidators = [validateEmail];
      break;

    case 'firstname':
      Component = FormInputText;
      ComponentExtraValidators = [validateFirstname];
      break;

    case 'iban':
      Component = FormInputIban;
      ComponentExtraValidators = [validateIban];
      break;

    case 'lastname':
      Component = FormInputText;
      ComponentExtraValidators = [validateLastname];
      break;

    case 'mobilePhoneNumber':
      Component = FormInputPhoneNumber;
      ComponentExtraValidators = [validateMobilePhoneNumber];
      break;

    case 'password':
      Component = FormInputPassword;
      break;

    case 'passwordConfirmation':
      Component = FormInputPassword;
      break;

    case 'phoneNumber':
      Component = FormInputPhoneNumber;
      ComponentExtraValidators = [validatePhoneNumber];
      break;

    case 'radio':
      Component = FormInputRadio;
      break;

    case 'radioButton':
      Component = FormInputRadioButton;
      break;

    case 'select':
      Component = FormInputSelect;
      break;

    case 'streetAddress':
      Component = FormInputText;
      ComponentExtraValidators = [validateAddress];
      break;

    case 'siren':
      Component = FormInputSiren;
      ComponentExtraValidators = [validateSiren];
      break;

    case 'streetAddressComplement':
      Component = FormInputText;
      ComponentExtraValidators = [validateAddressNote];
      break;

    case 'text':
      Component = FormInputText;
      break;

    case 'zipCode':
      Component = FormInputText;
      ComponentExtraValidators = [validateZipCode];
      break;
  }

  // Build the component with validation rules
  if (Component !== React.Fragment) {
    // Combine validators and add required if necessary
    const __validators: IValidator[] = [
      props.required ? validateRequired(props.label) : noopValidator,
    ];
    if (typeof formInputField.validate === 'object')
      __validators.push.apply(__validators, [...formInputField.validate]);
    else if (typeof formInputField.validate === 'function')
      __validators.push(formInputField.validate);

    // Set a flag if the field needs to be validated (required or with value if not required)
    const isCandidateToValidation =
      (fieldValue || props.required) && !props.readonly;
    if (!isCandidateToValidation) {
      ComponentExtraValidators = [noopValidator];
    }

    // Merge all declared if exists
    const validators = composeValidators(formInputField.required, [
      ...__validators,
      ...ComponentExtraValidators,
    ]);

    // Build formInput
    formInput = (
      <Component
        {...attrs}
        {...InputProps}
        validate={validators}
        readOnly={opts?.readOnly}
        inputtype={inputtype}
      />
    );

    // Returns the input into a suspense component
    return <Suspense fallback={inputSkeleton()}>{formInput}</Suspense>;
  }

  // Component is not mapped, returns default
  return props.name;
};
