import { addYears, max, min, parseISO } from 'date-fns';
import { createSelector } from 'reselect';

import type { RootState } from '../../../store';
import {
  getCardsWithAllowedProductType,
  getCustomerCard,
} from '../../subscription';
import { subscriptionSelectors } from '../../subscription/selectors';
import type { ICard } from '../models/customer';
import {
  CardStatus,
  CardType,
  NsdStatus,
  PictureStatus,
} from '../models/customer';

// Selectors

const getCustomerState = (state: RootState) => state.customer;

const getCustomerFirstName = createSelector(
  [getCustomerState],
  state => state.customer?.firstName,
);
const getCustomerLastName = createSelector(
  [getCustomerState],
  state => state.customer?.lastName,
);

const getCustomerCards = createSelector(
  [getCustomerState],
  state => state.customer?.cards ?? [],
);

const getAllowedCards = createSelector(
  [getCustomerCards],
  getCardsWithAllowedProductType,
);

const getCardBookCounters = createSelector(
  [getCustomerCards, subscriptionSelectors.getSubscriptionNumber],
  (cards, subscriptionNumber) => {
    const card = getCustomerCard(cards, subscriptionNumber);
    return {
      bookCounterN0: card?.bookCounterN0 ?? 0,
      bookCounterN1: card?.bookCounterN1 ?? 0,
    };
  },
);

const getCardNumber = createSelector(
  [getCustomerCards, subscriptionSelectors.getSubscriptionNumber],
  (cards, subscriptionNumber) =>
    getCustomerCard(cards, subscriptionNumber)?.cardNumber,
);

const getProductType = createSelector(
  [getCustomerCards, subscriptionSelectors.getSubscriptionNumber],
  (cards, subscriptionNumber) =>
    getCustomerCard(cards, subscriptionNumber)?.productType,
);

const getValidityEndDate = createSelector(
  [getCustomerCards, subscriptionSelectors.getSubscriptionNumber],
  (cards, subscriptionNumber) =>
    getCustomerCard(cards, subscriptionNumber)?.validityEndDate,
);

const getCardsRangeStartDates = createSelector([getCustomerState], state => {
  const filteredCards = state.customer?.cards?.filter(
    card =>
      card.productType === CardType.NF &&
      [CardStatus.VALIDE, CardStatus.Actif].includes(card.contractStatus),
  );
  return filteredCards?.reduce(
    (acc: { min: Date; max: Date } | undefined, cur: ICard) => {
      const date = parseISO(cur.validityStartDate);
      if (!acc) return { min: date, max: date };
      return { min: min([acc.min, date]), max: max([acc.max, date]) };
    },
    undefined,
  );
});

const getValidCards = createSelector([getCustomerCards], cards =>
  cards.filter(
    card =>
      card.productType === CardType.NF &&
      [
        CardStatus.VALIDE,
        CardStatus.SUSPENSION_CLIENT,
        CardStatus.SUSPENSION_IMPAYE,
      ].includes(card.contractStatus),
  ),
);

const hasAlreadyMAXSubscription = createSelector([getCustomerCards], cards =>
  cards.some(
    card =>
      [
        CardType.TGV_MAX_JEUNE,
        CardType.TGV_MAX_SENIOR,
        CardType.IDTGV_MAX,
      ].includes(card.productType) &&
      [
        CardStatus.VALIDE,
        CardStatus.SUSPENSION_CLIENT,
        CardStatus.SUSPENSION_IMPAYE,
      ].includes(card.contractStatus),
  ),
);

// Returns if true the profile of a customer is incomplete (case for TMJ)
// Warning : as features flipping is async, we've to check outside of that selector if Features.migration.enabled is true, can't use it here.
const hasCustomerProfileIncomplete = createSelector(
  [getCustomerState],
  state => {
    const customer = state.customer;

    // Skip if customer is not connected
    if (!customer) {
      return false;
    }

    // Check if information are missing in the current customer's profile
    const mandatoryFields = [
      'address',
      'city',
      'country',
      'zipCode',
      'mobilePhone',
    ];
    const countCustomerFields = Object.keys(customer).filter(key =>
      mandatoryFields.includes(key),
    );

    // Profile is incomplete
    return mandatoryFields.length !== countCustomerFields.length;
  },
);

const canUpdatePhoto = createSelector([getCustomerState], state => {
  const { customer } = state;

  // Skip if customer has not yet been fetched
  if (!customer) return false;

  // Shortcuts
  const { VALIDATED, NOT_VALIDATED, INVALIDATED, DELETED } = PictureStatus;
  const { pictureStatus, pictureCounter } = customer;

  // If customer has no photo
  if (!pictureStatus) return true;

  // If customer has a picture, not in error and pictureCounter > 0
  if (
    [VALIDATED, NOT_VALIDATED].includes(pictureStatus) &&
    (pictureCounter ?? -1) > 0
  )
    return true;

  // If status is invalid or deleted
  if ([INVALIDATED, DELETED].includes(pictureStatus)) return true;

  // In other case, don't allow
  return false;
});

const hasValidPhoto = createSelector([getCustomerState], state => {
  const { customer } = state;

  // Skip if customer has not yet been fetched
  if (!customer) return false;

  // Test condition
  return (
    customer?.nsdStatus === NsdStatus.SUBSCRIBED &&
    (customer?.pictureStatus === PictureStatus.VALIDATED ||
      new Date(customer?.pictureUpdate) < addYears(new Date(), 3))
  );
});

const isCustomerWithoutSubscription = createSelector(
  [getCustomerState, subscriptionSelectors.getSubscriptionState],
  ({ customer }, { loading, subscription }) =>
    !!customer && !subscription && !loading,
);

// Get max bookable travels per day
export const MAX_MFA_TRAVELS_PER_DAY_PER_CARD = 4;
const getMaxBookableTravelsPerDay = createSelector(
  [getValidCards],
  cards => MAX_MFA_TRAVELS_PER_DAY_PER_CARD * Math.max(cards.length, 1),
);

export const customerSelectors = {
  getCustomerState,
  getAllowedCards,
  getCardBookCounters,
  getCardNumber,
  getProductType,
  getCustomerCards,
  getCustomerFirstName,
  getCustomerLastName,
  getCardsRangeStartDates,
  getValidCards,
  getValidityEndDate,
  getMaxBookableTravelsPerDay,
  canUpdatePhoto,
  hasAlreadyMAXSubscription,
  hasCustomerProfileIncomplete,
  isCustomerWithoutSubscription,
  hasValidPhoto,
};
