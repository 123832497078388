import { getEnv } from '@sticky/config';

export enum ProductType {
  MFA = 'MFA',
  TMJ = 'TMJ',
  TMS = 'TMS',
}

const CLIENT_APP = {
  [ProductType.MFA]: 'MAX_ACTIF',
  [ProductType.TMJ]: 'MAX_JEUNE',
  [ProductType.TMS]: 'MAX_SENIOR',
};

export const isMFA = (): boolean =>
  getEnv('VITE_PRODUCT_TYPE') === ProductType.MFA;
export const isTMJ = (): boolean =>
  getEnv('VITE_PRODUCT_TYPE') === ProductType.TMJ;
export const isTMS = (): boolean =>
  getEnv('VITE_PRODUCT_TYPE') === ProductType.TMS;

export const getClientApp = (): string =>
  CLIENT_APP[getEnv('VITE_PRODUCT_TYPE') as ProductType] ?? '';
