import { lazy } from 'react';

import { t } from '@sticky/i18n';

import { LAYOUT } from '../layout';
import type { IRoute } from '../routes';

const routes: IRoute[] = [
  {
    id: 'enrollment/documents',
    path: '/souscription/mes-pieces-identite',
    title: t('enrollment.documents-validation.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(() => import('../../pages/enrollment/document-validation')),
    private: true,
  },
  {
    id: 'enrollment/documents/pending',
    path: '/souscription/mes-pieces-identite-verification',
    title: t('enrollment.documents-validation.pageTitle'),
    layout: LAYOUT.EMPTY,
    element: lazy(
      () => import('../../pages/enrollment/document-validation/pending'),
    ),
    private: true,
  },
];

export default routes;
