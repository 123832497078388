import { encodeStringHex, toQueryString } from '@sticky/helpers';

import { useAuthStore } from '../../store';

type Options<T = unknown> = {
  email?: string;
  state?: T;
};

/**
 * Retourne l'URL qui sera appelée après la validation du compte. En général,
 * après que l'utilisateur a cliqué sur "Activer mon compte" dans l'email de
 * création de compte.
 *
 * @deprecated
 */
export const getRedirectUrlAfterAccountValidation = ({
  email,
  state,
}: Options = {}) => {
  const authState = useAuthStore.getState();

  if (!authState.config) {
    throw new Error('The auth store should be initialized');
  }

  const queryString = toQueryString({
    login_hint: email,
    state: state ? encodeStringHex(JSON.stringify(state)) : undefined,
  });

  return `${authState.config.loginRedirectUri.replace('www.', '')}${
    queryString ? `?${queryString}` : ''
  }`;
};
