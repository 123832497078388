import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRedirectUrlAfterAccountValidation } from '@sticky/auth';
import { StickyGatewayClient } from '@sticky/gateway-client';
import { removeUndefined } from '@sticky/helpers';

import type { IError } from '../../../resources/types';
import { formatApiDateUTC } from '../../../utils/date';
import { formatPhoneToApi } from '../../../utils/phone/phone';
import type {
  ICustomerAuthReason,
  ICustomerCreation,
} from '../models/customer';

// Request Handler to create customer
export const createCustomer = createAsyncThunk<
  ICustomerCreation,
  { customer: ICustomerCreation; reasonCode: ICustomerAuthReason },
  { rejectValue: IError }
>(
  'post/createCustomer',
  async (
    {
      customer,
      reasonCode,
    }: { customer: ICustomerCreation; reasonCode: ICustomerAuthReason },
    thunkApi,
  ) => {
    // remove confirmation fields
    const {
      emailConfirmation,
      passwordConfirmation,
      birthDate,
      mobilePhone,
      ...rest
    } = customer;

    // Build request
    const input = removeUndefined({
      ...rest,
      mobileNumber: formatPhoneToApi(mobilePhone),
      birthdate: formatApiDateUTC(birthDate),
      redirectUrl: getRedirectUrlAfterAccountValidation({
        email: emailConfirmation || rest.email,
        state: { reasonCode },
      }),
    });

    // Prepare request
    const httpClient = StickyGatewayClient.anonymousClient();
    const asyncFn = httpClient.post<ICustomerCreation>(
      '/public/customer/create-customer',
      input,
    );
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);
