import { registerSW } from 'virtual:pwa-register';

type Options = {
  onNeedRefresh: () => void;
  onPreloadingUpdate: () => void;
  onRegisterError: (error?: unknown) => void;
  onRegistered: () => void;
  onCheckForUpdate: () => void;
};

/**
 * Facilite l'utilisation de la fonction registerSW à notre cas d'usage.
 */
export const registerStickyServiceWorker = ({
  onNeedRefresh,
  onRegistered,
  onPreloadingUpdate,
  onRegisterError,
  onCheckForUpdate,
}: Options) => {
  const activateNewVersion = registerSW({
    immediate: true,

    onNeedRefresh,
    onRegisteredSW: (swUrl, registration) => {
      if (!registration) {
        onRegisterError();
        return;
      }

      onRegistered();

      // Cette fonction est appelée lorsque le service worker:
      // - s'installe pour la première fois
      // - ou quand il y a une mise à jour
      registration.addEventListener('updatefound', event => {
        // S'il y a déjà un service worker actif, c'est que c'est une mise à
        // jour
        const isUpdate = !!(event.currentTarget as ServiceWorkerRegistration)
          .active;

        if (isUpdate) {
          onPreloadingUpdate();
        }
      });

      // Vérification toutes les 30 secondes qu'il n'y a pas eu de mise à jour
      setInterval(() => {
        onCheckForUpdate();

        // Si la mise à jour est téléchargé mais encore en attente, on force le
        // rafraichissement de la page
        if (registration.waiting) {
          onNeedRefresh();
        } else {
          try {
            registration.update();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            onRegisterError();
          }
        }
      }, 30 * 1000);
    },
    onRegisterError,
  });

  return { activateNewVersion };
};
