import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { t } from '@sticky/i18n';

interface AriaLabelStepperProps {
  active: number;
  totalStep: number;
  indexStepList: number;
}

export const AriaLabelStepper = ({
  active,
  totalStep,
  indexStepList,
}: AriaLabelStepperProps) => {
  const [status, setStatus] = useState('to-come');

  useEffect(() => {
    if (active === indexStepList) {
      setStatus('in-progress');
    } else if (active > indexStepList) {
      setStatus('finished');
    }
  }, [active, indexStepList]);

  return (
    <Typography sx={visuallyHidden}>
      {`${t('steppers.waystep.step-out-of-total', {
        current: indexStepList + 1,
        total: totalStep,
      })} ${t('steppers.waystep', { context: status })}`}
    </Typography>
  );
};
