import { toQueryString } from '@sticky/helpers';

import { midEnvVars } from '../../config';

type Input = {
  redirect_uri: string;
  login_hint?: string;
  state?: string;
};

/**
 * Retourne l'url de la popup de connexion
 */
export const loginUrl = (input: Input) =>
  `${midEnvVars.VITE_MID_URL}/login?${toQueryString({
    client_id: midEnvVars.VITE_MID_CLIENT_ID,
    scope: midEnvVars.VITE_MID_SCOPE,
    response_type: midEnvVars.VITE_MID_RESPONSE_TYPE,
    redirect_uri: encodeURI(input.redirect_uri.replace('www.', '')),
    login_hint: input.login_hint,
    state: input.state,
  })}`;
