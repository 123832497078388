import { addYears, parseISO } from 'date-fns';
import { createSelector } from 'reselect';

import { parseYYYYMMDDOrNull } from '../../../utils/date';
import { CardType } from '../../customer/models/customer';
import {
  periodTypeContracts,
  SubscriptionCancelledStatusResultCode,
  SubscriptionProductId,
  SubscriptionTermType,
} from '../models/subscription';

import { getSubscriptionInvoicesAndCreditMemo } from './get-subscription-invoices-and-credit-memo';
import { getSubscriptionState } from './get-subscription-state';
import { hasInvoiceUnpaid } from './has-invoice-unpaid';

/**
 * Returns the product selected from the subscription with a special case for IDTGV_MAX
 */
const getSubscriptionProductSelect = createSelector(
  [getSubscriptionState],
  ({ subscription }) => {
    const productId = subscription?.subscription?.productId;
    const productSelect = subscription?.subscription?.productSelect;

    if (!productSelect && productId === SubscriptionProductId.IDTGV_MAX) {
      return CardType.IDTGV_MAX;
    }

    return productSelect;
  },
);

const getAnniversaryDate = createSelector(
  [getSubscriptionState],
  ({ subscription }) =>
    parseYYYYMMDDOrNull(
      subscription?.subscription?.anniversaryDate ??
        subscription?.subscription?.endDate,
    ),
);

const getProductId = createSelector(
  [getSubscriptionState],
  ({ subscription }) => subscription?.subscription?.productId,
);

const getSubscriptionNumber = createSelector(
  [getSubscriptionState],
  ({ subscription }) => subscription?.subscription?.number,
);

const getRatePlanSelected = createSelector(
  [getSubscriptionState],
  ({ subscription }) => subscription?.subscription?.ratePlanSelected,
);

const getSuspendRangeDates = createSelector(
  [getSubscriptionState],
  ({ subscription }) => ({
    start: subscription?.subscription?.suspendStartDate
      ? parseISO(subscription.subscription.suspendStartDate)
      : undefined,
    end: subscription?.subscription?.suspendEndDate
      ? parseISO(subscription.subscription.suspendEndDate)
      : undefined,
  }),
);

const hasSubscription = createSelector(
  [getSubscriptionState],
  ({ subscription }) => subscription?.subscription !== undefined,
);

const isAnnual = createSelector(
  [getSubscriptionState],
  ({ subscription }) =>
    subscription?.subscription?.termType === SubscriptionTermType.TERMED &&
    !subscription?.subscription?.autoRenew,
);

const isFirstAccess = createSelector(
  [getSubscriptionState],
  ({ subscription, subscriptionSelectCardNumber }) =>
    subscription !== undefined && !subscriptionSelectCardNumber,
);

const isFullTime = createSelector(
  [getProductId],
  productId => productId === SubscriptionProductId.MAX_ACTIF_PLUS,
);

const isBlocked = createSelector(
  [getSubscriptionState],
  ({ subscription }) =>
    subscription?.statusResult === SubscriptionCancelledStatusResultCode.ELEVEN,
);

const isCancelled = createSelector(
  [getSubscriptionState],
  ({ subscription }) =>
    subscription?.statusResult !== undefined &&
    Object.values(SubscriptionCancelledStatusResultCode).includes(
      subscription.statusResult,
    ),
);

const isIbanOrBillToUpdatable = createSelector(
  [getSubscriptionState],
  ({ subscription }) => {
    const update = subscription?.account?.lastUpdateIban;
    const opposition = subscription?.account?.lastOppositionMandate;
    const updateIsSameOrBeforeOneYearAgo =
      update && parseISO(update) <= addYears(new Date(), -1);
    const oppositionIsBeforeSameOrBeforeOneYearAgo =
      opposition && parseISO(opposition) <= addYears(new Date(), -1);
    const updateIsSameOrBeforeOpposition =
      opposition && update && parseISO(update) <= parseISO(opposition);

    if (!update) {
      return true;
    }

    if (
      (!opposition && updateIsSameOrBeforeOneYearAgo) ||
      (oppositionIsBeforeSameOrBeforeOneYearAgo &&
        updateIsSameOrBeforeOneYearAgo)
    ) {
      return true;
    }

    return !!updateIsSameOrBeforeOpposition;
  },
);

const isSepaPaymentMethod = createSelector(
  [getSubscriptionState],
  ({ subscription }) =>
    (subscription?.subscription?.autoRenew &&
      subscription?.subscription?.periodTypeContract ===
        periodTypeContracts.MONTHS) ||
    !!subscription?.account?.rum,
);

const isSubscriptionReady = createSelector(
  [getSubscriptionState],
  ({ error, loading, subscription }) => !!subscription || (!!error && !loading),
);

const isIdTgvMax2Subscription = createSelector(
  [getProductId],
  productId => productId === SubscriptionProductId.IDTGV_MAX,
);

export const subscriptionSelectors = {
  getAnniversaryDate,
  getProductId,
  getRatePlanSelected,
  getSubscriptionNumber,
  getSubscriptionState,
  getSuspendRangeDates,
  getSubscriptionProductSelect,
  getSubscriptionInvoicesAndCreditMemo,
  hasSubscription,
  hasInvoiceUnpaid,
  isAnnual,
  isBlocked,
  isCancelled,
  isFirstAccess,
  isFullTime,
  isIbanOrBillToUpdatable,
  isSepaPaymentMethod,
  isSubscriptionReady,
  isIdTgvMax2Subscription,
};
