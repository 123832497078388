import type { ElementType } from 'react';
import { useState } from 'react';
import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';

import { Logger } from '@sticky/logger';

import { useProductId } from '../../features/terms/hooks/use-product-id';
import { getCGVUrl } from '../../features/terms/store/terms-slice';
import { CGVErrorModal } from '../modal/cgv-error/cgv-error-modal';
import { CircularLoader } from '../suspense/circular-progress';

type CGVLinkProps = {
  link: { product?: string | undefined; 'data-test-id'?: string };

  className?: string;
  variant?: TypographyProps['variant'];
  startDateValidity?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ElementType<any>;
  children?: JSX.Element | string;
};

export const CGVLink = ({
  children,
  link,
  startDateValidity,
  className,
  variant,
  component = 'span',
}: CGVLinkProps) => {
  const [isLoading, setLoading] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);

  // Get the productId from translation files
  const productId = useProductId(link.product);

  return (
    <>
      <CGVErrorModal open={isOpenModal} onclose={() => setOpenModal(false)} />
      <Typography
        className={className}
        color="inherit"
        data-test-id={link['data-test-id']}
        id={link['data-test-id']}
        onClick={(ev: { preventDefault: () => void }) => {
          ev.preventDefault();
          setLoading(true);
          getCGVUrl({ productId, startDateValidity })
            .then(response => {
              window.open(response);
              setLoading(false);
            })
            .catch(err => {
              Logger.error('getCGVUrl', err);
              setLoading(false);
              setOpenModal(true);
            });
        }}
        component={component}
        variant={variant}
      >
        {children}
        {isLoading && <CircularLoader />}
      </Typography>
    </>
  );
};
