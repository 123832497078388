import { StickyGatewayClient } from '@sticky/gateway-client';

export type LoginInput = {
  authCode: string;
  redirectUri: string;
};

export type LoginOutput = {
  idToken: string;
  refreshToken: string;
  expiresIn: number;
};

/**
 * Ask for a token on login.
 */
export const login = (input: LoginInput) =>
  StickyGatewayClient.anonymousClient().post<LoginOutput>(
    `/public/auth/sfc/token`,
    {
      type: 'AUTH_CODE',
      authCode: input.authCode,
      redirectUri: input.redirectUri.replace('www.', ''),
    },
  );
