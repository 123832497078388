import { Field } from 'react-final-form';
import { MenuItem, Typography } from '@mui/material';
import type { SelectData, SelectProps } from 'mui-rff';
import { Select } from 'mui-rff';

import type { IValidator } from './form-validators';

export interface FormInputSelectProps extends SelectProps {
  readOnly?: boolean;
  validate?: IValidator;
  formatOnBlur?: boolean;
}

export const FormInputSelect = (props: FormInputSelectProps): JSX.Element => {
  const inputProps = {
    inputProps: {
      'aria-labelledby': props.name + '-label',
    },
  };
  return (
    <Field
      name={props.name}
      validate={props.validate}
      formatOnBlur={props.formatOnBlur}
    >
      {({ input }) => {
        const { type, validate, required, disabled, ...attrs } = input;

        // Display value if readOnly
        if (props.readOnly) {
          return <Typography>{input.value}</Typography>;
        }

        // Else display form input
        return (
          <Select
            {...attrs}
            placeholder={props.placeholder}
            disabled={props.disabled}
            required={props.required}
            variant={props.variant || 'outlined'}
            {...inputProps}
          >
            {props.data?.map((data: SelectData, key: number) => (
              <MenuItem key={key} value={data.value}>
                {data.label}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    </Field>
  );
};
