import { Link, useLocation } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Icon from '@mui/material/Icon';
import type { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

import { useAppSelector } from '../../../../hooks';
import { subscriptionSelectors } from '../../../subscription/selectors';
import type { INavigationMenuItem } from '../../resources/navigation/menu';
import { getNavigationMenuItems } from '../../resources/navigation/menu';

// Styling
const useStyles = makeStyles()((theme: Theme) => {
  const {
    app: { colors },
  } = theme;
  return {
    disabled: {
      '& .MuiBottomNavigationAction-label': {
        color: colors.warmGray2,
      },
    },
    color: {
      '& .MuiBottomNavigationAction-label.Mui-selected': {
        color: colors.textContrasted,
      },

      '& .Mui-selected::before': {
        content: '""',
        position: 'absolute',
        width: 30,
        height: 3,
        bottom: 0,
        left: '50%',
        marginLeft: -15,
        backgroundColor: colors.textContrasted,
        borderRadius: '2px 2px',
      },

      '& .MuiIcon-root': {
        width: '2rem',
        height: '2rem',
      },
    },
  };
});

export const NavigationMobile = (): JSX.Element => {
  const location = useLocation();
  const { classes } = useStyles();
  const theme = useTheme();

  const hasSubscription = useAppSelector(subscriptionSelectors.hasSubscription);

  // Init the active menu with current location
  const navigationMenuItems = getNavigationMenuItems(hasSubscription);
  const currentMenuIndex =
    navigationMenuItems.findIndex((el: INavigationMenuItem) =>
      new RegExp(el.route).test(location.pathname),
    ) || 0;

  // Create the menu icon
  const menuIcon = (
    src: string,
    selected: boolean,
    disabled = false,
  ): JSX.Element => {
    const {
      app: { colors },
    } = theme;
    const backgroundColor = selected
      ? colors.primary
      : disabled
        ? colors.warmGray1
        : colors.black;
    return (
      <Icon
        style={{
          maskImage: `url(${src})`,
          WebkitMaskImage: `url(${src})`,
          backgroundColor,
        }}
      />
    );
  };

  // Generate desktop menu
  return (
    <BottomNavigation
      component="nav"
      role="navigation"
      value={currentMenuIndex >= 0 ? currentMenuIndex : 0}
      showLabels
      data-test-id="list-items-navigation-mobile"
    >
      {navigationMenuItems
        .filter((el: INavigationMenuItem) => !el.hideOnMobile && el.route)
        .map((el: INavigationMenuItem, index: number) => (
          <BottomNavigationAction
            component={Link}
            to={el.route}
            key={el.label}
            label={t(el.label, { context: 'mobile' })}
            icon={menuIcon(
              el.icon ?? '',
              currentMenuIndex === index,
              el.disabled,
            )}
            disabled={el.disabled}
            className={[classes.color, el.disabled && classes.disabled].join(
              ' ',
            )}
          />
        ))}
    </BottomNavigation>
  );
};
