import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import { addMinutes } from 'date-fns';

import { ConfigCommon } from '@sticky/config';
import { StickyGatewayClient } from '@sticky/gateway-client';

import {
  cleanPersistentState,
  loadPersistentState,
  savePersistentState,
} from '../..';
import { BrandNameProduct } from '../../subscription/models/subscription';
import type {
  IProposalInput,
  IProposalPlan,
  IProposalState,
  OffertTypeQuoteOutput,
} from '../models/proposal';
import {
  PaymentMethod,
  ProductCodes,
  ProductSelect,
  PurchaseType,
} from '../models/proposal';

// Constants for localStorage
const LOCALSTORAGE_KEY = 'propal';

function getProductCode() {
  switch (ConfigCommon.appName) {
    case BrandNameProduct.MFA:
      return ProductCodes.MFA;
    case BrandNameProduct.TMJ:
      return ProductCodes.TGV_MAX;
    case BrandNameProduct.TMS:
      return ProductCodes.TMS;
    default:
      return '';
  }
}

// Setup initial state
const defaultState = {
  loading: false,
  productCode: getProductCode(),
  productSelect:
    ConfigCommon.appName === BrandNameProduct.MFA
      ? ProductSelect.FULL_TIME
      : ProductSelect.PART_TIME,
  purchaseType: PurchaseType.INITIAL,
  serviceUnavailable: false,
};
const initialState: IProposalState =
  loadPersistentState(LOCALSTORAGE_KEY) || defaultState;

export const getProposalQuoteSales = createAsyncThunk(
  'post/order/quote-sales',
  async (proposalInput: IProposalInput, thunkApi): Promise<IProposalPlan[]> => {
    const httpClient = proposalInput.isKnown
      ? StickyGatewayClient.authClient()
      : StickyGatewayClient.anonymousClient();
    const postData = {
      productCode: proposalInput.productCode,
      purchaseType: proposalInput.purchaseType,
      productSelect: proposalInput.proposal?.productSelect,
      startDateValidity: proposalInput.startDateValidity,
      origin: proposalInput.proposal?.origin?.codeStation,
      destination: proposalInput.proposal?.destination?.codeStation,
      travelClass: proposalInput.proposal?.travelClass,
      birthDate: proposalInput.birthDate,
      cashPayment: proposalInput.proposal?.cashPayment,
    };
    const url = proposalInput.isKnown
      ? '/public/order/known-customer-quote-sales'
      : '/public/order/quote-sales';
    const asyncFn = httpClient
      .post<IProposalPlan[]>(url, postData)
      .catch((reason: AxiosError) => {
        throw reason.response;
      });
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

export const getOfferTypeQuote = createAsyncThunk(
  'post/refdata/offertype-quote',
  async (offerType: string, thunkApi): Promise<OffertTypeQuoteOutput[]> => {
    const httpClient = StickyGatewayClient.anonymousClient();
    const asyncFn = httpClient
      .post<OffertTypeQuoteOutput[]>('/public/refdata/offertype-quote', {
        offerType,
      })
      .catch((reason: AxiosError) => {
        throw reason.response;
      });
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

export const infoStock = createAsyncThunk(
  'get/order/info-stock',
  async (thunkApi): Promise<{ projectedInventory: number }> => {
    const httpClient = StickyGatewayClient.anonymousClient();
    const asyncFn = httpClient
      .get<{ projectedInventory: number }>('/public/order/info-stock')
      .catch((reason: AxiosError) => {
        throw reason.response;
      });
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

// Store state in localStorage
const saveProposalState = (state: IProposalState) => {
  const stateCopy: IProposalState = { ...state };
  const keys: (keyof IProposalState)[] = [
    'loading',
    'serviceUnavailable',
    'error',
    'offerTypeQuotes',
    'projectedInventory',
  ];
  keys.forEach(key => delete stateCopy[key]);
  savePersistentState(LOCALSTORAGE_KEY, stateCopy);
};

// Redux slice
const PROPOSAL_VALIDITY_MINUTES = 30;
const proposalSlice = createSlice({
  name: 'proposal',
  initialState,
  reducers: {
    setProposalProductCode: (state, action) => {
      state.productCode = action.payload;
      saveProposalState(state);
    },
    setProposalPurchaseType: (state, action) => {
      state.purchaseType = action.payload;
      saveProposalState(state);
    },
    setProposalProductSelect: (state, action) => {
      state.productSelect = action.payload;
      saveProposalState(state);
    },
    setProposalStartValidity: (state, action) => {
      state.startDateValidity = action.payload;
      saveProposalState(state);
    },
    setProposalPlanId: (state, action) => {
      const plan = state?.plans?.find(p => p.idProposal === action.payload);
      if (plan) {
        state.planId = plan.idProposal;
        state.travelClass = plan.travelClass;
      }
      saveProposalState(state);
    },
    setProposalPlanSelected: (state, action) => {
      state.planSelected = action.payload;
      saveProposalState(state);
    },
    setProposalAmount: (state, action) => {
      state.amount = action.payload;
      saveProposalState(state);
    },
    setProposalTravelClass: (state, action) => {
      const plan = state?.plans?.find(p => p.travelClass === action.payload);
      if (plan) {
        state.travelClass = plan.travelClass;
      } else {
        state.travelClass = action.payload;
      }
      saveProposalState(state);
    },
    setProposalOriginStation: (state, action) => {
      state.origin = action.payload;
      saveProposalState(state);
    },
    cleanProposalOriginStation: state => {
      delete state.origin;
      saveProposalState(state);
    },
    setProposalDestinationStation: (state, action) => {
      state.destination = action.payload;
      saveProposalState(state);
    },
    setProposalPaymentInitialInMonths: (state, action) => {
      state.paymentInitialInMonths = action.payload;
      saveProposalState(state);
    },
    setProposalPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
      state.cashPayment = state.paymentMethod === PaymentMethod.CB;
      saveProposalState(state);
    },
    cleanProposalDestinationStation: state => {
      delete state.destination;
      saveProposalState(state);
    },
    cleanProposalStartValidity: state => {
      delete state.startDateValidity;
      saveProposalState(state);
    },
    cleanProposalPlansAndPlanId: state => {
      delete state.planId;
      delete state.plans;
      saveProposalState(state);
    },
    cleanProposalPlans: state => {
      delete state.plans;
      saveProposalState(state);
    },
    cleanProposalPaymentMethod: state => {
      delete state.paymentMethod;
      saveProposalState(state);
    },
    cleanProposalPaymentInitialInMonths: state => {
      delete state.paymentInitialInMonths;
      saveProposalState(state);
    },
    cleanProposalCashPayment: state => {
      delete state.cashPayment;
      saveProposalState(state);
    },
    cleanProposalError: state => {
      delete state.error;
      saveProposalState(state);
    },
    cleanProposalDestination: state => {
      delete state.destination;
      saveProposalState(state);
    },
    cleanProposalWithProductSelect: (state, action) => {
      cleanPersistentState(LOCALSTORAGE_KEY);
      return {
        ...defaultState,
        productSelect: action.payload as ProductSelect,
      };
    },
    cleanProposal: () => {
      cleanPersistentState(LOCALSTORAGE_KEY);
      return defaultState;
    },
  },
  extraReducers: builder => {
    builder.addCase(getProposalQuoteSales.pending, state => {
      state.loading = true;
      state.serviceUnavailable = false;
      delete state.error;
      state.at = Date.now();
    });
    builder.addCase(getProposalQuoteSales.fulfilled, (state, action) => {
      state.loading = false;
      delete state.error;
      state.plans = action.payload;
      state.at = Date.now();
      state.expirationDate = addMinutes(
        new Date(),
        PROPOSAL_VALIDITY_MINUTES,
      ).getTime();
      saveProposalState(state);
    });
    builder.addCase(getProposalQuoteSales.rejected, state => {
      state.loading = false;
      state.error = 'QUOTE_SALES_KO';
      state.serviceUnavailable = true;
    });

    builder.addCase(getOfferTypeQuote.pending, state => {
      state.offerTypeQuotes = {
        loading: true,
        at: Date.now(),
      };
    });
    builder.addCase(getOfferTypeQuote.fulfilled, (state, action) => {
      state.offerTypeQuotes = {
        loading: false,
        at: Date.now(),
        value: action.payload,
      };
    });
    builder.addCase(getOfferTypeQuote.rejected, (state, action) => {
      state.offerTypeQuotes = {
        loading: false,
        at: Date.now(),
        error: action.error,
      };
    });
    builder.addCase(infoStock.pending, state => {
      state.projectedInventory = {
        loading: true,
        at: Date.now(),
      };
    });
    builder.addCase(infoStock.fulfilled, (state, action) => {
      state.projectedInventory = {
        loading: false,
        value: action.payload.projectedInventory,
        at: Date.now(),
      };
    });
    builder.addCase(infoStock.rejected, state => {
      state.projectedInventory = {
        loading: false,
        error: 'infoStock',
        at: Date.now(),
      };
    });
  },
});

export const {
  cleanProposalDestination,
  cleanProposalError,
  cleanProposalPaymentMethod,
  cleanProposalCashPayment,
  cleanProposalPlans,
  cleanProposalStartValidity,
  cleanProposalWithProductSelect,
  cleanProposalPaymentInitialInMonths,
  setProposalAmount,
  setProposalPlanSelected,
  setProposalProductCode,
  setProposalProductSelect,
  setProposalStartValidity,
  setProposalPurchaseType,
  setProposalPlanId,
  setProposalOriginStation,
  cleanProposalOriginStation,
  setProposalDestinationStation,
  cleanProposalDestinationStation,
  setProposalPaymentInitialInMonths,
  setProposalPaymentMethod,
  cleanProposalPlansAndPlanId,
  cleanProposal,
  setProposalTravelClass,
} = proposalSlice.actions;

const proposalReducer = proposalSlice.reducer;
export { proposalReducer };
