import { useMemo } from 'react';
import type { AlertColor } from '@mui/material';
import DOMPurify from 'dompurify';

import { AlertMessage as StickyAlertMessage } from '@sticky/components';
import type { Keys } from '@sticky/i18n';
import { t } from '@sticky/i18n';

import type { IError } from '../../resources/types';

export type AlertMessageProps = {
  children?: JSX.Element | string;
  onClose?: () => void;
  id?: string;
  className?: string;
  closable?: boolean;
  title?: string;
  error?: IError<JSX.Element | string>;
  severity?: 'info' | 'error' | 'warning' | 'success';
};

type ErrorContext = {
  title?: string;
  message?: JSX.Element | string;
  closable?: boolean;
  severity?: AlertColor;
};

const tAsErrorContext = (key: Keys, options: { context?: string } = {}) => {
  const errorFromI18n = t(key, {
    context: options?.context,
    returnObjects: true,
  }) as ErrorContext | string;

  if (key === errorFromI18n) {
    return undefined;
  }

  if (typeof errorFromI18n === 'string') {
    return {
      message: errorFromI18n,
    } as ErrorContext;
  }

  return errorFromI18n;
};

export const AlertMessage = ({
  closable,
  children,
  severity,
  className,
  onClose,
  title,
  error,
  ...rest
}: AlertMessageProps) => {
  const alertCtx = useMemo(() => {
    const errorPartner = error?.errorCode?.split('_')?.shift();

    const errorContext = [
      { severity: 'error' } as ErrorContext,
      errorPartner && tAsErrorContext(`errorCode.${errorPartner}` as Keys),
      error?.errorCode &&
        tAsErrorContext(`errorCode.${error.errorCode}` as Keys),
    ]
      .filter(Boolean)
      .reduce<ErrorContext>((acc, e) => ({ ...acc, ...e }), {});

    const errorDefault = tAsErrorContext('errorCode.default', {
      context: errorContext.severity,
    });

    return {
      ...errorDefault,
      ...errorContext,
    };
  }, [error]);

  const message = children ?? alertCtx.message;

  return (
    <StickyAlertMessage
      className={className}
      closable={closable ?? alertCtx.closable}
      severity={severity ?? alertCtx.severity}
      title={title ?? alertCtx.title}
      onClose={onClose}
      description={
        typeof message === 'object' ? (
          message
        ) : (
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(message as string),
            }}
          />
        )
      }
      {...rest}
    />
  );
};
