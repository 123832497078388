import type { PropsWithChildren } from 'react';
import type { Theme } from '@mui/material';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';

type StickyThemeProviderProps = PropsWithChildren<{
  theme: Theme;
}>;

export const StickyThemeProvider = ({
  theme,
  children,
}: StickyThemeProviderProps) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </LocalizationProvider>
);
