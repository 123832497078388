import { z } from 'zod';

const MidJwtTokenSchema = z.object({
  remember_me: z.any().transform(value => value === 'true' || value === true),
});

export const decodeMidJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

  return MidJwtTokenSchema.parse(JSON.parse(jsonPayload));
};
