import { Link } from 'react-router-dom';
import { Typography, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { getEnv, getFeatures } from '@sticky/config';
import { i18n, t } from '@sticky/i18n';

import { proposalSelectors } from '../../features/proposal/selectors';
import { useAppSelector } from '../../hooks';
import { Routes } from '../../routes/routes';

import type { IStep } from './StepperCommon';
import { StepperCommon } from './StepperCommon';

type StepperSubscriptionProps = {
  kindOfStepper?: 'stepperMigration' | 'stepperSubscription';
};

// Create styles
const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { colors },
  } = theme;
  return {
    root: {
      display: 'grid',
      gridTemplateColumns: '60% 35%',
      gridTemplateRows: '90px min-content',
      gridTemplateAreas: `"logo logoProduct"
                          "stepper stepper"`,
      columnGap: '1rem',
      padding: '0 1rem',
      [breakpoints.up('breakPointDesktop')]: {
        padding: '0 1.5rem',
        height: '100%',
        columnGap: 0,
        gridTemplateRows: 'min-content min-content auto 1fr',
        gridTemplateAreas: `"logo logo"
                           "title title"
                           "stepper stepper"
                           "logoBrand logoProduct"`,
      },
      width: '100%',
    },
    logo: {
      gridArea: 'logo',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center left',
      height: '60px',
      alignSelf: 'center',
      [breakpoints.up('breakPointTablet')]: {
        height: '4rem',
        marginLeft: 0,
        marginTop: 0,
      },
      [breakpoints.up('breakPointDesktop')]: {
        height: '5rem',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
        marginLeft: 0,
      },
    },
    title: {
      gridArea: 'title',
      color: colors.textSecondaryContrasted,
      display: 'none',
      [breakpoints.up('breakPointDesktop')]: {
        display: 'block',
        marginBottom: '3rem',
      },
    },
    logoProduct: {
      gridArea: 'logoProduct',
      height: '60px',
      backgroundImage: `url(${theme.app.assets.companyLogo})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'right center',
      alignSelf: 'center',
      [breakpoints.up('breakPointDesktop')]: {
        height: theme.spacing(8),
        backgroundPosition: 'right',
        alignSelf: 'flex-end',
        marginBottom: '1rem',
      },
    },
    logoBrand: {
      gridArea: 'logoBrand',
      display: 'none',
      height: '45px',
      [breakpoints.up('breakPointDesktop')]: {
        display: 'grid',
        alignSelf: 'flex-end',
        marginBottom: '2rem',
        backgroundColor: colors.textSecondaryContrasted,
        maskImage: `url(${theme.app.assets.enterpriseLogo})`,
        maskSize: 'contain',
        maskRepeat: 'no-repeat',
      },
    },
  };
});
// Define step

// Describe component
export const StepperSubscription = ({
  kindOfStepper = 'stepperSubscription',
}: StepperSubscriptionProps): JSX.Element => {
  const { classes } = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));

  // Read proposal store
  const proposal = useAppSelector(state => state.proposal);
  const proposalLabel = useAppSelector(proposalSelectors.getProposalLabel);

  // Able to change the context for the translation
  const selectedContext = proposal.paymentMethod ?? '';

  // Get the logo from productSelect
  const brandLogoType: string = proposal?.productSelect ?? 'default';
  const brandLogo = theme.app?.assets?.brandLogo;
  const brandLogoUrl = brandLogo
    ? brandLogo[brandLogoType] || brandLogo.default
    : '';

  // Get differents steps in terms of context of payment method
  const steps: IStep[] = t(`${kindOfStepper}.steps`, {
    context: selectedContext,
    returnObjects: true,
  });

  // Generate Content
  return (
    <div className={classes.root}>
      <Link
        to={Routes.getPathById('home')}
        className={classes.logo}
        style={{ backgroundImage: `url(${brandLogoUrl})` }}
        title={t(`actions.go-home-page`, {
          context: brandLogoType ?? getEnv('VITE_PRODUCT_SELECT_DEFAULT'),
        })}
      >
        {''}
      </Link>

      {i18n.exists(`${kindOfStepper}.title`) && (
        <Typography variant="body2" className={classes.title}>
          <b>
            {t(`${kindOfStepper}.title`)} {proposalLabel}
          </b>
        </Typography>
      )}
      <StepperCommon
        orientation={`${isDesktop ? 'vertical' : 'horizontal'}`}
        steps={steps}
      />

      <div className={classes.logoBrand} aria-label="SNCF" role="img" />
      {!getFeatures().ouigo.enabled && (
        <div
          className={classes.logoProduct}
          aria-label="TGV INOUÏ"
          role="img"
        />
      )}
    </div>
  );
};
