import { toQueryString } from '@sticky/helpers';

import { midEnvVars } from '../../config';

type Input = {
  id_token_hint: string;
  post_logout_redirect_uri: string;
};

/**
 * Retourne l'url de la popup de déconnexion
 */
export const logoutUrl = (input: Input) =>
  `${midEnvVars.VITE_MID_URL}/logout?${toQueryString({
    client_id: midEnvVars.VITE_MID_CLIENT_ID,
    id_token_hint: input.id_token_hint,
    post_logout_redirect_uri: encodeURI(input.post_logout_redirect_uri).replace(
      'www.',
      '',
    ),
  })}`;
