import { useMemo } from 'react';
import { useForm } from 'react-final-form';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Typography, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { TooltipChip } from '../tooltip/tooltip';

import { buildFormField } from './utils/build-form-field';
import type { FormRowProps, IFormInputField } from '.';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { colors },
  } = theme;
  return {
    root: {
      display: 'grid',
      gridTemplateAreas: `"input tooltip"`,
      gridTemplateColumns: '1fr min-content',
      marginTop: '1rem',

      // Normal desktop display
      [breakpoints.up('breakPointDesktop')]: {
        gridTemplateAreas: `"label input tooltip"`,
        gridTemplateColumns: '34% 1fr min-content',
      },

      // Compact custom
      '&.type-checkbox,&.is-compact': {
        [breakpoints.up('breakPointDesktop')]: {
          gridTemplateAreas: `"input tooltip"`,
          gridTemplateColumns: '1fr min-content',
        },
      },

      // Readonly custom
      '&.is-readonly': {
        marginTop: theme.spacing(0.6),
        paddingBottom: theme.spacing(0.6),
        borderBottom: `1px solid ${colors.warmGray1}`,
        '&:last-child': {
          borderBottom: 'none',
        },
        gridTemplateAreas: `"label ."
                              "input tooltip"`,

        [breakpoints.up('breakPointDesktop')]: {
          gridTemplateAreas: `"label input tooltip"`,
          '& .field-label': {
            paddingLeft: theme.spacing(1),
          },
        },
      },

      // Compact readonly
      '&.is-readonly.is-compact': {
        [breakpoints.down('breakPointDesktop')]: {
          gridTemplateAreas: `"label tooltip"
                            "input tooltip"`,
        },
      },
    },
    label: {
      gridArea: 'label',
      paddingRight: theme.spacing(2),

      [breakpoints.up('breakPointDesktop')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        minHeight: '3rem',
      },
    },
    input: {
      gridArea: 'input',
      alignSelf: 'center',
    },
    tooltip: {
      gridArea: 'tooltip',
      alignSelf: 'start',
      paddingLeft: theme.spacing(2),
      [breakpoints.up('breakPointDesktop')]: {
        display: 'flex',
        height: theme.spacing(7),
        '& button': {
          alignSelf: 'center',
        },
      },
    },
    asterisk: {
      fontWeight: 700,
      color: colors.textContrasted,
    },
  };
});

export const FormRow = (props: FormRowProps) => {
  const { classes } = useStyles();
  const { field, values, compact = false, readonly = false } = props;

  // Define if readonly or not
  const isReadOnly = readonly || field.readonly;

  // Detect desktop / mobile
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));
  const form = useForm();
  const isCompact = compact || !isDesktop;

  // Manage requiredIf attribute
  if (field.requiredIf) {
    const requiredIfNot = /^!/.test(field.requiredIf);
    const requiredField = field.requiredIf.replace(/^!/, '');

    if (requiredIfNot) {
      if (values) {
        field.required = !values[requiredField];
      } else {
        form.reset(values); // Fix https://jira.vsct.fr/browse/SYG-1691
      }
    } else if (values?.[requiredField]) {
      field.required = true;
    } else {
      form.reset(values);
    }
  }

  // Build field
  const Field = useMemo(() => {
    const {
      hideIfReadOnly,
      hideifempty,
      requiredIf,
      hideIf,
      hide,
      fieldType,
      ...props
    } = field;
    return buildFormField(props, values, {
      compact: isCompact,
      readOnly: isReadOnly,
    });
  }, [field, values, isCompact, isReadOnly]);

  const fieldProps: IFormInputField = { ...field };

  // Set fields to show if company is checked
  if (fieldProps.showIf && values && !values[fieldProps.showIf]) {
    return <></>;
  }

  // Hide field if field doesn't display when company is checked to bill
  if (fieldProps.hideIf && values?.[fieldProps.hideIf]) {
    return <></>;
  }

  if (fieldProps.hide) {
    return <></>;
  }

  // Hide field if value is empty
  if (
    fieldProps.hideifempty &&
    values &&
    (!values[fieldProps.name] || values[fieldProps.name] === '') &&
    (fieldProps.disabled || fieldProps.readonly || props.readonly)
  ) {
    return <></>;
  }

  // Hide field if readOnly
  if (fieldProps.hideIfReadOnly && isReadOnly) {
    return <></>;
  }

  // Display required sign
  const requiredAsterisk =
    fieldProps.required && !isReadOnly ? (
      <span className={classes.asterisk}>* </span>
    ) : (
      ''
    );

  // Get className
  const className = [
    classes.root,
    'form-row',
    `type-${field.inputtype}`,
    isCompact ? 'is-compact' : undefined,
    isReadOnly ? 'is-readonly' : undefined,
  ]
    .filter(s => s !== undefined)
    .join(' ');

  // Build form row
  return (
    <div className={className}>
      {(!isCompact || isReadOnly) && fieldProps?.label && (
        <label
          className={`${classes.label} field-label`}
          htmlFor={fieldProps.name}
          id={fieldProps.name + '-label'}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            lineHeight={1.2}
            role={props.role}
            aria-level={props['aria-level']}
          >
            {fieldProps?.label} {requiredAsterisk}
          </Typography>
          {fieldProps.example && !isReadOnly && (
            <Typography variant="caption">{fieldProps.example}</Typography>
          )}
        </label>
      )}

      <div className={`${classes.input} field-input`} data-dd-privacy="mask">
        {Field}
      </div>

      {!isReadOnly && field.tooltip && (
        <div className={classes.tooltip}>
          <TooltipChip
            title={<Typography>{field.tooltip}</Typography>}
            size="h1"
          >
            <QuestionMarkIcon />
          </TooltipChip>
        </div>
      )}
    </div>
  );
};
