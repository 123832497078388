import { lazy } from 'react';

import { t } from '@sticky/i18n';

import { LAYOUT } from '../layout';
import type { IRoute } from '../routes';

const routes: IRoute[] = [
  {
    id: 'enrollment/choose-plan',
    path: '/souscription/choix-du-forfait',
    title: t('enrollment.choose-plan.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/choose-plan/choose-plan'),
    ),
    private: true,
  },
  {
    id: 'enrollment/choose-plan/error',
    path: '/souscription/choix-du-forfait-erreur',
    title: t('enrollment.choose-plan.error'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/choose-plan/choose-plan-error'),
    ),
    private: true,
  },
];

export default routes;
