import type { AxiosError, RawAxiosRequestHeaders } from 'axios';
import axios from 'axios';

import { ConfigCommon, getEnv } from '@sticky/config';

import { onCaptchaError } from '../captcha';
import { getDistributionChannel } from '../distributionChannel';
import { isStickyGatewayError } from '../errors/StickyGatewayError';

import { getClientApp } from './get-client-app';
import { getCorrelationId, HEADER_CORRELATION_ID } from './getCorrelationId';

export const createClient = (headers: RawAxiosRequestHeaders = {}) => {
  const axiosInstance = axios.create({
    baseURL: ConfigCommon.apiUrl,
    timeout: Number.parseInt(getEnv('VITE_SYG_TIMEOUT', '45000')),
    headers: {
      [HEADER_CORRELATION_ID]: getCorrelationId(),
      'x-client-app': getClientApp(),
      'x-client-app-version': ConfigCommon.version,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
  });

  // Request interceptor for API calls
  axiosInstance.interceptors.request.use(
    config => {
      config.headers['x-distribution-channel'] = getDistributionChannel();
      return config;
    },
    error => Promise.reject(error),
  );

  // Response interceptor for API calls
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      // Need to display captcha if too many requests
      if (
        (error as AxiosError)?.response?.status === 429 &&
        !isStickyGatewayError(error)
      ) {
        onCaptchaError();
      }

      return Promise.reject(error);
    },
  );

  return axiosInstance;
};
