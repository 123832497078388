export class SearchTravelRequest {
  constructor(
    private context: string,
    private origin: string,
    private destination: string,
    private departureDateTime: string,
    private travelClass: string,
    private productId: string,
    private cardNumber: string,
    private hasWheelchairPlacement: boolean,
  ) {}
}
