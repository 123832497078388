import type { TravelPreferences } from '..';

export const isPreferencesFilled = (
  preferences: TravelPreferences,
): boolean => {
  const { destination, origin } = preferences;
  return (
    destination !== null &&
    origin !== null &&
    destination.label.length > 0 &&
    origin.label.length > 0
  );
};
