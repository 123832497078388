import { z } from 'zod';

import { ConfigCommon } from '@sticky/config';

import { StrapiDocumentSchema, StrapiMediaSchema } from '../../schemas';
import { client } from '../client';

const InformationSchema = z.object({
  articleTitle: z.string().nullish(),
  informationContent: z
    .array(
      z.object({
        title: z.string().nullish(),
        content: z.string().nullish(),
        media: StrapiMediaSchema.nullish(),
        videoUrl: z.string().nullish(),
        visibility: z.boolean().default(false),
      }),
    )
    .nullish(),
  visibility: z.boolean().default(false),
});

const BannerSchema = z.object({
  caption: z.string().nullish(),
  title: z.string(),
  description: z.string().nullish(),
  visibility: z.boolean().default(false),
});

const BannerKeyNumbersSchema = z.object({
  keyNumbers: z.array(
    z.object({
      isPercentage: z.boolean().default(true),
      number: z.string(),
      text: z.string(),
    }),
  ),
  title: z.string(),
  visibility: z.boolean().default(false),
});

export const GetConfigurationOutputSchema = StrapiDocumentSchema({
  banner: BannerSchema.nullish(),
  information: InformationSchema.nullish(),
  bannerKeyNumbers: BannerKeyNumbersSchema.nullish(),
});

const normalizeOfferType: Record<string, string> = {
  MFA: 'mfa',
  TGVMAX: 'tmj',
  TMS: 'tms',
};

export type GetConfigurationOutput = z.infer<
  typeof GetConfigurationOutputSchema
>;

export const getConfiguration = async () => {
  const response = await client.get<GetConfigurationOutput>(
    `/${normalizeOfferType[ConfigCommon.offerType as keyof typeof normalizeOfferType]}-configuration?populate=deep`,
  );

  if (response.data) {
    response.data = GetConfigurationOutputSchema.parse(response.data);
  }

  return response;
};
