import { useCallback } from 'react';

import { useDidomiStore } from './useDidomiStore';

export const useDidomi = () => {
  const { consentedVendors } = useDidomiStore();

  const isConsentendVendor = useCallback(
    (vendor: string) => !!consentedVendors?.includes(`c:${vendor}`),
    [consentedVendors],
  );

  const showPreferencesModal = useCallback(() => {
    window.Didomi.preferences.show();
  }, []);

  return { consentedVendors, isConsentendVendor, showPreferencesModal };
};
