import { encryptStorage } from '../index';

export class InformationPopInService {
  static readonly LAST_SEEN_INFO_MODAL_VERSION_KEY = 'show-info-modal';

  public static updateInformationPopInLastSeenVersion(version: string): void {
    const encryptedInformation = encryptStorage.encryptMD5(version);
    localStorage.setItem(
      InformationPopInService.LAST_SEEN_INFO_MODAL_VERSION_KEY,
      encryptedInformation,
    );
  }

  public static shouldShowInformationPopIn(
    currentInfoModalInfo: string,
  ): boolean {
    const lastSeenInformationPopInVersion = localStorage.getItem(
      InformationPopInService.LAST_SEEN_INFO_MODAL_VERSION_KEY,
    );
    const encryptedPopInInformation =
      encryptStorage.encryptMD5(currentInfoModalInfo);
    return lastSeenInformationPopInVersion !== encryptedPopInInformation;
  }
}
