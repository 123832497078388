import type { Theme } from '@mui/material';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useSignUpPopup } from '@sticky/auth';
import { ActionButton } from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t, Trans } from '@sticky/i18n';

import { AuthenticationLoginButton } from '../../..';
import { Routes } from '../../../routes/routes';
import { ModalBox } from '../modal-box';

const useStyles = makeStyles()((theme: Theme) => ({
  message: {
    marginBottom: theme.spacing(2),
  },
}));

interface AccountConnectModalProps {
  open: boolean;
  onclose: () => void;
}

export const AccountConnectModal = ({
  open,
  onclose,
}: AccountConnectModalProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));
  const { signUpPopup } = useSignUpPopup();

  const actionButtons = [
    getFeatures().auth.midSignUp ? (
      <ActionButton
        onClick={() => {
          signUpPopup();
        }}
        key={0}
      >
        {t('auth.modals.connect.create-button')}
      </ActionButton>
    ) : (
      <ActionButton href={Routes.getPathById('customer/signup')} key={1}>
        {t('auth.modals.connect.create-button')}
      </ActionButton>
    ),
    <AuthenticationLoginButton logAfterQuote variant="outlined" key={2}>
      {t('auth.modals.connect.connect-button')}
    </AuthenticationLoginButton>,
  ];

  return (
    <ModalBox
      open={open}
      onClose={onclose}
      width={isDesktop ? '30rem' : '24rem'}
      title={t('auth.title')}
      closeButton="header"
      isDarkHeader
      alignTitleCenter
      colorCloseButton={theme.app.colors.white}
      actions={actionButtons}
      actionButtonsFullWidth
    >
      <>
        <Typography className={classes.message}>
          <Trans
            i18nKey="auth.modals.connect.content"
            values={{ authTitle: t('auth.title') }}
          />
        </Typography>
      </>
    </ModalBox>
  );
};
