import { initReactI18next } from 'react-i18next';
import { fr } from 'date-fns/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import i18n from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';

import { resources } from './locales';

export const setup = () => {
  // Initialisation de la lib i18next
  i18n.use(initReactI18next).init({
    resources: {
      fr: resources,
    },
    defaultNS: 'app',
    fallbackNS: 'common',
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [
        'br',
        'strong',
        'i',
        'b',
        'u',
        'p',
        'ul',
        'ol',
        'li',
      ],
    },
  });

  // Traduction des messages d'erreur zod
  z.setErrorMap(zodI18nMap);

  // Choix de la locale par défaut pour date-fns
  setDefaultOptions({ locale: fr });
};
