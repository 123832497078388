import CheckIcon from '@mui/icons-material/Check';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { tss } from 'tss-react/mui';

import { Panel, PanelContent } from '@sticky/components';
import { Trans } from '@sticky/i18n';

import { PriceCard } from '../../components';

import ChooseButton from './choose-button';
import type { Offer } from './types';

const useStyles = tss
  .withNestedSelectors<'dark'>()
  .create(({ classes, theme }) => ({
    root: {
      paddingTop: theme.spacing(2),
    },
    isBigger: {
      [theme.breakpoints.up('breakPointDesktop')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(4),
      },
    },
    dark: {
      backgroundColor: theme.app.colors.textPrimary,
      color: theme.palette.common.white,

      [theme.breakpoints.up('breakPointDesktop')]: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        marginTop: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        alignSelf: 'start',
      },
    },
    title: {
      maxWidth: '23rem',
      marginLeft: 'auto',
      marginRight: 'auto',

      [theme.breakpoints.up('breakPointDesktop')]: {
        marginBottom: theme.spacing(3),
      },
    },
    // offer: {
    //   paddingTop: '2rem',
    //   paddingBottom: '1.3rem',
    // },
    // offerDescription: {
    //   display: 'flex',
    //   alignItems: 'start',
    //   fontSize: '1.125rem',
    // },
    // paragraph: {
    //   marginTop: '0.625rem',
    // },
    icon: {
      marginRight: theme.spacing(1),
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.primary.main,

      [`.${classes.dark} &`]: { color: theme.palette.common.white },
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      padding: 0,
      margin: `${theme.spacing(4)} auto`,
    },
    item: {
      display: 'flex',
    },
    // hideOnDesktop: {
    //   [breakpoints.up('breakPointDesktop')]: {
    //     display: 'none',
    //   },
    // },
  }));

type OfferProps = {
  offer: Offer;
  withPriceCard?: boolean;
};

const OfferElement = ({ offer, withPriceCard = false }: OfferProps) => {
  const { chooseButton, descriptions, isDark, isBigger, title } = offer;
  const { cx, classes } = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));

  return (
    <Panel
      className={cx(
        classes.root,
        isDark && classes.dark,
        isBigger && classes.isBigger,
      )}
    >
      <PanelContent>
        <Typography
          className={classes.title}
          component="h3"
          variant="h1"
          align="center"
        >
          {title}
        </Typography>

        <ul className={classes.list}>
          {descriptions.map(description => (
            <li key={description} className={classes.item}>
              <CheckIcon className={classes.icon} />
              <Typography variant="body2">
                <Trans components={[<b key={0} />, <sup key={1} />]}>
                  {description}
                </Trans>
              </Typography>
            </li>
          ))}
        </ul>

        {!isDesktop && chooseButton && (
          <ChooseButton
            product={chooseButton.product}
            target={chooseButton.target}
          />
        )}

        {!isDesktop && withPriceCard && <PriceCard />}
      </PanelContent>
    </Panel>
  );
};

export default OfferElement;
