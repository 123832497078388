import { auth } from './auth';
import { banners } from './banners';
import { brands } from './brands';
import { customer } from './customer';
import { enrichment } from './enrichment';
import { enrollment } from './enrollment';
import { freePlaces } from './free-places';
import { idtgvmax2 } from './idtgvmax2';
import { migration } from './migration';
import { ouigo } from './ouigo';
import { popIn } from './pop-in';
import { reservation } from './reservation';
import { strapi } from './strapi';
import { subscription } from './subscription';
import { terms } from './terms';
import { tracker } from './tracker';

export const metaFeatures = {
  auth,
  banners,
  brands,
  customer,
  enrichment,
  enrollment,
  freePlaces,
  idtgvmax2,
  migration,
  ouigo,
  popIn,
  reservation,
  strapi,
  subscription,
  terms,
  tracker,
};
