import type { ReactNode } from 'react';
import { Button, CircularProgress } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(({ theme }) => ({
  root: {
    borderRadius: '1.5rem',
    minHeight: '3rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    width: '100%',
    minWidth: '10rem',

    '&.MuiButton-outlined': {
      backgroundColor: theme.app.colors.white,
      borderColor: theme.app.colors.primary,

      '&.Mui-disabled': {
        backgroundColor: theme.app.colors.white,
        borderColor: theme.app.colors.secondary,
      },

      '&:hover': {
        backgroundColor: theme.app.colors.primaryLight,
      },
    },

    [theme.breakpoints.up('breakPointDesktop')]: {
      width: 'auto',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      minWidth: '13.5rem',
    },
  },
  buttonProgress: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(1.5),
  },
}));

export type ActionButtonProps = ButtonProps & {
  loading?: boolean;
  loadingChildren?: ReactNode;
  target?: '_blank' | '_self' | '_parent' | '_top';
};

export const ActionButton = ({
  className,
  loading,
  loadingChildren,
  disabled,
  children,
  variant = 'contained',
  color = 'primary',
  ...rest
}: ActionButtonProps) => {
  const { classes, cx } = useStyles();

  return (
    <Button
      className={cx(classes.root, className)}
      disabled={loading || disabled}
      variant={variant}
      color={color}
      {...rest}
    >
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      {loading && loadingChildren ? loadingChildren : children}
    </Button>
  );
};
