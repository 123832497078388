import { useMemo } from 'react';
import { Alert, Chip, CircularProgress, Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { formatToDDMMYYYY } from '@sticky/helpers';
import { t } from '@sticky/i18n';

import {
  CardNumber,
  CardStatus,
  customerSelectors,
  parseYYYYMMDD,
  readSubscription,
  SubscriptionStatus,
} from '../../..';
import { ModalBox } from '../../../components/modal/modal-box';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import type { ICard } from '../../customer/models/customer';
import { ICardContractStatus } from '../../customer/models/customer';
import { getPreferences, resetReservationState } from '../../reservation';
import { clearEquivalentStations } from '../../reservation/store/equivalent-stations-slice';
import { useRoute } from '../../router/useRoute';
import { subscriptionSelectors } from '../selectors';
import {
  clearSubscription,
  rememberSubscriptionSelectedCard,
} from '../store/subcription-slice';

// Styling
const useStyles = tss.create(({ theme }) => {
  const {
    breakpoints,
    app: { colors },
  } = theme;

  return {
    alert: {
      marginTop: theme.spacing(3),
    },
    loading: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
    },
    row: {
      display: 'grid',
      gridTemplateAreas: `"cardDetails"
                            "chooseButton"`,
      gridTemplateColumns: 'auto',
      alignContent: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${colors.warmGray1}`,
      gap: theme.spacing(1),

      '&:last-child': {
        borderBottom: 'none',
      },
      [breakpoints.up('breakPointDesktop')]: {
        gridTemplateAreas: `"cardDetails chooseButton"`,
        gridTemplateColumns: '1fr auto',
      },
    },
    cardDetails: {
      display: 'grid',
      gridArea: 'cardDetails',
      gridTemplateAreas: `"cardNumber"
                          "validityStartDate"`,
    },
    cardNumber: {
      gridArea: 'cardNumber',
      display: 'flex',
      alignItems: 'center',

      '& .MuiChip-root': {
        marginLeft: theme.spacing(1),
      },
    },
    validityStartDate: {
      gridArea: 'validityStartDate',
      alignContent: 'center',
      alignSelf: 'center',
    },
    chooseButton: {
      gridArea: 'chooseButton',
      alignSelf: 'center',
      justifySelf: 'center',
      width: '100%',
    },
  };
});

const useContractStatusStyles = tss.create(() => ({
  success: {
    color: '#388e3c',
    backgroundColor: '#d9eeda',
    borderColor: '#4caf50',
    border: '1px solid',
  },
  warning: {
    color: '#b74806',
    backgroundColor: '#feece2',
    borderColor: '#e9c8b4',
    border: '1px solid',
  },
}));

type ContractStatusProps = {
  status: CardStatus;
};

const ContractStatus = ({ status }: ContractStatusProps) => {
  const { classes } = useContractStatusStyles();

  let severityClass = '';
  let label = SubscriptionStatus.TERMINATED;
  if (
    [
      CardStatus.SUSPENSION_IMPAYE,
      CardStatus.Suspendu,
      CardStatus.SUSPENSION_CLIENT,
    ].includes(status)
  ) {
    severityClass = classes.warning;
    label = SubscriptionStatus.SUSPENDED_FR;
  } else if ([CardStatus.Actif, CardStatus.VALIDE].includes(status)) {
    severityClass = classes.success;
    label = SubscriptionStatus.IN_PROGRESS;
  }

  if (ICardContractStatus[status]) {
    return <Chip label={label} size="small" className={severityClass}></Chip>;
  }

  return null;
};

type ModalChooseSubscriptionProps = {
  open: boolean;
  onClose: () => void;
};

export const ModalChooseSubscription = ({
  open,
  onClose,
}: ModalChooseSubscriptionProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const route = useRoute();

  // Get store states
  const cards = useAppSelector(customerSelectors.getAllowedCards);
  const isFirstAccess = useAppSelector(subscriptionSelectors.isFirstAccess);
  const subscriptionNumber = useAppSelector(
    subscriptionSelectors.getSubscriptionNumber,
  );

  // Bind close button
  const closeModal = (card?: ICard) => {
    // Store choose card or set default on 'close'
    dispatch(
      rememberSubscriptionSelectedCard(card?.cardNumber ?? subscriptionNumber),
    );
    onClose();
  };

  // Select another card
  const onSelectCard = (card: ICard) => {
    dispatch(clearSubscription());
    dispatch(resetReservationState());
    dispatch(readSubscription({ cardNumber: card.cardNumber }));
    dispatch(clearEquivalentStations());
    dispatch(getPreferences(card.cardNumber));
    closeModal(card);
  };

  const sortedCards = useMemo<ICard[] | undefined>(
    () =>
      cards?.sort(({ contractStatus }) => {
        if ([CardStatus.Actif, CardStatus.VALIDE].includes(contractStatus)) {
          return 1;
        } else if (
          [
            CardStatus.SUSPENSION_CLIENT,
            CardStatus.SUSPENSION_IMPAYE,
            CardStatus.Suspendu,
          ].includes(contractStatus)
        ) {
          return 0;
        } else {
          return -1;
        }
      }),
    [cards],
  );

  // Disable other card subscription when in booking tunnel
  const path = route?.path;
  let isBookingInProgress = false;
  if (path && typeof path === 'string')
    isBookingInProgress =
      path.startsWith('/mes-voyages/reservation') ||
      path.startsWith('/mes-voyages/echange');

  // Modal element
  return (
    <ModalBox
      open={open || isFirstAccess}
      onClose={closeModal}
      title={t('subscription.cards.subscription.modalSelectTitle')}
    >
      {sortedCards ? (
        sortedCards.map(card => (
          <div key={card.cardNumber} className={classes.row}>
            <div className={classes.cardDetails}>
              <div className={classes.cardNumber}>
                <CardNumber value={card.cardNumber} />
                <ContractStatus status={card.contractStatus} />
              </div>
              <Typography className={classes.validityStartDate} variant="body1">
                {t('subscription.cards.subscription.validityDates', {
                  fromDate: formatToDDMMYYYY(
                    parseYYYYMMDD(card.validityStartDate),
                  ),
                  toDate: formatToDDMMYYYY(parseYYYYMMDD(card.validityEndDate)),
                })}
              </Typography>
            </div>

            <div className={classes.chooseButton}>
              {subscriptionNumber !== card.cardNumber ? (
                <ActionButton
                  variant={isBookingInProgress ? 'contained' : 'outlined'}
                  size="small"
                  onClick={() => onSelectCard(card)}
                  disabled={isBookingInProgress}
                >
                  {t('subscription.cards.actions.select')}
                </ActionButton>
              ) : (
                <ActionButton
                  size="small"
                  onClick={() => onSelectCard(card)}
                  disabled={isBookingInProgress}
                >
                  {t('subscription.cards.actions.active')}
                </ActionButton>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className={classes.loading}>
          <CircularProgress size={60} />
        </div>
      )}

      {isBookingInProgress && (
        <Alert className={classes.alert} severity="error">
          {t('subscription.cards.subscription.alerts.inBooking')}
        </Alert>
      )}
    </ModalBox>
  );
};
