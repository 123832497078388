import type { ChangeEvent, FocusEvent, RefObject } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import type { AutocompleteProps, Theme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';

import { useDebouncedCallback } from '@sticky/helpers';

const useStyles = makeStyles()(({ app: { colors } }: Theme) => ({
  error: {
    '& fieldset': {
      borderColor: colors.errorDark,
    },
  },
}));

export interface IInputAutocompleteAsyncSearchOptions {
  delay: number;
  minChars: number;
  minCharsLabel: string;
  searchFunction: (inputValue: string) => void;
}

interface IInputAutocomplete<TOption>
  extends Pick<
    AutocompleteProps<TOption, boolean, boolean, boolean>,
    | 'clearText'
    | 'closeText'
    | 'filterOptions'
    | 'getOptionLabel'
    | 'loading'
    | 'loadingText'
    | 'openText'
    | 'autoComplete'
  > {
  defaultValue?: TOption | undefined;
  error?: boolean;
  ariaLabel?: string;
  helperText?: string;
  id?: string;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  onChange(event: ChangeEvent<unknown>, value?: TOption | null): void;
  onOpen(event: ChangeEvent<unknown | HTMLInputElement>): void;
  options: TOption[];
  value?: TOption | null;
  inputRef?: RefObject<HTMLInputElement>;
  errorId?: string;
  asyncSearchOptions?: IInputAutocompleteAsyncSearchOptions;
}

export function InputAutocomplete<TOption = string>(
  props: IInputAutocomplete<TOption>,
): JSX.Element {
  const {
    id,
    options,
    ariaLabel,
    onChange,
    onOpen,
    filterOptions,
    getOptionLabel,
    onBlur,
    loading,
    loadingText,
    defaultValue,
    value = null,
    inputRef,
    errorId,
    clearText,
    openText,
    closeText,
    asyncSearchOptions,
    autoComplete,
  } = props;

  const { classes } = useStyles();

  const inputText = useRef('');

  const search = useDebouncedCallback(
    (value: string) => asyncSearchOptions?.searchFunction(value),
    asyncSearchOptions?.delay,
  );

  const onLoading = useCallback(
    async (event: ChangeEvent<unknown | HTMLInputElement>) => {
      onOpen?.(event);
    },
    [onOpen],
  );

  const onAutocompleteChange = useCallback(
    (event: ChangeEvent<unknown>, value?: TOption | null) => {
      onChange?.(event, value);
    },
    [onChange],
  );

  const onInputChange = useCallback(
    (event: ChangeEvent<unknown>, value: string) => {
      inputText.current = value;
      if (asyncSearchOptions && value.length >= asyncSearchOptions.minChars)
        search(value);
    },
    [asyncSearchOptions, search],
  );

  const noOptionsText = useMemo<string | undefined>(() => {
    if (
      asyncSearchOptions &&
      inputText.current.length < asyncSearchOptions.minChars
    ) {
      return asyncSearchOptions.minCharsLabel;
    }
  }, [asyncSearchOptions]);

  return (
    <>
      <Autocomplete<TOption>
        id={id}
        className={props.helperText ? classes.error : undefined}
        clearOnEscape
        fullWidth
        autoHighlight={true}
        autoSelect={false}
        options={options}
        clearText={clearText}
        openText={openText}
        closeText={closeText}
        onChange={onAutocompleteChange}
        onOpen={onLoading}
        loading={loading}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        value={value}
        onHighlightChange={(e, _option) => {
          if (_option === null) return;
          document.querySelectorAll(`#${id}-popup > li`).forEach(option => {
            const label = getOptionLabel
              ? getOptionLabel(_option)
              : String(_option);
            option.innerHTML === label
              ? option.setAttribute('aria-selected', 'true')
              : option.setAttribute('aria-selected', 'false');
          });
        }}
        renderInput={_params => (
          <TextField
            {..._params}
            variant="outlined"
            onChange={onLoading}
            inputRef={inputRef}
            inputProps={{
              ..._params.inputProps,
              'aria-invalid': !!props.error,
              'aria-labelledby': ariaLabel,
              'aria-required': true,
            }}
            required
          />
        )}
        defaultValue={defaultValue}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        onBlur={onBlur}
        onInputChange={onInputChange}
        autoComplete={autoComplete}
      />
      {props.helperText && (
        <FormHelperText id={errorId} error={props.error}>
          {props.helperText}
        </FormHelperText>
      )}
    </>
  );
}
