import { useEffect, useState } from 'react';
import type { Theme } from '@mui/material';
import { Container, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { getEnv, getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { useRoute } from '../../features/router/useRoute';
import { subscriptionSelectors } from '../../features/subscription/selectors';
import { useAppSelector } from '../../hooks';
import { LAYOUT } from '../../routes/layout';
import { Routes } from '../../routes/routes';
import { RouteLink } from '../router/route-link';

import HomeBanner from './banners/home-banner';
import MigrationBanner from './banners/migration-banner';

// Setup styles
// TODO: improve that, it's not clean
const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    typography: { pxToRem },
    app: { colors },
  } = theme;

  // Header fixed height
  const headerFixedHeightSm = pxToRem(64);
  const headerFixedHeightMd = pxToRem(140);

  // Icon size
  const imgHeightSm = pxToRem(25);
  const imgHeightMd = pxToRem(75);

  return {
    root: {
      color: colors.textSecondary,
      backgroundColor: colors.headerBackground,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [breakpoints.up('breakPointDesktop')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: '60% 40%',
      gridTemplateRows: '100% 100%',
      gridTemplateAreas: `"brandContainer companyContainer"`,
      height: headerFixedHeightSm,
      [breakpoints.up('breakPointDesktop')]: {
        height: headerFixedHeightMd,
      },
    },
    brandContainer: {
      gridArea: 'brandContainer',
      display: 'flex',
      alignItems: 'center',
    },
    companyContainer: {
      gridArea: 'companyContainer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    logo: {
      height: imgHeightSm,
      [breakpoints.up('breakPointDesktop')]: {
        height: imgHeightMd,
      },
    },
    spacer: {
      height: 0,
      '& div': {
        background: colors.warmGray2,
        position: 'relative',
        zIndex: -1,
        height: 193,
      },
    },
  };
});

interface HeaderProps {
  spacerClassName?: string;
}

export const Header = ({ spacerClassName }: HeaderProps) => {
  const {
    app: {
      assets: { companyLogo, brandLogo },
    },
  } = useTheme();
  const { classes } = useStyles();
  const route = useRoute();

  // Get the brand logo from the subscription type
  const productSelect = useAppSelector(
    subscriptionSelectors.getSubscriptionProductSelect,
  );
  const [brandLogoUrl, setBrandLogo] = useState(brandLogo.default);
  const [brandContext, setBrandContext] = useState(
    getEnv('VITE_PRODUCT_SELECT_DEFAULT'),
  );
  const [showHeader, setShowHeader] = useState(false);

  // Change logo if env has changed
  useEffect(() => {
    setBrandLogo(brandLogo[productSelect ?? 'default'] ?? brandLogo.default);
    setBrandContext(productSelect ?? getEnv('VITE_PRODUCT_SELECT_DEFAULT'));
  }, [brandLogo, productSelect]);

  // Display or hide header depending on LAYOUT
  useEffect(() => {
    setShowHeader(
      [LAYOUT.HOME, LAYOUT.CUSTOMER, LAYOUT.TECHNICAL].includes(
        route?.layout ?? LAYOUT.EMPTY,
      ),
    );
  }, [route]);

  // Hide header if layout does not need it
  if (!showHeader) {
    return <></>;
  }

  return (
    <>
      <div className={classes.root}>
        <Container>
          <div className={classes.gridContainer}>
            <img
              alt={t('home.myAnnualPlan')}
              src={brandLogo['seo_og']}
              hidden
            />

            <RouteLink
              to={Routes.getPathById('home')}
              title={t('actions.go-home-page', { context: brandContext })}
              className={classes.brandContainer}
            >
              <img
                alt={t('home.subName', { context: brandContext })}
                src={brandLogoUrl}
                className={classes.logo}
              />
            </RouteLink>

            {!getFeatures().ouigo.enabled && (
              <div className={classes.companyContainer}>
                <img
                  alt={t('brand.brandName')}
                  src={companyLogo}
                  className={classes.logo}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
      <HomeBanner />
      <MigrationBanner />
      {/* Don't remove id attribute below, we use it in reservation-step component */}
      {route?.layout !== LAYOUT.TECHNICAL && (
        <div
          id="header-spacer"
          className={
            classes.spacer + (spacerClassName ? ` ${spacerClassName}` : '')
          }
        >
          <div />
        </div>
      )}
    </>
  );
};
