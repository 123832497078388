import axios from 'axios';

import { getEnv } from '@sticky/config';

const BASE_URL = getEnv('VITE_STRAPI_URL');
const STRAPI_ACCESS_KEY = getEnv('VITE_STRAPI_ACCESS_KEY');

export const client = axios.create({
  baseURL: BASE_URL,
  timeout: Number.parseInt(getEnv('VITE_SYG_TIMEOUT', '45000')),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: STRAPI_ACCESS_KEY && `Bearer ${STRAPI_ACCESS_KEY}`,
  },
});
