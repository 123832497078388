import { lazy } from 'react';

import { t } from '@sticky/i18n';

import { LAYOUT } from '../../../layout';
import type { IRoute } from '../../../routes';

const routes: IRoute[] = [
  {
    id: 'customer/my-coordinates/update-photo-documents',
    path: '/mes-coordonnees/modification-photo/documents-a-fournir',
    title: t('customer.coordinates.update-photo.docs.pageTitle'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import(
          '../../../../pages/customer/account/update-photo/customer-account-update-photo-documents'
        ),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-photo',
    path: '/mes-coordonnees/modification-photo/mes-documents',
    title: t('customer.coordinates.update-photo.docs.pageTitle'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () => import('../../../../pages/customer/account/update-photo'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-photo-waiting-id360',
    path: '/mes-coordonnees/modification-photo-chargement',
    title: t('customer.coordinates.update-photo.waiting.pageTitle'),
    layout: LAYOUT.EMPTY,
    element: lazy(
      () => import('../../../../pages/customer/account/update-photo/pending'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-photo-success',
    path: '/mes-coordonnees/modification-photo-succes',
    title: t('customer.coordinates.update-photo.success.pageTitle'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () => import('../../../../pages/customer/account/update-photo/success'),
    ),
    private: true,
  },
];

export default routes;
