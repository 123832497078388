import React from 'react';
import { Field } from 'react-final-form';
import {
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import type { RadiosProps } from 'mui-rff';
import { makeStyles } from 'tss-react/mui';

import { RadioButtonGroup } from '../form/radio-button-group';

import type { IValidator } from './form-validators';

interface FormInputRadioButtonProps extends RadiosProps {
  readOnly?: boolean;
  validate?: IValidator;
  formatOnBlur?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => {
  const {
    app: { colors },
  } = theme;
  return {
    root: {
      '& .MuiFormLabel-asterisk': {
        color: colors.textContrasted,
        fontWeight: 700,
      },
    },
    label: {
      paddingBottom: theme.spacing(1),
    },
    asterisk: {
      fontWeight: 700,
      color: colors.textContrasted,
    },
  };
});

export const FormInputRadioButton = (
  props: FormInputRadioButtonProps,
): JSX.Element => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));
  const { label, name, data, validate, ...rest } = props;

  // Display required sign
  const requiredAsterisk =
    props.required && !props.readOnly ? (
      <span className={classes.asterisk}>* </span>
    ) : (
      ''
    );

  // Build component
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const error = meta?.touched
          ? meta.error || meta.submitError
          : undefined;
        // Display value if readOnly
        if (props.readOnly) {
          return (
            <Typography className={`form-field ${classes.label}`} role="none">
              {input.value} {requiredAsterisk}
            </Typography>
          );
        }

        // Display editable field
        return (
          <div className={classes.root}>
            {!isDesktop && (
              <Typography
                variant="subtitle2"
                className={`form-field ${classes.label}`}
                role="none"
              >
                {props?.label}
              </Typography>
            )}

            <RadioButtonGroup
              {...rest}
              groupName={name}
              className="field-input"
              onChange={input.onChange}
              value={input.value}
              options={data as { label: string; value: string }[]}
            ></RadioButtonGroup>
            <FormHelperText error={!!error}>{error}</FormHelperText>
          </div>
        );
      }}
    </Field>
  );
};
