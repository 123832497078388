import { useEffect } from 'react';
import type { Theme } from '@mui/material';
import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { parseISO } from 'date-fns';
import { makeStyles } from 'tss-react/mui';

import { getFeatures } from '@sticky/config';
import { t, Trans } from '@sticky/i18n';

import { isTMJ, TravelStation } from '../..';
import { customerSelectors } from '../../features/customer/selectors';
import type { Travel, TravelDetailsCache } from '../../features/reservation';
import {
  getTravelDetails,
  reservationSelectors,
} from '../../features/reservation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { formatToFullHumanReadableDate } from '../../utils/date';
import { TypoWithSkeleton } from '../typography/typography-skeleton';

import BarCode from './bar-code';
import SeatDetails from './seat-details';

function removeLeadingZero(value = ''): string {
  return value.replace(/^0+/, '');
}

const useStyles = makeStyles()(
  ({ breakpoints, app: { colors }, typography: { pxToRem } }: Theme) => ({
    travel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [breakpoints.up('breakPointLargeDesktop')]: {
        display: 'grid',
        gridTemplate: `
        "title               segmentDetails barcode"
        "journey             segmentDetails barcode"
        "marketingCarrierRef segmentDetails barcode"
        "travelType          segmentDetails barcode"
        "travelPrice         segmentDetails barcode"
        ".                   segmentDetails barcode"
        "actions             actions        actions"
        / auto 16rem min-content`,
        columnGap: '1.2rem',
        alignItems: 'center',
        marginRight: '1rem',
      },
      marginTop: '1rem',
    },
    title: {
      gridArea: 'title',
      color: colors.textContrasted,
      fontSize: pxToRem(14),
      fontWeight: 900,
      alignSelf: 'center',
      marginBottom: pxToRem(20),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
      [breakpoints.up('breakPointLargeDesktop')]: {
        marginTop: pxToRem(14),
        fontSize: pxToRem(20),
      },
    },
    journey: {
      gridArea: 'journey',
      alignSelf: 'start',
      marginBottom: pxToRem(15),
      [breakpoints.up('breakPointLargeDesktop')]: {
        marginBottom: pxToRem(20),
      },
    },
    marketingCarrierRef: {
      gridArea: 'marketingCarrierRef',
      alignSelf: 'start',
      fontSize: pxToRem(12),
      marginBottom: pxToRem(15),
      [breakpoints.up('breakPointLargeDesktop')]: {
        fontSize: pxToRem(16),
        marginBottom: 0,
      },
    },
    travelType: {
      gridArea: 'travelType',
      alignSelf: 'start',
      fontSize: pxToRem(12),
      marginBottom: pxToRem(15),
      [breakpoints.up('breakPointLargeDesktop')]: {
        fontSize: pxToRem(16),
        marginBottom: 0,
      },
    },
    travelPrice: {
      gridArea: 'travelPrice',
      alignSelf: 'start',
      fontSize: pxToRem(12),
      marginBottom: pxToRem(15),
      [breakpoints.up('breakPointLargeDesktop')]: {
        fontSize: pxToRem(16),
        marginBottom: 0,
      },
    },
    marketingCarrierRefValue: {
      fontWeight: 900,
    },
    segmentDetails: {
      gridArea: 'segmentDetails',
      width: '100%',
      display: 'grid',
      gridTemplate: `
        "trainNumber coachAndSeatNumber"
        "travelClass seat"
        "wheelChairPlacementChip wheelChairPlacementChip" 
        / 1fr 1fr
        `,
      gridTemplateRows: 'min-content min-content auto',
      padding: pxToRem(15),
      marginBottom: pxToRem(20),
      backgroundColor: colors.disabledBackground,
      borderRadius: pxToRem(8),
      [breakpoints.up('breakPointLargeDesktop')]: {
        height: '100%',
        minHeight: pxToRem(180),
        padding: `${pxToRem(20)} ${pxToRem(16)}`,
        gridTemplate: `
        "trainNumber"
        "travelClass"
        "segmentDetailsSpacer"
        "coachAndSeatNumber"
        "seat"
        "wheelChairPlacementChip"
        `,
        gridTemplateRows: 'min-content min-content auto min-content auto auto',
        borderRadius: pxToRem(16),
        marginBottom: 0,
      },
    },

    trainNumber: {
      gridArea: 'trainNumber',
      fontSize: pxToRem(12),
      fontWeight: 900,
      [breakpoints.up('breakPointLargeDesktop')]: {
        fontSize: pxToRem(16),
      },
    },
    travelClass: {
      gridArea: 'travelClass',
      fontSize: pxToRem(12),
      [breakpoints.up('breakPointLargeDesktop')]: {
        fontSize: pxToRem(16),
      },
    },
    coachAndSeatNumber: {
      gridArea: 'coachAndSeatNumber',
      fontSize: pxToRem(12),
      fontWeight: 900,
      [breakpoints.up('breakPointLargeDesktop')]: {
        fontSize: pxToRem(16),
      },
    },
    segmentDetailsSpacer: {
      gridArea: 'segmentDetailsSpacer',
    },
  }),
);

interface BookingDetailTravelContentProps {
  travel: Travel;
  hideBarcode?: boolean;
  hideSeatDetails?: boolean;
  actions?: JSX.Element;
  hideActions?: boolean;
}

const TrainName = ({
  travel,
  details,
}: {
  travel?: Travel;
  details?: TravelDetailsCache;
}) => {
  const { classes } = useStyles();

  const trainName = t(
    `travels.detailsTravel.transporationService.${
      details?.travelDetails?.transportationServiceOffer ?? 'default'
    }`,
  );

  return (
    <TypoWithSkeleton
      variant="body1"
      skelProps={{ width: '60%' }}
      isReady={!!travel}
      className={classes.trainNumber}
    >
      <Trans
        i18nKey="travels.detailsTravel.train"
        values={{
          trainName,
          trainNumber: travel?.trainNumber,
        }}
      />
    </TypoWithSkeleton>
  );
};

export const BookingDetailTravelContent = ({
  travel,
  hideBarcode = false,
  actions = undefined,
  hideActions = false,
  hideSeatDetails = false,
}: BookingDetailTravelContentProps): JSX.Element => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const isEnrichmentActivated = getFeatures().enrichment.enabled;

  const customerName = useAppSelector(customerSelectors.getCustomerLastName);
  const details = useAppSelector(state =>
    reservationSelectors.getDetailedTravel(state, travel),
  );

  useEffect(() => {
    if (!hideSeatDetails && customerName && !details)
      dispatch(
        getTravelDetails({
          customerName,
          departureDateTime: travel.departureDateTime,
          marketingCarrierRef: travel.marketingCarrierRef,
          orderId: travel.orderId,
          trainNumber: travel.trainNumber,
        }),
      );
  }, [dispatch, hideSeatDetails, customerName, details, travel]);

  const priceContent = details?.travelDetails?.amount ? (
    <Typography
      className={classes.travelPrice}
      component="span"
      variant="body1"
    >
      {details.travelDetails.amount.replace('EUR', '€')}
    </Typography>
  ) : (
    <Skeleton variant="text" width={60} />
  );
  return (
    <section className={classes.travel}>
      <Typography variant="subtitle2" component="h3" className={classes.title}>
        <time dateTime={travel.departureDateTime}>
          {formatToFullHumanReadableDate(parseISO(travel.departureDateTime))}
        </time>
      </Typography>
      <div className={classes.journey}>
        <TravelStation
          travel={{
            state: 'success',
            arrivalDate: parseISO(travel.arrivalDateTime),
            departureDate: parseISO(travel.departureDateTime),
            origin: travel.origin.label,
            destination: travel.destination.label,
            avantage: travel.avantage,
            id: travel.orderId,
          }}
          dateSelector={trav => trav.departureDate}
          stationSelector={trav => trav.origin.substring(0, 30)}
          showError
        />
        <TravelStation
          travel={{
            state: 'success',
            arrivalDate: parseISO(travel.arrivalDateTime),
            departureDate: parseISO(travel.departureDateTime),
            origin: travel.origin.label,
            destination: travel.destination.label,
            avantage: travel.avantage,
            id: travel.orderId,
          }}
          dateSelector={trav => trav.arrivalDate}
          stationSelector={trav => trav.destination.substring(0, 30)}
        />
      </div>
      <Typography
        className={classes.marketingCarrierRef}
        component="span"
        variant="body1"
      >
        <Trans
          i18nKey="travels.detailsTravel.marketingCarrierRef"
          values={{ marketingCarrierRef: travel.marketingCarrierRef }}
          components={[
            <span className={classes.marketingCarrierRefValue} key={0} />,
          ]}
        />
      </Typography>
      {isEnrichmentActivated && (
        <>
          <Typography
            className={classes.travelType}
            component="span"
            variant="body1"
          >
            {travel.avantage
              ? t('travels.detailsTravel.avantageRate', {
                  brand: isTMJ() ? 'JEUNE' : 'SENIOR',
                })
              : t('travels.detailsTravel.maxRate', {
                  brand: isTMJ() ? 'JEUNE' : 'SENIOR',
                })}
          </Typography>
          {travel.avantage && priceContent}
        </>
      )}
      <div className={classes.segmentDetails}>
        <TrainName travel={travel} details={details} />
        <Typography
          component="span"
          variant="body1"
          className={classes.coachAndSeatNumber}
        >
          {travel.coachNumber && travel.seatNumber ? (
            <Trans
              i18nKey="travels.detailsTravel.coachAndSeat"
              values={{
                coachNumber: removeLeadingZero(travel.coachNumber),
                seatNumber: removeLeadingZero(travel.seatNumber),
              }}
            />
          ) : (
            <Trans i18nKey="travels.detailsTravel.coachAndSeatNotGuaranteed" />
          )}
        </Typography>
        <Typography
          component="span"
          variant="body1"
          className={classes.travelClass}
        >
          <Trans
            i18nKey={`reservation.myReservations.travelClass.${travel.travelClass}`}
            components={[<sup key={0} />]}
          />
        </Typography>
        {!details?.detailsState.error &&
          !hideSeatDetails &&
          !(
            details?.detailsState?.fetched && !details?.travelDetails?.seat
          ) && <SeatDetails seat={details?.travelDetails?.seat} />}
        <div className={classes.segmentDetailsSpacer} />
      </div>
      {!hideBarcode && <BarCode travel={travel} details={details} />}
      {!hideActions && actions}
    </section>
  );
};
