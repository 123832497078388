import { useEffect, useState } from 'react';

import type { IRoute } from '../../routes/routes';
import { Routes } from '../../routes/routes';

export const useStickyRoutes = (): IRoute[] => {
  const [allRoutes, setAllRoutes] = useState<IRoute[]>(Routes.getAllRoutes());

  useEffect(() => {
    const onChange = () => {
      setAllRoutes(Routes.getAllRoutes());
    };

    Routes.subscribe(onChange);

    return () => {
      Routes.unSubscribe(onChange);
    };
  }, []);

  return allRoutes;
};
