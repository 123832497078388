import { ConfigCommon } from '@sticky/config';
import { StickyGatewayClient } from '@sticky/gateway-client';

import type { BlacklistControls } from '../models/blacklist-controls';

export class BlacklistControlService {
  static async getControls(): Promise<BlacklistControls> {
    return StickyGatewayClient.authClient()
      .post<BlacklistControls>('/public/customer/blacklist-control', {
        offerType: ConfigCommon.offerType,
      })
      .then(response => response.data);
  }

  /**
   * Throw only in case that API send something differant than 'OK'.
   * Success : If the API is not available (catch) or customer is not blacklisted (result === 'OK').
   *
   * @returns {Promise<boolean>} The control status.
   */
  static async throwIfRejected(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getControls()
        .then(response => {
          if (response.fraudControlResult === 'KO') {
            reject(new Error('FRAUD'));
            return;
          }
          if (response.blacklistControlResultTotal === 'KO') {
            reject(new Error('BLACKLISTED'));
            return;
          }
          resolve(true);
        })
        .catch(err => {
          reject(new Error(err));
        });
    });
  }
}
