import type { PropsWithChildren } from 'react';
import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { colors },
  } = theme;

  return {
    root: {
      marginBottom: theme.spacing(3),
      '& span': {
        color: colors.textContrasted,
        fontWeight: 700,
      },
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    },
  };
});

type FormMandatoryInfoProps = PropsWithChildren<{
  align?: TypographyProps['align'];
}>;

export const FormMandatoryInfo = ({
  children,
  align = 'left',
}: FormMandatoryInfoProps) => {
  const { classes } = useStyles();

  return (
    <Typography className={classes.root} align={align}>
      <span>* </span>
      {children || t('form.mandatoryFields')}
    </Typography>
  );
};
