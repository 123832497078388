import type { BoxProps } from '@mui/system';
import { Box } from '@mui/system';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(({ theme }) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up('breakPointDesktop')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export const Wrapper = ({ children, className, ...rest }: BoxProps) => {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(className, classes.root)} {...rest}>
      {children}
    </Box>
  );
};
