import { UAParser } from 'ua-parser-js';

import { t } from '@sticky/i18n';

import type { Field } from '../../dataDisplay/data-table';
import { DataTable } from '../../dataDisplay/data-table';
import { ModalBox } from '../modal-box';

type AppInfoProps = { onClose: () => void };

export const AppInfo = ({ onClose }: AppInfoProps) => {
  const parser = new UAParser();

  const fields: Field[] = Object.entries(parser.getResult())
    .map(([key, value]) => {
      let values;
      // map key-value object to html list
      const keyValues = Object.entries(value ?? {})
        .filter(([, v]) => !!v)
        .map(([k, v]) => `${k}: ${v}`);

      if (keyValues.length === 0) return undefined;

      if (typeof value === 'object') {
        values = (
          <ul>
            {keyValues.map(v => (
              <li key={v}>{v}</li>
            ))}
          </ul>
        );
      }

      return [
        t(`modals.appInfo.content.${key}`, { defaultValue: key }),
        values ?? value,
      ];
    })
    .filter(maintenanceCategory => !!maintenanceCategory) as Field[];

  return (
    <ModalBox
      open={true}
      closeButton="header"
      onClose={onClose}
      title={t('modals.appInfo.title')}
    >
      <DataTable fields={fields} isReady={true} />
    </ModalBox>
  );
};
