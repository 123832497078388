import type { TextFieldProps } from 'mui-rff';

import type { IFormInputFieldType } from '.';
import type { IValidator } from './form-validators';
import { FormInputText } from './text';

interface FormInputSirenProps extends TextFieldProps {
  validate?: IValidator;
  inputtype?: IFormInputFieldType;
}

const FormInputSiren = (props: FormInputSirenProps) => {
  const { validate, inputtype, required, ...attrs } = props;

  const formatToMachineReadable = (siren: string): string =>
    siren.replace(/[^0-9]/g, '');

  const formatToHumanReadable = (siren: string): string => {
    // Remove non-numeric characters
    const numericInput = formatToMachineReadable(siren);

    // Insert spaces every 3 characters
    return numericInput.replace(/(.{3})(?=.)/g, '$1 ');
  };

  // Parse value stored in form
  const onInputParse = (value: string): string =>
    value ? formatToMachineReadable(value) : value;

  // Format value to display
  const onInputFormat = (value: string): string =>
    value ? formatToHumanReadable(value) : value;

  const inputProps = {
    inputProps: {
      'aria-labelledby': props.name + '-label',
    },
  };

  return (
    <FormInputText
      {...attrs}
      validate={validate}
      format={onInputFormat}
      parse={onInputParse}
      formatOnBlur={false}
      required={required}
      {...inputProps}
    />
  );
};

export default FormInputSiren;
