/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
import type { RefObject } from 'react';
import scrollIntoView from 'scroll-into-view';

// Scroll top offset to apply
const TOP_OFFSET = 150;

// Remember last scroll to avoid glitches
let lastScrollTime = Date.now();
let lastScrollTimeout = 0;

export const scrollToId = (id: string, click = false): number => {
  // Avoid multiple scroll calls
  if (Date.now() - 1000 < lastScrollTime) {
    return 0;
  }

  // Reset lastScroll
  lastScrollTime = Date.now();

  // Scroll to element, click on it if necessary and focus it after animation
  return requestAnimationFrame(() => {
    const elem = document.getElementById(id);
    if (elem) {
      scrollIntoView(elem, {
        align: { top: 0, topOffset: TOP_OFFSET },
        ease: val => 1 - (1 - val) * (1 - val),
        time: 200,
      });
      if (lastScrollTimeout) {
        clearTimeout(lastScrollTimeout);
      }
      lastScrollTimeout = window.setTimeout(function () {
        elem.setAttribute('tabindex', '0');
        elem.focus({ preventScroll: true });
      }, 100);
      click && elem.click();
      return () => {
        clearTimeout(lastScrollTimeout);
      };
    }
  });
};

const scrollTo = (element: HTMLElement, isInsideDiv: boolean) => {
  scrollIntoView(element, {
    align: { top: 0, topOffset: TOP_OFFSET },
    isScrollable: (
      target: HTMLElement,
      defaultIsScrollable: (target: HTMLElement) => any,
    ) =>
      isInsideDiv
        ? target.className?.indexOf('scrollable')
        : defaultIsScrollable(target),
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const scrollToErrorDecorator =
  (formRef: RefObject<any>, isInsideDiv = false) =>
  (form: any) => {
    const originalSubmit = form.submit;
    form.submit = () => {
      requestAnimationFrame(() => {
        const errorField = formRef.current?.querySelector(
          '[aria-invalid="true"]',
        );
        if (errorField) {
          scrollTo(errorField, isInsideDiv);
          errorField.focus({ preventScroll: true });
        }
      });
      return originalSubmit.call(form);
    };
    return () => void 0;
  };
