import { lazy } from 'react';

import { t } from '@sticky/i18n';

import { LAYOUT } from '../layout';
import type { IRoute } from '../routes';

const routes: IRoute[] = [
  {
    id: 'enrollment/complete',
    path: '/souscription/validation',
    title: t('enrollment.validation.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(() => import('../../pages/enrollment/validation/validation')),
    private: true,
  },
];

export default routes;
