import { t } from '@sticky/i18n';

import { DidomiPurposeMonitoring } from '../purposes';
import type { CustomVendor } from '../types';

export const DidomiVendorDatadog: CustomVendor = {
  id: 'datadog',
  name: t('didomi.vendors.datadog.name'),
  purposeIds: [DidomiPurposeMonitoring.id],
  policyUrl: t('didomi.vendors.datadog.policyUrl'),
};
