import type { PropsWithChildren } from 'react';
import { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import type { Theme } from '@mui/material/styles';

import { Authentication } from '@sticky/auth';
import { StickyThemeProvider } from '@sticky/components';
import { getEnv, getFeatures } from '@sticky/config';
import { HEADER_CORRELATION_ID } from '@sticky/gateway-client';
import { i18n } from '@sticky/i18n';
import { Logger } from '@sticky/logger';

import { LazyLoader } from '../components';
import { BrandProvider } from '../features/brand/brand-provider';
import { BrandsService } from '../features/brand/brands-service';
import {
  getCustomerWithSubscription,
  setCustomerAuth,
} from '../features/customer/store/customer-slice';
import { ErrorBoundary } from '../features/error-boundary';
import { InformationPopInService } from '../features/information-pop-in/information-pop-in-service';
import { PwaProvider } from '../features/pwa';
import { BrandRouter } from '../features/router/brand-router';
import { useAppDispatch } from '../hooks';
import { Routes } from '../routes/routes';
import { store } from '../store';
import { useStore } from '../useStore';

type AppCommonProps = PropsWithChildren<{
  theme: Theme;
}>;

const StickyAuthentication = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { cleanAllStores } = useStore();

  return (
    <Authentication
      loginRedirectUri={Routes.getAbsolutePathById('auth/redirect')}
      logoutRedirectUri={Routes.getAbsolutePathById('auth/logout/redirect')}
      onLogin={async () => {
        await dispatch(getCustomerWithSubscription({ force: true }));
        dispatch(setCustomerAuth(true));
      }}
      onLogout={async () => {
        dispatch(setCustomerAuth(false));
        cleanAllStores();

        // Ensure to clean all possible keys excepted in "whitelistKeys"
        try {
          // Keys to not delete
          const whitelistKeys = [
            HEADER_CORRELATION_ID,
            BrandsService.BRAND_ID_STORAGE_KEY,
            InformationPopInService.LAST_SEEN_INFO_MODAL_VERSION_KEY,
          ];

          const localStorageKeys = Object.keys(window.localStorage).filter(
            k => {
              const regexp = new RegExp(`(${whitelistKeys.join('|')})`);
              return !regexp.test(k);
            },
          );
          if (localStorageKeys?.length) {
            localStorageKeys.forEach(k => window.localStorage.removeItem(k));
          }
        } catch (err) {
          Logger.error('clean localStorage', err);
        }
      }}
    >
      {children}
    </Authentication>
  );
};

export const AppCommon = ({ theme, children }: AppCommonProps) => {
  // Interpolations for i18n as a package
  useEffect(() => {
    if (i18n.options.interpolation) {
      i18n.options.interpolation.defaultVariables = {
        PRODUCT_NAME: getEnv('VITE_PRODUCT_NAME'),
        MYID: 'Mon Identifiant SNCF',
      };

      if (
        getEnv('VITE_PRODUCT_NAME') === 'MAX JEUNE' &&
        getFeatures().idtgvmax2.enabled
      ) {
        i18n.options.interpolation.defaultVariables = {
          ...i18n.options.interpolation.defaultVariables,
          ['PRODUCT_NAME']: 'MAX JEUNE / iDTGVMAX2',
        };
      }
    }
  }, []);

  // If all is ok, load application
  return (
    <ErrorBoundary fallback={error => <div>{error.message}</div>}>
      <Provider store={store}>
        <StickyThemeProvider theme={theme}>
          <Suspense fallback={<LazyLoader />}>
            <PwaProvider>
              <StickyAuthentication>
                <BrandProvider>
                  <BrandRouter>{children}</BrandRouter>
                </BrandProvider>
              </StickyAuthentication>
            </PwaProvider>
          </Suspense>
        </StickyThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};
