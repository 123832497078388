import { activationCanBeDelayed } from './activation-can-be-delayed';
import { billTo } from './bill-to';
import { displayQRCode } from './display-qrcode';
import { linkWithJourney } from './link-wth-journey';

export const subscription = {
  activationCanBeDelayed,
  billTo,
  linkWithJourney,
  displayQRCode,
};
