import type { TextFieldProps } from 'mui-rff';

import { CardPrefixCodeNumber } from '../../features/customer/models/customer';

import type { IFormInputField, IFormInputFieldType } from '.';
import type { IValidator } from './form-validators';
import { FormInputText } from './text';

// The phone input field
export interface FormInputCardNumberProps extends TextFieldProps {
  validate?: IValidator;
  inputtype?: IFormInputFieldType;
  readOnly?: boolean;
  maxLength?: number;
  onChange?: IFormInputField['onChange'];
}

// Phone number component
const FormInputCardNumber = (props: FormInputCardNumberProps): JSX.Element => {
  const { validate, inputtype, required, maxLength, onChange, ...attrs } =
    props;

  const _maxLength = maxLength ?? 9;

  const inputProps = {
    inputProps: {
      'aria-labelledby': props.name + '-label',
    },
  };

  // Global method to remove card number prefix from input (accepts only number and remove card prefixes)
  const removePrefixCodeNumber = (value?: string) => {
    let output = (value ?? '').replace(/[^0-9.]/g, '');

    (
      Object.keys(CardPrefixCodeNumber) as Array<
        keyof typeof CardPrefixCodeNumber
      >
    ).forEach(key => {
      if (output.startsWith(CardPrefixCodeNumber[key])) {
        output = output.replace(
          new RegExp(`^${CardPrefixCodeNumber[key]}`),
          '',
        );
      }
    });

    return output;
  };

  // Parse value stored in form
  const onInputParse = (value: string): string => {
    if (typeof onChange === 'function') {
      onChange(value);
    }
    return removePrefixCodeNumber(value).substring(0, _maxLength);
  };

  // Format value to display
  const onInputFormat = (value: string): string =>
    removePrefixCodeNumber(value);

  return (
    <FormInputText
      {...attrs}
      validate={validate}
      parse={onInputParse}
      format={onInputFormat}
      formatOnBlur={false}
      InputProps={props.InputProps}
      required={required}
      {...inputProps}
    />
  );
};

export default FormInputCardNumber;
