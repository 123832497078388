import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  fieldset: {
    display: 'flex',
  },
  wrapper: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoColumns: '1fr',
    '& .MuiFormControlLabel-root': {
      marginLeft: '0px',
    },
    [theme.breakpoints.up('breakPointDesktop')]: {
      gridAutoFlow: 'column',
    },
  },
  radio: {
    border: '2px solid',
    // borderRadius: secondary.borderRadius,
    borderColor: theme.app.colors.primary,
    // padding: secondary.padding,
    width: '100%',
    maxWidth: '100%',
    minHeight: '3rem',
    // height: secondary.height,

    '& .MuiFormControlLabel-label': {
      color: theme.app.colors.primary,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'none',
      fontWeight: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& .MuiRadio-root': {
      display: 'none',
    },
    '&:focus-within': {
      outline: 'dashed',
      outlineOffset: '-4px',
      outlineWidth: 'thin',
      outlineColor: theme.app.colors.white,
    },
    [theme.breakpoints.up('breakPointDesktop')]: {
      fontSize: '1rem',
    },
  },
  selected: {
    backgroundColor: theme.app.colors.primary,
    '& .MuiFormControlLabel-label': {
      color: theme.app.colors.white,
    },
  },

  disabled: {
    '& .MuiFormControlLabel-label': {
      color: theme.app.colors.textPrimary,
    },
    backgroundColor: theme.app.colors.disabledBackground,
    borderColor: theme.app.colors.warmGray2,
  },
}));

export interface IRadioOption {
  id?: string;
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface IRadioButtonGroup {
  groupName?: string;
  options?: IRadioOption[];

  onChange(value?: string | number | null): void;

  value?: string | number | null;
  className?: string;
  disabled?: boolean;
  ariaLabel?: string;
  'data-test-id'?: string;
}

export const RadioButtonGroup = (props: IRadioButtonGroup) => {
  const { classes } = useStyles();
  const { options, onChange, value, ariaLabel } = props;

  return (
    <FormControl component="fieldset" className={classes.fieldset}>
      <RadioGroup
        row
        name={props.groupName ?? 'row-radio-buttons-group'}
        aria-label={ariaLabel}
        className={[classes.wrapper, props.className].join(' ')}
        id={props['data-test-id']}
      >
        {options?.map((btn, key) => {
          const isSelected = btn.value === value;
          const isDisabled = btn?.disabled;
          return (
            <FormControlLabel
              control={
                <Radio
                  icon={<></>}
                  checkedIcon={<></>}
                  aria-checked={isSelected}
                />
              }
              value={btn.value}
              checked={isSelected}
              onClick={() => !btn?.disabled && onChange(btn.value)}
              disabled={isDisabled}
              label={btn.label}
              id={btn.id}
              className={[
                classes.radio,
                isSelected && classes.selected,
                isDisabled && classes.disabled,
              ]
                .filter(Boolean)
                .join(' ')}
              key={key}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
