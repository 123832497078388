import type { PaletteOptions } from '@mui/material';

import type { StickyThemeConfig } from './types';

export const createPalette = ({
  colors,
}: StickyThemeConfig): PaletteOptions => ({
  background: {
    paper: colors.white,
  },
  primary: {
    main: colors.primary,
    light: colors.primaryLight,
    dark: colors.primaryDark,
    contrastText: colors.textContrasted,
  },
  secondary: {
    main: colors.white,
    contrastText: colors.primary,
  },
  text: {
    primary: colors.textPrimaryDark,
    secondary: colors.warmGray2,
  },
  warning: {
    main: colors.warningDark,
  },
  success: {
    main: colors.successDark,
  },
  info: {
    main: colors.infoDark,
  },
  action: {
    disabledBackground: colors.warmGray1,
  },
  error: {
    main: colors.errorDark,
  },
});
