import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { t } from '@sticky/i18n';
import { useGpgStore } from '@sticky/service-gpg';

import { ModalExit } from '../../components';
import { cleanBasket } from '../../features/basket/store/basket';
import { useAppDispatch } from '../../hooks';

export const ModalExitEnrollment = () => {
  const gpgStore = useGpgStore();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickCancelEnrolling = (pathHistory: string) => {
    gpgStore.reset();
    dispatch(cleanBasket());
    navigate(pathHistory);
  };

  return (
    <ModalExit
      title={t('enrollment.exit-modal.title')}
      matchUrls={['/souscription']}
      onExit={(pathHistory: string) => handleClickCancelEnrolling(pathHistory)}
      exitLabel={t('enrollment.exit-modal.quit-btn')}
    >
      <Typography>{t('enrollment.exit-modal.text')}</Typography>
    </ModalExit>
  );
};
