import type { WeekDay } from '../../..';

export const HOURS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
] as const;
export const MINUTES = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55] as const;

export type Hour = (typeof HOURS)[number];
export type Minute = (typeof MINUTES)[number];

export enum LevelOption {
  LOW = 'ANVB',
  HIGH = 'ANVH',
  NO_WISH = 'NO_WISH',
}

export enum PlacementOption {
  AISLE = 'ACOU',
  CLOSE_TO = 'CLOSE_TO',
  NO_WISH = 'NO_WISH',
  PLACE_NUMBER = 'PLACE_NUMBER',
  WINDOW = 'AFEN',
  ISOLATED = 'AFIS',
}

export type ExchangeStatus =
  | 'SUCCESS'
  | 'FULL_TRAIN'
  | 'TECHNICAL_ERROR'
  | 'PLACEMENT_WISH_NOT_SATISFIED'
  | 'PROPOSAL_NOT_FOUND';

export interface Proposal {
  id: string;
  departureDateTime: string;
  segmentId?: string;
  assignmentOption?: AssignmentOption;
  designatedSeat?: SeatWish;
  closeTo?: SeatWish;
}

interface SearchDates {
  fromDate: string | null; // must be string because of serialization/deserialization to/from localstorage
  toDate: string | null;
}

export interface ServiceInfo<TError> {
  error?: TError;
  fetched: boolean;
  loading: boolean;
}

export interface Time {
  hours: Hour;
  minutes: Minute;
}

export interface AssignmentOption {
  forwardFacing?: boolean;
  level?: string;
  place?: string;
  hasWheelchairPlacement?: boolean;
}

export interface SeatWish {
  coachNumber: string;
  seatNumber: string;
}

export interface Station {
  code: string;
  label: string;
}

interface TravelStation extends Omit<Station, 'code'> {
  rrCode: string;
}

export enum TravelConfirmed {
  TOO_EARLY_TO_CONFIRM = 'TOO_EARLY_TO_CONFIRM',
  TO_BE_CONFIRMED = 'TO_BE_CONFIRMED',
  CONFIRMED = 'CONFIRMED',
  WILL_BE_CANCELED = 'WILL_BE_CANCELED',
  CANCELED = 'CANCELED',
  TOO_LATE_TO_CONFIRM = 'TOO_LATE_TO_CONFIRM',
  UNKNOWN_DURING_CONFIRMATION = 'UNKNOWN_DURING_CONFIRMATION',
  UNKNOWN_AFTER_CONFIRMATION = 'UNKNOWN_AFTER_CONFIRMATION',
  NULL = 'NULL',
}

export enum TravelStatus {
  ACTIVE = 'ACTIVE',
  VALIDE = 'VALIDE',
  EXCHANGED = 'EXCHANGED',
  REFUND = 'REFUND',
  CANCELED = 'CANCELED',
  AFTERSALE = 'AFTERSALE',
}

export interface Travel {
  origin: TravelStation;
  destination: TravelStation;
  trainNumber: string;
  departureDateTime: string;
  arrivalDateTime: string;
  travelClass: string;
  coachNumber: string;
  seatNumber: string;
  marketingCarrierRef: string;
  orderId: string;
  segmentId: string;
  reservationDate: string;
  highlighted?: boolean;
  serviceItemId: string;
  travelConfirmed: TravelConfirmed;
  travelStatus: TravelStatus;
  refundable: boolean;
  exchangeable: boolean;
  avantage: boolean;
  amount: string;
}

export interface TravelDetailsCache {
  id: string;
  travelDetails?: DetailsTravel;
  detailsState: ServiceInfo<string>;
  expired?: boolean;
}

export interface DetailsTravel extends Travel {
  seat?: Seat;
  transportationServiceOffer?: string;
  tcn?: string;
}

export interface PlacementPreferences {
  assignmentOption?: AssignmentOption;
  closeTo?: SeatWish;
  designatedSeat?: SeatWish;
}

export interface TravelPreferences extends PlacementPreferences {
  origin: Station | null;
  destination: Station | null;
  time: Time;
}

export interface PreferencesForWeekDay {
  departurePreferences: TravelPreferences;
  returnPreferences?: TravelPreferences;
}

export interface ReservationPreferences {
  activeWeekDays: Record<WeekDay, boolean>;
  criteriaForWeekDay: Record<WeekDay, PreferencesForWeekDay | undefined>;
}

export interface IReservationState extends ReservationPreferences {
  searchDates: SearchDates;
  transactionId?: string;
  bookingFinished: boolean;
  travels: Travel[];
  preferencesInfo: ServiceInfo<'read' | 'update'>;
  travelsInfo: ServiceInfo<string>;
  unhighlightDate?: string;
  travelDetailsCache: TravelDetailsCache[];
}

export enum WheelchairType {
  ELECTRIC = 'WCHE',
  MANUAL = 'WHCM',
}

export interface Seat {
  businessPlacement?: string;
  coachType?: string;
  facingForward?: boolean;
  noiseComfort?: string;
  seatPosition?: string;
  smokingComfort?: string;
  spaceType?: string;
  tgvDeck?: string;
  wheelchair?: WheelchairType;
}

export interface CancelReservationServicePayload {
  marketingCarrierRef: string;
  orderId: string;
  customerName: string;
  trainNumber?: string;
  departureDateTime?: string;
}

export interface ReservationDay {
  date: Date;
  travels: Travel[];
}

export interface ReservationWeek {
  startDate: Date;
  endDate: Date;
  days: ReservationDay[];
}
