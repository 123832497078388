import { useEffect, useMemo, useRef, useState } from 'react';
import type { Theme } from '@mui/material';
import { Alert, Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { t, Trans } from '@sticky/i18n';

import { ReservationProgressBar } from '../../components';
import { refreshCounters } from '../../features/customer/store/customer-slice';
import {
  type CancelReservationInfo,
  removeReservations,
  ReservationService,
  setTravelsCacheExpired,
  type TravelInfoWithCustomer,
} from '../../features/reservation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { formatToHHMM, toFrenchDate } from '../../utils/date';
import { ModalBox } from '../modal/modal-box';

const useStyles = makeStyles()(
  ({ breakpoints, app: { colors }, typography: { pxToRem } }: Theme) => ({
    wordingContainer: {
      margin: `${pxToRem(10)} auto ${pxToRem(10)}`,
      [breakpoints.up('breakPointDesktop')]: {
        marginBottom: pxToRem(10),
      },
    },

    wording: {
      color: colors.labelGray,
      whiteSpace: 'pre-line',
    },

    alert: {
      color: colors.errorDark,
      fontSize: pxToRem(13),
      marginTop: pxToRem(24),
      [breakpoints.up('breakPointDesktop')]: {
        fontSize: pxToRem(14),
      },
    },
  }),
);

interface CancelReservationsModalProps {
  onBack: () => void;
  onClose: () => void;
  travels: TravelInfoWithCustomer[];
  confirmationMessage: JSX.Element;
  validationMessage: JSX.Element | string;
}

interface CancelResponse {
  successTravels: CancelReservationInfo[];
  failedTravels: CancelReservationInfo[];
}

export const MailSentMessage = ({
  nbTravels,
}: {
  nbTravels: number;
}): JSX.Element => {
  const { classes } = useStyles();

  return (
    <>
      <Typography component="span">
        {t('travels.detailsTravel.cancel.successMessage', { count: nbTravels })}
      </Typography>
      <Box className={classes.wordingContainer}>
        <Typography className={classes.wording}>
          <Trans i18nKey="reservation.reservationConfirmation.bookingMailSent" />
        </Typography>
      </Box>
    </>
  );
};

export const ConfirmationWithDate = ({
  departureDateTime,
}: {
  departureDateTime: string | undefined;
}): JSX.Element => {
  const { classes } = useStyles();

  return (
    <div>
      {departureDateTime ? (
        <Typography component="p">
          <Trans
            i18nKey="travels.detailsTravel.cancel.areYouSureMessageWithDate"
            values={{
              travelDate: toFrenchDate(departureDateTime),
              travelTime: formatToHHMM(new Date(departureDateTime)),
            }}
          />
        </Typography>
      ) : null}
      <Typography component="p">
        {t('travels.detailsTravel.cancel.areYouSureMessageWithDate2')}
      </Typography>
      <Box className={classes.wordingContainer}>
        <Typography className={classes.wording}>
          <Trans
            i18nKey={t('travels.detailsTravel.cancel.additional-information')}
          />
        </Typography>
      </Box>
    </div>
  );
};

export const CancelReservationsModal = ({
  onBack,
  onClose,
  travels,
  confirmationMessage,
  validationMessage,
}: CancelReservationsModalProps): JSX.Element => {
  const { classes } = useStyles();

  const modalContent = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();

  const { customer } = useAppSelector(state => state.customer);
  const customerName = customer?.lastName;

  const [processingTravels, setProcessingTravels] = useState<
    TravelInfoWithCustomer[]
  >([]);
  const [processedTravels, setProcessedTravels] = useState<
    CancelReservationInfo[]
  >([]);
  const [failedTravels, setFailedTravels] = useState<CancelReservationInfo[]>(
    [],
  );

  const { hasError, isFetched, isLoading } = useMemo(() => {
    const total = processedTravels.length + failedTravels.length;
    return {
      hasError: failedTravels.length > 0,
      isFetched:
        total > 0 && processedTravels.length === processingTravels.length,
      isLoading: total !== processingTravels.length,
    };
  }, [failedTravels, processedTravels, processingTravels]);

  useEffect(() => {
    if (!processingTravels.length || !customerName) return;
    const travelsToCancel: TravelInfoWithCustomer[] = processingTravels.map(
      travel => ({ ...travel, customerName }),
    );

    const cancel = async (
      travelsInfo: TravelInfoWithCustomer[],
    ): Promise<CancelResponse> => {
      try {
        const cancellations =
          await ReservationService.cancelReservations(travelsToCancel);
        return {
          successTravels: cancellations.info.filter(info => info.cancelled),
          failedTravels: cancellations.info
            .filter(info => !info.cancelled)
            .map(failedTravel => ({
              ...failedTravel,
              isMultiPassengerTravel:
                failedTravel.cancelErrorCode === 'SYG_44358',
            })),
        };
      } catch {
        return {
          successTravels: [],
          failedTravels: travelsInfo.map(
            ({
              departureDateTime,
              marketingCarrierRef,
              orderId,
              trainNumber,
            }) => ({
              cancelled: false,
              departureDateTime,
              marketingCarrierRef,
              orderId,
              trainNumber,
            }),
          ),
        };
      }
    };

    Promise.resolve(cancel(travelsToCancel)).then(response => {
      const { successTravels, failedTravels } = response;
      setProcessedTravels(successTravels);
      setFailedTravels(failedTravels);
      dispatch(removeReservations(successTravels));
      dispatch(setTravelsCacheExpired(successTravels));
      dispatch(refreshCounters());
    });
  }, [customerName, dispatch, processingTravels]); // eslint-disable-line react-hooks/exhaustive-deps

  const reset = (processingTravelsValue: TravelInfoWithCustomer[] = []) => {
    setProcessedTravels([]);
    setFailedTravels([]);

    setProcessingTravels(processingTravelsValue);
  };

  const retry = (info: CancelReservationInfo[]) => {
    if (!customerName) return;
    reset(
      info.map(
        ({ departureDateTime, marketingCarrierRef, orderId, trainNumber }) => ({
          customerName,
          marketingCarrierRef,
          orderId,
          trainNumber,
          departureDateTime,
        }),
      ),
    );
  };

  const close = () => {
    if (!isLoading) {
      onClose();
      reset();
    }
  };

  const getErrorActionButtons = (): JSX.Element[] => {
    const multiPassengerFailedTravel = failedTravels.find(
      failedTravel => failedTravel.isMultiPassengerTravel,
    );
    if (multiPassengerFailedTravel) {
      return [
        <ActionButton onClick={close} key={0}>
          {t('travels.detailsTravel.cancel.backButton')}
        </ActionButton>,
      ];
    } else {
      screenReaderMessage = t('travels.detailsTravel.cancel.aria.error');
      return [
        <ActionButton onClick={() => retry(failedTravels)} key={0}>
          {t('travels.detailsTravel.cancel.retryButton')}
        </ActionButton>,
      ];
    }
  };

  const actionButtons = (): JSX.Element[] | undefined => {
    if (!isLoading) {
      if (!hasError && !isFetched) {
        return [
          <ActionButton variant="outlined" onClick={onBack} key={0}>
            {t('travels.detailsTravel.cancel.backButton')}
          </ActionButton>,
          <ActionButton onClick={() => reset(travels)} key={1}>
            {t('travels.detailsTravel.cancel.confirmButton', {
              count: travels.length,
            })}
          </ActionButton>,
        ];
      }
      if (hasError) {
        return getErrorActionButtons();
      } else if (isFetched) {
        return [
          <ActionButton onClick={close} key={0}>
            {t('travels.detailsTravel.cancel.backToTravelsButton')}
          </ActionButton>,
        ];
      } else {
        return [
          <ActionButton onClick={() => reset(travels)} key={0}>
            {t('travels.detailsTravel.cancel.confirmButton', {
              count: travels.length,
            })}
          </ActionButton>,
        ];
      }
    }
  };

  const getErrorMessageContent = (): JSX.Element => {
    const multiPassengerFailedTravel = failedTravels.find(
      failedTravel => failedTravel.isMultiPassengerTravel,
    );
    if (multiPassengerFailedTravel) {
      return (
        <Typography component="span">
          <Trans i18nKey="travels.detailsTravel.cancel.multipaxInfoMessage" />
        </Typography>
      );
    } else {
      screenReaderMessage = t('travels.detailsTravel.cancel.aria.error');
      return (
        <Alert className={classes.alert} severity="error">
          {t('travels.detailsTravel.cancel.errorMessage', {
            count: travels.length,
          })}
        </Alert>
      );
    }
  };

  const currentBooking = processedTravels.length + failedTravels.length;
  const totalBooking = processingTravels.length;

  let screenReaderMessage;
  let message;
  if (isLoading) {
    screenReaderMessage = t('travels.detailsTravel.cancel.aria.loading', {
      currentBooking,
      totalBooking,
    });
    message = (
      <div ref={modalContent}>
        <Box className={classes.wordingContainer}>
          <Typography component="span">
            {t('travels.detailsTravel.cancel.pendingMessage')}
          </Typography>
        </Box>
        {processingTravels.length > 0 && (
          <ReservationProgressBar currentBooking={0} totalBooking={0} />
        )}
      </div>
    );
  } else if (hasError) {
    message = getErrorMessageContent();
  } else if (isFetched) {
    screenReaderMessage = t('travels.detailsTravel.cancel.aria.success', {
      count: totalBooking,
    });
    message = confirmationMessage;
  } else {
    message = validationMessage;
  }
  return (
    <ModalBox
      actions={actionButtons()}
      ariaBusy={isLoading}
      open={travels.length > 0}
      onClose={close}
      title={t('travels.detailsTravel.cancel.title')}
      closeButton={!isLoading && 'header'}
    >
      <>
        <span className="onlyForScreenReader" aria-live="polite">
          {screenReaderMessage}
        </span>
        {message}
      </>
    </ModalBox>
  );
};
