import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AccordionDetails,
  Divider,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';
import DOMPurify from 'dompurify';
import { tss } from 'tss-react/mui';

import { AccordionHeader, AccordionPanel } from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { useHasIdTgvMaxSubscription } from '../../features/subscription/hooks/useHasIdTgvMaxSubscription';
import { Routes } from '../../routes/routes';

import { CONTEXT_FAQ } from './types';

const useStyles = tss.create(({ theme }) => ({
  faq: {
    paddingBottom: theme.spacing(1),

    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.up('breakPointDesktop')]: {
      backgroundColor: 'transparent',
    },
  },
  title: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',

    [theme.breakpoints.up('breakPointDesktop')]: {
      paddingTop: 0,
      textAlign: 'left',
      paddingBottom: theme.spacing(4),
    },
  },
  wrapper: {
    gap: 0,

    [theme.breakpoints.up('breakPointDesktop')]: {
      gap: theme.spacing(2),
    },
  },
  accordion: {
    '& a:not(.MuiLink-root):not(.MuiButton-root)': {
      color: theme.app.colors.textContrasted,
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      fontWeight: 'bold',
    },
  },
  accordionDetails: {
    marginTop: 0,

    [theme.breakpoints.up('breakPointDesktop')]: {
      marginTop: 0,
    },
  },
}));

export const HomeFaq = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { breakpoints } = theme;
  const isDesktop = useMediaQuery(breakpoints.up('breakPointDesktop'));

  const hasIdTgvMaxSubscription = useHasIdTgvMaxSubscription();

  const getContext = useCallback(() => {
    if (hasIdTgvMaxSubscription) return CONTEXT_FAQ.IDTGVMAX;
    else if (getFeatures().idtgvmax2.enabled && getFeatures().ouigo.enabled)
      return CONTEXT_FAQ.OUIGO_IDTGVMAX;
    else if (getFeatures().idtgvmax2.enabled) return CONTEXT_FAQ.IDTGVMAX;
    else if (getFeatures().ouigo.enabled) return CONTEXT_FAQ.OUIGO;
  }, [hasIdTgvMaxSubscription]);

  const location = useLocation();
  const questions: {
    title: string;
    answer: string;
    answer_ouigo: string;
    answer_idtgvmax: string;
    link?: string;
    id?: string;
  }[] = t('home.faq.questions', {
    returnObjects: true,
    phoneNumber: t('home.contact-phone-number'),
    context: getContext(),
    simulatorUrl: getFeatures().freePlaces.enabled
      ? Routes.getPathById('free-places/available-places')
      : t('free-places.simulator.legacy-href'),
  });

  return (
    <Stack className={classes.faq}>
      <Typography component="h2" variant="h2" className={classes.title}>
        {t('home.faq.title')}
      </Typography>
      <Stack className={classes.wrapper} divider={!isDesktop && <Divider />}>
        {questions.map((question, index) => (
          <AccordionPanel
            id={question.id || `question-${index}`}
            key={question.id || `question-${index}`}
            square={!isDesktop}
            defaultExpanded={location.hash === question.id}
            className={classes.accordion}
          >
            <AccordionHeader>{question.title}</AccordionHeader>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(question.answer),
                }}
              />
              {question.link && (
                <Link href={question.link} target="_blank" rel="noreferrer">
                  {question.link}
                </Link>
              )}
            </AccordionDetails>
          </AccordionPanel>
        ))}
      </Stack>
    </Stack>
  );
};
