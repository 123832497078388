import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorOutline } from '@mui/icons-material';
import type { Theme } from '@mui/material';
import { CardActions } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { customerSelectors } from '../../../features/customer/selectors';
import { useAppSelector } from '../../../hooks';
import { Routes } from '../../../routes/routes';
import SubscribeButton from '../../price-card/subscribe-button';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    typography: { pxToRem },
    app: { colors },
  } = theme;

  return {
    background: {
      background: colors.textPrimaryDark,
    },
    banner: {
      alignItems: 'flex-start',
      color: colors.white,
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      maxWidth: 1360,
      padding: pxToRem(22),
      [breakpoints.up('breakPointDesktop')]: {
        alignItems: 'center',
        flexDirection: 'row',
        gap: pxToRem(80),
        '& > div': {
          width: '50%',
        },
      },
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: pxToRem(22),
      padding: 0,
      width: '100%',
      '& .MuiButton-root': {
        height: pxToRem(38),
      },
      [breakpoints.up('breakPointDesktop')]: {
        flexDirection: 'row',
        marginTop: 0,
        '& .MuiButton-root': {
          height: 'auto',
        },
      },
    },
    mainMessage: {
      fontSize: pxToRem(13),
      margin: `${pxToRem(18)} 0 0`,
      [breakpoints.up('breakPointDesktop')]: {
        fontSize: pxToRem(18),
        marginLeft: pxToRem(36),
        marginTop: pxToRem(6),
      },
    },
    title: {
      fontSize: pxToRem(16),
      fontWeight: 700,
      marginLeft: pxToRem(8),
      whiteSpace: 'nowrap',
      [breakpoints.up('breakPointDesktop')]: {
        fontSize: pxToRem(20),
      },
    },
    titleWrapper: {
      alignItems: 'center',
      display: 'flex',
      [breakpoints.up('breakPointDesktop')]: {
        '& > svg': {
          height: pxToRem(28),
          width: pxToRem(28),
        },
      },
    },
  };
});

const MigrationBanner = (): JSX.Element => {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // Get app states
  const isMigrationEnabled = getFeatures().migration.enabled;
  const currentRoute = Routes.getRouteByPathname(location.pathname);

  // Get store states
  const { customer } = useAppSelector(customerSelectors.getCustomerState);
  const hasAlreadyMAXSubscription = useAppSelector(
    customerSelectors.hasAlreadyMAXSubscription,
  );
  const hasCustomerProfileIncomplete = useAppSelector(
    customerSelectors.hasCustomerProfileIncomplete,
  );

  // Show the banner if the customer has not yet a subscription (only for 'customer/my-coordinates')
  const showBanner =
    customer &&
    isMigrationEnabled &&
    !hasAlreadyMAXSubscription &&
    currentRoute?.id === 'customer/my-coordinates';

  // Redirect to migration page if the migration is enabled and customer profile is incomplete
  const isProfileIncomplete =
    customer &&
    isMigrationEnabled &&
    hasAlreadyMAXSubscription &&
    hasCustomerProfileIncomplete;

  // Redirect to migration workflow if profile is incomplete
  if (
    isProfileIncomplete &&
    currentRoute &&
    currentRoute.id !== 'migration/update-personal-datas' &&
    !currentRoute.id.startsWith('auth/')
  ) {
    navigate(Routes.getPathById('migration/update-personal-datas'));
    return <></>;
  }

  // Do nothing, the conditions are not met
  if (!showBanner) {
    return <></>;
  }

  // Show the subscription banner
  return (
    <div className={classes.background}>
      <div className={classes.banner}>
        <div>
          <div className={classes.titleWrapper}>
            <ErrorOutline />
            <span className={classes.title}>
              {t('bannerMyCoordinates.title')}
            </span>
          </div>
          <p className={classes.mainMessage}>
            {t('bannerMyCoordinates.message')}
          </p>
        </div>
        <CardActions className={classes.buttons}>
          <ActionButton
            onClick={() =>
              navigate(Routes.getPathById('migration/update-personal-datas'))
            }
          >
            {t('bannerMyCoordinates.alreadySubscribedButton')}
          </ActionButton>
          <SubscribeButton
            color="secondary"
            label={t('bannerMyCoordinates.subscribeButton')}
            variant="outlined"
          />
        </CardActions>
      </div>
    </div>
  );
};

export default MigrationBanner;
