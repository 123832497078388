import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  alpha,
  buttonClasses,
  formLabelClasses,
  inputBaseClasses,
  inputLabelClasses,
  switchClasses,
  type Theme,
  type ThemeOptions,
} from '@mui/material';

import { LinkBehavior } from './LinkBehavior';
import type { StickyThemeConfig } from './types';

export const createComponents = (
  { colors }: StickyThemeConfig,
  theme: Theme,
): ThemeOptions['components'] => ({
  MuiContainer: {
    styleOverrides: {
      root: {
        [theme.breakpoints.between(0, 'breakPointTablet')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      '@global': {
        strong: {
          color: colors.textContrasted,
          fontWeight: 500,
        },
        'div[tabindex="0"]:focus-visible': {
          outline: 'none',
        },
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorPrimary: {
        color: colors.actionIcon,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        color: colors.textPrimary,
        [`&.${inputBaseClasses.disabled}`]: {
          backgroundColor: colors.disabledBackground,
          WebkitTextFillColor: colors.textPrimaryDark,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: colors.textPrimary,
        [`&.${inputLabelClasses.disabled}`]: {
          color: colors.textPrimary,
        },
        [`&.${formLabelClasses.asterisk}`]: {
          color: colors.textContrasted,
          fontWeight: 'bold',
          [`&.${formLabelClasses.error}`]: {
            color: colors.errorDark,
          },
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        [`& .${inputLabelClasses.shrink}.${inputLabelClasses.focused}`]: {
          color: colors.textContrasted,
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: colors.textContrasted,
          },
          '&.Mui-error fieldset': {
            borderColor: colors.errorDark,
          },
          [theme.breakpoints.down('breakPointDesktop')]: {
            fontSize: theme.typography.pxToRem(16),
          },
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        boxShadow: '0px -3px 0.4em rgba(0, 0, 0, 0.2)',
      },
      root: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          padding: '3.5px',
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LinkBehavior,
      disableRipple: true,
    },
  },
  // MuiButton: {
  //   styleOverrides: {
  //     outlined: {
  //       borderColor: theme.palette.primary.main,
  //       backgroundColor: theme.palette.common.white,

  //       '&:hover': {
  //         borderColor: theme.palette.primary.dark,
  //         backgroundColor: theme.palette.common.white,
  //       },
  //     },
  //   },
  // },
  MuiButton: {
    styleOverrides: {
      root: {
        [`&.${buttonClasses.disabled}`]: {
          color: colors.textPrimary,
          backgroundColor: colors.secondary,
        },
        [`&.${buttonClasses.focusVisible}`]: {
          outline: 'dashed',
          outlineOffset: '-4px',
          outlineWidth: 'thin',
          outlineColor: colors.white,
        },
      },
      textPrimary: {
        color: colors.textPrimary,
      },
      containedPrimary: {
        color: colors.textSecondaryContrasted,
        [`&.${buttonClasses.focusVisible}`]: {
          outlineColor: colors.textSecondaryContrasted,
        },
      },
      outlinedPrimary: {
        color: colors.outlinedButtonText,
        [`&.${buttonClasses.focusVisible}`]: {
          outlineColor: colors.textContrasted,
        },
      },
      outlinedSecondary: {
        color: colors.outlinedButtonText,
        backgroundColor: colors.white,
        border: `1px solid ${colors.primary}`,
        '&:hover': {
          border: `1px solid ${colors.primaryDark}`,
          backgroundColor: colors.primaryLight,
        },
        [`&.${buttonClasses.focusVisible}`]: {
          outlineColor: colors.textContrasted,
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          outline: 'dashed',
          outlineOffset: '-4px',
          outlineWidth: 'thin',
          outlineColor: colors.textContrasted,
        },
      },
      colorPrimary: {
        '&.Mui-checked': {
          color: colors.textContrasted,
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: theme.typography.pxToRem(46),
        height: theme.typography.pxToRem(26),
        padding: 0,
      },
      switchBase: {
        padding: theme.typography.pxToRem(1),
        // checked style
        '&.Mui-checked': {
          transform: `translateX(${theme.typography.pxToRem(20)})`,
        },
        [`&.Mui-focusVisible .${switchClasses.thumb}`]: {
          color: colors.primary,
          border: `${theme.typography.pxToRem(6)} solid ${colors.white}`,
        },
      },
      colorPrimary: {
        [`&.Mui-checked + .${switchClasses.track}`]: {
          backgroundColor: colors.primary,
        },
      },
      colorSecondary: {
        [`&..Mui-checked + .${switchClasses.track}`]: {
          backgroundColor: colors.secondary,
        },
      },
      thumb: {
        color: colors.white,
        width: theme.typography.pxToRem(24),
        height: theme.typography.pxToRem(24),
      },
      track: {
        backgroundColor: colors.warmGray2,
        borderRadius: theme.typography.pxToRem(26 / 2),
        border: `none`,
        opacity: 1 + '!important',
        transition: theme.transitions.create(['background-color', 'border']),
      },
    },
  },
  MuiFormGroup: {
    styleOverrides: {
      root: {
        flexDirection: 'row',
        minWidth: 250,
        justifyContent: 'space-between',
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: colors.warmGray2,
        '&.Mui-focusVisible': {
          outline: 'dashed',
          outlineOffset: '-4px',
          outlineWidth: 'thin',
          outlineColor: colors.textContrasted,
        },
      },
      colorPrimary: {
        '&.Mui-checked': {
          color: colors.textContrasted,
        },
      },
      colorSecondary: {
        '&.Mui-checked': {
          color: colors.textContrasted,
        },
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: colors.warmGray1,
      },
      wave: {
        '&:after': {
          background:
            'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent)',
        },
      },
    },
    variants: [
      {
        props: { variant: 'title' },
        style: {
          borderRadius: '4px',
          height: '48px',
          paddingBottom: theme.spacing(1),

          [theme.breakpoints.up('breakPointDesktop')]: {
            marginBottom: theme.spacing(4),
          },
        },
      },
    ],
  },
  MuiCard: {
    styleOverrides: {
      root: {
        background: colors.white,
        borderRadius: '24px',
        padding: '1.4rem 1rem',
        textAlign: 'left',
        position: 'relative',
        overflow: 'normal',
        width: 'calc(100% - 2rem)',
        margin: '0 auto',
        [theme.breakpoints.up('breakPointDesktop')]: {
          padding: '1.6rem 2rem',
          width: '100%',
        },
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: '0.85rem',
      },
      filled: {
        color: colors.black,
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        gap: '15px',
        marginTop: '0.625rem',
      },
      spacing: {
        '& > :not(:first-of-type)': {
          marginLeft: 0,
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      today: {
        '&:not(.Mui-selected)': {
          borderColor: colors.primary,
        },
      },
      root: {
        '&.Mui-selected': {
          color: colors.white,
        },
      },
    },
  },
  MuiYearCalendar: {
    styleOverrides: {
      root: {
        '.MuiPickersYear-yearButton': {
          fontSize: '1rem !important',

          '&.Mui-selected': {
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        borderBottom: `solid 1px ${alpha(colors.warmGray2, 0.25)}`,
        '&:last-child': {
          borderBottom: 'none',
        },
        '& .link': {
          '&:hover': {
            color: colors.textContrasted,
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        '&.selected': {
          color: colors.primary,
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: theme.spacing(2),
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      rounded: {
        borderRadius: '1rem',

        '&:first-of-type': {
          borderTopLeftRadius: theme.spacing(2),
          borderTopRightRadius: theme.spacing(2),
        },
        '&:last-of-type': {
          borderBottomLeftRadius: theme.spacing(2),
          borderBottomRightRadius: theme.spacing(2),
        },
      },
    },
    defaultProps: {
      disableGutters: true,
    },
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <ExpandMoreIcon />,
    },
    styleOverrides: {
      root: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

        [theme.breakpoints.up('breakPointDesktop')]: {
          padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
        },
      },
      expandIconWrapper: {
        color: colors.textContrasted,
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        margin: theme.spacing(2),
        padding: 0,

        [theme.breakpoints.up('breakPointDesktop')]: {
          margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
        },

        ['&:last-child']: {
          [theme.breakpoints.up('breakPointDesktop')]: {
            marginBottom: theme.spacing(3),
          },
        },
      },
    },
  },
  MuiAccordionActions: {
    styleOverrides: {
      root: {
        padding: 0,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),

        flexFlow: 'row-reverse wrap',
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(2),
        justifyContent: 'center',

        '& > :not(:first-of-type)': {
          marginLeft: 0,
        },

        [theme.breakpoints.up('breakPointDesktop')]: {
          marginLeft: theme.spacing(4),
          marginRight: theme.spacing(4),
          marginBottom: theme.spacing(4),
        },
      },
    },
  },

  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
      underline: 'none',
      color: colors.textContrasted,
    },
  },
  MuiTreeItem: {
    styleOverrides: {
      root: {
        '& .Mui-expanded .MuiTreeItem-label': {
          color: colors.primary,
          fontWeight: 'bold',
        },
      },
      iconContainer: {
        '& .MuiSvgIcon-root': {
          fill: colors.actionIcon,
        },
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        '&.MuiSvgIcon-colorPrimary': {
          fill: colors.primary,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: colors.white,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:last-child *': {
          borderBottom: 'none',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
        [theme.breakpoints.up('breakPointDesktop')]: {
          padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
        },
      },
    },
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        position: 'fixed',
        display: 'flex',
        height: 80,
        alignItems: 'center',
        zIndex: 1100,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: colors.white,
        justifyContent: 'space-around',
        boxShadow: '0px -3px 0.4em rgba(0, 0, 0, 0.2)',
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        height: 65,
        padding: '8px 6px',
        '&.Mui-selected': {
          paddingTop: 8,
        },
        '& .MuiIcon-root': {
          textAlign: 'center',
          width: '1em',
          height: '1em',
          display: 'inline-block',
          fontSize: '1.5rem',
          background: 'inherit',
          maskRepeat: 'no-repeat',
          maskPosition: 'center',
          [theme.breakpoints.up('breakPointTablet')]: {
            fontSize: '1.6rem',
            lineHeight: '2rem',
          },
        },
        [theme.breakpoints.up('breakPointDesktop')]: {
          padding: '8px 12px',
        },
      },
      label: {
        fontSize: 10,
        color: colors.black,
        lineHeight: '1rem',
        transition: 'none',
        '&.Mui-selected': {
          fontSize: 11,
          color: colors.primary,
        },
        [theme.breakpoints.up('breakPointTablet')]: {
          fontSize: 12,
          lineHeight: '1.3rem',
          '&.Mui-selected': {
            fontSize: 13,
            color: colors.primary,
          },
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      colorPrimary: {
        color: colors.textSecondaryContrasted,
      },
      root: {
        '& $avatarColorPrimary': {
          color: colors.textSecondaryContrasted,
        },
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        background: colors.textContrasted,
        '& svg': {
          fill: colors.white,
        },
        '&:hover': {
          background: colors.textContrastedDark,
        },
      },
      sizeSmall: {
        width: '1.7rem',
        height: '1.7rem',
        '& svg': {
          width: '1rem',
          height: '1rem',
        },
      },
    },
  },
  MuiPickersPopper: {
    styleOverrides: {
      paper: {
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: '0.5rem',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        border: '1px solid',
      },
      icon: {
        marginRight: theme.spacing(1),

        [theme.breakpoints.up('breakPointDesktop')]: {
          marginRight: theme.spacing(1.5),
        },
      },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        marginBottom: theme.spacing(1),
      },
    },
  },
});
