import type { CountryCode } from 'libphonenumber-js/max';
import parsePhoneNumber from 'libphonenumber-js/max';

import { t } from '@sticky/i18n';

import type { IValidator } from '../../components';

export type { CountryCode };

export const defaulPhoneNumberCountry = 'FR';

// Format a phone number to international format (E164) : +330102030405
export function getPhoneNumberE164(
  value: string | undefined,
  country: CountryCode,
): string | undefined {
  if (!value) return;
  const phoneNumber = parsePhoneNumber(value, country);
  return phoneNumber?.format('E.164');
}

// Format a phone number to national format : 01 02 03 04 05
export function getPhoneNumberNational(
  value: string | undefined,
  country: CountryCode,
): string | undefined {
  if (!value) return;
  const phoneNumber = parsePhoneNumber(value, country);

  // Returns E.164 format if phoneNumber is not from the default country
  if (country !== phoneNumber?.country) {
    return phoneNumber?.format('E.164', { fromCountry: phoneNumber?.country });
  }

  // Else returns the national format
  return phoneNumber?.formatNational();
}

// Check if a PhoneNumber is valid
export function isValidMobilePhoneNumber(
  value?: string,
  country?: CountryCode,
): boolean {
  if (!value) return false;
  const phoneNumber = parsePhoneNumber(
    value || '',
    country || defaulPhoneNumberCountry,
  );

  if (
    phoneNumber?.number.startsWith('+337') &&
    phoneNumber.number.length === 12
  ) {
    return true;
  }

  return !!phoneNumber?.isValid() && phoneNumber.getType() === 'MOBILE';
}

function isValidPhoneNumber(value?: string, country?: CountryCode): boolean {
  if (!value) return false;
  const phoneNumber = parsePhoneNumber(
    value || '',
    country || defaulPhoneNumberCountry,
  );

  if (
    phoneNumber?.number.startsWith('+337') &&
    phoneNumber.number.length === 12
  ) {
    return true;
  }

  return !!phoneNumber?.isValid();
}

// React Final Form PhoneNumber Validator
export const validateMobilePhoneNumber: IValidator = (
  value: string | undefined,
) => {
  if (!value || isValidMobilePhoneNumber(value)) return;
  return t('form.mobilePhoneNumber.invalid');
};

export const validatePhoneNumber: IValidator = (value: string | undefined) => {
  if (!value || isValidPhoneNumber(value)) return;
  return t('form.phoneNumber.invalid');
};

// Returns the phone number country
export function getPhoneNumberCountry(value: string | undefined): CountryCode {
  if (!value) return defaulPhoneNumberCountry;

  const phoneNumber = parsePhoneNumber(value || '');
  if (!phoneNumber?.isValid()) return defaulPhoneNumberCountry;

  return phoneNumber.country as CountryCode;
}

// Format phone number api format "00336XXXXXX" or "+336XXXXXXXX" to national format "06 98 XX XX XX"
export const formatDisplayPhone = (phone?: string): string | undefined => {
  if (!phone || !isValidPhoneNumber(phone)) return;
  return getPhoneNumberNational(phone, defaulPhoneNumberCountry);
};

// Format phone number french format form "0698XXXXX" to api format "0033698XXXXX"
export const formatPhoneToApi = (phone?: string): string | undefined => {
  if (!phone || !isValidPhoneNumber(phone)) return;
  return getPhoneNumberE164(phone, defaulPhoneNumberCountry)?.replace(
    /^\+/,
    '00',
  );
};
