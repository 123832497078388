import type { Theme } from '@mui/material/styles';
import type { TreeItemProps } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    fontSize: '1rem',
    '&.Mui-expanded,& .MuiTreeItem-root.Mui-expanded': {
      '& > .MuiTreeItem-content .MuiTypography-root': {
        color: theme.app.colors.textContrasted,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    '&:not(:last-child)': {
      borderBottom: `solid 1px ${theme.palette.action.disabledBackground}`,
    },
    '& .MuiTreeItem-root': {
      border: 'none',
    },
  },
}));

export const TreeItemPanel = ({ children, ...rest }: TreeItemProps) => {
  const { classes } = useStyles();

  return (
    <TreeItem className={classes.root} {...rest}>
      {children}
    </TreeItem>
  );
};
