import { Box, Hidden } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';
import {
  AuthenticationWidget,
  HomeDescription,
  HomeFaq,
  HomeLogin,
  LayoutHomePage,
  ResumptionModal,
  WhySubscribe,
} from '@sticky/sticky-common';
import { OfferWithPriceCard } from '@sticky/sticky-common/src/components/home/offer-with-price-card';
import type { Offer } from '@sticky/sticky-common/src/components/home/types';
import { FreePlacesHomeWidget } from '@sticky/sticky-common/src/pages/free-places/available-places-home-widget';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { commonSpacing },
  } = theme;
  return {
    spacing: {
      marginTop: theme.spacing(4),
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: theme.spacing(commonSpacing),
      },
    },
    spacingNegative: {
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: theme.spacing(commonSpacing * -1.5),
      },
    },
    introduction: {
      [breakpoints.up('breakPointDesktop')]: {
        display: 'grid',
        columnGap: theme.spacing(commonSpacing),
        gridTemplateColumns: 'repeat(2, 1fr)',
        height: 193,
      },
    },
    myId: {
      [breakpoints.up('breakPointDesktop')]: {
        display: 'none',
      },
    },
  };
});

export const Home = (): JSX.Element => {
  const { classes } = useStyles();

  const offers: Offer[] = [
    {
      isDark: false,
      title: t('home.offersBox.offer.title'),
      descriptions: t('home.offersBox.offer.descriptions', {
        returnObjects: true,
      }),
    },
  ];

  return (
    <LayoutHomePage>
      <Box className="main">
        <ResumptionModal />
        <Box className={classes.introduction}>
          <HomeDescription />
          <Hidden mdDown>
            <HomeLogin />
          </Hidden>
        </Box>
        <Box className={classes.spacingNegative}>
          <OfferWithPriceCard offers={offers} />
        </Box>
        <Box className={`${classes.spacing} ${classes.myId}`}>
          <AuthenticationWidget />
        </Box>
        {getFeatures().freePlaces.enabled && (
          <Box className={classes.spacing}>
            <FreePlacesHomeWidget />
          </Box>
        )}
        <Box className={classes.spacing}>
          <WhySubscribe />
        </Box>
        <Box className={classes.spacing}>
          <HomeFaq />
        </Box>
      </Box>
    </LayoutHomePage>
  );
};
