import type { AxiosResponse } from 'axios';

import { StickyGatewayClient } from '@sticky/gateway-client';

import type { SubscriptionProductId } from '../../subscription/models/subscription';

// Used for links stored in i18N translations files
export interface ITermsLink {
  label: string;
  productId?: SubscriptionProductId;
  'data-test-id'?: string;
}

// Response from API CGV References
export interface ITermsReferenceOutput {
  release: string;
  releaseType: string;
  applicationDate: string;
  productId: string;
  url: string;
}

export interface IServiceTermsReferenceInput {
  productId?: SubscriptionProductId;
  startValidityDate?: string;
}

export interface IServiceTermsReferenceOutput {
  current: ITermsReferenceOutput[];
  past: ITermsReferenceOutput[];
  future: ITermsReferenceOutput[];
}

export class ServiceTerms {
  static async getDatas(
    props?: IServiceTermsReferenceInput,
  ): Promise<AxiosResponse<IServiceTermsReferenceOutput>> {
    const client = StickyGatewayClient.authClient();
    return client.get<IServiceTermsReferenceOutput>(
      '/public/refdata/cgv-reference',
      { data: props },
    );
  }
}
