import { createSlice } from '@reduxjs/toolkit';

import { loadPersistentState, savePersistentState } from '../../index';
import type { IExchangeState } from '../models/exchange';

const EXCHANGE_LOCALSTORAGE_KEY = 'exchange';

const initialState: IExchangeState =
  loadPersistentState(EXCHANGE_LOCALSTORAGE_KEY) ?? {};

const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {
    setTravelToExchange: (state, action) => {
      state.travelToExchange = action.payload;
      savePersistentState(EXCHANGE_LOCALSTORAGE_KEY, state);
    },
    setExchangeCriteria: (state, action) => {
      state.exchangeCriteria = action.payload;
      savePersistentState(EXCHANGE_LOCALSTORAGE_KEY, state);
    },
    resetExchangeCriteria: state => {
      state.exchangeCriteria = undefined;
    },
  },
});

export const {
  setTravelToExchange,
  setExchangeCriteria,
  resetExchangeCriteria,
} = exchangeSlice.actions;

const exchangeReducer = exchangeSlice.reducer;
export { exchangeReducer };
