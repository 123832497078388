import type { FieldRenderProps } from 'react-final-form';
import { Field } from 'react-final-form';
import { Typography } from '@mui/material';
import type { TextFieldProps } from '@mui/material/TextField';
import type { DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers';
import { DesktopDatePicker as MuiDesktopDatePicker } from '@mui/x-date-pickers';
import type { ShowErrorFunc } from 'mui-rff';
import { showErrorOnChange } from 'mui-rff';

import { toFrenchDate } from '../../../utils/date';

export interface DatePickerProps
  extends Partial<Omit<MuiDatePickerProps<Date>, 'onChange'>> {
  name: string;
  locale?: string;
  fieldProps?: Partial<FieldRenderProps<string, HTMLElement, string>>;
  inputProps?: TextFieldProps;
  required?: boolean;
  showError?: ShowErrorFunc;
  placeholder?: string;
}

/**
 * rff date picker forked from https://github.com/lookfirst/mui-rff/blob/master/src/DatePicker.tsx in order to modify inputProps and read only
 * @param props DatePickerProps
 */
export const DatePicker = (props: DatePickerProps) => {
  const { name, fieldProps, required, ...rest } = props;

  return (
    <Field
      name={name}
      render={fieldRenderProps => {
        // Display value if readOnly
        if (props.readOnly) {
          return (
            <Typography>
              {toFrenchDate(fieldRenderProps.input.value)}
            </Typography>
          );
        }

        return (
          <DatePickerWrapper
            required={required}
            {...fieldRenderProps}
            {...rest}
          />
        );
      }}
      {...fieldProps}
    />
  );
};

interface DatePickerWrapperProps extends FieldRenderProps<string> {
  required?: boolean;
  placeholder?: string;
}

function DatePickerWrapper(props: DatePickerWrapperProps) {
  const {
    input,
    meta,
    showError = showErrorOnChange,
    required,
    placeholder,
    ...rest
  } = props;

  const { onChange, value, ...restInput } = input;

  const { error, submitError } = meta;
  const isError = showError({ meta });

  const { helperText, ...lessrest } = rest;

  return (
    <MuiDesktopDatePicker
      onChange={onChange}
      value={value === '' ? null : value}
      {...lessrest}
      slotProps={{
        textField: textFieldProps => ({
          fullWidth: true,
          helperText: isError ? error || submitError : helperText,
          error: isError,
          required: required,
          'aria-labelledby': `${textFieldProps.name}-label`,
          inputProps: {
            ...textFieldProps.inputProps,
            placeholder:
              (textFieldProps.value ?? '') !== '' ? undefined : placeholder,
            onBlur: event => {
              textFieldProps.inputProps?.onBlur?.(event);
              restInput.onBlur(event);
            },
            onFocus: event => {
              textFieldProps.inputProps?.onFocus?.(event);
              restInput.onFocus(event);
            },
          },
          ...props.inputProps,
        }),
      }}
    />
  );
}
