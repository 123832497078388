import { useLocation, useNavigate } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import type { StepIconProps, Theme } from '@mui/material';
import {
  Icon,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ConfigCommon } from '@sticky/config';
import { t } from '@sticky/i18n';
import { Logger } from '@sticky/logger';

import { AriaLabelStepper } from './aria-label-stepper';

interface StepperCommonProps {
  orientation: 'vertical' | 'horizontal';
  steps: IStep[];
  colorIcon?: string;
  colorLabels?: string;
  isWayStepper?: boolean;
  hasError?: boolean;
  showLabelOnMobile?: boolean;
}

// Create styles
const useStyles = (
  isWayStepper?: boolean,
  colorLabels?: string,
  colorIcon?: string,
) =>
  makeStyles()((theme: Theme) => {
    const {
      breakpoints,
      typography: { pxToRem },
      app: { colors },
    } = theme;
    return {
      stepper: {
        gridArea: 'stepper',
        alignSelf: 'flex-start',
        display: 'flex',
        justifyContent: 'space-around',
        '& .MuiStepConnector-lineVertical': {
          height: 32,
          borderColor: colorIcon ?? colors.textSecondaryContrasted,
        },
        '& .MuiStepConnector-lineHorizontal': {
          borderColor: colorIcon ?? colors.textSecondaryContrasted,
        },
        '& .MuiStepConnector-horizontal': {
          top: isWayStepper ? pxToRem(15) : pxToRem(17),
        },
        '& .MuiStepConnector-vertical': {
          [breakpoints.up('breakPointDesktop')]: {
            marginLeft: pxToRem(18),
            marginTop: pxToRem(4),
          },
        },
        '&.MuiStepper-root': {
          padding: 0,
          backgroundColor: 'transparent',
          [breakpoints.up('breakPointDesktop')]: {
            paddingBottom: isWayStepper ? 0 : theme.spacing(6),
            width: '91%',
          },
        },
      },
      stepIcon: {
        fontSize: isWayStepper ? '1.80rem' : '2.25rem',
        color: colorIcon ?? colors.textSecondaryContrasted,
      },
      stepPrevious: {
        '& .MuiStepConnector-lineHorizontal': {
          borderColor: isWayStepper
            ? colors.textContrasted
            : colors.textSecondaryContrasted,
        },
        '& .MuiIcon-root': {
          color: isWayStepper
            ? colors.textContrasted
            : colors.textSecondaryContrasted,
        },
      },
      stepLabel: {
        '& .MuiStepLabel-alternativeLabel': {
          marginTop: '0px',
          zIndex: 1,
          '& .MuiTypography-body1': {
            fontWeight: 'bold',
            fontSize: pxToRem(12),
          },
        },
        '& .MuiTypography-root': {
          color: colorLabels ?? colors.textSecondaryContrasted,
        },
      },
      emptyIcon: {
        color: colorIcon ?? colors.textSecondaryContrasted,
      },
    };
  })();

export interface IStep {
  label: string;
  url: string[];
  ariaLabel: string;
  errorUrl: string;
}

type IconStepperProps = StepIconProps & {
  step?: IStep;
  wayStepper?: boolean;
  colorLabels?: string;
  colorIcon?: string;
  hasError?: boolean;
};

const IconStepper = ({
  completed,
  active,
  wayStepper,
  colorLabels,
  colorIcon,
  step,
  hasError,
}: IconStepperProps) => {
  const { classes } = useStyles(wayStepper, colorLabels, colorIcon);
  const location = useLocation();

  if (!step) {
    return null;
  }

  const isError = location.pathname.includes(step.errorUrl);

  return (
    <Icon
      className={`${classes.stepIcon} ${!completed ? classes.emptyIcon : ''}`}
      aria-hidden={!active}
      aria-label={step.ariaLabel}
      role="img"
      component={
        active && (isError || hasError)
          ? CancelIcon
          : completed
            ? CheckCircleRoundedIcon
            : RadioButtonUncheckedRoundedIcon
      }
    />
  );
};

export const StepperCommon = ({
  orientation,
  steps,
  colorIcon,
  colorLabels,
  isWayStepper,
  hasError,
  showLabelOnMobile,
}: StepperCommonProps): JSX.Element => {
  const { classes } = useStyles(isWayStepper, colorLabels, colorIcon);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));
  const location = useLocation();
  const navigate = useNavigate();

  const indexActive = steps.findIndex(step =>
    step.url.includes(location.pathname),
  );

  // Draw a step point
  const drawStep = (index: number, step: IStep) => {
    // For dev usage only : enable click on step go to directly to the step
    const onClick = () => {
      if (ConfigCommon.env.isDev) {
        Logger.log(
          '[DEV]',
          'Enable click on step only in local developer env.',
        );
        navigate(step.url[0]);
      }
    };

    return (
      <Step
        key={index}
        active={indexActive === index}
        completed={indexActive >= index}
        role="listitem"
        aria-current={indexActive === index ? 'step' : undefined}
        className={`${indexActive >= index ? classes.stepPrevious : ''}`}
        onClick={() => onClick()}
      >
        <StepLabel
          StepIconComponent={IconStepper}
          StepIconProps={
            {
              step,
              wayStepper: isWayStepper,
              colorLabels,
              colorIcon,
              hasError,
            } as never
          }
          className={classes.stepLabel}
        >
          {(showLabelOnMobile || isDesktop) && (
            <>
              <Typography variant={isWayStepper ? undefined : 'body2'}>
                {index === indexActive ? <b>{step.label}</b> : step.label}
              </Typography>
              <AriaLabelStepper
                active={indexActive}
                indexStepList={index}
                totalStep={steps.length}
              />
            </>
          )}
        </StepLabel>
      </Step>
    );
  };

  return (
    <Stepper
      alternativeLabel={orientation === 'horizontal'}
      className={classes.stepper}
      orientation={orientation}
      role="list"
      aria-label={t('steppers.title-aria-label')}
    >
      {steps.map((step, index: number) => drawStep(index, step))}
    </Stepper>
  );
};
