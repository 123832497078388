import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { initDatadog } from '@sticky/tracker-datadog';
import { DidomiProvider } from '@sticky/tracker-didomi';

import { useTracker } from './useTracker';

interface TrackerProviderProps {
  children?: ReactNode | ReactNode[];
}

export const TrackerProvider = ({ children }: TrackerProviderProps) => {
  const tracker = useTracker();

  // Activation des logs datadog
  useEffect(() => {
    initDatadog(tracker.isDatadogEnabled);
  }, [tracker.isDatadogEnabled]);

  return (
    <DidomiProvider enabled={tracker.isDidomiEnabled}>
      {children}
    </DidomiProvider>
  );
};
