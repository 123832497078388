import { useState } from 'react';
import { Box, Container, Link, useTheme } from '@mui/material';
import { tss } from 'tss-react/mui';

import { Wrapper } from '@sticky/components';
import { ConfigCommon, getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';
import { useDidomi } from '@sticky/tracker-didomi';

import { AppInfo } from '../modal/app-info/app-info';

const useStyles = tss.create(({ theme }) => ({
  root: {
    background: theme.app.colors.black,
    color: theme.app.colors.white,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    [theme.breakpoints.up('breakPointDesktop')]: {
      flexDirection: 'row',
    },
  },
  logo: {
    height: '2rem',

    [theme.breakpoints.up('breakPointDesktop')]: {
      height: '3.5rem',
    },
  },
  version: {
    display: 'block',
    fontSize: '0.8rem',

    button: {
      color: theme.app.colors.white,
    },
  },
  didomiPreferencesLink: {
    display: 'flex',
    color: 'inherit',
    fontSize: '0.8rem',

    [theme.breakpoints.up('breakPointDesktop')]: {
      alignSelf: 'flex-end',
    },
  },
}));

export const EnterpriseFooter = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const { showPreferencesModal } = useDidomi();
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  return (
    <Box className={classes.root}>
      <Container className={classes.container}>
        <Wrapper>
          <Box>
            <img
              alt={t('brand.enterpriseName')}
              src={theme.app.assets.enterpriseLogo}
              className={classes.logo}
            />

            <div className={classes.version}>
              <Link
                component="button"
                underline="hover"
                onClick={() => {
                  setInfoModalOpen(true);
                }}
              >
                Version&nbsp;: {ConfigCommon.version}
              </Link>
            </div>
            {infoModalOpen && (
              <AppInfo onClose={() => setInfoModalOpen(false)} />
            )}
          </Box>

          {getFeatures().tracker.didomi.enabled && (
            <Link
              className={classes.didomiPreferencesLink}
              underline="hover"
              component="button"
              onClick={() => showPreferencesModal()}
            >
              {t('didomi.manage.cookies.label')}
            </Link>
          )}
        </Wrapper>
      </Container>
    </Box>
  );
};
