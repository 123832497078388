import React from 'react';

import { t, Trans } from '@sticky/i18n';

import { ModalBox } from '../modal-box';

interface AgeRequiredModalProps {
  open: boolean;
  onclose: () => void;
}

export const AgeRequiredModal = ({
  open,
  onclose,
}: AgeRequiredModalProps): JSX.Element => (
  <ModalBox
    open={open}
    title={t('home.ageRequired.title')}
    titleComponent="h2"
    closeButton="header"
    onClose={onclose}
  >
    <Trans i18nKey="home.ageRequired.content" />
  </ModalBox>
);
