let authConfig: {
  getAuthToken: () => string | undefined;
  refreshToken: () => Promise<void>;
};

export const setAuthConfig = (config: typeof authConfig) => {
  authConfig = config;
};

export const getAuthConfig = () => authConfig;
