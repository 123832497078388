import { type PropsWithChildren, useEffect } from 'react';

import { StickyGatewayClient } from '@sticky/gateway-client';

import { stickyClient } from './clients';
import type { AuthStore } from './store';
import { useAuthStore } from './store';

export type AuthenticationProps = PropsWithChildren<AuthStore['config']>;

/**
 * Initialise la gestion de l'authentification.
 */
export const Authentication = ({
  onLogin,
  onLogout,
  loginRedirectUri,
  logoutRedirectUri,
  children,
}: AuthenticationProps) => {
  const authStore = useAuthStore();

  useEffect(() => {
    const initialize = async () => {
      authStore.initialize({
        onLogin,
        onLogout,
        loginRedirectUri,
        logoutRedirectUri,
      });

      // Autorise Sticky Gateway à effectuer des appels en mode authentifié.
      StickyGatewayClient.setAuthConfig({
        getAuthToken: () => useAuthStore.getState().auth?.idToken,
        refreshToken: async () => {
          const authStore = useAuthStore.getState();

          if (!authStore.config) {
            throw new Error('Auth store is not initialized');
          }

          if (!authStore.auth?.refreshToken) {
            throw new Error('There is no refresh token');
          }

          const refreshTokenOutput = await stickyClient.refreshToken({
            refreshToken: authStore.auth.refreshToken,
            redirectUri: authStore.config.loginRedirectUri,
          });

          authStore.login(refreshTokenOutput.data);
        },
      });

      // Vérification du statut de l'authentification et récupération des
      // informations de l'utilisateur.
      if (authStore.auth) {
        try {
          if (onLogin) {
            await onLogin();
          }
          authStore.postLogin();
        } catch (error) {
          authStore.postLogin(error);
        }
      } else {
        authStore.logout();
      }
    };

    initialize();
  }, []);

  return children;
};
