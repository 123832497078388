import React, { useContext, useMemo } from 'react';
import type { RouteObject } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Header } from '../../components/header/header';
import { toRouterObject } from '../../routes/router-mapper';
import { BrandContext } from '../brand/brand-provider';

import { Layout } from './layout';
import { useStickyRoutes } from './useStickyRoutes';

type BrandRouterProps = {
  children: React.ReactNode;
};

const NotFound404 = React.lazy(() => import('../../pages/not-found'));

export const BrandRouter = ({ children }: BrandRouterProps) => {
  const { brand, loading: brandLoading } = useContext(BrandContext);

  const stickyRoutes = useStickyRoutes();

  const router = useMemo(() => {
    if (brandLoading) return null;

    const layout: RouteObject = {
      path: '/',
      element: <Layout>{children}</Layout>,
      children: stickyRoutes.map(toRouterObject),
      errorElement: (
        <>
          <Header />
          <NotFound404 />
        </>
      ),
    };
    const basename = brand?.id ?? '';
    return createBrowserRouter([layout], {
      basename: `/${basename}`,
    });
  }, [brandLoading, children, stickyRoutes, brand?.id]);

  return router ? <RouterProvider router={router} /> : <></>;
};
