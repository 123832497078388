import type { MouseEventHandler } from 'react';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Tooltip } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button';
import { tss } from 'tss-react/mui';

import { useAuthStore, useLoginPopup } from '@sticky/auth';
import { ActionButton } from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Routes } from '../../../routes/routes';
import { BrandsService } from '../../brand/brands-service';
import {
  getCustomerWithSubscription,
  setCustomerLogAfterQuote,
} from '../../customer/store/customer-slice';

type AuthenticationLoginButtonProps = ButtonProps & {
  redirectUrl?: string;
  logAfterQuote?: boolean;
  customerMail?: string;
};

const useStyles = tss.create(() => ({
  errorTitle: { fontSize: '0.8rem', lineHeight: 1.41 },
  errorIcon: { marginRight: '0.5rem' },
}));

export const AuthenticationLoginButton = ({
  logAfterQuote,
  customerMail,
  onClick,
  children,
  redirectUrl,
  ...rest
}: AuthenticationLoginButtonProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loginPopup } = useLoginPopup();
  const authStore = useAuthStore();

  const error = useAppSelector(state => state.customer.error);

  const [loginRetry, setLoginRetry] = useState(false);

  const onLoginSuccess = useCallback(async () => {
    if (logAfterQuote) {
      dispatch(setCustomerLogAfterQuote());
    }
  }, [dispatch, navigate, logAfterQuote]);

  const onLoginButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async event => {
      if (onClick) {
        onClick(event);
      }

      // Redirige vers la page de migration dans le cadre de TMJ
      if (getFeatures().migration.enabled) {
        return navigate(
          [
            Routes.getPathById('migration/start'),
            redirectUrl && `redirect=${redirectUrl}`,
          ]
            .filter(Boolean)
            .join('?'),
        );
      }

      // Affiche la popup mid pour la connnexion
      loginPopup({
        email: customerMail,
        state: {
          [BrandsService.BRAND_ID_STORAGE_KEY]: BrandsService.brand?.id,
        },
        callback: () => {
          if (logAfterQuote) {
            dispatch(setCustomerLogAfterQuote());
          }

          if (redirectUrl) {
            navigate(redirectUrl);
          }
        },
      });
    },
    [customerMail, navigate, onClick, redirectUrl],
  );

  const onLoginRetry = useCallback(async () => {
    setLoginRetry(true);
    await dispatch(getCustomerWithSubscription());
    onLoginSuccess();
    setLoginRetry(false);
  }, [dispatch, onLoginSuccess]);

  if (error || authStore.error) {
    return (
      <ActionButton
        onClick={() => onLoginRetry()}
        loading={loginRetry}
        {...rest}
      >
        {!loginRetry && (
          <Tooltip
            title={
              <div className={classes.errorTitle}>
                {t('auth.login.button-fail-tooltip')}
              </div>
            }
          >
            <ErrorOutlineOutlinedIcon className={classes.errorIcon} />
          </Tooltip>
        )}
        {t('auth.login.button-fail')}
      </ActionButton>
    );
  }

  return (
    <ActionButton
      onClick={onLoginButtonClick}
      loading={authStore.loading}
      {...rest}
    >
      {!!authStore.error && !authStore.loading && (
        <Tooltip
          title={
            <div className={classes.errorTitle}>
              {t('auth.login.button-fail-tooltip')}
            </div>
          }
        >
          <ErrorOutlineOutlinedIcon className={classes.errorIcon} />
        </Tooltip>
      )}
      {children}
    </ActionButton>
  );
};
