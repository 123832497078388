import { useCallback, useEffect, useRef } from 'react';

import { openPopup } from '@sticky/helpers';

import type { GetSignUpUrlOptions } from './getSignUpUrl';
import { getSignUpUrl } from './getSignUpUrl';

export const SIGNUP_POPUP_ID = 'signup-popup' as const;

export const useSignUpPopup = () => {
  const popupRef = useRef<Window>();

  // Ferme la popup proprement
  const closePopup = () => {
    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  // Ouvre la fenêtre contextuelle de création de compte MID
  const signUpPopup = useCallback((options?: GetSignUpUrlOptions) => {
    try {
      popupRef.current = openPopup({
        id: SIGNUP_POPUP_ID,
        url: getSignUpUrl(options),
      });
    } catch (error) {
      closePopup();
    }
  }, []);

  useEffect(
    () => () => {
      closePopup();
    },
    [],
  );

  return { signUpPopup };
};
