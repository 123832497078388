import type { ChangeEvent } from 'react';
import type { FormControlLabelProps, SwitchProps } from '@mui/material';
import { FormControlLabel, Switch } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface ToggleProps
  extends Pick<
    FormControlLabelProps,
    'label' | 'labelPlacement' | 'checked' | 'disabled'
  > {
  labelRootClassName?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  inputClassName?: string;
  color?: SwitchProps['color'];
}

const useStyles = makeStyles()(({ typography: { pxToRem } }: Theme) => ({
  labelRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: `${pxToRem(21)} 0`,
  },
}));

export function Toggle({
  label,
  labelPlacement = 'start',
  labelRootClassName,
  onChange,
  checked,
  disabled,
  color,
  inputClassName,
}: ToggleProps): JSX.Element {
  const { classes, cx } = useStyles();
  return (
    <FormControlLabel
      classes={{
        root: cx(classes.labelRoot, labelRootClassName),
      }}
      control={
        <Switch
          disableRipple
          checked={checked}
          onChange={onChange}
          classes={{ input: inputClassName }}
          disabled={disabled}
          color={color}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
}
