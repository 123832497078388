import { z } from 'zod';

export const StrapiMediaSchema = z.object({
  data: z
    .object({
      attributes: z
        .object({
          url: z.string().nullish(),
        })
        .nullish(),
    })
    .nullish(),
});
