import type { BoxProps } from '@mui/system';
import { Box } from '@mui/system';
import { tss } from 'tss-react/mui';

const useStyles = tss
  .withNestedSelectors<'dark'>()
  .create(({ theme, classes }) => ({
    root: {
      display: 'flex',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: '1.4rem',

      [theme.breakpoints.up('breakPointDesktop')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: '1.6rem',
      },
    },
    dark: {
      backgroundColor: theme.app.colors.black,
      color: theme.app.colors.white,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),

      [theme.breakpoints.up('breakPointDesktop')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    content: {
      width: '100%',
      borderBottom: `1px solid ${theme.app.colors.warmGray1}`,
      textAlign: 'center',

      [`.${classes.dark} &`]: {
        borderBottom: 0,
      },

      [theme.breakpoints.up('breakPointDesktop')]: {
        textAlign: 'left',
      },
    },
  }));

type PanelHeader = BoxProps & {
  variant?: 'default' | 'dark';
};

export const PanelHeader = ({
  className,
  children,
  variant,
  ...rest
}: PanelHeader) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(
        classes.root,
        variant === 'dark' && classes.dark,
        className,
      )}
      {...rest}
    >
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};
