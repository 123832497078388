type CaptchaSubscriber = () => void;

let subscriber: CaptchaSubscriber;

export const subscribeCaptchaError = (captchaSubscriber: CaptchaSubscriber) => {
  subscriber = captchaSubscriber;
};

export const onCaptchaError = () => {
  subscriber && subscriber();
};
