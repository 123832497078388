import { getEnv } from '@sticky/config';

import { EncryptStorage } from '../utils/encrypt-storage';

// Set MAX TTL
export const STORE_EXPIRATION_TIME = 7 * 86400 * 1000;

// Export store names
export const BILL_TO_STORE = 'billTo';

// Configure storage
const encryptKey = getEnv('VITE_LOCALSTORAGE_ENCRYPT_KEY');
const encryptPrefix = '@sticky';
const encryptStorage = EncryptStorage(encryptKey as string, {
  prefix: encryptPrefix,
  expiresIn: STORE_EXPIRATION_TIME,
});

// Export instance
export { encryptStorage };

// Get state from localStorage
export const loadPersistentState = <T = string>(
  store: string,
): T | undefined => {
  try {
    const serializedState = encryptStorage.getItem(store);
    if (serializedState === null) return undefined;
    return serializedState;
  } catch (err) {
    return undefined;
  }
};

// Persist state in localStorage
export const savePersistentState = <T>(store: string, state: T): void => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = { ...state };
    delete payload.error;
    delete payload.loading;
    delete payload.message;
    encryptStorage.setItem(store, payload);
  } catch {
    // ignore write errors
  }
};

// Change or set an expiration timestamp on a store
export const setPersistentStateExpiration = (
  store: string,
  expiresAt: number,
): boolean => encryptStorage.setExpiresAt(store, expiresAt);

// Clean pertisant state
export const cleanPersistentState = (store: string): void => {
  encryptStorage.removeItem(store);
};
