import { toQueryString } from '@sticky/helpers';

import { midEnvVars } from '../../config';

type Input = {
  // Url appelée lorsque l'utilisateur clique sur "Activer mon compte" dans som
  // email d'activation après la création du compte.
  callback: string;
};

/**
 * Retourne l'url de la popup de création de compte
 */
export const signUpUrl = (input: Input) =>
  `${midEnvVars.VITE_MID_URL}/createAccount?${toQueryString({
    async: false,
    callback: encodeURI(input.callback.replace('www.', '')),
    client_id: midEnvVars.VITE_MID_CLIENT_ID,
  })}`;
