import type { PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export const AccordionContext = createContext<AccordionContextType | undefined>(
  undefined,
);

type AccordionContextType = {
  id: string;
  expanded: boolean;
  toggle: () => void;
};

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error(
      'Accordion compound components cannot be rendered outside the Accordion component',
    );
  }

  return context;
};

export const AccordionProvider = ({
  id,
  defaultExpanded,
  children,
}: PropsWithChildren<{ id: string; defaultExpanded?: boolean }>) => {
  // Pour ouvrir ou fermer par défaut l'accordéon, on se base sur la valeur
  // donnée dans les props sinon en se basant sur le hash dans l'url
  const [expanded, setExpandedValue] = useState<boolean>(
    defaultExpanded ?? `#${id}` === window.location.hash,
  );

  // Permet d'ouvrir ou de fermer l'accordéon
  const toggle = useCallback(() => {
    // Si l'url contient l'id, on le supprime de l'url
    if (`#${id}` === window.location.hash) {
      window.history.replaceState(null, '', window.location.pathname);
    }

    setExpandedValue(!expanded);
  }, [expanded]);

  useEffect(() => {
    if (`#${id}` === window.location.hash) {
      setExpandedValue(true);
    }
  }, [window.location.hash, id]);

  return (
    <AccordionContext.Provider value={{ id, expanded, toggle }}>
      {children}
    </AccordionContext.Provider>
  );
};
