import { Box, Container, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { makeStyles } from 'tss-react/mui';

import { getEnv } from '@sticky/config';
import { t } from '@sticky/i18n';

import { TypoWithSkeleton } from '../../../../components';
import { useAppSelector } from '../../../../hooks';
import { customerSelectors } from '../../selectors';

import { NavigationHeaderCardNumber } from './navigation-header-card-number';
import { NavigationHeaderCounter } from './navigation-header-counter';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { colors },
  } = theme;

  return {
    root: {
      display: 'grid',
      gridTemplateColumns: '60% 40%',
      gridTemplateAreas: `"userInfoContainer remainingContainer"`,
      paddingTop: '17px',
      paddingBottom: '17px',
      [breakpoints.up('breakPointDesktop')]: {
        gridTemplateAreas: `"userInfoContainer"
                            "remainingContainer"`,
        gridTemplateColumns: '100%',
        gridTemplateRows: 'min-content min-content',
        gridGap: '1px',
        color: colors.textSecondary,
        backgroundColor: colors.headerMenuBackground,
        borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
      },
      '&.MuiContainer-root': {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    userInfoContainer: {
      maxWidth: '360px',
      gridArea: 'userInfoContainer',
    },
    typography: {
      color: colors.textPrimary,
      [breakpoints.up('breakPointDesktop')]: {
        color: colors.textSecondary,
      },
    },
    typographyFullName: {
      '&.MuiTypography-root': {
        fontSize: theme.typography.pxToRem(23),
        fontWeight: 700,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
      },
    },
    typographyLastname: {
      textTransform: 'uppercase',
    },
  };
});

export const CustomerNavHeader = () => {
  const { classes } = useStyles();
  const {
    customer,
    error: customerError,
    loading: customerLoading,
  } = useAppSelector(state => state.customer);
  const hideReservationCounter = JSON.parse(
    getEnv('VITE_HIDE_RESERVATION_COUNTER', 'false'),
  );

  // Is ready
  const isReady = !!customer || (!!customerError && !customerLoading);

  const subscriptionCards = useAppSelector(customerSelectors.getAllowedCards);

  return (
    <Container
      className={classes.root}
      data-test-id="header-nav-informations"
      id="header-nav-informations"
    >
      <Box className={classes.userInfoContainer}>
        <Typography sx={visuallyHidden}>{t('customer.nav.aria')}</Typography>
        <TypoWithSkeleton
          component="div"
          className={classes.typography + ' ' + classes.typographyFullName}
          skelProps={{ width: '40%' }}
          isReady={isReady}
          data-dd-privacy="mask"
        >
          {customer?.firstName}{' '}
          <span className={classes.typographyLastname}>
            {customer?.lastName}
          </span>
        </TypoWithSkeleton>

        <NavigationHeaderCardNumber />
      </Box>
      {!hideReservationCounter && subscriptionCards?.length > 0 && (
        <NavigationHeaderCounter />
      )}
    </Container>
  );
};
