import { getEnv } from '@sticky/config';
import { getQueryParam, removeUndefined } from '@sticky/helpers';

import { BrandsService } from '../../brand/brands-service';

const compress = <T extends object>(state: T) => {
  const uint8Array = new TextEncoder().encode(
    JSON.stringify(removeUndefined(state)),
  );

  return btoa(
    String.fromCharCode.apply(null, uint8Array as unknown as number[]),
  );
};

const decompress = <T>(chars: string) => JSON.parse(atob(chars)) as T;

export class Basket {
  /**
   * Build the resumption link.
   */
  public static getResumptionLink({
    paymentCode,
    companyName,
    firstName,
    lastName,
  }: {
    paymentCode: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
  }) {
    const qs = new URLSearchParams(
      removeUndefined({
        repriseSouscription: `BASKET-ID-VALUE`,
        paymentCode,
        state:
          companyName || firstName || lastName
            ? compress({ companyName, firstName, lastName })
            : undefined,
      }),
    );

    if (BrandsService.brand?.id) {
      qs.set(BrandsService.BRAND_ID_STORAGE_KEY, BrandsService.brand?.id);
    }

    return `${getEnv('VITE_PUBLIC_URL')}?${qs.toString()}`;
  }

  public static readResumptionLink() {
    const basketId = getQueryParam('repriseSouscription');
    const paymentCode = getQueryParam('paymentCode');
    const compressState = getQueryParam('state');
    const state = compressState
      ? decompress<{
          firstName?: string;
          lastName?: string;
          companyName?: string;
        }>(compressState)
      : undefined;

    return {
      basketId: basketId?.length === 20 ? basketId : undefined,
      paymentCode: paymentCode?.length === 3 ? paymentCode : undefined,
      state,
    };
  }
}
