import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { SimpleTreeViewProps } from '@mui/x-tree-view';
import { SimpleTreeView } from '@mui/x-tree-view';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    fontSize: '1rem',
    '& ul': {
      marginLeft: '0.75rem',
      '& ul': {
        marginLeft: '0',
      },
    },
    '& li': {
      padding: '1.25rem 0',
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label':
      {
        backgroundColor: 'transparent',
      },
    '& .MuiTreeItem-root li': {
      padding: '0.63rem 0',
    },
  },
}));

export const TreeViewPanel = ({
  children,
  ...rest
}: SimpleTreeViewProps<false>) => {
  const { classes } = useStyles();

  return (
    <SimpleTreeView
      slots={{
        collapseIcon: ExpandMoreIcon,
        expandIcon: ChevronRightIcon,
      }}
      className={classes.root}
      {...rest}
    >
      {children}
    </SimpleTreeView>
  );
};
