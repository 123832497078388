import type { Theme } from '@mui/material';
import { Typography } from '@mui/material';
import { addHours, parseISO } from 'date-fns';
import { makeStyles } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import type { Travel } from '../..';
import { reservationSelectors, TravelConfirmed } from '../..';
import { useAppSelector } from '../../hooks';

import { InfoMessage } from './info-message';

const useStyles = makeStyles()(
  ({ breakpoints, app: { colors }, typography: { pxToRem } }: Theme) => ({
    actionsContainer: {
      gridArea: 'actions',
      width: '100%',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: pxToRem(15),
      [breakpoints.up('breakPointDesktop')]: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: pxToRem(30),
        marginTop: pxToRem(14),
      },
    },
    actionButton: {
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: 0,
      },
    },
    noShowLabel: {
      fontSize: '1.2rem',
      alignSelf: 'center',
    },
    noShowLabelConfirmed: {
      color: colors.successDarker,
    },
    autoCancelled: {
      width: '100%',
      fontSize: '1.2rem',
      textAlign: 'center',
      color: colors.errorDark,
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: pxToRem(14),
      },
    },
    actionInfo: {
      marginBottom: pxToRem(14),
      [breakpoints.up('breakPointDesktop')]: {
        marginBottom: 0,
      },
    },
  }),
);

export const NoShowActions = ({
  travel,
  onConfirmTravel,
  onCancelTravel,
}: {
  travel: Travel;
  onConfirmTravel: (travel: Travel) => void;
  onCancelTravel: (travel: Travel) => void;
}): JSX.Element => {
  const { classes } = useStyles();
  const travelConfirmed = travel.travelConfirmed;
  const confirmationDelayStart = addHours(
    parseISO(travel.departureDateTime),
    -48,
  );
  const trainIsConfirmedAfterDeparture =
    new Date() > parseISO(travel.departureDateTime);
  const details = useAppSelector(state =>
    reservationSelectors.getDetailedTravel(state, travel),
  );

  const isConfirmed = travelConfirmed === TravelConfirmed.CONFIRMED;
  const isUnknownAfterConfirmation =
    travelConfirmed !== TravelConfirmed.UNKNOWN_AFTER_CONFIRMATION;

  const showConfirmButton = isConfirmed && (
    <div className={`${classes.noShowLabel} ${classes.noShowLabelConfirmed}`}>
      {t('travels.detailsTravel.noShow.confirmed')}
    </div>
  );

  const showActionButton = isUnknownAfterConfirmation && (
    <ActionButton
      disabled={travelConfirmed !== TravelConfirmed.TO_BE_CONFIRMED}
      className={classes.actionButton}
      onClick={() => onConfirmTravel(travel)}
    >
      {t('travels.detailsTravel.noShow.buttonConfirm')}
    </ActionButton>
  );

  const confirmButton = isConfirmed ? showConfirmButton : showActionButton;

  const cancelButton = getFeatures().customer.noShow.cancel ? (
    <ActionButton
      variant={
        !details?.detailsState?.fetched || !details?.travelDetails?.refundable
          ? 'contained'
          : 'outlined'
      }
      onClick={() => onCancelTravel(travel)}
      disabled={!details?.travelDetails?.refundable}
    >
      {t('travels.detailsTravel.noShow.buttonCancel')}
    </ActionButton>
  ) : null;

  return (
    <div className={classes.actionsContainer}>
      <InfoMessage
        travelConfirmed={travelConfirmed}
        confirmationDelayStart={confirmationDelayStart}
      />
      {trainIsConfirmedAfterDeparture ||
      travelConfirmed === TravelConfirmed.CANCELED ||
      travelConfirmed === TravelConfirmed.WILL_BE_CANCELED ? null : (
        <div className={classes.buttonsContainer}>
          {cancelButton}
          {confirmButton}
        </div>
      )}
      <div className={`${classes.autoCancelled} ${classes.actionInfo}`}>
        {details?.detailsState?.fetched &&
          !details?.travelDetails?.refundable && (
            <Typography>{t('travels.detailsTravel.nonRefundable')}</Typography>
          )}
      </div>
    </div>
  );
};
