/* eslint-disable no-console */

import { ConfigCommon } from '@sticky/config';

import { BaseLoggerOutput } from './base';

export class LoggerOutputConsole extends BaseLoggerOutput {
  log(title: string, ...args: unknown[]) {
    if (!ConfigCommon.env.isProd) {
      const now = new Date();
      console.log.apply(console.log, [
        `[${now.toISOString()}]`,
        title,
        ...args,
      ]);
    }
  }

  warn(title: string, ...args: unknown[]) {
    const now = new Date();
    console.warn.apply(console.warn, [
      `[${now.toISOString()}]`,
      title,
      ...args,
    ]);
  }

  error(title: string, exception: Error | unknown) {
    const now = new Date();
    const args: (string | Error | unknown)[] = [
      `[${now.toISOString()}]`,
      title,
    ];
    if (exception instanceof Error) {
      args.push(exception.message);
    } else {
      args.push(exception);
    }
    console.error.apply(console.error, args);
  }
}
