import { differenceInYears, parse } from 'date-fns';

import { t } from '@sticky/i18n';

// Interface for a form validator function
export type IValidator = (
  value: string | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allValues?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any,
) => string | undefined;

// Combine array of form validators
export const composeValidators =
  (required = false, validators: IValidator[]): IValidator =>
  value =>
    validators.reduce((error: string | undefined, validator) => {
      if (!required && !value) return '';
      return error || validator(value);
    }, undefined);

// Validate a required field
export const validateRequired: (inputName?: string) => IValidator =
  (inputName = '') =>
  (value: string | undefined) => {
    const context = inputName ? {} : { context: 'noName' };
    return value
      ? undefined
      : t('form.requiredField', { inputName, ...context });
  };

// Regexp

// Validate lastname
export const validateLastname: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const regex = /^[ \u00c0-\u01ffa-zA-Z\\'-]{1,32}$/i;
  return regex.test(value.trim()) ? undefined : t('form.lastname.invalid');
};

// Validate firstname
export const validateFirstname: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const regex = /^[ \u00c0-\u01ffa-zA-Z\\'-]{2,38}$/i;
  return regex.test(value.trim()) ? undefined : t('form.firstname.invalid');
};

// Validate email
export const validateEmail: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const regex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(value.trim()) ? undefined : t('form.email.invalid');
};

// Validate address
export const validateAddress: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const maxLength = 50;
  if (value.length > maxLength) {
    return t('form.isTooLong', { size: maxLength });
  }

  const regex =
    /^[a-zA-Z\d\\' .,:&áàâäÁÀÂÄçÇéèêëÉÈÊËíîïÍÎÏÑñóôõöÓÔÕÖúùûüÚÙÛÜÿŸß-]{1,320}$/;
  const numberOnly = /^[0-9\s.,:]{1,320}$/;
  if (numberOnly.test(value.trim()) || !regex.test(value.trim())) {
    return t('form.streetAddress.invalid');
  }
};

// Validate addressNote
export const validateAddressNote: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const maxLength = 38;
  if (value.length > maxLength) {
    return t('form.isTooLong', { size: maxLength });
  }

  const regex =
    /^[a-zA-Z\d\\' .,:&áàâäÁÀÂÄçÇéèêëÉÈÊËíîïÍÎÏÑñóôõöÓÔÕÖúùûüÚÙÛÜÿŸß-]{1,320}$/;
  return regex.test(value.trim()) ? undefined : t('form.address.invalid');
};

// Validate zipCode
export const validateZipCode: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const regex = /^\d{5}$/;
  return regex.test(value.trim()) ? undefined : t('form.zipCode.invalid');
};

// Validate city
export const validateCity: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const regex =
    /^[a-zA-Z\\' .,:&áàâäÁÀÂÄçÇéèêëÉÈÊËíîïÍÎÏÑñóôõöÓÔÕÖúùûüÚÙÛÜÿŸß-]{1,40}$/;
  return regex.test(value.trim()) ? undefined : t('form.city.invalid');
};

// Validate country
export const validateCountry: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const regex =
    /^[a-zA-Z\\' .,:&áàâäÁÀÂÄçÇéèêëÉÈÊËíîïÍÎÏÑñóôõöÓÔÕÖúùûüÚÙÛÜÿŸß-]{1,38}$/;
  return regex.test(value.trim()) ? undefined : t('form.country.invalid');
};

// Validate a french date
export const validateDate: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const regex = /^([0-2]\d|(3)[0-1])(\/)(((0)\d)|((1)[0-2]))(\/)\d{4}$/;
  return regex.test(value.trim()) ? undefined : t('form.date.invalid');
};

// Validate a migration card number (9 digits) - The 8 digits are for prefix
export const validateCardNumberMigration: IValidator = (
  value: string | undefined,
) => {
  if (!value) {
    return undefined;
  }

  const regex = /^\d{9}$/;
  return regex.test(value.trim()) ? undefined : t('form.cardNumber.invalid');
};

const isTooYoung = (age: number) => age < 15;

const isTooOld = (age: number) => age > 110;

export const validateBirthDate: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const regex = /^([0-2]\d|(3)[0-1])(\/)(((0)\d)|((1)[0-2]))(\/)\d{4}$/;
  if (!regex.test(value)) {
    return t('form.birthdate.invalid');
  }

  const date = parse(value, 'dd/MM/yyyy', new Date());
  const today = new Date();
  const nbFullYears = differenceInYears(today, date);
  if (isTooYoung(nbFullYears)) {
    return t('form.isTooYoung');
  }
  if (isTooOld(nbFullYears)) {
    return t('form.isTooOld');
  }
};

// Validate companyName
export const validateCompanyName: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const regex = /^.{1,100}$/;
  return regex.test(value.trim()) ? undefined : t('form.companyName.invalid');
};

// Validate SIREN number
export const validateSiren: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const regex = /^[0-9]{9}$/;
  return regex.test(value.trim().replaceAll(' ', ''))
    ? undefined
    : t('form.siren.invalid');
};

// Validate IBAN
export const validateIban: IValidator = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  // IBAN format depends on the country, and the length is not fixed in its
  // specification. We assume it is between 15 and 35 characters long
  const regex = /^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{11,31}$/i;
  return regex.test(value.trim().replaceAll(' ', ''))
    ? undefined
    : t('form.iban.invalid');
};
