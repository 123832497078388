import type { StickyCustomVars, StickyThemeConfig } from './types';

export const createCustomVars = ({
  colors,
  assets,
}: StickyThemeConfig): StickyCustomVars => ({
  commonSpacing: 6,
  columnGap: '40px',
  colors,
  assets,
  button: {
    primary: {
      borderRadius: '1.5625rem',
      padding: '0.625rem 0.625rem 0.5625rem',
      height: '50px',
    },
    secondary: {
      borderRadius: '0.25rem',
      padding: '0.625rem 0.625rem 0.5625rem',
      height: '45px',
    },
  },
});
