import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { t } from '@sticky/i18n';

type LazyLoaderProps = {
  title?: string;
  description?: string;
  reloadTimeout?: number;
};

const useStyles = tss.create(({ theme }) => ({
  additionalContent: {
    position: 'fixed',
    top: '50%',
    left: theme.spacing(2),
    right: theme.spacing(2),
    textAlign: 'center',
    paddingTop: theme.spacing(8),
  },
}));

export const LazyLoader = ({
  title,
  description,
  reloadTimeout,
}: LazyLoaderProps) => {
  const { classes } = useStyles();
  const currentTimeout = useRef<NodeJS.Timeout | undefined>();
  const [showReload, setShowReload] = useState<boolean>(false);

  useEffect(() => {
    const destroyTimeout = () => {
      if (currentTimeout.current) {
        clearTimeout(currentTimeout.current);
      }
      currentTimeout.current = undefined;
    };

    destroyTimeout();

    if (!reloadTimeout) {
      setShowReload(false);
      return;
    }

    currentTimeout.current = setTimeout(() => {
      setShowReload(true);
    }, reloadTimeout);

    return () => {
      destroyTimeout();
    };
  }, [reloadTimeout]);

  return (
    <div className="content-loader">
      <div className="logo animated" />

      {title && (
        <Box className={classes.additionalContent}>
          <Typography variant="h3">{title}</Typography>

          {description && (
            <Typography variant="body1">{description}</Typography>
          )}

          {showReload && (
            <ActionButton
              sx={{ marginTop: 2 }}
              onClick={() => {
                window.location.reload();
              }}
            >
              {t('actions.reload')}
            </ActionButton>
          )}
        </Box>
      )}
    </div>
  );
};
