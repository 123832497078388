import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { StickyGatewayClient } from '@sticky/gateway-client';

import type { RootState } from '../../../store';
import { formatPhoneToApi } from '../../../utils/phone/phone';
import {
  cleanPersistentState,
  loadPersistentState,
  savePersistentState,
} from '../..';
import type { IIssuingCreditCardOutput } from '../../basket/models/basket';
import type { IBillToContact } from '../models/bill-to-contact';
import type { IEmployeeCertificationInput } from '../models/employee-certification';
import type {
  IConfirmRegularizationInput,
  IRegularizationInitInvoiceInput,
  IRegularizationInitInvoiceOutput,
} from '../models/invoice';
import type {
  IReadSubscriptionSummaryState,
  ISummary,
} from '../models/summary';
import SummaryRequest from '../services/summary-request';

// Name of the store
const SUBSCRIPTION_STORE = 'subscription';
export const SUBSCRIPTION_STORE_SHOW_CARD_NUMBER = `${SUBSCRIPTION_STORE}:number`;
const SUBSCRIPTION_STORE_REGULARIZATION = `${SUBSCRIPTION_STORE}:regularizationInfos`;
const SUBSCRIPTION_STORE_EDIT_BILL_TO = `${SUBSCRIPTION_STORE}:editBillTo`;

// Setup initial state
const initialState = (): IReadSubscriptionSummaryState => ({
  loading: false,
  error: '',
  at: 0,
  readAt: 0,
  ...loadPersistentState(SUBSCRIPTION_STORE_SHOW_CARD_NUMBER),
  ...loadPersistentState(SUBSCRIPTION_STORE_REGULARIZATION),
  ...loadPersistentState(SUBSCRIPTION_STORE_EDIT_BILL_TO),
});

export const readSubscription = createAsyncThunk(
  'posts/readSummary',
  async ({ cardNumber }: { cardNumber: string; force?: boolean }) =>
    (await StickyGatewayClient.authClient())
      .post<ISummary>(
        '/public/subscription/summary',
        new SummaryRequest(cardNumber),
      )
      .then(response => response.data)
      .catch((reason: AxiosError) => {
        if (reason.response?.status === 404) {
          return undefined;
        }
        throw reason;
      }),
  {
    condition: ({ cardNumber, force }, { getState }) => {
      if (!cardNumber) return false;
      if (force) return true;
      const { subscription } = getState() as RootState;
      const tenMinutes = 1000 * 60 * 10;
      if (
        subscription.loading ||
        (!subscription.error && Date.now() - subscription.readAt < tenMinutes)
      ) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  },
);

export const downloadEmployeeCertification = createAsyncThunk(
  'post/public/subscription/download-purchase-proof',
  async (employeeCertificationInput: IEmployeeCertificationInput) =>
    StickyGatewayClient.authClient()
      .post<string>(
        `/public/subscription/download-purchase-proof`,
        employeeCertificationInput,
        {
          headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(response => response.data)
      .catch((reason: AxiosError) => {
        if (reason.response?.status === 404) {
          return undefined;
        }
        throw reason;
      }),
);

export const confirmRegularization = createAsyncThunk(
  'post/invoice/regularization-confirm',
  async (confirmRegularizationInput: IConfirmRegularizationInput) =>
    StickyGatewayClient.authClient()
      .post<IIssuingCreditCardOutput>(
        `public/invoice/regularization-confirm`,
        confirmRegularizationInput,
      )
      .then(response => response.data)
      .catch((reason: AxiosError) => {
        if (reason.response?.status === 404) {
          return undefined;
        }
        throw reason;
      }),
);

export const regularizationInitInvoice = createAsyncThunk(
  'post/invoice/regularization-init',
  async (
    regularizationInitInvoiceInput: IRegularizationInitInvoiceInput,
    thunkApi,
  ) => {
    const httpClient = StickyGatewayClient.authClient();
    const asyncFn = httpClient.post<IRegularizationInitInvoiceOutput>(
      '/public/invoice/regularization-init',
      regularizationInitInvoiceInput,
    );
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

// Request Handler to update billTo
export const updateBillTo = createAsyncThunk(
  'post/updateBillTo',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (billTo: any, thunkApi) => {
    const { ...input } = billTo;
    const httpClient = StickyGatewayClient.authClient();

    const asyncFn = httpClient.post<IBillToContact>(
      '/public/customer/update-bill-to-v2',
      {
        lastName: input.lastName,
        firstName: input.firstName,
        companyName: input.companyName,
        civility: input.civility,
        workEmail: input.eMail,
        workPhone: formatPhoneToApi(input.phone),
        address1: input.address,
        address2: input.additionalAddress,
        zipCode: input.zipCode,
        city: input.city,
        country: input.country,
        cardNumber: input.cardNumber,
        RUM: input.rum,
      },
    );

    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

const subscriptionSlice = createSlice({
  name: SUBSCRIPTION_STORE,
  initialState: initialState(),
  reducers: {
    openModifyBillTo: (state, action) => {
      state.editBillto = action.payload || {
        ...action.payload,
        isBillToCompany: !!action.payload.companyName,
      };
    },
    saveModifyBillTo: (state, action) => {
      state.editBillto = action.payload || {
        ...action.payload,
        isBillToCompany: !!action.payload?.companyName,
      };
      savePersistentState(SUBSCRIPTION_STORE_EDIT_BILL_TO, {
        editBillto: state.editBillto,
      });
    },
    rememberSubscriptionSelectedCard: (state, action) => {
      state.subscriptionSelectCardNumber = action.payload;
      savePersistentState(SUBSCRIPTION_STORE_SHOW_CARD_NUMBER, {
        subscriptionSelectCardNumber: action.payload,
      });
    },
    setIsRegularized: state => {
      if (state.subscription?.subscription) {
        state.subscription.subscription.isRegularized = true;
      }
    },
    cancelModifyBillTo: state => {
      delete state.editBillto;
      cleanPersistentState(SUBSCRIPTION_STORE_EDIT_BILL_TO);
    },
    clearError: state => {
      delete state.error;
    },
    clearIsRegularized: state => {
      if (state.subscription?.subscription) {
        state.subscription.subscription.isRegularized = false;
      }
    },

    clearSubscription: () => {
      cleanPersistentState(SUBSCRIPTION_STORE_SHOW_CARD_NUMBER);
      cleanPersistentState(SUBSCRIPTION_STORE_REGULARIZATION);
      cleanPersistentState(SUBSCRIPTION_STORE_EDIT_BILL_TO);
      return initialState();
    },
    clearReceipt: state => {
      state.receipt = '';
      state.at = Date.now();
    },
    clearInvoice: state => {
      state.invoice = '';
      state.at = Date.now();
    },
    clearRegularizationInfos: state => {
      state.regularizationInfos = undefined;
    },
    clearUpdateBillTo: state => {
      delete state.message;
      delete state.editBillto;
      delete state.error;
      cleanPersistentState(SUBSCRIPTION_STORE_EDIT_BILL_TO);
    },
  },
  extraReducers: builder => {
    builder.addCase(readSubscription.pending, state => {
      state.loading = true;
      state.at = Date.now();
      state.readAt = Date.now();
    });
    builder.addCase(readSubscription.fulfilled, (state, action) => {
      state.loading = false;
      state.subscription = action.payload;
      if (state.subscription?.subscription) {
        state.subscription.subscription.ratePlans =
          state.subscription?.subscription?.ratePlans.filter(
            r => r.endDate && r.startDate,
          );
      }
      // FIXME Preparation for implementation of https://jira.vsct.fr/browse/SYG-796
      if (state.subscription?.subscription?.ratePlans?.length) {
        state.subscription.subscription.ratePlanSelected =
          state.subscription?.subscription?.ratePlans[0];
      }
      if (state.subscription?.billToContact) {
        state.subscription.billToContact.isBillToCompany =
          !!state.subscription.billToContact.companyName;
      }
    });
    builder.addCase(readSubscription.rejected, state => {
      state.loading = false;
      state.error = 'default_error';
    });
    builder.addCase(downloadEmployeeCertification.pending, state => {
      state.loading = true;
      state.at = Date.now();
    });
    builder.addCase(
      downloadEmployeeCertification.fulfilled,
      (state, action) => {
        state.receipt = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(downloadEmployeeCertification.rejected, state => {
      state.loading = false;
      // TODO unstable => remove errors and wait for a better error management
      // state.error = 'errors.subscription.download';
    });

    builder.addCase(updateBillTo.pending, state => {
      state.loading = true;
      state.at = Date.now();
      state.readAt = 0;
    });
    builder.addCase(updateBillTo.fulfilled, (state, action) => {
      state.loading = false;
      state.message = 'UPDATE_SUCCESS';
      state.error = '';
      delete state.editBillto;
      if (state.subscription?.billToContact) {
        state.subscription.billToContact = {
          ...state.subscription.billToContact,
          eMail: action.meta.arg.eMail,
          phone: action.meta.arg.phone,
          address: action.meta.arg.address,
          additionalAddress: action.meta.arg.additionalAddress,
          city: action.meta.arg.city,
          zipCode: action.meta.arg.zipCode,
          country: action.meta.arg.country,
          companyName: action.meta.arg.companyName,
          civility: action.meta.arg.civility,
          firstName: action.meta.arg.firstName,
          lastName: action.meta.arg.lastName,
        } as IBillToContact;
      }
    });
    builder.addCase(updateBillTo.rejected, state => {
      state.loading = false;
      state.message = 'default_error';
      state.error = 'default_error';
    });

    builder.addCase(confirmRegularization.pending, state => {
      state.loading = true;
      state.at = Date.now();
      state.readAt = 0;
    });
    builder.addCase(confirmRegularization.fulfilled, (state, action) => {
      if (state.subscription?.subscription) {
        state.subscription.subscription.issuingRegularization =
          action.payload as IIssuingCreditCardOutput;
      }
      state.error = '';
      state.loading = false;
    });
    builder.addCase(confirmRegularization.rejected, state => {
      state.loading = false;
      state.error = 'error';
      state.message = '';
    });

    builder.addCase(regularizationInitInvoice.pending, state => {
      state.loading = true;
      state.at = Date.now();
      state.message = '';
      state.error = '';
    });
    builder.addCase(regularizationInitInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.message = '';
      state.error = '';
      const sub = {
        ...state.subscription?.subscription,
        regularization: action.payload,
      };
      state.subscription = {
        ...state.subscription,
        subscription: sub,
      } as ISummary;
      savePersistentState(SUBSCRIPTION_STORE_REGULARIZATION, {
        regularizationInfos: {
          invoiceId: action.meta.arg.invoiceId,
          paymentId: action.payload.paymentId,
        },
      });
    });
    builder.addCase(regularizationInitInvoice.rejected, state => {
      state.loading = false;
      state.message = 'regularization';
      state.error = 'default_error';
    });
  },
});

export const {
  cancelModifyBillTo,
  clearError,
  clearIsRegularized,
  clearSubscription,
  clearReceipt,
  clearInvoice,
  clearRegularizationInfos,
  openModifyBillTo,
  saveModifyBillTo,
  setIsRegularized,
  rememberSubscriptionSelectedCard,
  clearUpdateBillTo,
} = subscriptionSlice.actions;

export const subscriptionReducer = subscriptionSlice.reducer;
