import { enabled } from './enabled';
import { sessionReplaySampleRate } from './session-replay-samplerate';
import { sessionSampleRate } from './session-samplerate';
import { traceSampleRate } from './trace-samplerate';
import { trackUserInteractions } from './track-user-interactions';

export const datadog = {
  enabled,
  sessionSampleRate,
  traceSampleRate,
  sessionReplaySampleRate,
  trackUserInteractions,
};
