import React from 'react';

import { t, Trans } from '@sticky/i18n';

import { ModalBox } from '../modal-box';

interface EnrollmentErrorModalProps {
  open: boolean;
  onClose: () => void;
}

export const QuoteSalesErrorModal = ({
  open,
  onClose,
}: EnrollmentErrorModalProps): JSX.Element => (
  <ModalBox
    open={open}
    title={t('enrollment.quote-sales-error.title')}
    titleComponent="h2"
    closeButton="header"
    onClose={onClose}
  >
    <Trans i18nKey="enrollment.quote-sales-error.message" />
  </ModalBox>
);
