import { useNavigate } from 'react-router-dom';
import type { Theme } from '@mui/material';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  ActionButton,
  KeyNumber,
  Panel,
  PanelContent,
  Wrapper,
} from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { Routes } from '../../routes/routes';
import { useStrapiConfig } from '../../utils/useStrapiConfig';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(2)} 0`,

    [theme.breakpoints.up('breakPointDesktop')]: {
      backgroundImage:
        'url(/assets/free-places/free-places-home-widget-background-desktop.png)',
      backgroundPosition: 'top 0 left -2rem',
      backgroundSize: '22rem 14rem',
      backgroundRepeat: 'no-repeat',
    },
  },
  panelContent: {
    [theme.breakpoints.up('breakPointTablet')]: {
      display: 'flex',
      gap: '3rem',
      justifyContent: 'space-around',
    },
  },
  content: {
    margin: 'auto',
    [theme.breakpoints.up('breakPointTablet')]: {
      alignItems: 'center',
    },
  },
  keyNumbers: {
    display: 'flex',
    flexFlow: 'wrap',
    marginBottom: '2rem',
    gap: '1rem',
    [theme.breakpoints.up('breakPointTablet')]: {
      flexDirection: 'row',
      gap: '2rem',
    },
  },
  keyNumber: {
    [theme.breakpoints.up('breakPointTablet')]: {
      maxWidth: '16rem',
      margin: 'auto',
    },
  },
  freePlacesContent: {
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center',
  },
  freePlacesStack: {
    alignItems: 'center',
  },
  freePlacesTitle: {
    paddingBottom: '1rem',
    fontWeight: 'bold',
  },
  freePlacesText: {
    marginBottom: '1.5rem',
    [theme.breakpoints.up('breakPointTablet')]: {
      textAlign: 'center',
      maxWidth: '27rem',
    },
  },
  pictureMobile: {
    minWidth: '5rem',
    width: '12%',
  },
  pictureDesktop: {
    width: '7rem',
    objectFit: 'contain',
    [theme.breakpoints.up('breakPointDesktop')]: {
      marginLeft: '15rem',
    },
  },
  button: {
    [theme.breakpoints.down('breakPointTablet')]: {
      minWidth: 0,
    },
  },
}));

export const FreePlacesHomeWidget = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointTablet'));

  const strapiConfigQuery = useStrapiConfig();
  const bannerKeyNumbers = strapiConfigQuery.data?.attributes?.bannerKeyNumbers;

  return (
    <Wrapper>
      <Panel className={classes.root}>
        <PanelContent className={classes.panelContent}>
          {isDesktop && (
            <img
              src="/assets/free-places/free-places-home-widget-desktop.png"
              alt=""
              className={classes.pictureDesktop}
            />
          )}
          <Stack className={classes.content}>
            {bannerKeyNumbers?.visibility && (
              <>
                <Typography variant="h3" textAlign="center" marginBottom={2}>
                  {bannerKeyNumbers?.title}
                </Typography>
                <div className={classes.keyNumbers}>
                  {bannerKeyNumbers?.keyNumbers?.map((keyNumber, index) => (
                    <KeyNumber
                      key={index}
                      isPercentage
                      number={keyNumber.number}
                      text={keyNumber.text}
                      className={classes.keyNumber}
                    />
                  ))}
                </div>
              </>
            )}
            <Typography className={classes.freePlacesTitle} variant="h4">
              {getFeatures().enrichment.enabled
                ? t('free-places.widget-home.titleAvantage')
                : t('free-places.widget-home.title')}
            </Typography>
            <div className={classes.freePlacesContent}>
              <Stack className={classes.freePlacesStack}>
                <Typography variant="body1" className={classes.freePlacesText}>
                  {t('free-places.widget-home.content')}
                </Typography>

                <ActionButton
                  className={classes.button}
                  onClick={() =>
                    navigate(Routes.getPathById('free-places/available-places'))
                  }
                >
                  {t('free-places.name')}
                </ActionButton>
              </Stack>
              {!isDesktop && (
                <img
                  src="/assets/free-places/free-places-home-widget-mobile.png"
                  alt=""
                  className={classes.pictureMobile}
                />
              )}
            </div>
          </Stack>
        </PanelContent>
      </Panel>
    </Wrapper>
  );
};
