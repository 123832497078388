import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { StickyGatewayClient } from '@sticky/gateway-client';

import type { RootState } from '../../..';
import { cleanPersistentState } from '../../index';
import type {
  IEquivalentStationResponse,
  IEquivalentStationsState,
} from '../models/equivalent-stations';

export interface Od {
  origin: string;
  destination: string;
}

const initialState: IEquivalentStationsState = {
  loading: false,
  error: '',
};

export const getEquivalentStationsList = createAsyncThunk(
  'get/equivalentStationsList',
  async (od: Od) => {
    const { data } =
      await StickyGatewayClient.authClient().get<IEquivalentStationResponse>(
        '/public/reservation/equivalent-stations',
        {
          params: {
            origin: od.origin,
            destination: od.destination,
          },
        },
      );
    return data;
  },
  {
    condition: (_, { getState }) => {
      const { equivalentStations } = getState() as RootState;
      if (
        equivalentStations.loading ||
        equivalentStations.equivalentStations?.length
      ) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  },
);

const EQUIVALENT_STATIONS_STORE_NAME = 'equivalentStations';

const equivalentStationsSlice = createSlice({
  name: EQUIVALENT_STATIONS_STORE_NAME,
  initialState,
  reducers: {
    clearEquivalentStations: () => {
      cleanPersistentState(EQUIVALENT_STATIONS_STORE_NAME);
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(getEquivalentStationsList.pending, state => {
      state.loading = true;
    });
    builder.addCase(getEquivalentStationsList.fulfilled, (state, action) => {
      state.loading = false;
      state.equivalentStations = action.payload.travel;
    });
    builder.addCase(getEquivalentStationsList.rejected, state => {
      state.loading = false;
      state.error = 'errors.generic-error';
    });
  },
});

export const { clearEquivalentStations } = equivalentStationsSlice.actions;

const equivalentStationsReducer = equivalentStationsSlice.reducer;
export { equivalentStationsReducer };
