import { midClient } from '../../clients';
import { useAuthStore } from '../../store';

/**
 * Retourne l'URL de déconnexion de MID (Mon Identifiant)
 */
export const getLogoutUrl = () => {
  const authState = useAuthStore.getState();

  if (!authState.config) {
    throw new Error('The auth store should be initialized');
  }

  const idToken = authState.auth?.idToken;
  const redirectUrl = authState.config.logoutRedirectUri;

  // Si le jeton d'identité n'est pas présent, l'utilisateur est déjà
  // déconnecté, retourne l'URL de redirection
  if (!idToken) {
    return redirectUrl;
  }

  return midClient.logoutUrl({
    id_token_hint: idToken,
    post_logout_redirect_uri: redirectUrl,
  });
};
