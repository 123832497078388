import type { ReactNode, RefObject } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { formatPrice } from '@sticky/helpers';
import { t, Trans } from '@sticky/i18n';

import { RadioCard } from './radio-card';

interface RadioCardPlanProps {
  title?: ReactNode | string;
  label?: string;
  value: string;
  pricePerMonth?: number;
  pricePerYear?: number;
  selected?: string;
  tabIndex?: number;
  'aria-label'?: string;

  onChange(value?: string | null): void;

  inputRef?: RefObject<HTMLElement>;
}

const useStyles = makeStyles()(() => ({
  pricePerMonth: { paddingTop: '1.5rem' },
}));

export const RadioCardPlan = (props: RadioCardPlanProps): JSX.Element => {
  const { classes } = useStyles();
  const {
    title,
    value,
    label,
    tabIndex,
    onChange,
    selected,
    pricePerMonth,
    pricePerYear,
    inputRef,
  } = props;
  const ariaLabel =
    props['aria-label'] ??
    `${t('actions.choose')} ${label} : ${formatPrice(pricePerMonth as number, {
      condensed: true,
    })} ${t('per')} ${t('proposal.month')} ${t('proposal.vat-included')}`;
  return (
    <RadioCard
      value={value}
      selected={selected}
      onChange={onChange}
      title={title}
      label={label}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      inputRef={inputRef}
    >
      {pricePerYear ? (
        <>
          <Typography
            variant="subtitle1"
            color="primary"
            className={classes.pricePerMonth}
            role="none"
          >
            {formatPrice(pricePerMonth as number, {
              condensed: true,
            })}{' '}
            / {t('proposal.month')} {t('proposal.vat-included')}
          </Typography>
          <Typography variant="body1">
            {t('proposal.total-annual-amount-is')}
          </Typography>
          <Typography variant="subtitle2" color="primary" role="none">
            {formatPrice(pricePerYear, {
              condensed: true,
            })}{' '}
            {t('proposal.vat-included')}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body1" role="none">
            <Trans i18nKey="proposal.amount.monthly" />
          </Typography>
          <Typography variant="subtitle1" color="primary" role="none">
            {formatPrice(pricePerMonth as number, {
              condensed: true,
            })}{' '}
            / {t('proposal.month')} {t('proposal.vat-included')}
          </Typography>
        </>
      )}
    </RadioCard>
  );
};
