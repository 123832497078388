import { getFeatures } from '@sticky/config';
import { DidomiVendorDatadog, useDidomi } from '@sticky/tracker-didomi';

export const useTracker = () => {
  const { isConsentendVendor } = useDidomi();

  const isDidomiEnabled = getFeatures().tracker.didomi.enabled;

  const isDatadogEnabled =
    getFeatures().tracker.datadog.enabled &&
    getFeatures().tracker.didomi.enabled &&
    isConsentendVendor(DidomiVendorDatadog.id);

  return {
    isDidomiEnabled,
    isDatadogEnabled,
  };
};
