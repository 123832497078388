import { lazy } from 'react';

import { t } from '@sticky/i18n';

import { LAYOUT } from '../layout';
import type { IRoute } from '../routes';

const routes: IRoute[] = [
  {
    id: 'enrollment/mandate',
    path: '/souscription/mandat',
    title: t('enrollment.mandate.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(() => import('../../pages/enrollment/mandate/index')),
    private: true,
  },
  {
    id: 'enrollment/bank-list',
    path: '/souscription/liste-banques',
    title: t('enrollment.mandate.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(() => import('../../pages/enrollment/mandate/bank-list')),
    private: true,
  },
  {
    id: 'enrollment/bank-branch-list',
    path: '/souscription/liste-branches',
    title: t('enrollment.mandate.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/mandate/bank-branch-list'),
    ),
    private: true,
  },
  {
    id: 'enrollment/iban-input',
    path: '/souscription/saisie-iban',
    title: t('enrollment.mandate.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(() => import('../../pages/enrollment/mandate/iban-input')),
    private: true,
  },
  {
    id: 'enrollment/mandate/update',
    path: '/souscription/utiliser-mandat',
    title: t('enrollment.mandate.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/mandate/update-mandate'),
    ),
    private: true,
  },
  {
    id: 'enrollment/mandate/sign-mandate',
    path: '/souscription/signature-mandat',
    title: t('enrollment.mandate.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(() => import('../../pages/enrollment/mandate/sign-mandate')),
    private: true,
  },
  {
    id: 'enrollment/mandate/issuing',
    path: '/souscription/signature-mandat-attente',
    title: t('enrollment.mandate.pageTitle'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/mandate/sign-mandate-issuing'),
    ),
    private: true,
  },
  {
    id: 'enrollment/mandate/unavailable',
    path: '/souscription/signature-mandat-indisponible',
    title: t('enrollment.mandate.error'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/mandate/sign-mandate-failed'),
    ),
    private: true,
  },
  {
    id: 'enrollment/mandate/error',
    path: '/souscription/signature-mandat-erreur',
    title: t('enrollment.mandate.error'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(
      () => import('../../pages/enrollment/mandate/sepa-unavailable'),
    ),
    private: true,
  },
  {
    id: 'enrollment/verification-otu',
    path: '/souscription/signature-mandat-code-otu',
    title: t('enrollment.mandate.error'),
    layout: LAYOUT.ENROLLMENT,
    element: lazy(() => import('../../pages/enrollment/mandate/code-otu')),
    private: true,
  },
];

export default routes;
