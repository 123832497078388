import { Hidden, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { getLogoutUrl, useAuthStore, useSignUpPopup } from '@sticky/auth';
import { ActionButton } from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { AuthenticationLoginButton } from '../../features/authentication';
import { useAppSelector } from '../../hooks';
import { Routes } from '../../routes/routes';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    display: 'flex',
    marginTop: theme.spacing(4),
    alignSelf: 'start',
  },
  info: {
    fontSize: theme.spacing(2.5),
    marginRight: theme.spacing(3),
    alignSelf: 'center',
  },
  fullname: {
    fontSize: theme.spacing(2.5),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    marginRight: theme.spacing(3),
    alignSelf: 'center',
  },
  spacing: {
    marginLeft: theme.spacing(3),
  },
}));

export const HomeLogin = () => {
  const { classes } = useStyles();
  const customer = useAppSelector(state => state.customer.customer);
  const subscription = useAppSelector(state => state.subscription.subscription);
  const authStore = useAuthStore();
  const { signUpPopup } = useSignUpPopup();

  if (authStore.status === 'authenticated') {
    const redirectUrl = subscription?.subscription
      ? Routes.getPathById('customer/my-travels')
      : Routes.getPathById('customer/my-coordinates');

    return (
      <div className={classes.root}>
        <Hidden lgDown>
          <Typography
            variant="subtitle2"
            noWrap
            className={classes.fullname}
            role="none"
            data-dd-privacy="mask"
          >
            {customer?.firstName} {customer?.lastName}
          </Typography>
        </Hidden>

        <ActionButton variant="contained" href={redirectUrl}>
          {t('auth.account.access')}
        </ActionButton>

        <ActionButton
          variant="outlined"
          className={classes.spacing}
          href={getLogoutUrl()}
          target="_self"
        >
          {t('auth.logout.button')}
        </ActionButton>
      </div>
    );
  }

  const info = t('auth.info');
  return (
    <div className={classes.root}>
      {'auth.info' !== info && (
        <Hidden lgDown>
          <Typography
            variant="subtitle2"
            noWrap
            className={classes.info}
            role="none"
          >
            {info}
          </Typography>
        </Hidden>
      )}
      <AuthenticationLoginButton
        variant="contained"
        redirectUrl={Routes.getPathById('customer/my-travels')}
      >
        {t('auth.login.button')}
      </AuthenticationLoginButton>

      {getFeatures().auth.midSignUp ? (
        <ActionButton
          variant="outlined"
          className={classes.spacing}
          onClick={() => {
            signUpPopup();
          }}
        >
          {t('auth.account.create')}
        </ActionButton>
      ) : (
        <ActionButton
          variant="outlined"
          className={classes.spacing}
          href={Routes.getPathById('customer/signup')}
        >
          {t('auth.account.create')}
        </ActionButton>
      )}
    </div>
  );
};
