import { useEffect, useState } from 'react';

import { getEnv } from '@sticky/config';

import {
  getOfferTypeQuote,
  infoStock,
} from '../../features/proposal/store/proposal-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { QuoteSalesErrorModal } from '../modal/quote-sales-error/quote-sales-error-modal';

import { Offers } from './offers';
import type { Offer } from './types';

interface OfferWithPriceCardProps {
  offers: Offer[];
}

export const OfferWithPriceCard = ({
  offers,
}: OfferWithPriceCardProps): JSX.Element => {
  const offerTypeQuotes = useAppSelector(
    state => state.proposal.offerTypeQuotes,
  );
  const projectedInventory = useAppSelector(
    state => state.proposal.projectedInventory,
  );
  const serviceUnavailable = useAppSelector(
    state => state.proposal.serviceUnavailable,
  );

  const dispatch = useAppDispatch();

  const [isDisplayQuoteSalesErrorModal, setDisplayQuoteSalesErrorModal] =
    useState(false);

  // Fetch offer type quotes if not yet fetched
  useEffect(() => {
    if (
      !offerTypeQuotes?.loading &&
      !offerTypeQuotes?.error &&
      typeof offerTypeQuotes?.value === 'undefined'
    ) {
      dispatch(getOfferTypeQuote(getEnv('VITE_PRODUCT_OFFER_TYPE')));
    }
  }, [
    dispatch,
    offerTypeQuotes?.error,
    offerTypeQuotes?.loading,
    offerTypeQuotes?.value,
  ]);

  // FetchinfoStock datas if not yet fetched
  useEffect(() => {
    if (
      !projectedInventory?.loading &&
      !projectedInventory?.error &&
      typeof projectedInventory?.value === 'undefined'
    ) {
      dispatch(infoStock());
    }
  }, [
    dispatch,
    projectedInventory?.error,
    projectedInventory?.loading,
    projectedInventory?.value,
  ]);

  // Manage quote sales service error
  useEffect(() => {
    if (serviceUnavailable) {
      setDisplayQuoteSalesErrorModal(true);
    }
  }, [serviceUnavailable]);

  return (
    <>
      <QuoteSalesErrorModal
        open={isDisplayQuoteSalesErrorModal}
        onClose={() => setDisplayQuoteSalesErrorModal(false)}
      />
      <Offers offers={offers} withPriceCard />
    </>
  );
};
