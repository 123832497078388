import { Trans as ReactTrans } from 'react-i18next';
import i18n from 'i18next';

import { setup } from './setup';

export * from './t';
export * from './types';

setup();

// Inspired by this issue (https://github.com/i18next/react-i18next/issues/1375)
// to fix interpolation problems on some occurrences
const Trans: typeof ReactTrans = ({ values = {}, ...rest }) =>
  ReactTrans({ values, ...rest });

export { i18n, Trans };
