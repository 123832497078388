import { stickyClient } from '../../clients';
import { useAuthStore } from '../../store';

/**
 * Dernière étape de la connexion après avoir récupéré le code
 * d'authentification MID.
 */
export const loginWithAuthenticateCode = async ({
  authenticationCode,
}: {
  authenticationCode: string;
}) => {
  try {
    const loginOutput = await stickyClient.login({
      authCode: authenticationCode,
      redirectUri: useAuthStore.getState().config!.loginRedirectUri,
    });
    useAuthStore.getState().login(loginOutput.data);

    const onLogin = useAuthStore.getState().config!.onLogin;
    if (onLogin) {
      await onLogin();
    }

    useAuthStore.getState().postLogin();
  } catch (error) {
    useAuthStore.getState().postLogin(error);
  }
};
