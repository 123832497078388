import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { TypoWithSkeleton } from '../typography/typography-skeleton';

const root = {
  padding: '1.25rem 0.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 'auto',
  width: '100%',
  minWidth: 'initial',
  borderRadius: 0,
};

const useStyles = makeStyles()((theme: Theme) => ({
  navigationList: {
    ...root,
    textTransform: 'none',
    '& svg': {
      fill: theme.palette.primary.main,
    },
    '& .MuiTypography-body1': {
      textAlign: 'left',
    },
  },
  active: {
    '&.MuiTypography-body1': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

export const NavigationList = (props: NavigationListProps): JSX.Element => {
  const { classes } = useStyles();

  const { isReady = true } = props;
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const renderTitle = () => (
    <>
      <TypoWithSkeleton
        className={props.isActive ? classes.active : ''}
        component="p"
        skelProps={{ width: '60%' }}
        isReady={isReady}
      >
        <span role="heading" aria-level={props.ariaLevel}>
          {props.title}
        </span>
      </TypoWithSkeleton>
      <ChevronRightIcon />
    </>
  );

  return (
    <>
      {props.onClick ? (
        <Button
          role={props.role}
          id={props.id}
          aria-controls={props['aria-controls']}
          aria-selected={props['aria-selected']}
          tabIndex={props.tabindex}
          className={classes.navigationList}
          onClick={handleClick}
        >
          {renderTitle()}
        </Button>
      ) : (
        <div className={classes.navigationList}>{renderTitle()}</div>
      )}
    </>
  );
};

export interface NavigationListProps {
  id?: string;
  title: string;
  onClick?: () => void;
  isActive?: boolean;
  isReady?: boolean;
  role?: string;
  ariaLevel?: number;
  'aria-controls'?: string;
  'aria-selected'?: boolean;
  tabindex?: number;
}
