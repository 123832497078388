import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface StylesProps {
  bottomShadowOpacity: number;
  hasScrollbar: boolean;
  topShadowOpacity: number;
}

const useStyles = makeStyles<StylesProps>()(
  ({ breakpoints, typography: { pxToRem } }: Theme, props) => ({
    content: {
      height: '100%',
      overflow: 'auto',
      padding: `${pxToRem(25)} 1rem ${props.hasScrollbar ? pxToRem(25) : 0}`,
      [breakpoints.up('breakPointDesktop')]: {
        padding: `${pxToRem(25)} 2rem ${props.hasScrollbar ? pxToRem(25) : 0}`,
      },
      // shadows
      '&::after, &::before': {
        content: `''`,
        display: 'block',
        height: '1.2rem',
        left: pxToRem(25),
        position: 'absolute',
        pointerEvents: 'none',
        right: pxToRem(25),
        zIndex: 100,
        [breakpoints.up('breakPointDesktop')]: {
          left: pxToRem(38),
          right: pxToRem(38),
        },
      },
      // bottom shadow
      '&::after': {
        background:
          'radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)',
        bottom: 0,
        opacity: props.bottomShadowOpacity,
      },
      // top shadow
      '&::before': {
        background:
          'radial-gradient(farthest-side at 50% 0%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)',
        top: 0,
        opacity: props.topShadowOpacity,
      },
    },
  }),
);

interface ModalBoxScrollerProps {
  children: JSX.Element;
}

const ModalBoxScroller = ({ children }: ModalBoxScrollerProps): JSX.Element => {
  const content = useRef<HTMLDivElement | null>(null);

  const [bottomShadowOpacity, setBottomShadowOpacity] = useState(0);
  const [topShadowOpacity, setTopShadowOpacity] = useState(0);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const { classes } = useStyles({
    bottomShadowOpacity,
    hasScrollbar,
    topShadowOpacity,
  });

  const updateShadows = () => {
    if (!content.current) return;

    const { offsetHeight, scrollHeight, scrollTop } = content.current;
    const scrollDiff = scrollHeight - offsetHeight;
    if (offsetHeight === scrollHeight || scrollDiff <= 0) {
      setBottomShadowOpacity(0);
      setTopShadowOpacity(0);
      setHasScrollbar(false);
    } else {
      const scroll = scrollTop / scrollDiff;
      setBottomShadowOpacity(1 - scroll);
      setTopShadowOpacity(scroll);
      setHasScrollbar(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateShadows);
    return () => {
      window.removeEventListener('resize', updateShadows);
    };
  }, []);

  useLayoutEffect(() => {
    updateShadows();
  }, [children, content]);

  return (
    <div className={classes.content} onScroll={updateShadows} ref={content}>
      {children}
    </div>
  );
};

export default ModalBoxScroller;
