import { useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { StickyGatewayClient } from '@sticky/gateway-client';
import { t, Trans } from '@sticky/i18n';
import { Logger } from '@sticky/logger';

import { AlertMessage } from '../../alert/alert-message';
import { ModalBox } from '../modal-box';

import { CaptchaService } from './captcha-service';

export const useStyles = makeStyles()(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '1rem',
  },
}));

const CAPTCHA_KEY = '6LfSSfkSAAAAADF4KnlarXLOSrQiSCQNqZpvtlcQ';

export const CaptchaModal = (): JSX.Element => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const onError = () => setOpen(true);
    StickyGatewayClient.subscribeCaptchaError(onError);
  }, []);

  const onChange = useCallback((value: string | null) => {
    if (!value) return;
    CaptchaService.sendCaptcha(value)
      .then(() => setOpen(false))
      .catch(error => {
        Logger.log('Erreur lors de la validation du captcha', error);
        setError(true);
      });
  }, []);

  // I use a condition instead of the modal parameter to avoid parsing the modal scripts uselessly
  if (!open) return <></>;

  // Use recaptcha.net to avoid allowing google.com on the app (that would allow analytics, etc.)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).recaptchaOptions = {
    useRecaptchaNet: true,
  };

  return (
    <ModalBox
      open={true}
      title={t('errors.captcha.title')}
      titleComponent="h2"
      closeButton={false}
    >
      <div className={classes.content}>
        {error && (
          <AlertMessage severity="error">
            {t('errors.captcha.validationError')}
          </AlertMessage>
        )}

        <Typography>
          <Trans i18nKey="errors.captcha.message" />
        </Typography>

        <ReCAPTCHA size="compact" sitekey={CAPTCHA_KEY} onChange={onChange} />
      </div>
    </ModalBox>
  );
};
