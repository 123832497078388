import { useLocation } from 'react-router-dom';
import { Container, List, ListItem } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

import { RouteLink } from '../../../../components/router/route-link';
import { useAppSelector } from '../../../../hooks';
import { subscriptionSelectors } from '../../../subscription/selectors';
import type { INavigationMenuItem } from '../../resources/navigation/menu';
import { getNavigationMenuItems } from '../../resources/navigation/menu';

// Styling
const useStyles = makeStyles()((theme: Theme) => {
  const {
    app: { colors, commonSpacing },
  } = theme;
  return {
    root: {
      background: colors.white,
      borderRadius: '0 0 1rem 1rem',
      marginBottom: theme.spacing(commonSpacing),
      fontSize: '1.2rem',
    },
    list: {
      marginTop: 0,
      marginBottom: 0,
    },
    listItem: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  };
});

export const NavigationDesktop = (): JSX.Element => {
  const { classes } = useStyles();
  const location = useLocation();

  const hasSubscription = useAppSelector(subscriptionSelectors.hasSubscription);

  // Init the active menu with current location
  const navigationMenuItems = getNavigationMenuItems(hasSubscription);
  const currentMenuIndex =
    navigationMenuItems.findIndex((el: INavigationMenuItem) =>
      new RegExp(el.route).test(location.pathname),
    ) || 0;

  // Generate desktop menu
  return (
    <List
      id="list-mainNavigation"
      component="nav"
      classes={{ root: classes.root }}
      role="navigation"
    >
      <Container
        component="ul"
        className={classes.list}
        data-test-id="list-items-navigation"
        id="list-items-navigation"
      >
        {navigationMenuItems.map((el: INavigationMenuItem, index: number) => (
          <ListItem key={el.label} divider className={classes.listItem}>
            <RouteLink
              to={el.route}
              active={index === currentMenuIndex}
              disabled={el.disabled}
              aria-label={t(el.label)}
              data-test-id={el['data-test-id']}
            >
              {t(el.label)}
            </RouteLink>
          </ListItem>
        ))}
      </Container>
    </List>
  );
};
