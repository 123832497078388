import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

import { StickyGatewayClient } from '@sticky/gateway-client';

import { BrandChoiceModal } from './brand-choice-modal';
import { BrandsService } from './brands-service';
import type { BrandConfig } from './config';

export const BrandContext = React.createContext<{
  brand?: BrandConfig;
  loading: boolean;
}>({ brand: undefined, loading: true });

type BrandProviderProps = {
  children: ReactNode;
};

export const BrandProvider = ({ children }: BrandProviderProps) => {
  const [brand, setBrand] = useState<BrandConfig | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    BrandsService.loadBrand().then(_brand => {
      setBrand(_brand);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    StickyGatewayClient.setDistributionChannelGetter(
      () => BrandsService.brand?.distributionChannel || '',
    );
  }, []);

  const content = !loading && (
    <>
      <BrandChoiceModal /> {children}
    </>
  );

  return (
    <BrandContext.Provider value={{ brand, loading }}>
      {content}
    </BrandContext.Provider>
  );
};
