import type { Proposal } from './reservation';

export class ExchangeConfirmRequest {
  constructor(
    private context: string,
    private cardNumber: string,
    private orderId: string,
    private serviceItemId: string,
    private itemToExchangeDepartureDateTime: string,
    private proposal: Proposal,
  ) {}
}
