/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ICustomer } from '../../customer/models/customer';
import type { Ticket } from '../../subscription/models/payment';

export enum PaymentMethodCodeResumptionLink {
  // https://wiki.vsct.fr/display/STICKY/Emettre+un+abonnement
  SEPA = '001',
  CB = '002',
  SEPA_AND_CB = '003',
}

export enum Status {
  OK = 'OK',
  KO = 'KO',
}

export interface IBasketState {
  loading: boolean;
  at?: number;
  message?: string;
  error?: any;
  lastUpdate?: number;
  basketId?: string;
  billTo?: IBillTo;
  customer?: ICustomer;
  cashPayment?: ICashPaymentOutput;
  issuingOutput?: IIssuingCreditCardOutput;
  paymentSuccess?: boolean;
  paymentError?: boolean;
  paymentCB?: boolean;
  paymentIBAN?: boolean;
}

export interface IBillTo {
  firstName?: string;
  lastName?: string;
  civility?: string;
  companyName?: string;
  siren?: string;
  address1: string;
  address2?: string;
  city: string;
  zipCode: string;
  country: string;
  workEmail: string;
  workPhone?: string;
  isBillToDifferent?: boolean;
  isBillToCompany?: boolean;
}

export interface IPaymentOutput {
  paymentId: string;
  urlPage: string;
  reference: string;
}

export interface IIssuingSalesInput {
  basketId: string;
  paymentId?: string;
}

export interface IIssuingCreditCardOutput {
  paymentId: string;
  paoOrder?: string;
  paymentState: string;
  paymentAgreement: string;
  paymentTicket: Ticket;
  merchant: string;
  merchantURL: string;
  transactionType: string;
  details?: any;
}

export interface IIssuingSepaOutput {
  details?: any;
}

export interface ICashPaymentInput {
  basketId: string;
  callbackUrl?: string;
}

export interface ICashPaymentOutput {
  urlPage: string;
  paymentId: string;
}

export interface DocumentOutput {
  checkStatus: Status;
}
export interface DocumentCheckOutput {
  globalStatus: Status;
  countCheckAriadNext: number;
  supportUrl?: string;
  customer: DocumentOutput;
  billTo?: DocumentOutput;
}
