import zod from 'zod-i18n-map/locales/fr/zod.json';

import {
  actions,
  auth,
  brand,
  common,
  components,
  contactFaq,
  customer,
  didomi,
  enrollment,
  errorCode,
  errors,
  form,
  freePlaces,
  helpdesk,
  mandate,
  modals,
  password,
  payment,
  proposal,
  pwa,
  reservation,
  subscription,
  support,
  terms,
  travels,
} from './fr/common';
import {
  dev,
  home,
  maintenance,
  migration,
  notFound,
  privacyPolicy,
} from './fr/pages';
import {
  stepperCommon,
  stepperMigration,
  stepperUpdateBillto,
  stepperUpdateBilltoGpg,
  stepperUpdateIban,
  stepperUpdateIbanGpg,
  stepperUpdatePhoto,
} from './fr/steppers';

export const resources = {
  common,
  app: {
    actions,
    auth,
    brand,
    components,
    contactFaq,
    customer,
    dev,
    didomi,
    enrollment,
    errorCode,
    errors,
    form,
    'free-places': freePlaces,
    helpdesk,
    home,
    maintenance,
    mandate,
    migration,
    modals,
    notFound,
    password,
    payment,
    privacyPolicy,
    proposal,
    pwa,
    reservation,
    stepperCommon,
    stepperMigration,
    stepperUpdateBillto,
    stepperUpdateBilltoGpg,
    stepperUpdateIban,
    stepperUpdateIbanGpg,
    stepperUpdatePhoto,
    subscription,
    support,
    terms,
    travels,
  },
  zod,
};

export type CommonKeys<R extends typeof resources = typeof resources> =
  R['app'] & R['common'] & Omit<R, 'app' | 'common'>;
