import { lazy } from 'react';

import { t } from '@sticky/i18n';

import { LAYOUT } from '../../../layout';
import type { IRoute } from '../../../routes';

const routes: IRoute[] = [
  {
    id: 'customer/my-coordinates/update-iban',
    path: '/mes-coordonnees/modification-mandat',
    title: t('customer.coordinates.IBAN.update.edit'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () => import('../../../../pages/customer/account/update-iban/bank-list'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-iban/bank-branch-list',
    path: '/mes-coordonnees/modification-mandat/liste-branches',
    title: t('customer.coordinates.IBAN.update.edit'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import(
          '../../../../pages/customer/account/update-iban/bank-branch-list'
        ),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-iban/iban-input',
    path: '/mes-coordonnees/modification-mandat/saisie-iban',
    title: t('customer.coordinates.IBAN.update.edit'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () => import('../../../../pages/customer/account/update-iban/iban-input'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-iban/otu-input',
    path: '/mes-coordonnees/modification-mandat/saisie-otu',
    title: t('customer.coordinates.IBAN.update.edit'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () => import('../../../../pages/customer/account/update-iban/otu-input'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-iban-success',
    path: '/mes-coordonnees/modification-mandat-succes',
    title: t('customer.coordinates.IBAN.update.success'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () => import('../../../../pages/customer/account/update-iban/success'),
    ),
    private: true,
  },
  {
    id: 'customer/my-coordinates/update-iban-error',
    path: '/mes-coordonnees/modification-mandat-erreur',
    title: t('customer.coordinates.IBAN.update.error'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(
      () =>
        import(
          '../../../../pages/customer/account/update-iban/sepa-unavailable'
        ),
    ),
    private: true,
  },
];

export default routes;
