import { encodeStringHex } from '@sticky/helpers';

import { midClient } from '../../clients';
import { useAuthStore } from '../../store';

type Options = {
  state?: unknown;
  email?: string;
};

/**
 * Retourne l'URL de la page de connexion MID.
 */
export const getLoginUrl = ({ state, email }: Options = {}) => {
  const authState = useAuthStore.getState();

  if (!authState.config) {
    throw new Error('The auth store should be initialized');
  }

  return midClient.loginUrl({
    redirect_uri: authState.config.loginRedirectUri,
    state: state ? encodeStringHex(JSON.stringify(state)) : undefined,
    login_hint: email,
  });
};
