import deepmerge from 'deepmerge';

import { ConfigCommon } from '../global-config';

import { defaultConfig } from './default-config';
import type { FeatureFlippingConfig, PartialConfig } from './types';

export let envDev: FeatureFlippingConfig;
export let envProd: FeatureFlippingConfig;

export const LOCAL_STORAGE_FEATURES_KEY = 'FEATURES:DEV';

let features: FeatureFlippingConfig;

export const getFeatures = () => {
  if (!features) {
    throw new Error('You can get features before call configureFeatures');
  }

  return features;
};

export const updateFeatures = (config: PartialConfig = {}) => {
  features = deepmerge(features, config) as FeatureFlippingConfig;
  localStorage.setItem(LOCAL_STORAGE_FEATURES_KEY, JSON.stringify(features));
};

export const configureFeatures = ({
  dev,
  prod = {},
}: {
  dev: PartialConfig;
  prod?: PartialConfig;
}) => {
  // Init dev and prod env from defaults and common features
  envDev = deepmerge(defaultConfig, dev) as FeatureFlippingConfig;
  envProd = deepmerge(defaultConfig, prod) as FeatureFlippingConfig;

  // Update running config with prod env
  if (ConfigCommon.env.isProd || ConfigCommon.env.isPab) {
    features = envProd;
  } else {
    // Init dev variables
    features = envDev;

    // Override local features with config set by admin/features
    let localStorageFeatures;
    try {
      localStorageFeatures = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_FEATURES_KEY)!,
      );
    } catch (err) {}
    features = deepmerge(
      envDev,
      localStorageFeatures || {},
    ) as FeatureFlippingConfig;
  }
};
