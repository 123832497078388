import { CardActions } from '@mui/material';

import { ActionButton } from '@sticky/components';
import { t } from '@sticky/i18n';

import {
  cleanProposalPlansAndPlanId,
  scrollToId,
  setProposalProductSelect,
} from '../..';
import { useAppDispatch } from '../../hooks';

interface ChooseButtonProps {
  product: string;
  target: string;
}

const ChooseButton = ({ product, target }: ChooseButtonProps) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setProposalProductSelect(product));
    dispatch(cleanProposalPlansAndPlanId());
    scrollToId(target, true);
  };

  return (
    <CardActions>
      <ActionButton onClick={handleClick}>
        {t('proposal.action.choose')}
      </ActionButton>
    </CardActions>
  );
};

export default ChooseButton;
