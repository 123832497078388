import React from 'react';
import { Field } from 'react-final-form';
import { Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import type { RadioData, RadiosProps } from 'mui-rff';
import { Radios } from 'mui-rff';
import { makeStyles } from 'tss-react/mui';

import type { IValidator } from './form-validators';

interface FormInputRadiosProps extends RadiosProps {
  data: RadioData[];
  validate?: IValidator;
  readOnly?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => {
  const {
    app: { colors },
  } = theme;
  return {
    root: {
      '& .MuiFormLabel-asterisk': {
        color: colors.textContrasted,
        fontWeight: 700,
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: colors.textContrasted,
      },
    },
  };
});

export const FormInputRadio = (props: FormInputRadiosProps): JSX.Element => {
  const { label, name, data, validate, value, ...rest } = props;
  const { classes } = useStyles();
  const inputProps = {
    inputProps: {
      'aria-labelledby': props.name + '-label',
    },
  };
  return (
    <Field name={name} validate={validate}>
      {({ input }) => {
        // Display value if readOnly
        if (props.readOnly) {
          return <Typography>{input.value}</Typography>;
        }

        // Display editable field
        return (
          <div className={classes.root}>
            <Radios
              label={label}
              name={name}
              data={data}
              {...rest}
              aria-labelledby={name + '-label'}
              {...inputProps}
            />
          </div>
        );
      }}
    </Field>
  );
};
