import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';

import { ConfigCommon } from '@sticky/config';

import { basketReducer } from './features/basket/store/basket';
import { customerReducer } from './features/customer/store/customer-slice';
import { disclaimerReducer } from './features/disclaimer';
import { proposalReducer, stationsReducer } from './features/proposal';
import {
  equivalentStationsReducer,
  exchangeReducer,
  reservationReducer,
} from './features/reservation';
import { subscriptionReducer } from './features/subscription/store/subcription-slice';
import { termsReducer } from './features/terms';

export let store: ReturnType<typeof createStore>;

export const createStore = () => {
  if (store) {
    throw new Error('store is already created');
  }
  const instance = configureStore({
    reducer: {
      customer: customerReducer,
      terms: termsReducer,
      subscription: subscriptionReducer,
      stations: stationsReducer,
      proposal: proposalReducer,
      basket: basketReducer,
      reservation: reservationReducer,
      equivalentStations: equivalentStationsReducer,
      disclaimer: disclaimerReducer,
      exchange: exchangeReducer,
    },
    devTools: !(ConfigCommon.env.isProd || ConfigCommon.env.isPab),
  });
  store = instance;
  return instance;
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
