/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const sortObjectByKeys = <T>(obj: any = {}): T | undefined => {
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
  return Object.keys(obj)
    .sort()
    .reduce((r: any, k: string) => ((r[k] = obj[k]), r), {});
};

export const removeEmptyStringKey = (obj: any = {}) => {
  Object.keys(obj).forEach(key => obj[key] === '' && delete obj[key]);
};
