/* eslint-disable @typescript-eslint/no-explicit-any */
import { AES, enc, MD5 } from 'crypto-js';

import { InvalidSecretKeyError } from './errors';

export interface EncryptStorageOptions {
  prefix?: string;
  expiresIn?: number;
  stateManagementUse?: boolean;
  storageType?: 'localStorage' | 'sessionStorage';
}

interface EncryptStorageInternalPayload {
  createdAt: number;
  payload: any;
  expiresAt?: number;
}

export interface EncryptStorageTypes extends Storage {
  /**
   * `setItem` - Is the function to be set `safeItem` in `selected storage`
   * @param {string} key - Is the key of `data` in `selected storage`.
   * @param {any} value - Value to be `encrypted`, the same being a `string` or `object`.
   * @return {void} `void`
   * @usage
   *    setItem('any_key', {key: 'value', another_key: 2})
   *    setItem('any_key', 'any value')
   */
  setItem(key: string, value: any): void;

  /**
   * `getItem` - Is the faction to be get `safeItem` in `selected storage`
   * @param {string} key - Is the key of `data` in `selected storage`.
   * @return {string | any | undefined} - Returns a formatted value when the same is an object or string when not.
   * Returns `undefined` when value not exists.
   * @usage
   *    getItem('any_key') -> `{key: 'value', another_key: 2}`
   *    getItem('any_key') -> `'any value'`
   */
  getItem(key: string): string | any | undefined;

  /**
   * `setExpiresAt` - Set a new expiration date  in `selected storage`.
   * @param {string} key - Is the key of `data` in `selected storage`.
   * @param {number} expiresAt - The timestamp to set for `expiresAt` property.
   * @return {boolean} - Returns `boolean` success.
   * @usage
   *    setExpiresAt('any_key', Date.now() + 10 * 60000)
   */
  setExpiresAt(key: string, expiresAt: number): boolean;

  /**
   * `removeItem` - Is the faction to be remove `safeItem` in `selected storage`
   * @param {string} key - Is the key of `data` in `selected storage`.
   * @return {void}
   * Returns `void`.
   * @usage
   *    removeItem('any_key')
   */
  removeItem(key: string): void;

  /**
   * `removeItemFromPattern` - Is the faction to be remove `safeItem` in `selected storage` from `pattern` based
   * @param {string} pattern - Is the pattern existent in keys of `selected storage`.
   * @return {void}
   * Returns `void`.
   * @usage
   *    // itemKey = '12345678:user'
   *    // another itemKey = '12345678:item'
   *    removeItem('12345678') -> item removed from `selected storage`
   */
  removeItemFromPattern(pattern: string): void;

  /**
   * `clear` - Clear all selected storage
   */
  clear(): void;

  /**
   * `key` - Return a `key` in selected storage index or `null`
   * @param {number} index - Index of `key` in `selected storage`
   */
  key(index: number): string | null;

  /**
   * `encryptString` - Is the faction to be `encrypt` any string and return encrypted value
   * @param {string} key - A `string` to be encrypted.
   * @return {string} result
   * Returns `string`.
   * @usage
   *    encryptString('any_string') -> 'encrypted value'
   */
  encryptString(key: string): string;

  /**
   * `decryptString` - Is the faction to be `decrypt` any string encrypted by `encryptString` and return decrypted value
   * @param {string} key - A `string` to be decrypted.
   * @return {string} result
   * Returns `string`.
   * @usage
   *    decryptString('any_string') -> 'decrypted value'
   */
  decryptString(key: string): string;
}

/**
 * EncryptStorage provides a wrapper implementation of `localStorage` and `sessionStorage` for a better security solution in browser data store
 *
 * @param {string} secretKey - A secret to encrypt data must contain min of 10 characters
 * @param {EncryptStorageOptions} options - A optional settings to set encryptData or select `sessionStorage` to browser storage
 */
export function EncryptStorage(
  secretKey: string,
  options: EncryptStorageOptions = {},
): EncryptStorageTypes {
  if (secretKey?.length < 10) {
    throw new InvalidSecretKeyError();
  }

  const storage: Storage = window[options.storageType || 'localStorage'];
  const prefix = options.prefix || '';
  const stateManagementUse = options.stateManagementUse || false;
  const expiresIn = options.expiresIn || 0;

  return {
    length: storage.length,
    setItem(key: string, value: any): void {
      const storageKey = prefix ? `${prefix}:${key}` : key;
      const payload: EncryptStorageInternalPayload = {
        createdAt: Date.now(),
        payload: value,
      };
      if (expiresIn) payload.expiresAt = payload.createdAt + expiresIn;
      const encryptedValue = this.encryptString(JSON.stringify(payload));

      storage.setItem(storageKey, encryptedValue);
    },
    getItem(key: string): string | any | undefined {
      const storageKey = prefix ? `${prefix}:${key}` : key;
      const item = storage.getItem(storageKey as string);

      if (item) {
        const decryptedValue = this.decryptString(item);

        if (stateManagementUse) {
          return decryptedValue;
        }

        try {
          const payload: EncryptStorageInternalPayload =
            JSON.parse(decryptedValue);
          if (payload.expiresAt && payload.expiresAt < Date.now()) {
            this.removeItem(key);
            return undefined;
          }
          return payload.payload;
        } catch (error) {
          return decryptedValue;
        }
      }

      return undefined;
    },
    setExpiresAt(key: string, expiresAt: number): boolean {
      const storageKey = prefix ? `${prefix}:${key}` : key;
      const item = storage.getItem(storageKey as string);
      if (item) {
        const decryptedValue = this.decryptString(item);
        try {
          const payload: EncryptStorageInternalPayload =
            JSON.parse(decryptedValue);
          payload.expiresAt = expiresAt;
          storage.setItem(
            storageKey,
            this.encryptString(JSON.stringify(payload)),
          );

          return true;
        } catch (error) {
          return false;
        }
      }
      return false;
    },
    removeItem(key: string): void {
      const storageKey = prefix ? `${prefix}:${key}` : key;
      storage.removeItem(storageKey as string);
    },
    removeItemFromPattern(pattern: string): void {
      const storageKeys = Object.keys(storage);
      const filteredKeys = storageKeys.filter(key => key.includes(pattern));

      filteredKeys.forEach(key => {
        this.removeItem(key);
      });
    },
    clear(): void {
      storage.clear();
    },
    key(index: number): string | null {
      return storage.key(index);
    },
    encryptString(str: string): string {
      return AES.encrypt(str, secretKey).toString();
    },
    decryptString(str: string): string {
      return AES.decrypt(str, secretKey).toString(enc.Utf8);
    },
    encryptMD5<T>(data: T): string {
      return MD5(JSON.stringify(data)).toString();
    },
  };
}

export default EncryptStorage;
