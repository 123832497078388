import type { DatePickerProps } from 'mui-rff';

import { formatToDDMMYYYY } from '@sticky/helpers';

import type { IValidator } from '../form-validators';

import { DatePicker } from './datePicker';

type FormInputDatepickerProps = DatePickerProps & {
  validate: IValidator;
  format?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  name: string;
  autoComplete?: string;
  placeholder?: string;
};

export const FormInputDatepicker = ({
  disableFuture = true,
  disablePast = false,
  name,
  autoComplete,
  placeholder,
  required,
  format,
  validate,
  value,
  label,
  ...rest
}: FormInputDatepickerProps) => {
  const customValidate = (newValue?: Date | string) => {
    if (!validate) {
      return undefined;
    }

    return validate(newValue && formatToDDMMYYYY(newValue));
  };

  return (
    <DatePicker
      name={name}
      fieldProps={{
        validate: customValidate,
      }}
      inputProps={{
        id: name,
        autoComplete: autoComplete,
      }}
      placeholder={placeholder}
      required={required}
      format={format ?? 'dd/MM/yyyy'}
      disableFuture={disableFuture}
      disablePast={disablePast}
      {...rest}
    />
  );
};
