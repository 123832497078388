import type { FeatureFlippingMeta } from '../../types';

export const canGoBackToProposal: FeatureFlippingMeta = {
  label:
    'Affichage du bouton "Modifier" dans la page mes coordonnées personnelles',
  description:
    'Affichage d\'un bouton "Modifier" dans la page mes coordonnées personnelles dans le parcours de souscription pour revenir sur la page précédente. (specif MAX ACTIF/MAX ACTIF+)',
  projects: ['MFA'],
  defaultValue: false,
};
