export interface IInputPasswordRule {
  condition: string;
  points: number;
}
const passwordRules: IInputPasswordRule[] = [
  {
    condition: '^.{8,50}$',
    points: 10,
  },
  {
    condition: '.*[a-z]',
    points: 1,
  },
  {
    condition: '.*[A-Z]',
    points: 1,
  },
  {
    condition: '.*[0-9]',
    points: 1,
  },
  {
    condition: ".*[\\!'\\-_{}[\\]()@$£?;:éèêçàîïù]",
    points: 1,
  },
];
export const getPasswordPoints = (_value: string): number => {
  let points = 0;
  if (_value && _value.length > 0) {
    // Return 10 if password has correct length but invalid characters
    if (
      _value.length > 7 &&
      new RegExp("[^A-Za-z0-9\\!'\\-_{}[\\]()@$£?;:éèêçàîïù]").test(_value)
    ) {
      return 10;
    }
    for (const rule of passwordRules) {
      if (rule.condition && new RegExp(rule.condition).test(_value)) {
        points += rule.points;
      }
    }
  }
  return points;
};
