import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Box, Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

import { getLogoutUrl } from '@sticky/auth';
import { ActionButton } from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t, Trans } from '@sticky/i18n';

import { useAppSelector } from '../../../hooks';
import { ModalBox } from '../modal-box';

const useStyles = tss.create(({ theme }) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    marginTop: theme.spacing(2),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const IdleTimeoutModal = () => {
  const { classes } = useStyles();

  const isAuth = useAppSelector(state => state.customer.isAuth);

  // Modal "still here" prompt modal and logout modal open states
  const [promptOpen, setPromptOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  // Set timeout values
  const timeout = getFeatures().auth.logout.idleTimeout || 86400000; // 1 day if no config
  const promptBeforeIdle =
    getFeatures().auth.logout.promptBeforeIdle || 1000 * 60; // 1 minute to ask if the user is still here

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 60 minutes.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active the `reset()` method is called in the modal.
    setPromptOpen(true);
    setRemaining(promptBeforeIdle / 1000);
  };

  const onIdle = async () => {
    // onIdle will be called after the promptBeforeIdle is reached.
    // Log out the user and show log out modal.
    window.location.href = getLogoutUrl();
  };

  const { getRemainingTime, isPrompted, activate, pause } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    startManually: true,
  });

  useEffect(() => {
    if (isAuth) {
      activate();
    } else {
      pause();
    }
  }, [activate, isAuth, pause]);

  const handleStillHere = () => {
    setPromptOpen(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <ModalBox
      open={promptOpen}
      title={t('auth.modals.idle.title')}
      titleComponent="h2"
      closeButton={false}
    >
      <Typography>
        <Trans i18nKey="auth.modals.idle.content" values={{ remaining }} />
      </Typography>
      <Box className={classes.content}>
        <ActionButton className={classes.btn} onClick={() => handleStillHere()}>
          {t('auth.modals.idle.action')}
        </ActionButton>
      </Box>
    </ModalBox>
  );
};
