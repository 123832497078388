import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ConfigCommon } from '@sticky/config';
import { StickyGatewayClient } from '@sticky/gateway-client';

import type { AppThunk } from '../../../store';
import { formatApiDateUTC } from '../../../utils/date';
import { formatPhoneToApi } from '../../../utils/phone/phone';
import { ProductType } from '../../../utils/product';
import {
  cleanPersistentState,
  loadPersistentState,
  savePersistentState,
} from '../..';
import { cleanProposal } from '../../proposal';
import { Basket } from '../class/basket-class';
import type {
  IBasketState,
  IBillTo,
  ICashPaymentInput,
  ICashPaymentOutput,
  IIssuingCreditCardOutput,
  IIssuingSalesInput,
  IIssuingSepaOutput,
} from '../models/basket';
import { PaymentMethodCodeResumptionLink } from '../models/basket';
import type {
  BookingSalesInput,
  BookingSalesOutput,
} from '../models/booking-sales';

// Name of the reducer
const REDUCER_NAME = 'basket';

// Setup initial state
const defaultState = {
  loading: false,
};
const initialState: IBasketState =
  loadPersistentState(REDUCER_NAME) || defaultState;

// Create a booking sale based on current store state
export const createBasketBookingSale = createAsyncThunk(
  'post/booking-sales',
  async (input: BookingSalesInput, thunkApi) => {
    const httpClient = StickyGatewayClient.authClient();
    const asyncFn = httpClient.post<BookingSalesOutput>(
      '/public/order/booking-sales',
      input,
    );
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

// Request Handler to get payment hub
export const initCashPayment = createAsyncThunk(
  'post/init-cash-payment',
  async (payment: ICashPaymentInput, thunkApi) => {
    const httpClient = StickyGatewayClient.authClient();
    const asyncFn = httpClient.post<ICashPaymentOutput>(
      'public/order/init-cash-payment',
      payment,
    );
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

// Request Handler to get payment hub response
export const issuingSales = createAsyncThunk(
  'post/issuing-sales',
  async (issuing: IIssuingSalesInput, thunkApi) => {
    const httpClient = StickyGatewayClient.authClient();
    const asyncFn = httpClient.post<
      IIssuingCreditCardOutput | IIssuingSepaOutput
    >('/public/order/issuing-sales', issuing);
    return StickyGatewayClient.thunkApiHandler(asyncFn, thunkApi);
  },
);

export const cleanBasketAndProposal = (): AppThunk => async dispatch => {
  await dispatch(cleanBasket());
  await dispatch(cleanProposal());
};

// Redux slice
const slicer = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setBasketId: (state, action) => {
      state.basketId = action.payload;
    },
    setBasketCustomer: (state, action) => {
      state.customer = action.payload;
      savePersistentState(REDUCER_NAME, state);
    },
    cleanBasketCustomer: state => {
      delete state.customer;
      savePersistentState(REDUCER_NAME, state);
    },
    setBasketBillTo: (state, action) => {
      state.billTo = {
        ...action.payload,
        workPhone: formatPhoneToApi(action.payload.workPhone),
      } as IBillTo;
      savePersistentState(REDUCER_NAME, state);
    },
    setBasketCashPayment: (state, action) => {
      state.cashPayment = action.payload;
      savePersistentState(REDUCER_NAME, state);
    },
    setBasketMethodPaymentCB: state => {
      state.paymentCB = true;
      savePersistentState(REDUCER_NAME, state);
    },
    setBasketMethodPaymentIBAN: state => {
      state.paymentIBAN = true;
      savePersistentState(REDUCER_NAME, state);
    },
    cleanBasketId: state => {
      delete state.basketId;
      savePersistentState(REDUCER_NAME, state);
    },
    cleanBasketMethodPaymentCB: state => {
      state.paymentCB = false;
      savePersistentState(REDUCER_NAME, state);
    },
    cleanBasketMethodPaymentIBAN: state => {
      state.paymentIBAN = false;
      savePersistentState(REDUCER_NAME, state);
    },
    setBasketIssuingOutput: (state, action) => {
      state.issuingOutput = action.payload;
      savePersistentState(REDUCER_NAME, state);
    },
    cleanBasketIssuingOutput: state => {
      delete state.issuingOutput;
      savePersistentState(REDUCER_NAME, state);
    },
    cleanBasketBillTo: state => {
      delete state.billTo;
      savePersistentState(REDUCER_NAME, state);
    },

    cleanBasketBookingSale: state => {
      delete state.basketId;
      savePersistentState(REDUCER_NAME, state);
    },
    cleanBasketPayment: state => {
      delete state.cashPayment;
      state.paymentError = false;
      state.paymentSuccess = false;
      savePersistentState(REDUCER_NAME, state);
    },
    cleanBasket: () => {
      cleanPersistentState(REDUCER_NAME);
      return defaultState;
    },
    setBasketError: (state, action) => {
      state.error = action.payload;
    },
    cleanBasketError: state => {
      delete state.error;
      savePersistentState(REDUCER_NAME, state);
    },
  },
  extraReducers: builder => {
    // Create booking sale reducers
    builder.addCase(createBasketBookingSale.pending, state => {
      state.loading = true;
      delete state.error;
      state.at = Date.now();
    });
    builder.addCase(createBasketBookingSale.fulfilled, (state, action) => {
      state.loading = false;
      delete state.error;
      state.basketId = action.payload.basketId;
      savePersistentState(REDUCER_NAME, state);
    });
    builder.addCase(createBasketBookingSale.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      delete state.basketId;
      savePersistentState(REDUCER_NAME, state);
    });

    // Init Cash payment
    builder.addCase(initCashPayment.pending, state => {
      state.loading = true;
      state.at = Date.now();
    });
    builder.addCase(initCashPayment.fulfilled, (state, action) => {
      state.loading = false;
      state.message = '';
      state.error = '';
      state.cashPayment = action.payload;
      slicer.caseReducers.setBasketCashPayment(state, action);
    });
    builder.addCase(initCashPayment.rejected, (state, action) => {
      state.loading = false;
      state.message = '';
      state.error = action.payload;
    });

    builder.addCase(issuingSales.pending, state => {
      state.loading = true;
      state.at = Date.now();
      state.paymentSuccess = false;
      state.paymentError = false;
    });
    builder.addCase(issuingSales.fulfilled, (state, action) => {
      state.loading = false;
      state.message = '';
      state.error = '';
      state.paymentSuccess = true;
      state.paymentError = false;
      state.issuingOutput = action.payload.details ?? action.payload;
      slicer.caseReducers.setBasketIssuingOutput(state, action);
    });
    builder.addCase(issuingSales.rejected, (state, action) => {
      const error = action.payload as { details: IIssuingCreditCardOutput };
      state.loading = false;
      state.message = '';
      state.error = error;
      state.paymentSuccess = false;
      state.paymentError = true;
      state.issuingOutput = error.details;
    });
  },
});

// Sequential init of the basket
// - retrieve the customer if not in store
// - create the booking sale
export const initBasket = (): AppThunk => async (dispatch, getState) => {
  // Get states
  const { basket, customer, proposal } = getState();

  // Skip if infos are missing
  if (!customer?.isAuth) {
    dispatch(
      slicer.actions.setBasketError(
        'Abort basket init : `customer` must be logged',
      ),
    );
    return;
  }
  if (!proposal.planId) {
    dispatch(
      slicer.actions.setBasketError(
        'Abort basket init : `proposal.planId` is missing',
      ),
    );
    return;
  }

  // Default IBAN
  let paymentCode = PaymentMethodCodeResumptionLink.SEPA;

  // Specific case for TMS/TMJ
  if (
    [ProductType.TMS, ProductType.TMJ].includes(
      ConfigCommon.appName as ProductType,
    )
  ) {
    paymentCode = PaymentMethodCodeResumptionLink.SEPA_AND_CB;
  }

  // CB payment
  else if (proposal.cashPayment) {
    paymentCode = PaymentMethodCodeResumptionLink.CB;
  }

  // Create the booking sale
  return dispatch(
    createBasketBookingSale({
      proposalId: proposal.planId,
      birthDate: formatApiDateUTC(basket.customer?.birthDate) ?? '',
      billTo: basket.billTo,
      resumptionLink: Basket.getResumptionLink({
        paymentCode,
        firstName: basket.billTo?.firstName,
        lastName: basket.billTo?.lastName,
        companyName: basket.billTo?.companyName,
      }),
      offerType: ConfigCommon.offerType,
    }),
  );
};

export const {
  cleanBasketId,
  cleanBasketMethodPaymentCB,
  cleanBasketMethodPaymentIBAN,
  setBasketCustomer,
  setBasketMethodPaymentCB,
  setBasketMethodPaymentIBAN,
  cleanBasketCustomer,
  setBasketBillTo,
  cleanBasketBillTo,
  cleanBasketBookingSale,
  cleanBasket,
  cleanBasketError,
  cleanBasketIssuingOutput,
  cleanBasketPayment,
  setBasketId,
} = slicer.actions;

export const basketReducer = slicer.reducer;
