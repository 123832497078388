import type {
  AssignmentOption,
  CancelReservationServicePayload,
  SeatWish,
} from '..';

export class BookTravelsRequest {
  constructor(
    private id: string,
    private context: string,
    private cardNumber: string,
    private proposals: Array<TransactionProposal>,
  ) {}
}

export class TransactionProposal {
  constructor(
    private id: string,
    private departureDateTime: string,
    private segmentId?: string,
    private assignmentOption?: AssignmentOption,
    private designatedSeat?: SeatWish,
    private closeTo?: SeatWish,
  ) {}
}

export class CancelReservationRequest {
  constructor(private travelsInfo: CancelReservationServicePayload[]) {}
}
