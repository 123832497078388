import { datadogRum } from '@datadog/browser-rum';

import { ConfigCommon, getEnv, getFeatures } from '@sticky/config';

const sanitizeDatadogValue = (rawValue: string) =>
  rawValue.replaceAll(/,/g, '_').toLowerCase();

export const initDatadog = (enabled: boolean) => {
  if (enabled)
    datadogRum.init({
      applicationId: getEnv('VITE_DATADOG_APPLICATION_ID'),
      clientToken: getEnv('VITE_DATADOG_CLIENT_TOKEN'),
      site: getEnv('VITE_DATADOG_SITE'),
      service: sanitizeDatadogValue(`max-front-${getEnv('VITE_NAME')}`),
      env: sanitizeDatadogValue(ConfigCommon.env.name),
      version: sanitizeDatadogValue(ConfigCommon.version),

      // Pourcentage de sessions remontées
      sessionSampleRate: getFeatures().tracker.datadog.sessionSampleRate,
      // Pourcentage de traces remontées
      traceSampleRate: getFeatures().tracker.datadog.traceSampleRate,
      defaultPrivacyLevel: 'mask-user-input',

      // Real User Monitoring
      trackUserInteractions:
        getFeatures().tracker.datadog.trackUserInteractions,
      // Pourcentage de Session Replay
      sessionReplaySampleRate:
        getFeatures().tracker.datadog.sessionReplaySampleRate,
    });
};

export const trackError = (error: unknown) => {
  datadogRum.addError(error);
};
