import useSWR from 'swr';

import { getFeatures } from '@sticky/config';
import { StrapiClient } from '@sticky/strapi-client';

export const useStrapiConfig = () => {
  const strapiQuery = useSWR(
    getFeatures().strapi.enabled ? ['strapi', 'configuration'] : null,
    () => StrapiClient.getConfiguration(),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: true,
      keepPreviousData: true,
    },
  );

  const s3BackupQuery = useSWR(
    !getFeatures().strapi.enabled || strapiQuery.error
      ? ['s3', 'configuration']
      : null,
    async (): Promise<StrapiClient.GetConfigurationOutput> => {
      const response = await fetch('/config.json');
      const data = await response.json();

      return StrapiClient.GetConfigurationOutputSchema.parse(data);
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      keepPreviousData: true,
    },
  );

  return {
    isLoading: strapiQuery.isLoading || s3BackupQuery.isLoading,
    data: strapiQuery.data?.data || s3BackupQuery.data,
  };
};
