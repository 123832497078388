import type { Shadows } from '@mui/material';
import { createTheme } from '@mui/material';
import { frFR } from '@mui/material/locale';
import { frFR as datePickers_frFR } from '@mui/x-date-pickers/locales';

import { createComponents } from './createComponents';
import { createCustomVars } from './createCustomVars';
import { createPalette } from './createPalette';
import { createTypography } from './createTypography';
import type { StickyThemeConfig } from './types';

export const createStickyTheme = (config: StickyThemeConfig) => {
  const defaultTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        breakPointTablet: 612,
        sm: 612,
        breakPointDesktop: 1024,
        md: 1024,
        breakPointLargeDesktop: 1360,
        lg: 1360,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: '"Avenir", sans-serif',
    },
    shadows: Array(25).fill('none' as const) as Shadows,
  });

  const stickyTheme = createTheme(
    defaultTheme,
    {
      app: createCustomVars(config),
      components: createComponents(config, defaultTheme),
      palette: createPalette(config),
      typography: createTypography(defaultTheme),
    },
    frFR,
    datePickers_frFR,
  );

  return stickyTheme;
};
