import type { Theme, ThemeOptions } from '@mui/material';

export function createTypography(theme: Theme): ThemeOptions['typography'] {
  return {
    // Liste des variants disponibles
    h1: {
      fontWeight: 900,
      fontSize: theme.typography.pxToRem(22),
      [theme.breakpoints.up('breakPointDesktop')]: {
        fontSize: theme.typography.pxToRem(30),
        lineHeight: 1.17,
      },
    },
    h2: {
      fontWeight: 900,
      fontSize: theme.typography.pxToRem(18),

      [theme.breakpoints.up('breakPointDesktop')]: {
        fontSize: theme.typography.pxToRem(24),
      },
    },
    h3: {
      fontWeight: 900,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
    body1: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),

      [theme.breakpoints.up('breakPointDesktop')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    body2: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
    },
    button: {
      fontWeight: 900,
      fontSize: theme.typography.pxToRem(16),
      textTransform: 'none',
    },
    caption: {
      fontSize: theme.typography.pxToRem(12),
    },
    subtitle2: {
      fontWeight: 900,
      fontSize: theme.typography.pxToRem(16),
    },
    subtitle1: {
      fontWeight: 900,
      fontSize: theme.typography.pxToRem(16),

      [theme.breakpoints.up('breakPointDesktop')]: {
        fontSize: theme.typography.pxToRem(26),
      },
    },

    // Désactivation des autres variants
    h4: undefined,
    h5: undefined,
    h6: undefined,
    overline: undefined,
  };
}
