export const getQueryParam = (param: string): string | undefined => {
  const name = param.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(window.location.href);
  if (!results || results.length < 3) return undefined;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

let popup: Window | null = null;
export const buildPopup = (
  url: string,
  title: string,
  popupWidth: number,
  popupHeight: number,
): void => {
  if (popup instanceof Window) {
    popup.close();
  }
  const left = window.screen.width / 2 - popupWidth / 2;
  const top = window.screen.height / 2 - popupHeight / 2;
  const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`;
  popup = window.open(url, title, options);

  if (popup instanceof Window) {
    popup.focus();
  }
};

/**
 * Returns true if the page is loaded inside an iFrame.
 *
 * @returns {boolean} - True if inside an iFrame.
 */
export const isInsideIframe = (): boolean =>
  window.location.href !== window.parent.location.href;

export const WindowMessages = {
  UPDATE_BILL_TO_SUCCESS: 'UPDATE_BILL_TO_SUCCESS',
  PAYMENT_ISSUING_ENDED: 'PAYMENT_ISSUING_ENDED',
};
