import type { BoxProps } from '@mui/material';
import { Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

import { formatPrice, formatToDDMMYYYY } from '@sticky/helpers';
import { Trans } from '@sticky/i18n';

type PromoCardProps = Omit<BoxProps, 'children'> & {
  promo: {
    price?: number;
    effectiveEndDate?: string;
    promoPeriods?: number;
  };
  standardPrice?: number;
};

const useStyles = tss.create(({ theme }) => {
  const {
    app: { colors },
    typography: { pxToRem },
  } = theme;

  return {
    root: {
      textAlign: 'center',
      alignItems: 'center',
      width: '76%',
      padding: '10px',
      [theme.breakpoints.up('breakPointDesktop')]: {
        width: '100%',
      },
    },
    content: {
      border: `${pxToRem(4)} solid ${colors.primary}`,
      marginBottom: '1rem',
      borderRadius: pxToRem(20),
      padding: '1rem',
      paddingTop: '2rem',
    },
    price: {
      fontSize: pxToRem(25),
      [theme.breakpoints.up('breakPointDesktop')]: {
        fontSize: pxToRem(35),
      },
    },
    title: {
      backgroundColor: colors.primary,
      color: colors.textSecondaryContrasted,
      borderRadius: pxToRem(30),
      width: '85%',
      margin: 'auto',
      marginBottom: pxToRem(-26),
      padding: '0.2rem',
    },
  };
});

export const PromoCard = ({ promo, standardPrice }: PromoCardProps) => {
  const { classes } = useStyles();
  if (promo.price === undefined || standardPrice === undefined) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        <Trans
          i18nKey="home.promo-card.title"
          values={{
            untilDate: formatToDDMMYYYY(promo?.effectiveEndDate || ''),
          }}
        />
      </Typography>
      <Typography variant="body1" className={classes.content}>
        <Trans
          i18nKey="home.promo-card.content"
          values={{
            amount: formatPrice(promo?.price, {
              condensed: true,
            }),
            duration: promo?.promoPeriods,
            standardPrice: formatPrice(standardPrice, {
              condensed: true,
            }),
          }}
          components={{ b: <b className={classes.price} /> }}
        />
      </Typography>
    </div>
  );
};
