import type { ReactNode } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import type { CollapseProps } from '@mui/material';
import { Alert, AlertTitle, Button, Collapse, Typography } from '@mui/material';
import { Box } from '@mui/system';
import scrollIntoView from 'scroll-into-view';
import { tss } from 'tss-react/mui';

import { t } from '@sticky/i18n';

type AlertMessageProps = CollapseProps & {
  onClose?: () => void;
  closable?: boolean;
  title?: string;
  description?: ReactNode;
  severity?: 'error' | 'warning' | 'info' | 'success';
  autoScroll?: boolean;
};

const useStyles = tss.create(({ theme }) => ({
  closeButton: {
    minWidth: 0,
    color: 'inherit',

    ['& .MuiButton-endIcon']: {
      fontSize: 'inherit',
      marginLeft: 0,

      [theme.breakpoints.up('breakPointDesktop')]: {
        marginLeft: theme.spacing(1),
      },
    },
  },
  closeLabel: {
    display: 'none',

    [theme.breakpoints.up('breakPointDesktop')]: {
      display: 'block',
    },
  },
  typographyColor: {
    color: 'inherit !important',
  },
}));

export const AlertMessage = ({
  closable,
  description,
  onClose,
  severity = 'error',
  title,
  autoScroll = true,
  ...rest
}: AlertMessageProps) => {
  const [open, setOpen] = useState(true);
  const { classes } = useStyles();
  const ref = useRef();

  useLayoutEffect(() => {
    if (!autoScroll || !open || !ref.current) {
      return;
    }

    scrollIntoView(ref.current, {
      align: { top: 0, topOffset: 100 },
    });
  }, [autoScroll, open, !!ref.current]);

  return (
    <Collapse ref={ref} in={open} {...rest}>
      <Alert
        action={
          closable && (
            <Button
              className={classes.closeButton}
              aria-label={t('actions.close')}
              onClick={() => {
                setOpen(false);
                onClose?.();
              }}
              size="small"
              endIcon={<Close />}
            >
              <Box className={classes.closeLabel}>{t('actions.close')}</Box>
            </Button>
          )
        }
        severity={severity}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        <Typography className={classes.typographyColor}>
          {description}
        </Typography>
      </Alert>
    </Collapse>
  );
};
