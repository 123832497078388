import type { IDidomiObject } from '@didomi/react';
import { DidomiSDK } from '@didomi/react';
import { useTheme } from '@mui/material';

import { getEnv } from '@sticky/config';

import { getDidomiProviderConfig } from './utils/didomi-provider-config';
import type { cwtToken, VendorConsent } from './types';
import { useDidomiStore } from './useDidomiStore';

const adjustConsentedVendor = (
  vendor: VendorConsent,
  consentedVendors: string[],
) =>
  vendor.status
    ? getConsentedVendor(vendor.id, consentedVendors)
    : removeNotConsentedVendor(vendor.id, consentedVendors);

const getConsentedVendor = (id: string, consentedVendors: string[]) => [
  ...consentedVendors,
  id,
];

const removeNotConsentedVendor = (id: string, consentedVendors: string[]) =>
  consentedVendors.filter(consentedVendor => consentedVendor !== id);

export interface DidomiProviderProps {
  enabled?: boolean;
  apiKey?: string;
  config?: DidomiReact.IDidomiConfig;
  children?: React.ReactNode;
}

export const DidomiProvider = ({
  children,
  config,
  enabled,
  apiKey,
}: DidomiProviderProps) => {
  const { consentedVendors, setConsentedVendors } = useDidomiStore();
  const theme = useTheme();

  if (!enabled) return <>{children}</>;

  // Récupération de la configuration de didomi
  const didomiApiKey = apiKey ?? getEnv('VITE_DIDOMI_API_KEY');
  const didomiConfig = getDidomiProviderConfig({ ...config, theme });

  const onDidomiReady = (didomi: IDidomiObject) => {
    // initialize the consentedVendors on didomiReady event
    setConsentedVendors(
      didomi?.getUserConsentStatusForAll()?.vendors?.enabled ?? [],
    );
  };

  const onDidomiConsentChanged = (cwtToken: cwtToken) => {
    // if the vendor is consented we add it to the consentedVendors otherwise we remove it
    setConsentedVendors(
      cwtToken.consents.flatMap(consent =>
        consent.vendors.flatMap(vendor =>
          adjustConsentedVendor(vendor, consentedVendors),
        ),
      ),
    );
  };

  return (
    <>
      <DidomiSDK
        apiKey={didomiApiKey}
        config={didomiConfig}
        iabVersion={2}
        gdprAppliesGlobally={true}
        onReady={onDidomiReady}
        onConsentChanged={onDidomiConsentChanged}
      />
      {children}
    </>
  );
};
