import { createRoot } from 'react-dom/client';

import './configs/feature-flipping';
import './routes';
import './i18n';

import { App } from './App';

import './index.css';

// Init React APP
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
