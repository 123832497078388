import { useState } from 'react';
import Cancel from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

const useStyles = makeStyles<{ color?: string }>()((theme, { color }) => {
  const {
    app: { colors },
  } = theme;
  return {
    closeButton: {
      gridArea: 'closeButton',
      padding: 0,
      border: '2px solid transparent',
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    onFocus: {
      color: color ?? colors.primary,
      width: theme.spacing(4),
      height: theme.spacing(4),
      border: '2px dashed',
    },
    closeIcon: {
      '&.MuiSvgIcon-root': {
        fill: color ?? colors.primary,
      },
    },
  };
});

interface CloseButtonProps {
  onClose?: () => void;
  color?: string;
}

export const CloseButton = ({ onClose, color }: CloseButtonProps) => {
  const { classes } = useStyles({ color });
  const [onFocus, setOnFocus] = useState(false);

  return (
    <IconButton
      aria-label={t('actions.close-modal')}
      className={`${classes.closeButton} ${onFocus ? classes.onFocus : ''}`}
      onClick={onClose}
      onFocus={() => setOnFocus(true)}
      onBlur={() => setOnFocus(false)}
      size="large"
    >
      <Cancel className={classes.closeIcon} />
    </IconButton>
  );
};
