import { lazy } from 'react';

import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { LAYOUT } from '../layout';
import type { IRoute } from '../routes';

const routes: IRoute[] = [
  {
    id: 'free-places/available-places',
    path: '/max-planner',
    title: t('free-places.pageTitle'),
    layout: LAYOUT.CUSTOMER,
    element: lazy(() => import('../../pages/free-places/available-places')),
    isDisabled: !getFeatures().freePlaces.enabled,
  },
];

export default routes;
