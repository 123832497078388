import React from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { useAuthStore } from '@sticky/auth';
import { t } from '@sticky/i18n';

import { LazyLoader } from '../components';

import type { IRoute } from './routes';

type RequireAuthProps = {
  children: React.ReactNode;
};

const RequireAuth = ({ children }: RequireAuthProps) => {
  const authStore = useAuthStore();

  if (!['authenticated', 'not-authenticated'].includes(authStore.status)) {
    return <LazyLoader title={t('auth.connecting')} />;
  }

  if (authStore.status === 'not-authenticated') {
    return <Navigate to="/" replace />;
  }

  return children;
};

export function toRouterObject(route: IRoute): RouteObject {
  const RouteElement = route.element;
  return {
    ...route,
    element: route.private ? (
      <RequireAuth>
        <RouteElement />
      </RequireAuth>
    ) : (
      <RouteElement />
    ),
  };
}
