import { makeStyles } from 'tss-react/mui';

type CardNumberProps = {
  value?: string;
  className?: string;
};

const useStyles = makeStyles()(() => ({
  root: {
    whiteSpace: 'nowrap',
  },
  baseCardNumber: {
    fontWeight: 300,
    letterSpacing: '-0.05rem',
  },
  valuedCardNumber: {
    fontWeight: 500,
  },
}));

export const CardNumber = ({ value, className }: CardNumberProps) => {
  const { classes, cx } = useStyles();

  if (!value || value.length <= 8) {
    return null;
  }

  const baseCardNumber = value.substring(0, 8);
  const valuedCardNumber = value.substring(8);

  return (
    <span className={cx(classes.root, className)} data-dd-privacy="mask">
      <span className={classes.baseCardNumber}>{baseCardNumber}</span>
      <span className={classes.valuedCardNumber}>{valuedCardNumber}</span>
    </span>
  );
};
