import type { DidomiSDKProps } from '../types';

export const getSDKConfig = (
  config: DidomiSDKProps,
): DidomiReact.IDidomiConfig => ({
  app: {
    ignoreCountry: true,
    privacyPolicyURL: config.urlPrivacyPolicy,
    vendors: {
      custom: config.customVendors,
    },
    customPurposes: config.customPurposes,
    essentialPurposes: config.essentialPurposes,
  },
  languages: {
    enabled: ['fr'],
    default: 'fr',
  },
  notice: {
    position: 'popup',
    closeOnClick: true,
    closeOnClickBackdrop: false,
    daysBeforeShowingAgain: 0,
    textAlignment: 'left',
    learnMoreMargin: '0 20px 0 0',
    logoAlignment: 'flex-start',
    denyOptions: {
      button: 'secondary',
      link: true,
    },
    content: {
      notice: config.contentNotice,
      dismiss: config.contentDismiss,
      learnMore: config.contentLearnMore,
    },
  },
  preferences: {
    defaultChoice: true,
    enableAllButtons: true,
    information: {
      enable: false,
    },
    content: {
      text: config.contentPreferences,
    },
  },
  theme: {
    color: config.primaryColor,
    linkColor: config.primaryColor,
    font: config.font,
    css: `
            #didomi-host #didomi-popup .didomi-popup-notice {
                padding: 20px 0 !important;
            }

            #didomi-host .didomi-popup-view {
                display: flex;
                flex-direction: column;
            }

            #didomi-host .didomi-exterior-border {
                border: none;
            }

            #didomi-host #didomi-popup .didomi-popup-notice {
                max-width: 80vw;
                padding: 30px 0;
                border: 1px solid ${config.primaryColor};
                border-radius: 24px;
            }

            #didomi-host .didomi-continue-without-agreeing {
                align-self: flex-end;
                margin: 0 30px;
                margin-bottom: 30px;
            }

            #didomi-host .didomi-popup-notice-text-container {
                margin: 0 30px;
            }

            #didomi-host .didomi-buttons .didomi-popup-notice-buttons {
                margin: 0 30px;
            }

            #didomi-consent-popup .didomi-popup-header {
                text-align: center;
            }

            .didomi-consent-popup-body__explanation {
                margin-top: 0;
            }

            .didomi-notice-view-partners-link {
                display: none !important;
            }
        `,
    buttons: {
      highlightButtons: {
        // Agree/save/agree to all buttons.
        borderColor: config.noButtonBorder
          ? 'transparent'
          : config.primaryColor,
        borderWidth: '1px',
        borderRadius: '25px',
      },
      regularButtons: {
        // Learn more/disagree/disagree to all buttons.
        borderColor: config.noButtonBorder
          ? 'transparent'
          : config.secondaryColor,
        borderWidth: '1px',
        borderRadius: '25px',
      },
    },
  },
});
