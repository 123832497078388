import { format } from 'date-fns';
import kebabCase from 'lodash.kebabcase';
import { createSelector } from 'reselect';

import type { IInvoiceOrCreditMemo } from '../models/creditmemo';
import { InvoiceStatusPayment } from '../models/invoice';
import { SubscriptionCancelledStatusResultCode } from '../models/subscription';

import { getSubscriptionState } from './get-subscription-state';

/**
 * Merge invoices and credit memo into an all-in-one group.
 * - normalize datas
 * - group by months
 */
export const getSubscriptionInvoicesAndCreditMemo = createSelector(
  [getSubscriptionState],
  ({ subscription }): IInvoiceOrCreditMemo[] | null => {
    // If subscription is not yet loaded => return null
    if (!subscription) {
      return null;
    }

    // Init documents to returns
    const documents: IInvoiceOrCreditMemo[] = [];

    // Format all invoices
    subscription?.invoices.forEach(invoice => {
      let status: InvoiceStatusPayment = InvoiceStatusPayment.UNPAID;
      const amount = -1 * invoice.amount;

      if (invoice.balance === 0) {
        status = InvoiceStatusPayment.PAID;
      } else if (!Number(invoice.rejectNumber) && !invoice.rejectType) {
        status = InvoiceStatusPayment.WAITING;
      } else if (Number(invoice.rejectNumber) > 0 && invoice.rejectType) {
        status = InvoiceStatusPayment.UNPAID;
      }

      // Define if we have to display a -pay button for that invoice
      const hasUnpaidBalance = invoice.balance > 0;
      const isCancelled = Object.values(
        SubscriptionCancelledStatusResultCode,
      ).includes(subscription.statusResult);
      const needsRegularization =
        !!hasUnpaidBalance &&
        (status === InvoiceStatusPayment.UNPAID || isCancelled);

      documents.push({
        type: 'invoice',
        at: new Date(invoice.billingDate),
        status,
        amount: hasUnpaidBalance ? amount : Math.abs(invoice.amount), // Don't display minus sign if invoice is acquited
        balance: invoice.balance,
        accountId: subscription?.account?.id,
        fileId: invoice.id,
        fileName: 'WILL BE COMPLETE BELOW',
        needsRegularization,
      });
    });

    // Formaty all refunds
    subscription?.creditmemo.forEach(refund => {
      const status: InvoiceStatusPayment = InvoiceStatusPayment.REFUND;

      documents.push({
        type: 'creditmemo',
        at: new Date(refund.creditMemoDate),
        status,
        amount: refund.refundAmount,
        balance: refund.amount,
        accountId: subscription?.account?.id as string,
        fileId: refund.pdfFileId,
        fileName: 'WILL BE COMPLETE BELOW',
      });
    });

    // Adjust download fileName
    const completeDocuments = documents.map(doc => {
      const humanDate = format(doc.at, 'yyyyMMdd');
      const subscriptionType = kebabCase(
        subscription.subscription.ratePlanSelected?.productName,
      ).toUpperCase();

      return {
        ...doc,
        fileName: [humanDate, subscriptionType].join('_') + '.pdf',
      };
    });

    // All is merged and ok to return
    return completeDocuments.sort((a, b) => (a.at > b.at ? -1 : 1));
  },
);
