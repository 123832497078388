import { useState } from 'react';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import { tss } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { t } from '@sticky/i18n';

import { Routes } from '../../../routes/routes';
import { useStrapiConfig } from '../../../utils/useStrapiConfig';

const MAX_WIDTH = 1360;

const useStyles = tss.create(({ theme }) => ({
  background: {
    background: theme.app.colors.bannerBackground,
    position: 'relative',
  },
  backgroundTitle: {
    background: theme.app.colors.white,
    height: '1.8rem',
    left: 0,
    right: `calc(${MAX_WIDTH}px + (100vw - ${MAX_WIDTH}px) / 2 - 2rem)`,
    position: 'absolute',
    top: '1.5rem',
  },
  banner: {
    alignItems: 'center',
    color: theme.app.colors.white,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: MAX_WIDTH,
    padding: '1.5rem 0',
    [theme.breakpoints.up('breakPointDesktop')]: {
      alignItems: 'baseline',
      justifyContent: 'center',
      flexDirection: 'row',
    },
  },
  content: {
    flex: 1,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    padding: `0 ${theme.typography.pxToRem(16)}`,
    textAlign: 'center',
    [theme.breakpoints.up('breakPointDesktop')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  mainMessage: {
    margin: `${theme.typography.pxToRem(20)} 0 0`,
    [theme.breakpoints.up('breakPointDesktop')]: {
      margin: 0,
    },
  },
  informationButton: {
    fontWeight: 400,
    padding: 0,
    marginTop: theme.typography.pxToRem(20),
    [theme.breakpoints.up('breakPointDesktop')]: {
      marginTop: 0,
    },
    '&.MuiButton-root': {
      [theme.breakpoints.up('breakPointDesktop')]: {
        minWidth: 170,
      },
    },
  },
  readMoreButton: {
    color: 'white',
    background: 'none',
    border: 'none',
    fontWeight: 400,
    padding: 0,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  secondMessage: {
    margin: '0.5rem 0 0',
  },
  title: {
    alignSelf: 'flex-start',
    background: theme.app.colors.white,
    borderRadius: '0 0.9rem 0.9rem 0',
    color: theme.app.colors.textPrimaryDark,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: '1.8rem',
    padding: `0 1rem`,
    whiteSpace: 'nowrap',
    zIndex: 1,
    [theme.breakpoints.up('breakPointDesktop')]: {
      paddingLeft: theme.typography.pxToRem(24),
    },
  },
  gridContainer: {
    alignItems: 'center',
    [theme.breakpoints.up('breakPointDesktop')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}));

const HomeBanner = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));
  const [secondMessageExpanded, setSecondMessageExpanded] = useState(isDesktop);

  const strapiConfigQuery = useStrapiConfig();
  const banner = strapiConfigQuery.data?.attributes?.banner;
  const information = strapiConfigQuery.data?.attributes?.information;

  if (!banner?.visibility) {
    return null;
  }

  const showReadMoreButton = !isDesktop && !secondMessageExpanded;

  return (
    <div className={classes.background}>
      <div className={classes.backgroundTitle}></div>
      <div className={classes.banner}>
        <span className={classes.title}>{banner.caption}</span>
        <div className={classes.content}>
          <div className={classes.gridContainer}>
            <Container>
              <p className={classes.mainMessage}>{banner.title}</p>
              {!!banner.description && (
                <>
                  {showReadMoreButton ? (
                    <button
                      type="button"
                      className={classes.readMoreButton}
                      onClick={() => setSecondMessageExpanded(true)}
                    >
                      {t('actions.read-more')}
                    </button>
                  ) : (
                    <p className={classes.secondMessage}>
                      {banner.description}
                    </p>
                  )}
                </>
              )}
            </Container>
            {information?.visibility && (
              <ActionButton
                className={classes.informationButton}
                onClick={() => setSecondMessageExpanded(true)}
                href={Routes.getPathById('information')}
                variant="outlined"
              >
                {t('actions.info')}
              </ActionButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
