import { Typography } from '@mui/material';

import { t } from '@sticky/i18n';

import { ModalBox } from '../modal-box';

interface SubscriptionProblemModalModalProps {
  open: boolean;
  onClose: () => void;
}

export const BlacklistControlProblemModal = ({
  open,
  onClose,
}: SubscriptionProblemModalModalProps) => (
  <ModalBox
    open={open}
    title={t('enrollment.blacklist-control-error.title')}
    titleComponent="h2"
    closeButton="header"
    onClose={onClose}
  >
    <Typography>{t('enrollment.blacklist-control-error.message')}</Typography>
  </ModalBox>
);
