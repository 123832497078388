import { useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { shuffle } from '@sticky/helpers';
import { t } from '@sticky/i18n';

import { ModalBox } from '../../components/modal/modal-box';

import { BrandContext } from './brand-provider';
import { BrandsService } from './brands-service';

const useStyles = makeStyles()(() => ({
  distributorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: 'min-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const BrandChoiceModal = () => {
  const { classes } = useStyles();
  const publicBrands = BrandsService.getAllPublic();
  const { brand } = useContext(BrandContext);

  const distributors = shuffle(publicBrands).map(_brand => (
    <ActionButton
      key={_brand.id}
      onClick={() => {
        window.location.href = `/${_brand.id}`;
      }}
    >
      {t('brand.name', {
        context: _brand.id.toUpperCase(),
      })}
    </ActionButton>
  ));

  return (
    <ModalBox
      open={!brand}
      title={t('brand.choice-modal.title')}
      alignTitleCenter={true}
      closeButton={false}
      titleComponent="h2"
    >
      <div className={classes.distributorsContainer}>{distributors}</div>
    </ModalBox>
  );
};
