import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { getEnv } from '@sticky/config';

import { useAppSelector } from '../../hooks';
import { Routes } from '../../routes/routes';

interface HealthCheckProps {
  maintenanceRouteId?: string;
  whiteListPaths?: string;
  checkUrl?: string;
  checkInterval?: number;
}

const checkStatus = async (url: string): Promise<boolean> => {
  try {
    const response = await axios.request({
      url,
      params: {
        t: new Date().getTime(),
      },
    });
    if (response.data === '') {
      return true;
    }
    const data = JSON.parse(response.data);
    return !!data;
  } catch (err) {
    // Do nothing
  }
  return false;
};

const HealthCheck = (props: HealthCheckProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    whiteListPaths = [Routes.getPathById('maintenance')],
    maintenanceRouteId = 'maintenance',
    checkUrl = '/maintenance.json',
    checkInterval = 60000,
  } = props;

  // State for forces maintenance
  const [isForcedMaintenance, setForcedMaintenance] = useState(false);

  // Get the customer service availibility
  const customer = useAppSelector(state => state.customer);
  const isServiceMaintenance =
    customer.serviceAvailable === false || isForcedMaintenance;
  const isCheckForcedDisabled = !!customer.serviceAvailableForceDisable;

  // Get the maintenance page
  const maintenancePath = Routes.getPathById(maintenanceRouteId);

  // Check service availibity and fails to 'maintenance' page if customer service is down
  useEffect(() => {
    // Do nothing if service check is disabled
    if (isCheckForcedDisabled) {
      return;
    }

    // Go to maintenance page if required
    if (
      isServiceMaintenance &&
      maintenancePath &&
      !whiteListPaths?.includes(location.pathname)
    ) {
      navigate(maintenancePath);
    }

    // Returns to home if all is good again (by fully reloading the page)
    else if (
      !isServiceMaintenance &&
      maintenancePath &&
      location.pathname === maintenancePath
    ) {
      window.location.href = getEnv('VITE_PUBLIC_URL');
    }

    // Check the file status and set state
    const checkMaintenance = async function () {
      const flagMaintenance = await checkStatus(checkUrl);
      setForcedMaintenance(flagMaintenance);
    };

    // Check maintenance file on boot, then scheduled
    checkMaintenance();
    const id = setInterval(() => {
      checkMaintenance();
    }, checkInterval);

    // Cancel interval on useEffect end
    return () => clearInterval(id);
  }, [
    checkInterval,
    checkUrl,
    isCheckForcedDisabled,
    isServiceMaintenance,
    location.pathname,
    maintenancePath,
    navigate,
    whiteListPaths,
  ]);

  // Returns empty object
  return <></>;
};

export { HealthCheck };
