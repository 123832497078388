import { Box } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { formatToDDMMYYYY } from '@sticky/helpers';
import { t } from '@sticky/i18n';

import { TypoWithSkeleton } from '../../../../components';
import { useAppSelector } from '../../../../hooks';
import { subscriptionSelectors } from '../../../subscription/selectors';
import { customerSelectors } from '../../selectors';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { colors },
  } = theme;

  return {
    remainingContainer: {
      justifySelf: 'end',
      alignSelf: 'flex-end',
      display: 'grid',
      gridArea: 'remainingContainer',
      minWidth: '110px',
      columnGap: '0.7rem',
      gridTemplateColumns: 'min-content 1fr',
      gridTemplateRows: 'min-content',
      gridTemplateAreas: `"remainingCount remainingLabel"
                          "remainingCount remainingDate"`,
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: '0.6rem',
        gridArea: 'unset',
        justifySelf: 'start',
        minWidth: '200px',
      },
    },
    remainingCount: {
      '&.MuiTypography-root': {
        gridArea: 'remainingCount',
        borderRadius: '50%',
        border: `1px solid ${colors.black}`,
        width: '1.8rem',
        height: '1.8rem',
        lineHeight: '1',
        textAlign: 'center',
        fontSize: '0.8rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        alignSelf: 'flex-start',
        marginTop: '0.2rem',
        paddingTop: '0.1rem',
        [breakpoints.up('breakPointDesktop')]: {
          borderColor: colors.white,
          width: '2rem',
          height: '2rem',
          lineHeight: '2rem',
          paddingTop: '0rem',
        },
      },
    },
    remainingLabel: {
      gridArea: 'remainingLabel',
      fontWeight: 700,
      lineHeight: '1.2rem',
    },
    remainingDate: {
      gridArea: 'remainingDate',
      // Mui typo style
      '&.MuiTypography-root': {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    typography: {
      color: colors.textPrimary,
      [breakpoints.up('breakPointDesktop')]: {
        color: colors.textSecondary,
      },
    },
  };
});

export const NavigationHeaderCounter = () => {
  const { classes } = useStyles();

  const { bookCounterN0 } = useAppSelector(
    customerSelectors.getCardBookCounters,
  );
  const validityEndDate = useAppSelector(customerSelectors.getValidityEndDate);
  const isSubscriptionReady = useAppSelector(
    subscriptionSelectors.isSubscriptionReady,
  );

  return (
    <Box className={classes.remainingContainer}>
      <TypoWithSkeleton
        className={classes.typography + ' ' + classes.remainingCount}
        skelProps={{ variant: 'circular', width: '100%' }}
        isReady={isSubscriptionReady}
      >
        {bookCounterN0}
      </TypoWithSkeleton>

      <TypoWithSkeleton
        component="b"
        className={classes.typography + ' ' + classes.remainingLabel}
        skelProps={{ width: '80%' }}
        isReady={isSubscriptionReady}
      >
        {t('customer.nav.remainingReservations')}
      </TypoWithSkeleton>

      <TypoWithSkeleton
        className={classes.typography + ' ' + classes.remainingDate}
        skelProps={{ width: '60%' }}
        isReady={isSubscriptionReady}
      >
        {validityEndDate
          ? t('until-date', {
              validityEndDate: formatToDDMMYYYY(validityEndDate),
            })
          : ''}
      </TypoWithSkeleton>
    </Box>
  );
};
