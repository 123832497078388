import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { getFeatures } from '@sticky/config';
import { t, Trans } from '@sticky/i18n';

import { ModalBox } from '../../../components';
import { CGVErrorModal } from '../../../components/modal/cgv-error/cgv-error-modal';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { customerSelectors } from '../../customer/selectors';
import { buildCgvValidationStates } from '../store/terms-slice';

export type ModalTermsSimpleProps = {
  onClose?: () => void;
  title?: string;
};

// Modal style
const useStyles = makeStyles()(() => ({
  root: {
    position: 'relative',
  },
}));

// Modal for CGV popup
export const ModalTermsSimple = ({
  title = t('terms.title'),
}: ModalTermsSimpleProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const [isModalShown, setModalShow] = useState(false);

  // Get cgv + summary store
  const terms = useAppSelector(state => state.terms);
  const productType = useAppSelector(customerSelectors.getProductType);
  const { subscription: summary } = useAppSelector(state => state.subscription);
  const {
    cgvStatus,
    subscription: { number: subscriptionNumber },
    currentCgv,
  } = summary ?? { subscription: {} };
  const adminPage = window.location.pathname.includes('/admin/');

  // Decide to display modal or not
  useEffect(() => {
    if (
      cgvStatus &&
      subscriptionNumber !== undefined &&
      getFeatures().terms.validation &&
      !adminPage
    ) {
      setModalShow(true);
      dispatch(
        buildCgvValidationStates({
          helpDeskInput: {
            reasonCode: t('terms.helpDesk.reasonCode'),
            subreasonCode: t('terms.helpDesk.subreasonCode'),
            detailCode: t('terms.helpDesk.detailCode'),
            productType, // mostly used for iDTGVMAX
          },
          version: currentCgv?.release,
          mode: 'write',
          cardNumber: subscriptionNumber,
        }),
      );
    }
  }, [
    adminPage,
    cgvStatus,
    currentCgv?.release,
    dispatch,
    productType,
    subscriptionNumber,
  ]);

  if (terms.error) {
    return (
      <CGVErrorModal open={isModalShown} onclose={() => setModalShow(false)} />
    );
  }

  return (
    <ModalBox
      title={title}
      open={isModalShown}
      closeButton={false}
      isDisableBackdropClick
      actions={[
        <ActionButton href={terms.iFrameUrl} loading={!terms.iFrameUrl} key={0}>
          <Trans i18nKey="terms.show" />
        </ActionButton>,
      ]}
    >
      <div className={classes.root}>
        <Typography variant="body1">
          <Trans i18nKey="terms.description" />
        </Typography>
      </div>
    </ModalBox>
  );
};
