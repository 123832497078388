import { decodeStringHex } from '@sticky/helpers';

import type { BrandsService } from '../../brand/brands-service';
import type { ICustomerAuthReason } from '../models/customer';

export class MyId {
  /**
   * Parse redirect url to get queryParams.
   *
   * @static
   * @param {string} search
   * @return {*}  {{
   *     login_hint?: string;
   *     code?: string;
   *     state?: {
   *       [BrandService.BRAND_ID_STORAGE_KEY]?: string;
   *       reasonCode?: ICustomerAuthReason
   *     };
   *   }}
   * @memberof MyId
   */
  public static parseAuthRedirectUrl(search: string): {
    login_hint?: string;
    code?: string;
    state?: {
      [BrandsService.BRAND_ID_STORAGE_KEY]?: string;
      reasonCode?: ICustomerAuthReason;
    };
  } {
    // Conserve qs and try to parse state (is decoded from URIComponent)
    const qs = new URLSearchParams(search);
    const state = this.parseState(qs);

    // Remove 'state' from qs anyway
    qs.delete('state');

    // Keep passed qs entries
    const entries: Record<string, string> = {};
    try {
      qs.forEach((val, key) => (entries[key] = val));
    } catch (err) {
      // Do nothing
    }

    // Returns parsed response
    return {
      ...entries,
      state,
    };
  }

  /**
   * Parse and decode queryParam 'state'.
   *
   * @static
   * @param {URLSearchParams} qs
   * @return {*}  {Record<string, string>}
   * @memberof MyId
   */
  public static parseState(qs: URLSearchParams): Record<string, string> {
    let state = {};
    try {
      state = JSON.parse(decodeStringHex(qs.get('state') ?? '') ?? 'null');
    } catch (err) {
      // Do nothing
    }
    return state;
  }
}
