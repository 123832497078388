import { type AxiosResponse, isAxiosError } from 'axios';

import { setAuthConfig } from './authConfig';
import { subscribeCaptchaError } from './captcha';
import { anonymous, apiKey, auth } from './clients';
import { setDistributionChannelGetter } from './distributionChannel';

export { isStickyGatewayError } from './errors';
export { HEADER_CORRELATION_ID } from './helpers';
export * from './types';

export const StickyGatewayClient = {
  apiKeyClient: () => apiKey,
  authClient: () => auth,
  anonymousClient: () => anonymous,

  thunkApiHandler: async <T>(
    asyncFn: Promise<AxiosResponse<T>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thunkApi: any,
  ) => {
    try {
      const { data } = await asyncFn;
      return data;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return thunkApi.rejectWithValue(error.response?.data ?? error);
      }
      return thunkApi.rejectWithValue(error);
    }
  },

  setAuthConfig,
  setDistributionChannelGetter,
  subscribeCaptchaError,
};
