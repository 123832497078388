import { getEnv } from '@sticky/config';

import { isMFA, isTMJ, isTMS } from '../../../utils/product';
import { useHasIdTgvMaxSubscription } from '../../subscription/hooks/useHasIdTgvMaxSubscription';
import { SubscriptionProductId } from '../../subscription/models/subscription';

export function useProductId(
  context: string = getEnv('VITE_PRODUCT_SELECT_DEFAULT'),
): SubscriptionProductId {
  // Is a MAX subscription
  const hasIdTgvMaxSubscription = useHasIdTgvMaxSubscription();

  // If it's MFA and the context should be used
  if (isMFA()) {
    if (['PARTTIME', 'MFA_PT'].includes(context ?? '')) {
      return SubscriptionProductId.MAX_ACTIF;
    }

    if (['FULLTIME', 'MFA_FT'].includes(context ?? '')) {
      return SubscriptionProductId.MAX_ACTIF_PLUS;
    }
  }

  // MAX_SENIOR : simple, no monsters under the bed
  if (isTMS()) {
    return SubscriptionProductId.TGV_MAX_SENIOR;
  }

  // It's an IDTGVMAX2: end of the game
  if (isTMJ()) {
    if (
      hasIdTgvMaxSubscription ||
      context === SubscriptionProductId.IDTGV_MAX
    ) {
      return SubscriptionProductId.IDTGV_MAX;
    } else {
      return SubscriptionProductId.TGV_MAX_JEUNE;
    }
  }

  // If you are here, you're in a rabbit hole
  return context as SubscriptionProductId;
}
