import type { ZodRawShape } from 'zod';
import { z } from 'zod';

export const StrapiDocumentSchema = <T extends ZodRawShape = ZodRawShape>(
  body: T,
) =>
  z
    .object({
      data: z.object({
        id: z.number(),
        attributes: z.object({
          createdAt: z.string(),
          updatedAt: z.string(),
          publishedAt: z.string(),
          ...body,
        }),
      }),
    })
    .transform(({ data }) => data);
