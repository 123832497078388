/* eslint-disable @typescript-eslint/no-unused-vars */

export class BaseLoggerOutput {
  setUser(user: { iuc: string } | undefined) {
    // Do nohting
  }

  log(title: string, ...args: unknown[]) {
    // Do nohting
  }

  warn(title: string, ...args: unknown[]) {
    // Do nohting
  }

  error(title: string, exception: Error | unknown) {
    // Do nohting
  }
}
