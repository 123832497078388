import type { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { t } from '@sticky/i18n';

import { TravelConfirmed } from '../../features/reservation';
import { toNumericFrenchDateTime } from '../../utils/date';

const useStyles = makeStyles()(
  ({ breakpoints, app: { colors }, typography: { pxToRem } }: Theme) => ({
    notConfirmableLabel: {
      width: '100%',
      textAlign: 'center',
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: pxToRem(14),
      },
    },
    confirmTooLate: {
      width: '100%',
      textAlign: 'center',
      color: colors.errorDark,
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: pxToRem(14),
      },
    },
    autoCancelled: {
      width: '100%',
      fontSize: '1.2rem',
      textAlign: 'center',
      color: colors.errorDark,
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: pxToRem(14),
      },
    },
    actionInfo: {
      marginBottom: pxToRem(14),
      [breakpoints.up('breakPointDesktop')]: {
        marginBottom: 0,
      },
    },
  }),
);

export const InfoMessage = ({
  travelConfirmed,
  confirmationDelayStart,
}: {
  travelConfirmed: TravelConfirmed;
  confirmationDelayStart: Date;
}): JSX.Element => {
  const { classes } = useStyles();
  const infoMessageClasses = `${classes.notConfirmableLabel} ${classes.actionInfo}`;

  switch (travelConfirmed) {
    case TravelConfirmed.TOO_EARLY_TO_CONFIRM:
      return (
        <div className={infoMessageClasses}>
          {t('travels.detailsTravel.noShow.confirmableFrom', {
            confirmableFrom: toNumericFrenchDateTime(
              confirmationDelayStart.toISOString(),
            ),
          })}
        </div>
      );
    case TravelConfirmed.UNKNOWN_DURING_CONFIRMATION:
      return (
        <div className={infoMessageClasses}>
          {t('travels.detailsTravel.noShow.confirmationUnavailable')}
        </div>
      );
    case TravelConfirmed.WILL_BE_CANCELED:
      return (
        <div className={`${classes.confirmTooLate} ${classes.actionInfo}`}>
          {t('travels.detailsTravel.noShow.confirmTooLate')}
        </div>
      );
    case TravelConfirmed.CANCELED:
      return (
        <div className={`${classes.autoCancelled} ${classes.actionInfo}`}>
          {t('travels.detailsTravel.noShow.autoCancelled')}
        </div>
      );
    default:
      return <></>;
  }
};
