import { lazy } from 'react';

import { ConfigCommon, getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import commonCustomerMyCoordinatesUpdateIban from './customer/my-coordinates/update-iban/index';
import commonCustomerMyCoordinatesUpdatePhoto from './customer/my-coordinates/update-photo/index';
import commonCustomerMySubscriptionRegularization from './customer/my-subscription/regularization/index';
import commonEnrollmentChoosePlan from './enrollment/choose-plan';
import commonEnrollmentMyCoordinates from './enrollment/my-coordinates';
import commonEnrollmentPayment from './enrollment/payment';
import commonEnrollmentValidation from './enrollment/validation';
import { LAYOUT } from './layout';
import type { IRoute } from './routes';

const globalRoutes: IRoute[] = [
  {
    id: 'auth/redirect',
    path: '/auth/login/redirect',
    element: lazy(
      () => import('../pages/customer/account/customer-login-redirection'),
    ),
    title: t('auth.login.pageTitle'),
    layout: LAYOUT.EMPTY,
  },
  {
    id: 'auth/logout/redirect',
    path: '/auth/logout/redirect',
    element: lazy(
      () => import('../pages/customer/account/customer-logout-redirection'),
    ),
    title: t('auth.logout.pageTitle'),
    layout: LAYOUT.EMPTY,
  },
  {
    id: 'maintenance',
    path: '/maintenance',
    element: lazy(() => import('../pages/maintenance/maintenance')),
    title: t('maintenance.pageTitle'),
    layout: LAYOUT.TECHNICAL,
  },
  {
    id: 'information',
    path: '/information',
    element: lazy(() => import('../pages/information/information')),
    title: t('maintenance.pageTitle'),
    layout: LAYOUT.HOME,
  },
  {
    id: 'dev/admin',
    path: '/admin.version',
    element: lazy(() => import('../pages/dev/admin-version/admin-version')),
    title: t('dev.admin-version'),
    layout: LAYOUT.HOME,
  },
  {
    id: 'dev/admin/version',
    path: '/admin/version',
    element: lazy(() => import('../pages/dev/admin-version/admin-version')),
    title: t('dev.admin-version'),
    layout: LAYOUT.HOME,
  },
  {
    id: 'dev/admin/features',
    path: '/admin/features',
    element: lazy(
      () =>
        import('../pages/dev/admin-version/admin-version-features-flipping'),
    ),
    title: t('dev.admin-features'),
    layout: LAYOUT.HOME,
    isDisabled: ConfigCommon.env.isProd || ConfigCommon.env.isPab,
  },
  {
    id: 'dev/design-system',
    path: '/design-system',
    element: lazy(() => import('../pages/dev/design-system/design-system')),
    title: t('dev.design-system'),
    layout: LAYOUT.HOME,
  },
  {
    id: 'customer/signup',
    path: '/creer-mon-compte',
    element: lazy(
      () => import('../pages/customer/creation/customer-account-creation'),
    ),
    title: t('customer.creation.pageTitle'),
    layout: LAYOUT.CUSTOMER,
    isDisabled: getFeatures().auth.midSignUp,
  },
  {
    id: 'not-found',
    path: '/introuvable',
    element: lazy(() => import('../pages/not-found')),
    title: t('notFound.pageTitle'),
    layout: LAYOUT.TECHNICAL,
  },
  {
    id: 'customer/my-coordinates',
    path: '/mes-coordonnees',
    element: lazy(() => import('../pages/customer/account/customer-account')),
    title: t('customer.coordinates.index'),
    layout: LAYOUT.CUSTOMER,
    private: true,
  },
  {
    id: 'customer/my-subscription',
    path: '/mon-abonnement',
    element: lazy(
      () => import('../pages/customer/subscription/customer-subscription'),
    ),
    title: t('customer.subscription.index'),
    layout: LAYOUT.CUSTOMER,
    private: true,
  },
  {
    id: 'customer/faq',
    path: '/contact-et-faq',
    element: lazy(
      () => import('../pages/customer/account/customer-contact-faq'),
    ),
    title: t('customer.FAQ'),
    layout: LAYOUT.CUSTOMER,
    private: true,
  },
  {
    id: 'customer/download-subscription',
    path: '/mon-abonnement/telechargement',
    element: lazy(
      () => import('../pages/customer/account/customer-download-subscription'),
    ),
    title: t('customer.subscription.download-title'),
    layout: LAYOUT.CUSTOMER,
    private: true,
  },

  {
    id: 'privacy-policy',
    path: '/politique-de-confidentialite',
    element: lazy(() => import('../pages/privacy-policy/privacy-policy')),
    title: t('privacyPolicy.pageTitle'),
    layout: LAYOUT.HOME,
  },

  {
    id: 'support',
    path: '/support',
    element: lazy(() => import('../pages/support/support')),
    title: t('support.pageTitle'),
    layout: LAYOUT.EMPTY,
  },
];

const commonRoutes = [
  ...globalRoutes,
  ...commonCustomerMyCoordinatesUpdateIban,
  ...commonCustomerMyCoordinatesUpdatePhoto,
  ...commonEnrollmentChoosePlan,
  ...commonEnrollmentMyCoordinates,
  ...commonEnrollmentPayment,
  ...commonEnrollmentValidation,
  ...commonCustomerMySubscriptionRegularization,
];
export default commonRoutes;
