import { getFeatures } from '@sticky/config';

import { useAppSelector } from '../../../hooks';
import { subscriptionSelectors } from '../selectors';

export const useHasIdTgvMaxSubscription = (): boolean => {
  const hasIdTgvMaxSubscription = useAppSelector(
    subscriptionSelectors.isIdTgvMax2Subscription,
  );

  return hasIdTgvMaxSubscription && getFeatures().idtgvmax2.enabled;
};
