import type { Theme } from '@mui/material';

import { getEnv, getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import {
  DidomiPurposeAnalytics,
  DidomiPurposeMonitoring,
  DidomiPurposeStickyAuthentication,
} from '../purposes';
import type {
  CustomPurpose,
  CustomVendor,
  DefaultDidomiConfig,
} from '../types';
import { DidomiVendorDatadog, DidomiVendorMid } from '../vendors';

import { getSDKConfig } from './didomi-sdk-config';

type GetDidomiProviderConfigProps = {
  theme: Theme;
} & DefaultDidomiConfig;

const defaultCustomVendors: CustomVendor[] = [DidomiVendorMid];
const defaultCustomPurposes: CustomPurpose[] = [
  DidomiPurposeStickyAuthentication,
];
const defaultEssentialPurposes = [DidomiPurposeStickyAuthentication.id];

export const getDidomiProviderConfig = ({
  theme,
  logoUrl,
  customVendors,
  customPurposes,
  essentialPurposes,
  contentNotice,
  contentDismiss,
  contentLearnMore,
  contentPreferences,
  urlPrivacyPolicy,
  primaryColor,
  secondaryColor,
  noButtonBorder,
}: GetDidomiProviderConfigProps): DidomiReact.IDidomiConfig => {
  const vendors = [...(customVendors ?? defaultCustomVendors)];
  const purposes = [...(customPurposes ?? defaultCustomPurposes)];
  const requiredPurposes = [...(essentialPurposes ?? defaultEssentialPurposes)];

  // Ajout de datadog
  if (getFeatures().tracker.datadog.enabled) {
    vendors.push(DidomiVendorDatadog);
    purposes.push(DidomiPurposeMonitoring);
    purposes.push(DidomiPurposeAnalytics);
  }

  const didomiSDKConfig = getSDKConfig({
    /* Purposes and vendors */
    customVendors: vendors,
    customPurposes: purposes,
    essentialPurposes: requiredPurposes,

    /* Theme */
    logoUrl: logoUrl ?? '/assets/logos/brand-logo-max-actif.svg',
    primaryColor: primaryColor ?? theme.palette.primary.main,
    secondaryColor: secondaryColor ?? theme.palette.primary.contrastText,
    buttonBackground: '#FFFFFF',
    bannerBackground: theme.palette.grey[400],
    font: theme.typography.fontFamily,
    noButtonBorder: noButtonBorder ?? false,

    /* Text */
    contentNotice: contentNotice ?? t('didomi.content.notice.main'),
    contentLearnMore: contentLearnMore ?? t('didomi.content.notice.learnMore'),
    contentDismiss: contentDismiss ?? t('didomi.content.notice.dismiss'),
    contentPreferences:
      contentPreferences ?? t('didomi.content.preferences.text'),
    urlPrivacyPolicy:
      urlPrivacyPolicy ??
      getEnv('VITE_PUBLIC_URL') + '/politique-de-confidentialite',
  });

  return didomiSDKConfig;
};
