import type { PropsWithChildren } from 'react';
import { Container, useMediaQuery, useTheme } from '@mui/material';

import { EnterpriseFooter } from '../components/footer/enterprise-footer';
import { FooterButton } from '../components/footer/footer-button';
import { ContentLayout } from '../pages/app-content-layout';
import { FooterCGV } from '..';

export const LayoutHomePage = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('breakPointDesktop'));

  return (
    <ContentLayout>
      <Container role="main" id="main">
        {children}
      </Container>

      {!isDesktop && <FooterButton />}

      <FooterCGV />
      <EnterpriseFooter />
    </ContentLayout>
  );
};
