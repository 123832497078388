import type { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { isAfter } from 'date-fns';

import { StickyGatewayClient } from '@sticky/gateway-client';
import { Logger } from '@sticky/logger';

import type { WeekDay } from '../../../utils/date';
import { formatToISODateHHMM, setHours, setMinutes } from '../../../utils/date';
import type {
  BookingWish,
  CancelReservationResponse,
  CancelReservationServicePayload,
  ConfirmReservationPayload,
  ExchangeStatus,
  Proposal,
  ReservationPreferences,
  Time,
  Transaction,
  TransactionResponse,
} from '..';
import {
  BookTravelsRequest,
  CancelReservationRequest,
  TransactionProposal,
} from '../models/book-travels-request';
import { ExchangeConfirmRequest } from '../models/exchange-confirm-request';
import { ExchangeSearchRequest } from '../models/exchange-search-request';
import type { ExchangeSearchResponse } from '../models/exchange-search-response';
import type { InitSearchResponse } from '../models/init-search-response';
import type { PrintTravelResponse } from '../models/print-travel-response';
import { SearchTravelRequest } from '../models/search-travel-request';
import type { SearchTravelResponse } from '../models/search-travel-response';

interface PrintTravelParams {
  orderId: string;
  serviceItemId: string;
  tcn: string[];
}

export interface ProposalRequest {
  id: string;
  search: {
    context: string;
    originCode: string;
    destinationCode: string;
    isOutBound: boolean;
    departureDate: Date;
    travelClass: string;
    productId?: string;
    cardNumber: string;
    hasWheelchairPlacement: boolean;
  };
  error?: {
    errorCode: string;
    message: string;
    details: string;
  };
  status: 'PENDING' | 'FULLFILLED' | 'REJECTED';
  travels: SearchTravelResponse[];
  isChecked: boolean;
  isDisabled?: boolean;
  selectedTravel?: SearchTravelResponse;
}

export class ReservationService {
  /**
   * Start a reservation session by retrieving a city information
   *
   * @returns {Promise<InitSearchResponse>} - search init response
   */
  static async initSearch(): Promise<InitSearchResponse> {
    return StickyGatewayClient.authClient()
      .get<InitSearchResponse>('/public/reservation/init-search')
      .then(response => response.data)
      .catch(error => {
        Logger.error('init search error', error);
        throw error;
      });
  }

  /**
   * Search for a travel solution.
   *
   * @param context the search context
   * @param origin the origin station code
   * @param destination the destination station code
   * @param departureDateTime the departure date/time
   * @param travelClass the travel class
   * @param productId the id of the product
   * @param cardNumber the card number
   * @param hasWheelchairPlacement
   * @returns {Promise<SearchTravelResponse>} - search response
   */
  static async searchTravel(
    context: string,
    origin: string,
    destination: string,
    departureDateTime: Date,
    travelClass: string,
    productId: string,
    cardNumber: string,
    hasWheelchairPlacement: boolean,
  ): Promise<SearchTravelResponse[]> {
    return StickyGatewayClient.authClient()
      .post<SearchTravelResponse[]>(
        '/public/reservation/search-travels',
        new SearchTravelRequest(
          context,
          origin,
          destination,
          formatToISODateHHMM(departureDateTime),
          travelClass,
          productId,
          cardNumber,
          hasWheelchairPlacement,
        ),
      )
      .then(response => response.data.filter(travel => travel.proposal))
      .catch(error => {
        Logger.error('search travel error', error);
        throw error;
      });
  }

  static async fetchSearchTravels({
    proposals,
    updater,
  }: {
    proposals: ProposalRequest[];
    updater: Dispatch<SetStateAction<ProposalRequest[]>>;
  }): Promise<void> {
    // FIXME : to continue

    // Configure
    let concurrentRequests = 0;
    const maxConcurrentRequests = 4;

    // Prepare travels to fetch (clone travels)
    const pendingProposals = [...proposals];

    // Start by setting pending travels
    updater(proposals);

    // Unit travel fetching
    const fetchProposalUnit = async (req?: ProposalRequest) => {
      // Max concurrent tasks reached: wait
      if (concurrentRequests >= maxConcurrentRequests || !req) return;

      // Reserve a concurrent task
      concurrentRequests++;

      // Do job
      let pendingProposal = pendingProposals.find(
        proposal => proposal.id === req.id,
      ) as ProposalRequest;

      try {
        const data = await ReservationService.searchTravel(
          req.search.context,
          req.search.originCode,
          req.search.destinationCode,
          req.search.departureDate,
          req.search.travelClass,
          req.search.productId as string,
          req.search.cardNumber,
          req.search.hasWheelchairPlacement,
        );

        pendingProposal = {
          ...pendingProposal,
          status: 'FULLFILLED',
          travels: data,
          selectedTravel:
            data.find(travel =>
              isAfter(
                new Date(travel.departureDateTime),
                req?.search?.departureDate,
              ),
            ) ?? data[0],
        };
      } catch (err: unknown) {
        let error;
        if (axios.isAxiosError(err)) {
          error = err?.response?.data;
        }
        error = error as {
          errorCode: string;
          message: string;
          details: string;
        };
        if (!error.errorCode)
          error = {
            errorCode: 'UNKNOWN',
            message: 'Unknown error',
            details: 'Unknown error',
          };
        pendingProposal = {
          ...pendingProposal,
          status: 'REJECTED',
          error,
        };
      }

      // Update state value
      updater(proposals =>
        proposals.map(proposal => {
          if (proposal.id === req.id) {
            return { ...proposal, ...pendingProposal };
          } else {
            return proposal;
          }
        }),
      );

      // Decrease concurrent task counter
      concurrentRequests--;

      // Run next tesk if exists
      if (pendingProposals?.length) fetchProposalUnit(pendingProposals.shift());
    };

    // Init first batch of concurrent tasks
    for (let i = 0; i < maxConcurrentRequests; i++) {
      fetchProposalUnit(pendingProposals.shift());
    }
  }

  /**
   * Prepare the travels to search and init all workspaces for each one.
   *
   * @static
   * @param {{
   *     reservationContext: string;
   *     travelClass: string;
   *     productId: string;
   *     reservation: ReservationPreferences;
   *     selectedWeekdays: Date[];
   *   }} {
   *     reservationContext,
   *     travelClass,
   *     productId,
   *     reservation,
   *     selectedWeekdays,
   *   }
   * @return {*}  {ProposalRequest[]}
   * @memberof ReservationService
   */
  static prepareSearchTravels({
    reservationContext,
    travelClass,
    productId,
    reservation,
    selectedWeekdays,
    cardNumber,
  }: {
    reservationContext: string;
    travelClass: string;
    productId: string;
    reservation: ReservationPreferences;
    selectedWeekdays: Date[];
    cardNumber: string;
  }): ProposalRequest[] {
    // Build the list of travels to request
    const list = selectedWeekdays.map(date => {
      // Get preferences for each day
      const proposalDayOfTheWeek = date.getDay() as WeekDay;
      const prefs = reservation.criteriaForWeekDay[proposalDayOfTheWeek];

      // Build search travels params and prepare promises
      const context = reservationContext;
      return [prefs?.departurePreferences, prefs?.returnPreferences].map(
        (_prefs, _idx) => {
          if (!_prefs) return null;

          const originCode = _prefs.origin?.code;
          const destinationCode = _prefs.destination?.code;
          const isOutBound = _idx === 0;
          const hasWheelchairPlacement =
            _prefs.assignmentOption?.hasWheelchairPlacement ?? false;

          const setTime = (date: Date, time: Time) =>
            setHours(setMinutes(date, time.minutes), time.hours);
          const departureDate = setTime(date, _prefs.time);

          return {
            id: [
              originCode,
              destinationCode,
              isOutBound,
              departureDate.valueOf(),
            ].join('-'),
            search: {
              context,
              originCode,
              destinationCode,
              travelClass,
              productId,
              isOutBound,
              departureDate,
              cardNumber,
              hasWheelchairPlacement,
            },
            status: 'PENDING',
            travels: [],
            isChecked: false,
          } as ProposalRequest;
        },
      );
    });

    // Setup travel list
    return list.flat().filter(item => item !== null) as ProposalRequest[];
  }

  static async createTransaction(
    transactionId: string,
    context: string,
    cardNumber: string,
    wishes: Array<BookingWish>,
  ): Promise<void> {
    return StickyGatewayClient.authClient()
      .post<void>(
        '/public/reservation/book-travels',
        new BookTravelsRequest(
          transactionId,
          context,
          cardNumber,
          wishes.map(
            wish =>
              new TransactionProposal(
                wish.proposalId,
                wish.departureDateTime,
                wish.segmentId,
                wish.assignmentOption,
                wish.designatedSeat,
                wish.closeTo,
              ),
          ),
        ),
      )
      .then(response => response.data)
      .catch(error => {
        Logger.error('create transaction error', error);
        throw error;
      });
  }

  static async getTransaction(transactionId: string): Promise<Transaction> {
    return StickyGatewayClient.authClient()
      .get<TransactionResponse>(
        `/public/reservation/get-transaction?transactionId=${transactionId}`,
      )
      .then(response => response.data?.transaction)
      .catch(error => {
        Logger.error('get transaction error', error);
        throw error;
      });
  }

  static async cancelReservations(
    travelsInfo: CancelReservationServicePayload[],
  ): Promise<CancelReservationResponse> {
    return StickyGatewayClient.authClient()
      .post<CancelReservationResponse>(
        `/public/reservation/cancel-reservation`,
        new CancelReservationRequest(travelsInfo),
      )
      .then(response => response.data)
      .catch(error => {
        Logger.error('cancel reservation error', error);
        throw error;
      });
  }

  static async confirmReservation(
    travelsInfo: ConfirmReservationPayload,
  ): Promise<never> {
    return StickyGatewayClient.authClient()
      .post<never>('/public/reservation/travel-confirm', { ...travelsInfo })
      .then(response => response.data)
      .catch(error => {
        Logger.error('confirm reservation error', error);
        throw error;
      });
  }

  static async exchangeConfirm(
    context: string,
    cardNumber: string,
    orderId: string,
    serviceItemId: string,
    itemToExchangeDepartureDateTime: string,
    proposal: Proposal,
  ): Promise<{ status: ExchangeStatus }> {
    return StickyGatewayClient.authClient()
      .post<{ status: ExchangeStatus }>(
        `/public/reservation/exchange-confirm`,
        new ExchangeConfirmRequest(
          context,
          cardNumber,
          orderId,
          serviceItemId,
          itemToExchangeDepartureDateTime,
          proposal,
        ),
      )
      .then(response => response.data)
      .catch(error => {
        Logger.error('exchange confirm error', error);
        throw error;
      });
  }

  static async exchangeSearch(
    customerName: string,
    departureDate: Date,
    destination: string,
    marketingCarrierRef: string,
    orderId: string,
    origin: string,
    cardNumber: string,
    productId: string,
    travelClass: string,
  ): Promise<ExchangeSearchResponse> {
    return StickyGatewayClient.authClient()
      .post<ExchangeSearchResponse>(
        `/public/reservation/exchange-search`,
        new ExchangeSearchRequest(
          customerName,
          formatToISODateHHMM(departureDate),
          destination,
          marketingCarrierRef,
          orderId,
          origin,
          cardNumber,
          productId,
          travelClass,
        ),
      )
      .then(response => response.data)
      .catch(error => {
        Logger.error('exchange search error', error);
        throw error;
      });
  }

  static async printTravel({
    orderId,
    serviceItemId,
    tcn,
  }: PrintTravelParams): Promise<PrintTravelResponse> {
    return StickyGatewayClient.authClient()
      .post<PrintTravelResponse>('/public/reservation/print-travel', {
        orderId,
        serviceItemId,
        tcn,
      })
      .then(response => response.data)
      .catch(error => {
        Logger.error('print travel error', error);
        throw error;
      });
  }

  static async downloadSummary(
    cardNumber: string,
    customerFirstName: string,
    customerLastName: string,
    startDate: string,
    isMaxActifPlus: boolean,
  ): Promise<{ data: string }> {
    return StickyGatewayClient.authClient()
      .post<{ data: string }>('/public/reservation/download-summary', {
        cardNumber,
        customerFirstName,
        customerLastName,
        startDate,
        isMaxActifPlus,
      })
      .then(response => response.data)
      .catch(error => {
        Logger.error('download summary error', error);
        throw error;
      });
  }
}
