import type { PropsWithChildren } from 'react';
import type { BoxProps } from '@mui/system';
import { Box } from '@mui/system';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(({ theme }) => ({
  root: {
    margin: '1.4rem 1rem',

    [theme.breakpoints.up('breakPointDesktop')]: {
      margin: '1.6rem 2rem',
    },
  },
}));

export const PanelContent = ({
  children,
  className,
  ...rest
}: PropsWithChildren<BoxProps>) => {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(classes.root, className)} {...rest}>
      {children}
    </Box>
  );
};
