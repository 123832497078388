/* eslint-disable jsx-a11y/label-has-associated-control */
import type { HTMLAttributes, ReactNode } from 'react';
import { ArrowForwardIosRounded } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { tss } from 'tss-react/mui';

import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';

import { isTMJ, Status, TravelStation } from '../..';

export type TravelSuccess = {
  state: 'success';
  id: string;
  origin: string;
  destination: string;
  departureDate: Date;
  arrivalDate: Date;
  segmentId?: string;
  marketingCarrierRef?: string;
  avantage: boolean;
};

export type TravelError = {
  state: 'error';
  origin: string;
  destination: string;
  departureDate: Date;
  arrivalDate?: Date;
  trainNumber?: string;
  errorMessage: ReactNode;
  avantage: boolean;
};

type TravelLoading = {
  state: 'loading';
  avantage: boolean;
};

export type Travel =
  | TravelSuccess
  | TravelError
  | (TravelLoading & {
      avantage: boolean; // Ajoutez cette ligne
    });

export type TravelDetailsProps = HTMLAttributes<HTMLDivElement> & {
  travel: Travel;
  fieldType?: 'checkbox' | 'radio' | 'none';
  isFullTrain?: boolean;
  selectionDisabled?: boolean;
  showArrow?: boolean;
};

const useStyles = tss.create(
  ({
    theme: {
      breakpoints,
      app: { colors },
      typography: { pxToRem },
    },
  }) => ({
    arrowIcon: {
      height: pxToRem(18),
      width: pxToRem(18),
    },

    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    travelDetails: {
      display: 'flex',
      alignItems: 'flexStart',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      [breakpoints.up('breakPointDesktop')]: {
        flexDirection: 'row',
      },
    },

    status: {
      padding: `${pxToRem(7)} ${pxToRem(15)} ${pxToRem(7)} 0`,
    },

    ticket: {
      flex: '1',
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'column',
      justifyContent: 'center',
      cursor: 'inherit',
    },

    ticketDisabled: {
      opacity: 0.5,
    },

    fullTrain: {
      background: colors.textPrimary,
      borderRadius: '999px',
      color: colors.white,
      fontSize: pxToRem(12),
      fontWeight: 900,
      padding: `${pxToRem(5)} ${pxToRem(9)}`,
      [breakpoints.up('breakPointDesktop')]: {
        fontSize: pxToRem(16),
        padding: `${pxToRem(5)} ${pxToRem(12)}`,
      },
    },

    marketingRef: {
      color: colors.textPrimary,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      alignSelf: 'auto',
      [breakpoints.up('breakPointDesktop')]: {
        alignSelf: 'center',
        fontSize: '14px',
        lineHeight: '19px',
      },
    },
    travelType: {
      color: colors.textPrimary,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      alignSelf: 'auto',
      [breakpoints.up('breakPointDesktop')]: {
        alignSelf: 'center',
        fontSize: '14px',
        lineHeight: '19px',
      },
    },
    marketingRefContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

export const TravelDetails = ({
  travel,
  fieldType = 'checkbox',
  isFullTrain = false,
  selectionDisabled = false,
  showArrow = false,
  ...props
}: TravelDetailsProps) => {
  const { classes } = useStyles();
  const isEnrichmentActivated = getFeatures().enrichment.enabled;

  return (
    <div className={`${classes.container} ${props.className}`}>
      {fieldType !== 'none' && (
        <div className={classes.status}>
          <Status
            fieldType={fieldType}
            travel={travel}
            selectionDisabled={selectionDisabled}
          />
        </div>
      )}
      <div className={classes.travelDetails}>
        <label
          className={
            classes.ticket +
            (selectionDisabled ? ` ${classes.ticketDisabled}` : '')
          }
          htmlFor={travel.state === 'success' ? `travel-${travel.id}` : ''}
        >
          <TravelStation
            travel={travel}
            dateSelector={travel => travel.departureDate}
            stationSelector={travel => travel.origin}
            showError
          />
          <TravelStation
            travel={travel}
            dateSelector={travel => travel.arrivalDate}
            stationSelector={travel => travel.destination}
          />
        </label>
        <div className={classes.marketingRefContainer}>
          {travel.state === 'success' && travel.marketingCarrierRef && (
            <Typography className={classes.marketingRef} component="span">
              {t('reservation.travelDetails.marketingCarrierRef', {
                reference: travel.marketingCarrierRef,
              })}
            </Typography>
          )}
          {isEnrichmentActivated && (
            <Typography
              className={classes.travelType}
              component="span"
              variant="body1"
            >
              {travel.avantage
                ? t('travels.detailsTravel.avantageRate', {
                    brand: isTMJ() ? 'JEUNE' : 'SENIOR',
                  })
                : t('travels.detailsTravel.maxRate', {
                    brand: isTMJ() ? 'JEUNE' : 'SENIOR',
                  })}
            </Typography>
          )}
        </div>
      </div>
      {isFullTrain && (
        <div className={classes.fullTrain}>
          <span>{t('reservation.travelDetails.fullyBooked')}</span>
        </div>
      )}
      {showArrow && (
        <ArrowForwardIosRounded color="primary" className={classes.arrowIcon} />
      )}
    </div>
  );
};
