import type { PropsWithChildren, ReactNode } from 'react';
import { Box, Container } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { ModalServiceTemporaryUnavailable } from '../features/customer/components/modal/ModalServiceTemporaryUnavailable';
import { useAppSelector } from '../hooks';
import { ContentLayout } from '../pages/app-content-layout';
import { ModalExitEnrollment } from '../pages/enrollment/modal-exit-enrollment';
import { LegalMentionsFooterWidget, StepperSubscription } from '..';

type EnrollmentPageLayoutProps = PropsWithChildren<{
  stepper?: ReactNode;
  preventExit?: boolean;
}>;

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { commonSpacing, colors },
  } = theme;

  return {
    container: {
      minHeight: '100wh',
      display: 'grid',
      gridTemplateColumns: '100%',
      gridTemplateAreas: `"stepper"
                          "content"`,
      backgroundColor: colors.disabledBackground,
      [breakpoints.up('breakPointDesktop')]: {
        gridTemplateColumns: '30% auto',
        gridTemplateAreas: '"stepper content"',
        gap: theme.spacing(commonSpacing),
      },
    },
    stepper: {
      backgroundColor: colors.headerBackground,
      height: theme.spacing(20),
      position: 'relative',

      [breakpoints.up('breakPointDesktop')]: {
        gridArea: 'stepper',
        height: '100%',
      },
    },
    content: {
      alignSelf: 'start',
      gridArea: 'content',
      paddingBottom: theme.spacing(commonSpacing),
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(-6),
      minWidth: 0,
      zIndex: 0,

      [breakpoints.up('breakPointDesktop')]: {
        paddingRight: theme.spacing(commonSpacing),
        marginTop: 0,
      },
    },
  };
});

export const EnrollmentPageLayout = ({
  children,
  preventExit = true,
}: EnrollmentPageLayoutProps) => {
  const { classes } = useStyles();
  const proposal = useAppSelector(state => state.proposal);

  return (
    <ContentLayout className={classes.container}>
      <Box className={classes.stepper}>
        <StepperSubscription />
      </Box>

      <Container role="main" id="main" className={classes.content}>
        {preventExit && <ModalExitEnrollment />}

        {children}

        <LegalMentionsFooterWidget
          withRetractation
          withSubscription
          productSelect={proposal?.productSelect}
        />
        <ModalServiceTemporaryUnavailable />
      </Container>
    </ContentLayout>
  );
};
