import { getFeatures } from '@sticky/config';
import { i18n } from '@sticky/i18n';
import type { IHomeBanner } from '@sticky/sticky-common/src/resources/types/banner/homeBanner';

import {
  auth,
  bannerMyCoordinates,
  brand,
  cgv,
  contactFaq,
  disclaimer,
  errorCode,
  errors,
  freePlaces,
  menu,
  proposal,
  reservation,
  subscription,
  travels,
  widgetMyId,
} from './resources/translations/locales/fr/common';
import {
  loadBannerHome,
  loadIdTGVMAX2CgvTranslation,
} from './resources/translations/locales/fr/feature-translations';
import { home } from './resources/translations/locales/fr/pages';
import {
  stepperDocumentsCheck,
  stepperSubscription,
} from './resources/translations/locales/fr/steppers';

const resources = {
  bannerMyCoordinates,
  brand,
  cgv,
  disclaimer,
  home,
  subscription,
  menu,
  contactFaq,
  proposal,
  auth,
  stepperSubscription,
  stepperDocumentsCheck,
  travels,
  errors,
  widgetMyId,
  errorCode,
  reservation,
  'free-places': freePlaces,
};

i18n.addResourceBundle('fr', 'app', resources, true, true);

// Activate banner text only if feature is enabled
if (getFeatures().banners.activeOnHomePage) {
  i18n.addResourceBundle(
    'fr',
    'app',
    {
      bannerHome: loadBannerHome(),
    },
    true,
    true,
  );
}

// Activate banner iDTGVMAX2 CGV text only if feature is enabled
if (getFeatures().idtgvmax2.enabled) {
  loadIdTGVMAX2CgvTranslation().then(result => {
    i18n.addResourceBundle('fr', 'app', result, true, true);
  });
}

type TmjKeys = typeof resources & {
  bannerHome: IHomeBanner[];
  cgvIdtgvmax2: {
    subscriptions: {
      title: string;
      content: string;
      'external-link': string;
    }[];
    receipts: {
      'cgv-link': {
        label: string;
        productId: string;
      };
    };
  };
  contactFaqIdtgvmax2: {
    productName: string;
    cards: {
      contact: {
        phone: {
          description: string;
          number: string;
        };
      };
    };
  };
};

declare module '@sticky/i18n' {
  export interface KeysOverrides extends TmjKeys {}
}

export default i18n;
