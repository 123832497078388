import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Theme } from '@mui/material';
import {
  Card,
  CardActions,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ActionButton } from '@sticky/components';
import { t } from '@sticky/i18n';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { Routes } from '../../../../routes/routes';
import type { IRegularizationInitInvoiceInput } from '../../../subscription/models/invoice';
import {
  clearError,
  clearInvoice,
  clearIsRegularized,
  regularizationInitInvoice,
} from '../../../subscription/store/subcription-slice';

const useStyles = makeStyles()((theme: Theme) => {
  const {
    app: { colors },
  } = theme;
  return {
    card: {
      marginTop: theme.spacing(3),
      height: '100%',
      padding: '2rem',
    },
    paragraph: {
      marginTop: theme.spacing(1),
    },
    title: {
      color: colors.textContrasted,
    },
    button: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
      display: 'contents',
    },
  };
});

export const ErrorInvoicePaymentCard = () => {
  const { classes } = useStyles();

  const theme = useTheme();
  const { breakpoints } = theme;
  const isDesktop = useMediaQuery(breakpoints.up('breakPointDesktop'));
  const navigate = useNavigate();
  const subscriptionState = useAppSelector(state => state.subscription);
  const dispatch = useAppDispatch();

  const retryPayment = () => {
    dispatch(clearError());
    dispatch(clearIsRegularized());
    const invoice = subscriptionState.subscription?.invoices.find(
      s => s.id === subscriptionState.regularizationInfos?.invoiceId,
    );
    const regularizeInvoiceInput: IRegularizationInitInvoiceInput = {
      invoiceId: invoice?.id as string,
      amount: invoice?.balance as number,
      callbackUrl: Routes.getAbsolutePathById(
        'customer/my-subscription/regularization/confirm',
      ),
      accountId: subscriptionState.subscription?.account?.id as string,
    };
    dispatch(regularizationInitInvoice(regularizeInvoiceInput));
  };

  useEffect(() => {
    if (subscriptionState?.subscription?.subscription?.regularization) {
      window.location.href =
        subscriptionState?.subscription?.subscription?.regularization.urlPage;
    }
  }, [dispatch, subscriptionState?.subscription?.subscription?.regularization]);

  const actions = (
    <div className={classes.button}>
      <ActionButton
        variant="outlined"
        onClick={retryPayment}
        loading={subscriptionState.loading}
      >
        {t('actions.retry')}
      </ActionButton>
      <ActionButton
        onClick={() => {
          dispatch(clearError());
          dispatch(clearInvoice());
          navigate(Routes.getPathById('customer/my-subscription', 'invoices'));
        }}
      >
        {t('customer.subscription.regularization.error.back-invoices')}
      </ActionButton>
    </div>
  );

  return (
    <Card className={classes.card}>
      <Typography
        variant={isDesktop ? 'h2' : 'subtitle2'}
        className={classes.title}
      >
        {t('customer.subscription.regularization.error.title')}
      </Typography>
      <Typography
        variant={isDesktop ? 'h6' : 'body2'}
        className={classes.paragraph}
      >
        {t('customer.subscription.regularization.error.subtitle')}
      </Typography>
      <Typography variant={isDesktop ? 'h6' : 'body2'}>
        {t('customer.subscription.regularization.error.retry')}
      </Typography>
      <CardActions>{actions}</CardActions>
    </Card>
  );
};
