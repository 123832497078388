import { useState } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router';

import { ActionButton } from '@sticky/components';
import { Trans } from '@sticky/i18n';

import { Routes } from '../../../routes/routes';
import { ModalBox } from '../modal-box';

interface ModalExitProps {
  title: string;
  children: JSX.Element;
  matchUrls: string[];
  onExit: (pathHistory: string) => void;
  exitLabel?: string | JSX.Element;
}

export const ModalExit = ({
  title,
  children,
  matchUrls,
  onExit,
  exitLabel,
}: ModalExitProps) => {
  const [showModal, setShowModal] = useState(false);
  const [pathHistory, setPathHistory] = useState('');

  useBlocker(({ nextLocation: location }) => {
    const nextLocation =
      Routes.getRouteByPathname(location.pathname)?.path ?? '';
    if (!(showModal || matchUrls.some(url => nextLocation.startsWith(url)))) {
      setPathHistory(nextLocation);
      setShowModal(true);
      return true;
    }
    return false;
  });

  const actionButtons = [
    <ActionButton
      onClick={() => setShowModal(false)}
      key={0}
      variant="outlined"
    >
      <Trans i18nKey="actions.go-back" />
    </ActionButton>,
    <ActionButton onClick={() => onExit(pathHistory)} key={1}>
      {exitLabel ?? <Trans i18nKey="actions.quit" />}
    </ActionButton>,
  ];

  return (
    <ModalBox
      open={showModal}
      onClose={() => setShowModal(false)}
      closeButton="header"
      title={title}
      actions={actionButtons}
    >
      {children}
    </ModalBox>
  );
};
